import React from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import theme from "../../utils/theme";

const BaseButton = styled.button`
  ${theme.buttons.base}
  ${({ size }) => theme.buttons.sizes[size]}
  ${({ variant }) => theme.buttons.variants[variant]}
  ${({ fullwidth }) => (fullwidth ? "width: 100%;" : "")}
  ${({ loading }) => (loading ? "color: transparent !important;" : "")}
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const IconLayout = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform-origin: center center;
    animation: 800ms ${spin} linear infinite, 300ms ${fadeIn} linear;
  }
`;

const SvgLoadingIndicator = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M15 8a7 7 0 1 0-7 7" strokeWidth={1.5} strokeLinecap="round" />
  </svg>
);

function LoadingIndicator() {
  return (
    <IconLayout>
      <SvgLoadingIndicator />
    </IconLayout>
  );
}

function Button({ children, ...props }) {
  return (
    <BaseButton {...props}>
      {props.loading && <LoadingIndicator aria-hidden="true" />}
      {children}
    </BaseButton>
  );
}

Button.defaultProps = {
  size: "medium",
  variant: "primary",
  app: "suite",
  fullwidth: undefined,
  loading: undefined
};

Button.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  app: PropTypes.oneOf(["cma", "streams", "mlx", "attract", "suite", "base"])
    .isRequired,
  fullwidth: PropTypes.bool,
  loading: PropTypes.bool
};

export default Button;
