import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
`;

export const Content = styled.div`
  position: absolute;
  overflow: visible;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
`;
