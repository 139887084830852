import React from "react";
import PropTypes from "prop-types";
import PlanPrice from "../PlanPrice";
import { useSubscriptionWorkflow } from "../SubscriptionWorkflow";

function PlanCardPrice({ planKey }) {
  const planPrice = usePlanPrice(planKey);
  return <PlanPrice planKey={planKey} {...planPrice} />;
}

PlanCardPrice.propTypes = {
  planKey: PropTypes.string.isRequired
};

function usePlanPrice(planKey) {
  const { state } = useSubscriptionWorkflow();
  const {
    discounts,
    addOns,
    plans,
    nonMemberBundles,
    billingCycle
  } = state.context;

  const plan =
    // look for subscriptions that have a billing cycle first (new plans)
    plans.find(
      (plan) => plan.key === planKey && plan.billingCycle === billingCycle
    ) ||
    // look for subscriptions without a billing cycle next (old plans)
    plans.find((plan) => plan.key === planKey) ||
    // if we didn't find it in the plans, then it might be an addOn
    addOns.find((addOn) => addOn.key === planKey) ||
    // finally, look for non-member plans (Attract-only), which are now deprecated
    nonMemberBundles.find((bundle) => bundle.key === planKey) ||
    {};

  const foundDiscount = discounts.byPlanKey[planKey];
  const planPrice = plan.price || 0;
  const finalPrice = foundDiscount
    ? planPrice - foundDiscount.amount
    : planPrice;

  return {
    planPrice,
    finalPrice,
    billingCycle: plan.billingCycle
  };
}

export default PlanCardPrice;
