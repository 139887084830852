import React, { useState, useEffect, useRef } from "react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { getInitials } from "@wrstudios/utils";
import { pickBy, identity, flatten, uniq } from "lodash";
import TextField from "../../common/TextField";
import Button from "../../common/Button";
import ErrorOutline from "../../icons/ErrorOutline";
import UserAvatar from "../UserAvatar";
import { Error, Success } from "./styled/settings";
import { GET_AUTHED_USER } from "../../../api/queries/user";

const GET_STATES = gql`
  query GetStates {
    mls {
      state
    }
  }
`;

const GET_USER = gql`
  query GetUser {
    user {
      id
      avatar {
        small
      }
      name
      email
      phoneNumber: phone_number
      city
      state
      companyName: company_name
      website
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($user: UserRegistrationInput!) {
    updateUser(user: $user) {
      id
      avatar {
        small
      }
      name
      email
      phoneNumber: phone_number
      city
      state
      companyName: company_name
      website
    }
  }
`;

function SettingsProfile() {
  const [avatar, setAvatar] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [originalAvatarUrl, setOriginalAvatarUrl] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [states, setStates] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [updated, setUpdated] = useState(false);
  const [updateUser, { loading, error }] = useMutation(UPDATE_USER, {
    context: { useAuthedEndpoint: true },
    refetchQueries: [
      {
        query: GET_AUTHED_USER,
        context: { useAuthedEndpoint: true }
      }
    ],
    awaitRefetchQueries: true,
    onCompleted: ({ updateUser }) => {
      setUpdated(true);
      setOriginalAvatarUrl(updateUser.avatar ? updateUser.avatar.small : "");
    }
  });
  const isDisabled = !email || loading;
  const { loading: isLoadingUser } = useQuery(GET_USER, {
    context: { useAuthedEndpoint: true },
    onCompleted: ({ user }) => {
      setAvatarUrl(user.avatar ? user.avatar.small : "");
      setOriginalAvatarUrl(user.avatar ? user.avatar.small : "");
      setName(user.name || "");
      setEmail(user.email || "");
      setPhoneNumber(user.phoneNumber || "");
      setCity(user.city || "");
      setState(user.state || "");
      setCompanyName(user.companyName || "");
      setWebsite(user.website || "");
    }
  });

  useQuery(GET_STATES, {
    onCompleted: ({ mls }) => {
      setStates(uniq(flatten(mls.map((mls) => mls.state.split(",")))).sort());
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser({
      variables: {
        user: {
          ...pickBy(
            {
              name,
              email,
              phone_number: phoneNumber,
              city,
              state,
              company_name: companyName,
              website
            },
            identity
          )
        }
      }
    });
  };

  const handleAvatarRemove = () => {
    setAvatar(null);
    setAvatarUrl(null);
    updateUser({
      variables: {
        user: {
          email,
          avatar: null
        }
      }
    });
  };

  const handleAvatarCrop = ({ image, imageUrl }) => {
    if (imageUrl === originalAvatarUrl) return;
    setAvatar(image);
    setAvatarUrl(imageUrl);
    updateUser({
      variables: {
        user: {
          email,
          avatar: image
        }
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <UserAvatar
        key={avatarUrl}
        avatar={avatarUrl}
        initials={getInitials(name)}
        isFetching={isLoadingUser || loading}
        onRemove={handleAvatarRemove}
        onCrop={handleAvatarCrop}
      />
      <TextField>
        <label htmlFor="name">Full Name</label>
        <input
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </TextField>
      <TextField>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </TextField>
      <TextField>
        <label htmlFor="phone-number">Phone Number</label>
        <input
          id="phone-number"
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </TextField>
      <TextField>
        <label htmlFor="city">City</label>
        <input
          id="city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </TextField>
      <TextField>
        <label htmlFor="state">State</label>
        <select
          id="state"
          value={state}
          onChange={(e) => setState(e.target.value)}>
          <option value="">Select a State</option>
          {states.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </TextField>
      <TextField>
        <label htmlFor="company-name">Company Name</label>
        <input
          id="company-name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </TextField>
      <TextField>
        <label htmlFor="website">Website</label>
        <input
          id="website"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </TextField>
      <Button fullwidth disabled={isDisabled} loading={loading}>
        Update Profile
      </Button>
      {error && (
        <Error>
          <ErrorOutline />
          {error.message}
        </Error>
      )}
      {updated && <Success>Updated Profile!</Success>}
    </form>
  );
}

export default SettingsProfile;
