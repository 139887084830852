import styled, { keyframes } from "styled-components";
import theme from "../../../../utils/theme";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2.4rem;

  @media (max-width: 1199px) {
    max-width: 992px;
  }

  @media (max-width: 991px) {
    max-width: 768px;
  }

  @media (max-width: 767px) {
    max-width: 576px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 3rem 0;
  animation: ${fadeIn} 200ms linear;
  @media (max-width: 575px),
    (min-device-width: 414px) and (max-device-width: 736px) /* iPhone 6+ 7+ and 8+ */,
    (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) /* iPhone X */ {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  margin-bottom: 3rem;
  margin-top: 3rem;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 4.8rem;
  color: ${theme.colors.gray[900]};
`;

export const Sidebar = styled.aside`
  flex-shrink: 0;
  margin: 2rem 0;
  margin-left: -11.2rem;
  padding-right: 6rem;
  position: sticky;
  top: 8rem;

  @media (max-width: 991px) {
    margin-left: 0;
  }
  @media (max-width: 575px),
    (min-device-width: 414px) and (max-device-width: 736px) /* iPhone 6+ 7+ and 8+ */,
    (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) /* iPhone X */ {
    margin-top: 0;
    margin-bottom: 3rem;
    padding: 0;
    position: static;
    width: 100%;
  }
`;
export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  @media (max-width: 575px),
    (min-device-width: 414px) and (max-device-width: 736px) /* iPhone 6+ 7+ and 8+ */,
    (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) /* iPhone X */ {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -1rem;
  }
`;

export const ListItem = styled.li`
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  text-align: right;

  a {
    color: ${theme.colors.gray[800]};
    text-decoration: none;

    &[aria-current="page"] {
      color: ${({ theme }) => theme.colors.suite[500]};
    }
  }
  @media (max-width: 575px),
    (min-device-width: 414px) and (max-device-width: 736px) /* iPhone 6+ 7+ and 8+ */,
    (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) /* iPhone X */ {
    margin: 0;
    padding: 1rem;
  }
`;

export const Main = styled.main`
  width: 100%;
  max-width: 60rem;
  padding: 4rem;

  /* TODO: Is this a candidate for a <Well> component? */
  background-color: ${theme.colors.white};
  border: 0.1rem solid ${theme.colors.gray[500]};
  border-radius: 0.6rem;
`;

export const Nav = styled.nav``;

export const Error = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.accent.red};

  svg {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 6px;
    fill: ${({ theme }) => theme.colors.accent.red};
  }
`;

export const Success = styled.div`
  font-size: 13px;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.accent.green};
`;

export const ReturnToAppWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
`;

export const LeftArrowWrapper = styled.div`
  width: 12px;
  margin-right: 11px;
`;

export const ReturnToAppText = styled.div`
  color: ${theme.colors.suite[900]};
  ${theme.text.link};
`;
