import React from "react";
import PropTypes from "prop-types";
import { Transition, animated } from "react-spring";
import { StepSelection as Selection } from "../styled/StepWrapper";

const StepSelection = ({ isCurrent, dataTestId, children }) => (
  <Transition
    native
    items={!isCurrent}
    from={{
      opacity: 0,
      transform: `translateY(40px)`
    }}
    enter={{
      opacity: 1,
      transform: `translateY(0)`
    }}
    leave={{
      opacity: 0,
      transform: `translateY(40px)`
    }}>
    {(show) => (styles) => (
      <Selection data-testid={dataTestId}>
        <animated.p style={{ ...styles, margin: 0 }}>{children}</animated.p>
      </Selection>
    )}
  </Transition>
);

StepSelection.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default StepSelection;
