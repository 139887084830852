import React, { createContext, useContext, useState, useMemo } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import ResourceLoader from "../common/ResourceLoader";

const LaunchpadContext = createContext();

function LaunchpadProvider({ children }) {
  const [user, setUser] = useState();
  const [isSubscribedToHomebeat, setIsSubscribedToHomebeat] = useState();
  const [allProducts, setAllProducts] = useState();
  const [onboardingItems, setOnboardingItems] = useState();

  useQuery(GET_ALL_PRODUCTS_QUERY, {
    onCompleted: (data) => {
      setAllProducts(data.products);
      setOnboardingItems(data.onboardingItems);
    }
  });

  const { refetch } = useQuery(GET_USER_SUBSCRIPTION_AND_ACCOUNTS_QUERY, {
    context: { useAuthedEndpoint: true },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setUser(data.user);
      setIsSubscribedToHomebeat(
        (data.user.addOnSubscriptions || []).some(
          (addOn) => addOn.sku.key === "homebeat"
        )
      );
    }
  });

  // make a hash where key is product_key (ie. cloud_cma) and value is the sorted list of onboarding items for that product
  // ! perhaps we should not rely on the onboarding items coming pre-sorted and should defensively sort using the `sort` key on each item?
  const shortcuts = useMemo(() => {
    if (onboardingItems) {
      return onboardingItems.reduce((hash, onboardingItem) => {
        hash[onboardingItem.product.key] = Array.isArray(
          hash[onboardingItem.product.key]
        )
          ? [...hash[onboardingItem.product.key], onboardingItem]
          : [onboardingItem];
        return hash;
      }, {});
    }
  }, [onboardingItems]);

  return (
    <ResourceLoader isLoading={!user || !allProducts}>
      <LaunchpadContext.Provider
        value={{
          user,
          allProducts,
          shortcuts,
          isSubscribedToHomebeat,
          refetchUserAndSubscription: refetch
        }}>
        {children}
      </LaunchpadContext.Provider>
    </ResourceLoader>
  );
}

export const GET_USER_SUBSCRIPTION_AND_ACCOUNTS_QUERY = gql`
  query getUserSubscriptionAndAccounts {
    user {
      id
      name
      email
      token: jwt
      mls {
        id
        code
        name
        products {
          key
        }
      }
      provisionedAccounts: provisioned_accounts {
        id
        remoteUserEmail: remote_user_email
        provisionedAt: provisioned_at
        product {
          key
        }
      }
      addOnSubscriptions: add_on_subscriptions {
        sku {
          key
        }
      }
      subscription {
        id
        products {
          id
          key
          name
          tagline
        }
      }
    }
  }
`;

export const GET_ALL_PRODUCTS_QUERY = gql`
  query {
    products(show_all: "true") {
      id
      key
      name
      tagline
    }
    onboardingItems: onboarding_items {
      id
      title
      description
      key
      sort
      product {
        id
        key
        name
      }
    }
  }
`;

export const useLaunchpad = () => useContext(LaunchpadContext);
export default LaunchpadProvider;
