import qs from "qs";

export function getProductLaunchUrl({ productId, token, beta = false }) {
  const queryString = qs.stringify(
    {
      jwt: token,
      ...(beta && { beta })
    },
    { addQueryPrefix: true }
  );
  return `/product_login/${productId}${queryString}`;
}

const CMA_URLS_BY_ENV = {
  production: "https://cloudcma.com",
  staging: "https://staging.cloudcma.com",
  development: "http://localhost:3000"
};

const STREAMS_URLS_BY_ENV = {
  production: "https://app.cloudstreams.com",
  staging: "https://app.staging.cloudcma.com",
  development: "http://localhost:3100"
};

const MLX_URLS_BY_ENV = {
  production: "https://app.cloudmlx.com",
  staging: "https://app.staging.cloudmlx.com",
  development: "http://localhost:3201"
};

const ATTRACT_URLS_BY_ENV = {
  production: "https://cloudattract.com",
  staging: "https://staging.cloudattract.com",
  development: "http://localhost:3300"
};

export const APP_INFO = {
  cloud_cma: {
    displayName: "Cloud CMA",
    url: CMA_URLS_BY_ENV[process.env.RAILS_ENV]
  },
  cloud_streams: {
    displayName: "Cloud Streams",
    url: STREAMS_URLS_BY_ENV[process.env.RAILS_ENV]
  },
  cloud_mlx: {
    displayName: "Cloud MLX",
    url: MLX_URLS_BY_ENV[process.env.RAILS_ENV]
  },
  cloud_attract: {
    displayName: "Cloud Attract",
    url: ATTRACT_URLS_BY_ENV[process.env.RAILS_ENV]
  }
};
