import React from "react";
import styled from "styled-components";
import { useField } from "formik";
import theme from "../utils/theme";
import Checkbox from "./common/Checkbox";

export default function TermsOfService() {
  const [field] = useField("tos");
  return (
    <Checkbox {...field}>
      <LabelText>
        I have read and agree with the{" "}
        <a href="/tos" target="_blank" tabIndex={-1}>
          Terms of Service
        </a>
        ,{" "}
        <a
          href="http://wrstudios.com/privacy_policy"
          target="_blank"
          tabIndex={-1}>
          Privacy Policy
        </a>
        , and{" "}
        <a
          href="http://wrstudios.com/dmca_policy"
          target="_blank"
          tabIndex={-1}>
          DMCA Policy
        </a>
      </LabelText>
    </Checkbox>
  );
}

// Styled Components
const LabelText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.gray[800]};

  a {
    color: ${theme.colors.suite[500]};
    text-decoration: underline;
  }
`;
