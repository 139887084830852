import styled, { css } from "styled-components";
import theme from "../utils/theme";

export const ButtonContainer = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 52px;
  padding: 10px;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? theme.colors.gray[300] : theme.colors.white};
`;

export const InnerButton = styled.button`
  width: 100%;
  min-height: 32px;
  padding: 0;
  border: ${({ isHighlighted }) =>
    isHighlighted
      ? css`1px solid ${theme.colors.gray[800]}`
      : css`1px solid ${theme.colors.gray[500]}`};
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? theme.colors.gray[300] : theme.colors.white};
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  color: ${theme.colors.gray[900]};
`;
