import styled, { css } from "styled-components";
import dropdownArrow from "../../images/dropdown-arrow.svg";
import theme from "../../utils/theme";

// ? TODO: should `selectStyles` and `inputStyles` be moved into the `theme` object instead?
// selectStyles can be applied to `select`, `input`, and `button` elements
export const selectStyles = css`
  ${theme.text.body}
  line-height: normal;
  height: 36px;
  border: none;
  border-radius: 3px;
  padding: 0 32px 0 12px;
  box-shadow: inset 0 0 0 1px #e2e5e8, inset 0px 1px 2px rgba(0, 0, 0, 0.1),
    inset 0px 0px 2px rgba(0, 0, 0, 0.2);
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #f9fafb;
  background-image: url(${dropdownArrow});
  background-repeat: no-repeat;
  background-position: center right 11px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 200ms;
  cursor: pointer;

  &:focus {
    box-shadow: inset 0px 0px 0px 1px rgba(10, 161, 220, 1),
      inset 0px 1px 2px rgba(0, 0, 0, 0.1), inset 0px 0px 2px rgba(0, 0, 0, 0.2);
  }

  &:active {
    outline: none;
    border: none;
    box-shadow: inset 0 0 0 1px #e2e5e8, inset 0px 1px 2px rgba(0, 0, 0, 0.1),
      inset 0px 0px 2px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const labelStyles = css`
  font-family: ${theme.fonts.system};
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.gray[800]};
`;

export const inputStyles = css`
  ${theme.text.body}
  height: 36px;
  background: ${theme.colors.white};
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 12px;
  border: none;
  transition: all 200ms;
  box-shadow: inset 0px 0px 0px 1px rgba(226, 229, 232, 1),
    inset 0px 1px 2px rgba(0, 0, 0, 0.1), inset 0px 0px 2px rgba(0, 0, 0, 0.2);
  appearance: none;

  &:focus {
    outline: none;
    box-shadow: inset 0px 0px 0px 1px
        ${({ productKey }) =>
          productKey
            ? theme.colors.utils.getByProductKey(productKey)
            : "rgba(10, 161, 220, 1)"},
      inset 0px 1px 2px rgba(0, 0, 0, 0.1), inset 0px 0px 2px rgba(0, 0, 0, 0.2);
  }

  &::placeholder {
    color: ${theme.colors.gray[600]};
  }

  &:disabled {
    opacity: 0.5;
  }

  &[readonly] {
    background-color: ${theme.colors.gray[200]};
    &:focus {
      box-shadow: inset 0 0 0 1px ${theme.colors.gray[600]},
        inset 0px 1px 2px rgba(0, 0, 0, 0.1),
        inset 0px 0px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;

export default styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;

  label {
    ${labelStyles}
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }

  input {
    ${inputStyles}
  }

  input[role="combobox"],
  select {
    ${selectStyles}

    &[aria-expanded="true"] {
      background-color: white;
    }
  }

  select {
    width: 100%;
    background-color: #fff;
    appearance: none;
    outline: none;
  }
`;
