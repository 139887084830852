import React from "react";

const IconShortcutSearch = (props) => (
  <svg width={25} height={24} fill="none" {...props}>
    <rect x={0.488} width={24} height={24} rx={5} fill="#BAD8F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.688 7a4.2 4.2 0 1 0 0 8.4 4.2 4.2 0 0 0 0-8.4zm-5.4 4.2a5.4 5.4 0 1 1 9.619 3.37l2.205 2.206a.6.6 0 0 1-.849.848l-2.205-2.205a5.4 5.4 0 0 1-8.77-4.22z"
      fill="#3A7ABE"
    />
  </svg>
);

export default IconShortcutSearch;
