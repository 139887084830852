import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import LeftArrow from "../icons/LeftArrow";
import {
  Container,
  PageHeading,
  Card,
  BackLink,
  LeftArrowLayout,
  CardHeading,
  InvoiceTable,
  InvoiceLink
} from "./styled/invoices-route";
import { transformTransactions } from "../../../modules/subscription";

const InvoicesRoute = () => {
  const { loading, transactions } = useInvoices();
  if (loading) return null;
  return (
    <Container>
      <PageHeading>Invoices</PageHeading>
      <Card>
        <BackLink to="/app/billing">
          <LeftArrowLayout>
            <LeftArrow />
          </LeftArrowLayout>
          <u>Back</u>
        </BackLink>
        <CardHeading>Invoice History</CardHeading>
        <InvoiceTable>
          <tbody>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th />
            </tr>
            {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>{transaction.paidOn}</td>
                <td>{transaction.formattedAmount}</td>
                <td className="right">
                  <InvoiceLink to={`/app/billing/invoices/${transaction.id}`}>
                    View Invoice
                  </InvoiceLink>
                </td>
              </tr>
            ))}
          </tbody>
        </InvoiceTable>
      </Card>
    </Container>
  );
};

InvoicesRoute.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      paidOn: PropTypes.string.isRequired,
      formattedAmount: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  )
};

export const GET_SUBSCRIPTION_TRANSACTIONS = gql`
  query getSubscriptionTransactions {
    user {
      id
      name
      email
    }
    subscription {
      id
      transactions {
        id
        amount
        created_at
        sku {
          key
          name
        }
      }
    }
  }
`;
export function useInvoices() {
  const { loading, data } = useQuery(GET_SUBSCRIPTION_TRANSACTIONS, {
    context: { useAuthedEndpoint: true }
  });

  return {
    loading,
    user: data ? data.user : { name: "", email: "" },
    transactions: data
      ? transformTransactions(data.subscription.transactions)
      : []
  };
}

export default InvoicesRoute;
