import React, { useRef, useState, useEffect } from "react";
import format from "date-fns/format";
import styled from "styled-components";
import theme from "../utils/theme";
import Text from "./common/Text";
import { useSubscriptionWorkflow, useIsFreePlan } from "./SubscriptionWorkflow";

export default function TrialDetails() {
  const expanderRef = useRef();
  const [isExpanded, setIsExpanded] = useState(false);
  const { state } = useSubscriptionWorkflow();
  const { selectedPlan, discounts } = state.context;
  const isFreePlan = useIsFreePlan();
  const trialEndDate = format(window.trial_end_date, "MMMM DD, YYYY");
  const annualRenewalDate = window.annual_subscription_renewal_date;
  const isAnnual = selectedPlan.key === "suite_yearly";
  const discount = discounts.byPlanKey[selectedPlan.key];
  const price = !!discount
    ? selectedPlan.price - discount.amount
    : selectedPlan.price;
  const paymentInterval = selectedPlan.billingCycle === 12 ? "year" : "month";

  useEffect(() => {
    expanderRef.current && expanderRef.current.focus();
  }, [isExpanded]);

  return (
    <Layout>
      {isFreePlan ? (
        <FinePrint>
          Periodically, you will be sent emails for onboarding, promotional and
          support purposes.
        </FinePrint>
      ) : (
        <>
          <Text variant="heading" as="h6">
            A few details about your free trial
          </Text>
          {isAnnual ? (
            <>
              <FinePrint>
                Here’s the deal, you’ll have full access to the tools in your
                plan through the entire trial period.{" "}
                <strong>
                  Your trial will end at midnight on {trialEndDate}.
                </strong>{" "}
                After that your discounted annual plan will be{" "}
                <strong>
                  ${price} per {paymentInterval}
                </strong>{" "}
                for a period of 12 months ending on {annualRenewalDate}. We will
                send you a reminder email 4 days before the end of your trial.
                Your credit card <strong>will not be billed</strong> if you
                cancel within trial period.
                {!isExpanded && (
                  <ExpandToggle
                    ref={expanderRef}
                    onClick={() => setIsExpanded(!isExpanded)}>
                    More important terms...
                  </ExpandToggle>
                )}
              </FinePrint>
              {isExpanded && (
                <>
                  <FinePrint>
                    Since you're signing up for our discounted annual
                    subscription, after the trial period, you're agreeing to
                    subscribe for a minimum contract term of one year. Your
                    annual plan will be at the discounted rate of ${price} per{" "}
                    {paymentInterval}. If you cancel your service before the
                    one-year period expires, you will remain obligated for the
                    remainder of the monthly installments for the entire
                    one-year contract term.
                  </FinePrint>
                  <FinePrint>
                    Once you've completed your contract term, your annual plan
                    will automatically renew for successive one-year periods,
                    until you terminate the annual subscription by providing us
                    at least thirty (30) days advance notice prior to the end of
                    an annual subscription that you desire to cancel the
                    service.
                    {isExpanded && (
                      <ExpandToggle
                        ref={expanderRef}
                        onClick={() => setIsExpanded(!isExpanded)}>
                        Show less...
                      </ExpandToggle>
                    )}
                  </FinePrint>
                </>
              )}
            </>
          ) : (
            <FinePrint>
              Here’s the deal, you’ll have full access to the tools in your plan
              through the entire trial period.{" "}
              <strong>
                Your trial will end at midnight on {trialEndDate}.
              </strong>{" "}
              After that your plan will be{" "}
              <strong>
                ${price} per {paymentInterval}
              </strong>
              . We will send you a reminder email 4 days before the end of your
              trial. Your credit card <strong>will not be billed</strong> if you
              cancel within trial period. Go get ‘em tiger!
            </FinePrint>
          )}
        </>
      )}
    </Layout>
  );
}

// Styled Components
const Layout = styled.div``;

const FinePrint = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.gray[800]};

  strong {
    font-weight: 500;
    color: ${theme.colors.gray[900]};
  }
`;

const ExpandToggle = styled.button`
  box-sizing: border-box;
  color: #131943;
  font-weight: 400;
  text-decoration: underline;
  border: none;
  background: transparent;
  border-radius: 0px;
  padding: 0 4px;
  font-size: 13px;
  line-height: 22px;

  &:focus {
    outline: 2px solid #9f39f8;
  }
`;
