import styled from "styled-components";

export const GuestpassLayout = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const GuestpassSection = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 92px;

  @media (max-width: 767px) {
    padding-top: 44px;
  }
`;

export const GuestpassContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
`;

export const HomeLink = styled.a`
  display: flex;
  max-height: 32px;
  width: 100%;
`;

export const FlashError = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.accent.red};
  border-radius: 3px;
  padding: 8px 16px;
  box-sizing: border-box;
  color: white;
  text-align: center;
  margin-top: 21px;
`;

export const Invalid = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.accent.red};
  margin-top: 30px;
  text-align: center;
`;
