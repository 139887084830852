import styled from "styled-components";
import { Link as BaseLink } from "@reach/router";

export const Container = styled.div`
  padding: 50px 0;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

export const PageHeading = styled.h1`
  font-family: "Circular";
  font-size: 36px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.suite[900]};
  letter-spacing: 0;
  text-align: center;
  line-height: 41px;
  margin-bottom: 31px;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 20px 40px 40px;
`;

export const BackLink = styled(BaseLink)`
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  transform: translateX(-21px);
`;

export const LeftArrowLayout = styled.div`
  margin-right: 11px;

  svg {
    width: 10px;
    height: 10px;
    fill: ${({ theme }) => theme.colors.suite[900]};
  }
`;

export const CardHeading = styled.h3`
  font-family: Circular;
  font-weight: 500;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.suite[900]};
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 14px;
`;

export const InvoiceTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    th {
      font-family: Circular;
      font-size: 14px;
      font-weight: 400;
      color: #566373;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  th,
  td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #d8d8d8;

    &.right {
      text-align: right;
    }
  }

  tr {
    height: 46px;
  }
`;

export const InvoiceLink = styled(BaseLink)`
  text-decoration: underline;
`;
