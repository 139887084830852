import styled, { css } from "styled-components";
import theme from "../../utils/theme";
import { selectStyles } from "../common/TextField";

export const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 36px;
  cursor: pointer;
`;

export const DropdownInput = styled.input`
  ${selectStyles}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  outline: none;
  z-index: ${({ isOpen, isButton }) => (isOpen && isButton ? "-1" : "0")};
  pointer-events: ${({ readOnly }) => (readOnly ? "none" : "all")};
`;

export const DropdownList = styled.ul`
  display: block;
  position: absolute;
  top: 40px;
  z-index: 10;
  width: 100%;
  max-height: 379px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: white;
  box-shadow: ${theme.shadows.overlay};
  border-radius: 4px;
`;

export const DropdownItem = styled.li`
  ${theme.text.body}
  font-family: ${theme.fonts.system};
  font-weight: 500;
  width: 100%;
  height: 28px;
  padding: 0 10px;
  line-height: 30px;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? theme.colors.gray[400] : theme.colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ empty }) =>
    empty &&
    css`
      opacity: 0.5;
    `};
`;
