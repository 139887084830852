import React from "react";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useIsAuthed } from "../../../utils/auth";
import { useIntercom } from "../../../utils/intercom";
import ErrorBoundary from "../../ErrorBoundary";
import LegacyNavigation from "../../LegacyNavigation";
import Navigation from "../../Navigation";
import Login from "../Login";
import TOSModal from "../TOSAcceptanceWall";
import { Main } from "./styled/authed-routes-with-nav";

function AuthedRoutesWithNav({ children }) {
  const isAuthed = useIsAuthed();
  const { loading, data: currentUser } = useCurrentUser();

  useIntercom(currentUser ? currentUser : {});

  if (loading) return null;

  return isAuthed ? (
    <ErrorBoundary>
      {currentUser.lwaJwt ? <Navigation /> : <LegacyNavigation />}
      {currentUser && !currentUser.newUser && !currentUser.hasAgreedToTos && (
        <TOSModal user={currentUser} />
      )}
      <Main>{children}</Main>
    </ErrorBoundary>
  ) : (
    <Login path="login" />
  );
}

export default AuthedRoutesWithNav;
