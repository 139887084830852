import React from "react";
import { useFormikContext } from "formik";
import { useIsFreePlan, useSubscriptionWorkflow } from "./SubscriptionWorkflow";
import { ButtonLayout, SubmitButton } from "./styled/billing-fields";

const SignupButton = () => {
  const { state, send } = useSubscriptionWorkflow();
  const isSigningUp =
    state.matches("billing.subscription.new.signingUp") ||
    state.matches("billing.subscription.new.signupSuccess");
  const isFreePlan = useIsFreePlan();
  const formikContext = useFormikContext();
  return (
    <ButtonLayout>
      <SubmitButton
        loading={isSigningUp}
        disabled={!formikContext.isValid || isSigningUp}
        onClick={() => send("SIGNUP", { formikContext })}
        data-testid="signup-submit">
        {isFreePlan ? "Activate Free Products" : "Start Free Trial"}
      </SubmitButton>
    </ButtonLayout>
  );
};

export default SignupButton;
