import React from "react";
import ProductBadges from "./ProductBadges";
import PlanCardPrice from "./PlanCard/PlanCardPrice";
import { useSubscriptionWorkflow } from "./SubscriptionWorkflow";
import {
  Layout,
  PlanMiniCard,
  Arrow,
  CardLabel,
  PlanTitle,
  PlanTitleLayout,
  ProductBadgesLayout,
  PriceWrapper
} from "./styled/plan-change-overview";
import PlanPrice from "./PlanPrice";

const PlanChangeOverview = () => {
  const { state } = useSubscriptionWorkflow();
  const { user, subscription, selectedPlan, selectedMls } = state.context;
  const isSubscribedToHomebeat = user.addOns.find(
    (addOn) => addOn.sku && addOn.sku.key === "homebeat"
  );

  const currentPlanProducts = isSubscribedToHomebeat
    ? // if they are subscribed to homebeat, add homebeat key into products array so the icon gets rendered after cloud cma's icon
      subscription.products.reduce(
        (planProducts, product) =>
          planProducts
            .concat(product, product.key === "cloud_cma" && { key: "homebeat" })
            .filter(Boolean),
        []
      )
    : subscription.products;

  const newPlanProducts =
    selectedMls.hasHomebeat && selectedPlan.key !== "site_license"
      ? selectedPlan.products.reduce(
          (planProducts, product) =>
            planProducts
              .concat(
                product,
                product.key === "cloud_cma" && { key: "homebeat" }
              )
              .filter(Boolean),
          []
        )
      : selectedPlan.products;

  return (
    <Layout>
      <PlanMiniCard>
        <CardLabel>Current Plan</CardLabel>
        <PlanTitleLayout>
          <PlanTitle>
            {subscription.sku.groupKey === "cma" && user.mls.hasHomebeat
              ? "Cloud CMA + Homebeat"
              : subscription.sku.name}
          </PlanTitle>
        </PlanTitleLayout>
        <ProductBadgesLayout>
          <ProductBadges products={currentPlanProducts} size={32} />
        </ProductBadgesLayout>
        <PriceWrapper>
          <PlanPrice
            planKey={subscription.sku.key}
            price={subscription.sku.price}
            finalPrice={subscription.sku.price}
            billingCycle={subscription.sku.billingCycle}
          />
        </PriceWrapper>
      </PlanMiniCard>

      <Arrow />

      <PlanMiniCard>
        <CardLabel color="#A33DFC">New Plan</CardLabel>
        <PlanTitleLayout>
          <PlanTitle data-testid="new-plan-title">
            {selectedPlan.groupKey === "cma" && selectedMls.hasHomebeat
              ? "Cloud CMA + Homebeat"
              : selectedPlan.name}
          </PlanTitle>
        </PlanTitleLayout>
        <ProductBadgesLayout>
          <ProductBadges products={newPlanProducts || selectedPlan} size={32} />
        </ProductBadgesLayout>
        <PriceWrapper>
          <PlanCardPrice planKey={selectedPlan.key} />
        </PriceWrapper>
      </PlanMiniCard>
    </Layout>
  );
};

export default PlanChangeOverview;
