import React from "react";
import { Link } from "@reach/router";
import IconComputer from "../icons/IconComputer";
import { Container, Title, Text } from "./styled/resource-error";

function ResourceError() {
  return (
    <Container>
      <Title>Internal Server Error</Title>
      <Text>
        We've encountered an error on our end. We're looking in to it. Let's
        take you back <Link to="/">home</Link>.
      </Text>
      <IconComputer />
    </Container>
  );
}

export default ResourceError;
