import React from "react";

const IconPlus = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M13.5 10.5H24v3H13.5V24h-3V13.5H0v-3h10.5V0h3v10.5z"
      fillRule="evenodd"
    />
  </svg>
);

export default IconPlus;
