import {
  ApplicationSwitcher,
  Navbar,
  NavbarAdditionalItems,
  NavbarHelpLink,
  NavbarNavigationBar,
  NavbarUserMenu
} from "@helix/navbar";
import PlatformService from "@lwolf-common/platform-service/build/PlatformService";
import { Link } from "@reach/router";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useCurrentUser from "../hooks/useCurrentUser";
import { config } from "../utils/lw-platform-config";

export default function Navigation() {
  const { data: currentUser } = useCurrentUser();
  const { data: appSwitcher } = useAppSwitcher({ currentUser });

  return (
    <Container id="navigation">
      <Navbar dataLwtId="default">
        <ApplicationSwitcher
          applicationName="Cloud Agent Suite"
          appSections={appSwitcher ? appSwitcher.appSections : []}
          launchpadUrl={config.platformUrl}
        />
        <NavbarNavigationBar
          navItems={[
            { label: "Launchpad", url: "/app/launchpad" },
            {
              label: "My Account",
              url: currentUser.lwaId
                ? `${config.lwAgentUrl}/billing?jwt=${currentUser.lwaJwt}`
                : "/app/billing"
            }
          ]}
          linkWrapper={({ item }) => {
            if (currentUser.lwaId) {
              return <a href={item.url}>{item.label}</a>;
            }
            return <Link to={item.url}>{item.label}</Link>;
          }}
        />
        <NavbarAdditionalItems>
          <NavbarHelpLink
            url="https://learn.cloudagentsuite.com/"
            linkWrapper={({ url, label }) => (
              <a href={url} target="_blank" rel="noreferrer">
                {label}
              </a>
            )}
          />
          <NavbarUserMenu
            id="avatar"
            user={{
              name: currentUser.name,
              email: currentUser.email,
              avatarURL: currentUser.avatarUrl
            }}
            menuItems={[
              {
                label: "Learning Resources",
                url: "https://learn.cloudagentsuite.com/"
              },
              {
                label: "Get Support",
                url: "https://lonewolf.my.site.com/s/cloud-cma-resources"
              },
              { divider: true },
              { label: "Sign Out", url: "/app/logout" }
            ]}
            linkWrapper={({ item }) => {
              const isExternal = ["Learning Resources", "Get Support"].includes(
                item.label
              );

              if (isExternal) {
                return (
                  <a href={item.url} target="_blank" rel="noreferrer">
                    {item.label}
                  </a>
                );
              }

              return <Link to={item.url}>{item.label}</Link>;
            }}
          />
        </NavbarAdditionalItems>
      </Navbar>
    </Container>
  );
}

function useAppSwitcher({ currentUser }) {
  const [data, setData] = useState();

  const { lwaJwt } = currentUser || {};

  useEffect(() => {
    if (!lwaJwt) {
      return;
    }

    PlatformService.initialize({
      lwDomain: config.lwUrl,
      token: lwaJwt,
      appId: "cas"
    })
      .then((platformService) => platformService.getAppSections())
      .then((appSections) => setData({ appSections }));
  }, [lwaJwt]);

  return { data };
}

export const Container = styled.div`
  margin-bottom: 3rem;
  position: sticky;
  top: 0;
  z-index: 1000;

  .label {
    font-size: 1.4rem !important;
  }

  .description {
    font-size: 1.2rem !important;
  }
`;
