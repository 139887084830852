import { css } from "styled-components";
import colors from "./colors";
import shadows from "./shadows";
import breakpoints, { sizes } from "./breakpoints";

export default {
  breakpoints,
  shadows,
  colors,
  sizes,
  fonts: {
    system: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    marketing: `Circular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
  },
  text: {
    // Design System Values - BEGIN //
    code: css`
      font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
        monospace;
      font-size: 12px;
      line-height: 20px;
      color: ${colors.gray[900]};

      @media (max-width: 576px) {
        font-size: 13px;
      }
    `,
    small: css`
      font-size: 12px;
      line-height: 16px;
      color: ${colors.gray[900]};

      @media (max-width: 576px) {
        font-size: 13px;
        line-height: 17px;
      }
    `,
    body: css`
      font-size: 14px;
      line-height: 20px;
      color: ${colors.gray[900]};

      @media (max-width: 576px) {
        font-size: 15px;
      }
    `,
    subheading: css`
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: ${colors.gray[900]};

      @media (max-width: 576px) {
        font-size: 13px;
        line-height: 17px;
      }
    `,
    heading: css`
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: ${colors.gray[900]};

      @media (max-width: 576px) {
        font-size: 17px;
        line-height: 24px;
      }
    `,
    displayExtraSmall: css`
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: ${colors.gray[900]};

      @media (max-width: 576px) {
        font-size: 15px;
        line-height: 18px;
      }
    `,
    displaySmall: css`
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: ${colors.gray[900]};

      @media (max-width: 576px) {
        font-size: 16px;
        line-height: 20px;
      }
    `,
    displayMedium: css`
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      color: ${colors.gray[900]};

      @media (max-width: 576px) {
        font-size: 20px;
        line-height: 24px;
      }
    `,
    displayLarge: css`
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      color: ${colors.gray[900]};

      @media (max-width: 576px) {
        font-size: 24px;
        line-height: 29px;
      }
    `,
    displayExtraLarge: css`
      font-weight: 600;
      font-size: 48px;
      line-height: 52px;
      color: ${colors.gray[900]};

      @media (max-width: 576px) {
        font-size: 32px;
        line-height: 35px;
      }
    `,
    // Design System Values - END   //
    base: css`
      color: ${colors.gray[500]};
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    `,
    link: css`
      color: ${colors.suite[600]};
      font-size: 16px;
      line-height: 24px;
      text-decoration: underline;
    `,
    small: css`
      color: ${colors.gray[500]};
      font-size: 14px;
      line-height: 20px;
    `,
    micro: css`
      color: ${colors.gray[500]};
      font-size: 12px;
      line-height: 18px;
    `
  },
  heading: {
    3: css`
      color: ${colors.suite[900]};
      font-size: 24px;
      line-height: 41px;
      font-weight: 500;
    `
  },
  buttons: {
    base: css`
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-weight: 600;
      overflow: hidden;
      outline: none;
      border: none;
      transition: all 300ms;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    `,
    sizes: {
      small: css`
        padding: 5px 8px;
        font-size: 14px;
        line-height: 18px;
        border-radius: 3px;
      `,
      medium: css`
        padding: 9px 16px;
        font-size: 14px;
        line-height: 18px;
        border-radius: 4px;
      `,
      large: css`
        padding: 11px 20px;
        font-size: 16px;
        line-height: 24px;
        border-radius: 4px;
      `
    },
    variants: {
      base: css`
        box-shadow: ${shadows.raised};
        background-color: white;
        color: ${colors.gray[900]};
        &:hover:not(:disabled) {
          background-color: ${colors.gray[300]};
        }
        &:focus {
          background-color: ${colors.gray[300]};
          box-shadow: 0 0 0 2px ${colors.gray[700]};
        }
        &:active {
          background-color: ${colors.gray[300]};
          box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1),
            inset 0px 0px 2px rgba(0, 0, 0, 0.2);
        }
        svg {
          stroke: ${colors.gray[900]};
        }
      `,
      primary: css`
        box-shadow: ${shadows.raised};
        background-color: ${({ app }) => colors[app][700]};
        color: ${colors.white};
        &:hover:not(:disabled) {
          background-color: ${({ app }) => colors[app][500]};
        }
        &:focus {
          background-color: ${({ app }) => colors[app][500]};
          box-shadow: 0 0 0 2px ${({ app }) => colors[app][700]};
        }
        &:active {
          background-color: ${({ app }) => colors[app][600]};
          box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1),
            inset 0px 0px 2px rgba(0, 0, 0, 0.2);
        }
        svg {
          stroke: white;
        }
      `,
      secondary: css`
        background-color: transparent;
        box-shadow: inset 0 0 0 1px ${({ app }) => colors[app][600]};
        color: ${({ app }) => colors[app][600]};
        &:hover:not(:disabled) {
          box-shadow: inset 0 0 0 1px ${({ app }) => colors[app][500]};
        }
        &:focus {
          box-shadow: inset 0 0 0 1px ${({ app }) => colors[app][500]},
            0 0 0 2px ${({ app }) => colors[app][400]};
        }
        &:active {
          box-shadow: inset 0 0 0 1px ${({ app }) => colors[app][600]};
          color: ${({ app }) => colors[app][700]};
        }
        svg {
          stroke: ${({ app }) => colors[app][600]};
        }
      `,
      tertiary: css`
        background-color: transparent;
        box-shadow: none;
        color: ${({ app }) => colors[app][600]};
        &:hover:not(:disabled) {
          color: ${({ app }) => colors[app][700]};
          background-color: transparent;
        }
        &:focus {
          background-color: ${colors["gray"][100]};
          box-shadow: inset 0 0 0 1px ${({ app }) => colors[app][500]},
            0 0 0 2px ${({ app }) => colors[app][400]};
        }
        &:active {
          box-shadow: inset 0 0 0 1px ${({ app }) => colors[app][600]};
          color: ${({ app }) => colors[app][700]};
        }
        svg {
          stroke: ${({ app }) => colors[app][600]};
        }
      `
    }
  }
};
