import { Link } from "@reach/router";
import React, { useState } from "react";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { APP_INFO } from "../../../utils/interProduct";
import { translate } from "../../../utils/locale";
import { config } from "../../../utils/lw-platform-config";
import { useQueryString } from "../../../utils/url";
import LeftArrow from "../icons/LeftArrow";
import {
  Container,
  Header,
  LeftArrowWrapper,
  List,
  ListItem,
  Main,
  Nav,
  ReturnToAppText,
  ReturnToAppWrapper,
  Sidebar,
  Title,
  Wrapper
} from "./styled/settings";

function Settings({ children }) {
  const params = useQueryString();
  const returnToAppKey = params.return_to_app;
  const [returnToApp] = useState(APP_INFO[returnToAppKey]);
  const { data: currentUser } = useCurrentUser();

  return (
    <Wrapper>
      {returnToApp && (
        <a href={`${returnToApp.url}`}>
          <ReturnToAppWrapper>
            <LeftArrowWrapper>
              <LeftArrow />
            </LeftArrowWrapper>
            <ReturnToAppText>{`Back to ${returnToApp.displayName}`}</ReturnToAppText>
          </ReturnToAppWrapper>
        </a>
      )}
      <Header>
        <Title>Account Settings</Title>
      </Header>
      <Container>
        <Sidebar>
          <Nav>
            <List>
              <ListItem>
                <Link to="/app/settings">Profile</Link>
              </ListItem>
              <ListItem>
                <Link to="/app/settings/password">Password</Link>
              </ListItem>
              <ListItem>
                <Link to="/app/settings/mls">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translate("mls.label", "MLS").replace(
                        "®",
                        "<sup>®</sup>"
                      )
                    }}
                  />{" "}
                  Credentials
                </Link>
              </ListItem>
              {currentUser.lwaJwt && (
                <ListItem>
                  <a
                    href={`${config.lwAgentUrl}/billing?jwt=${currentUser.lwaJwt}`}>
                    Billing
                  </a>
                </ListItem>
              )}
            </List>
          </Nav>
        </Sidebar>
        <Main>{children}</Main>
      </Container>
    </Wrapper>
  );
}

export default Settings;
