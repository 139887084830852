import React from "react";

const IconShortcutBuyerActivity = (props) => (
  <svg width={25} height={24} fill="none" {...props}>
    <rect x={0.744} width={24} height={24} rx={5} fill="#B4E9FD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.044 7c-1.51 0-2.7 1.196-2.7 2.757 0 .958.418 1.874 1.274 2.938.862 1.073 2.106 2.224 3.662 3.661l.464.428.463-.427c1.556-1.438 2.8-2.589 3.663-3.662.855-1.064 1.274-1.98 1.274-2.938 0-1.561-1.19-2.757-2.7-2.757-.86 0-1.696.41-2.241 1.059a.6.6 0 0 1-.919 0A2.976 2.976 0 0 0 10.044 7zm-3.9 2.757c0-2.205 1.708-3.957 3.9-3.957 1.003 0 1.965.386 2.7 1.021a4.158 4.158 0 0 1 2.7-1.021c2.192 0 3.9 1.752 3.9 3.957 0 1.346-.602 2.523-1.539 3.69-.924 1.15-2.232 2.358-3.75 3.76l-.034.031-.87.803a.6.6 0 0 1-.814 0l-.904-.834c-1.519-1.402-2.827-2.61-3.75-3.76-.938-1.167-1.54-2.344-1.54-3.69z"
      fill="#0AA1DC"
    />
  </svg>
);

export default IconShortcutBuyerActivity;
