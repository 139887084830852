import { useEffect } from "react";
import { INTERCOM_APP_ID } from "../constants/intercom";
import { IS_PROD } from "../constants/config";

export function useIntercom({ email, intercom_user_hash }) {
  useEffect(() => {
    if (!intercom_user_hash || !IS_PROD) return;

    if (window.Intercom) {
      window.Intercom("boot", {
        app_id: INTERCOM_APP_ID,
        user_id: email,
        user_hash: intercom_user_hash
      });
    }

    return () => {
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
    };
  }, [intercom_user_hash]);
}
