import { navigate } from "@reach/router";
import gql from "graphql-tag";
import Cookies from "js-cookie";
import { keyBy } from "lodash";
import { actions, assign, Machine } from "xstate";
import yup from "yup";
import { SIGN_UP_USER_MUTATION } from "../graphql/mutations";
import { transformCheckoutError } from "../modules/checkout";
import { indexDiscountsByPlanKey } from "../modules/products";
import { sendSignupEventToAnalytics } from "../utils/analytics";
import airbrake from "../utils/errors";
import { translate } from "../utils/locale";
const { log } = actions;

// Some users will not have an MLS, so we represent their "mls" with this object:
const NULL_MLS = {
  code: "null_mls",
  name: `My ${translate("mls.label", "MLS")} isn’t on the list 🤔`
};

// Form Validation Errors
export const VALIDATION_ERRORS = Object.freeze({
  INVALID_EMAIL: "Invalid email",
  EMPLOYEE_EMAIL: "Must use employee email",
  EMAIL_TAKEN: "Email already exists",
  REQUIRED: "Required",
  TOS_ACCEPTANCE: "Please accept the TOS.",
  // We use the Braintree Payment Field keys as the validation error keys for easy access
  number: "Invalid card number",
  expirationDate: "Invalid expiration date",
  cvv: "Invalid CVV",
  postalCode: "Invalid postal code"
});

// This cookie is stored when a user SSO's from their MLS system
const cookiedMlsId = Cookies.get("mls_id");

// all of the business logic of our checkout/change-plan process is controlled by this finite state machine
export default Machine({
  id: "subscriptionWorkflow",
  context: {
    apiClient: null,
    mls: [],
    addOns: [],
    plans: [],
    promo: "",
    products: {},
    discounts: {
      byPlanKey: {}
    },
    cookiedMlsId,
    mode: undefined,
    allowMlsEdit: !cookiedMlsId, // If user SSO'ed in from their MLS System, we disallow editing of the MLS field
    selectedTerritory: "",
    nullMls: NULL_MLS,
    selectedMls: {},
    billingCycle: 1, // monthly: 1, annual: 12
    selectedPlan: {
      price: undefined,
      products: []
    },
    bestSavingsAvailable: undefined,
    subscription: {},
    braintreeToken: window.braintree_token || "",
    braintreeTokenRef: null,
    user: {
      name: "",
      email: "",
      phone: ""
    },
    validationSchema: null,
    validationErrors: VALIDATION_ERRORS,
    signupErrors: null,
    changePlanErrors: null
  },
  initial: "loading",
  states: {
    loading: {
      entry: "generateValidationSchema",
      initial: "unknown",
      states: {
        unknown: {
          on: {
            "": [
              { target: "preloadMlsPlans", cond: "hasMlsUrlParam" },
              { target: "preloadMlsPlans", cond: "hasCookiedMlsId" },
              { target: "loadPlan", cond: "isEditingSubscription" },
              { target: "loadCheckout" }
            ]
          }
        },
        preloadMlsPlans: {
          invoke: {
            src: "getPlansForSelectedMls",
            onDone: {
              target: "ready",
              actions: "mergeResultIntoContext"
            },
            onError: {
              actions: log((_, event) => event, "preloadMlsPlans.error")
            }
          }
        },
        loadPlan: {
          invoke: {
            id: "getUserSubscription",
            src: "getUserSubscription",
            onDone: [
              {
                target: "ready",
                cond: "hasPlanInUrl",
                actions: ["mergeResultIntoContext", "selectPlanFromUrlParam"]
              },
              {
                target: "ready",
                actions: "mergeResultIntoContext"
              }
            ],
            onError: {
              actions: log((...args) => args, "getUserSubscription.error")
            }
          }
        },
        loadCheckout: {
          invoke: {
            id: "initNewSubscriptionWorkflow",
            src: "initNewSubscriptionWorkflow",
            onDone: {
              target: "ready",
              actions: "mergeResultIntoContext"
            },
            onError: {
              actions: log(
                (...args) => args,
                "initnewSubscriptionWorkflow.error"
              )
            }
          }
        },
        ready: { type: "final" }
      },
      onDone: [
        { target: "billing", cond: "foundPlanMatchingUrlParam" },
        { target: "account", cond: "hasCookiedMlsId" },
        { target: "account", cond: "isAttractOnlySubscriber" },
        { target: "plan", cond: "isEditingSubscription" },
        { target: "account" }
      ]
    },
    account: {
      on: {
        SET_USER_FIELD: {
          actions: assign({
            user: (context, event) => ({
              ...context.user,
              [event.fieldKey]: event.value
            })
          })
        },
        SELECT_TERRITORY: {
          actions: assign({ selectedTerritory: (_, event) => event.territory })
        },
        SELECT_MLS: {
          target: "account.fetchingPlans",
          actions: assign({ selectedMls: (_, event) => event.selectedMls })
        },
        VIEW_MLS_PLANS: { target: "plan", actions: "createAgentLead" }
      },
      initial: "dataEntry",
      states: {
        dataEntry: {},
        fetchingPlans: {
          invoke: {
            src: "getPlansForSelectedMls",
            onDone: {
              target: "dataEntry",
              actions: "mergeResultIntoContext"
            },
            onError: "dataEntry"
          }
        },
        hist: { type: "history" }
      }
    },
    plan: {
      on: {
        "BILLING_CYCLE.SET": {
          actions: assign({ billingCycle: (_, event) => event.value })
        },
        SELECT_PLAN: {
          target: "billing",
          actions: assign({ selectedPlan: (_, event) => event.selectedPlan })
        },
        SET_BEST_SAVINGS: {
          actions: assign({
            bestSavingsAvailable: (_, event) => event.bestSavingsAvailable
          })
        }
      }
    },
    billing: {
      type: "parallel",
      states: {
        promoCode: {
          initial: "dataEntry",
          states: {
            dataEntry: {
              on: {
                APPLY_PROMOCODE: {
                  target: "fetching",
                  actions: assign({ promo: (_, event) => event.promo })
                }
              }
            },
            fetching: {
              invoke: {
                src: "getDiscounts",
                onDone: {
                  target: "dataEntry",
                  actions: assign({
                    discounts: (_, event) => event.data.discounts
                  })
                }
              }
            }
          }
        },
        subscription: {
          initial: "unknown",
          states: {
            unknown: {
              on: {
                "": [
                  {
                    target: "new",
                    cond: ({ mode }) => mode === "new"
                  },
                  { target: "edit" }
                ]
              }
            },
            new: {
              initial: "dataEntry",
              states: {
                dataEntry: {
                  on: {
                    SIGNUP: {
                      target: "signingUp",
                      actions: assign({
                        formikContext: (_, { formikContext }) => formikContext
                      })
                    }
                  }
                },
                signingUp: {
                  invoke: {
                    src: "signUpUser",
                    onDone: {
                      target: "signupSuccess",
                      actions: ["addAuthTokenToLocalStorage"]
                    },
                    onError: {
                      target: "dataEntry",
                      actions: ["handleSignupError", "reportSignupError"]
                    }
                  }
                },
                signupSuccess: { type: "final" }
              },
              onDone: {
                actions: () => {
                  window.location.replace(
                    `${window.location.origin}/app/thanks`
                  );
                }
              }
            },
            edit: {
              initial: "review",
              states: {
                review: {
                  on: {
                    UPGRADE_TO_PAID_PLAN: "upgradingToPaidPlan",
                    CHANGE_PLAN: "changingPlan"
                  }
                },
                upgradingToPaidPlan: {
                  invoke: {
                    src: "upgradeToPaidPlan",
                    onDone: "editSuccess",
                    onError: {
                      target: "review",
                      actions: "handleChangePlanError"
                    }
                  }
                },
                changingPlan: {
                  invoke: {
                    src: "changePlan",
                    onDone: "editSuccess",
                    onError: {
                      target: "review",
                      actions: "handleChangePlanError"
                    }
                  }
                },
                editSuccess: { type: "final" }
              },
              onDone: {
                actions: () => navigate("/app/billing")
              }
            }
          }
        }
      }
    }
  },
  on: {
    EDIT_MLS: "account.hist",
    EDIT_PLAN: "plan",
    SET_BRAINTREE_TOKEN_REF: {
      actions: assign({ braintreeTokenRef: (_, event) => event.tokenRef })
    }
  }
});

export const machineConfig = {
  actions: {
    selectPlanFromUrlParam: assign((context) => {
      const targetPlan = context.plans.find(
        (plan) => plan.key === context.planUrlParam
      );
      const foundPlanMatchingUrlParam = Boolean(targetPlan);
      return {
        ...context,
        selectedPlan: foundPlanMatchingUrlParam ? targetPlan : {},
        foundPlanMatchingUrlParam
      };
    }),
    generateValidationSchema: assign({
      validationSchema: (context) =>
        yup.object().shape({
          name: yup.string().required(VALIDATION_ERRORS.REQUIRED),
          email: yup
            .string()
            .email(VALIDATION_ERRORS.INVALID_EMAIL)
            .required(VALIDATION_ERRORS.REQUIRED)
            .when("promo", {
              is: (value) => value === "wrstudios",
              then: yup.string().matches(/^\S+@(wr-?studios|lwolf)\.com$/, {
                message: VALIDATION_ERRORS.EMPLOYEE_EMAIL
              })
            })
            .test({
              name: "emailIsTaken",
              message: VALIDATION_ERRORS.EMAIL_TAKEN,
              test: async function isUserUnique(email = "") {
                if (!email || context.mode === "edit") return true;
                if (yup.string().email().isValidSync(email)) {
                  const response = await context.apiClient.query({
                    query: USER_EXISTS_QUERY,
                    variables: { email }
                  });
                  return !response.data.userExists;
                }
              }
            }),
          phone: yup
            .string()
            .matches(
              /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
              "Invalid Phone Number"
            ),
          promo: yup.string(),
          price: yup.number(),
          tos: yup.bool().oneOf([true]),
          // All payment fields are validated only if the plan has a price above 0
          ...["number", "expirationDate", "cvv", "postalCode"].reduce(
            (paymentFieldValidations, field) => {
              paymentFieldValidations[field] = yup.bool().when("price", {
                is: (value) => value > 0,
                then: yup
                  .bool()
                  .required()
                  .oneOf([true], VALIDATION_ERRORS[field])
              });
              return paymentFieldValidations;
            },
            {}
          )
        })
    }),
    // When using this action, ensure that the keys on event.data match the keys within machine.context exactly
    mergeResultIntoContext: assign((context, event) => ({
      ...context,
      ...event.data
    })),
    createAgentLead: ({ apiClient, selectedMls, user, mode }) => {
      // Only capture leads on new signups (not on plan changes)
      if (mode === "new") {
        apiClient
          .mutate({
            mutation: CREATE_AGENT_LEAD_MUTATION,
            variables: {
              name: user.name,
              email: user.email,
              phone_number: user.phone,
              mls: {
                id: Number(selectedMls.id)
              }
            }
          })
          .catch((error) => airbrake.notify({ error }));
      }
    },
    handleSignupError: assign({
      signupErrors: (context, event) => {
        const transformedErrors = event.data.graphQLErrors.reduce(
          (errors, graphQLError) => {
            const parsedErrors = JSON.parse(graphQLError.message).map(
              (parsedError) => transformCheckoutError(parsedError)
            );
            return errors.concat(parsedErrors);
          },
          []
        );
        return transformedErrors;
      }
    }),
    reportSignupError: (context) => {
      airbrake.notify({ error: context.signupErrors });
    },
    addAuthTokenToLocalStorage: (_, event) => {
      localStorage.setItem("token", event.data.token);
    },
    handleChangePlanError: assign({
      changePlanErrors: (_, event) => {
        return event.data.graphQLErrors.map((graphQLError) => {
          let formattedError;
          try {
            let parsedError = JSON.parse(graphQLError.message);
            formattedError = parsedError
              .flatMap((messageArr) => messageArr.map((msg) => msg.message))
              .filter(Boolean)
              .join(", ");
          } catch (error) {
            formattedError = "An unknown error occurred";
          }
          return formattedError;
        });
      }
    })
  },

  guards: {
    hasPlanInUrl: (context) => Boolean(context.planUrlParam),
    foundPlanMatchingUrlParam: (context) => !!context.foundPlanMatchingUrlParam,
    isAttractOnlySubscriber: (context) =>
      context.selectedPlan.key === "cloud_attract" ||
      context.selectedPlan.groupKey === "attract",
    isEditingSubscription: (context) => context.mode === "edit",
    preloadedMls: (context) =>
      Boolean(context.mlsUrlParam || context.cookiedMlsId),
    hasMlsUrlParam: (context) => Boolean(context.mlsUrlParam),
    hasCookiedMlsId: (context) => Boolean(context.cookiedMlsId),
    isSiteLicensedMls: (context) =>
      context.selectedMls.territory.includes("SL"),
    isAttractOnlySubscriber: (context) =>
      context.subscription.sku &&
      context.subscription.sku.key === "cloud_attract",
    isViewingPlansForCurrentMls: (context) =>
      context.mode === "edit" &&
      context.user.mls.code === context.selectedMls.code
  },

  services: {
    getUserSubscription,
    initNewSubscriptionWorkflow,
    getPlansForSelectedMls,
    upgradeToPaidPlan,
    getDiscounts,
    changePlan,
    signUpUser
  }
};

export const GET_CHECKOUT_CONFIG_QUERY = gql`
  query getCheckoutConfig {
    products(show_all: "true") {
      id
      key
      name
      price
      value
      tagline
    }
    bundles {
      id
      key
      name
      tagline
      groupKey: group_key
      price
    }
    addOns: add_ons {
      id
      key
      name
      tagline
      price
      billingCycle: billing_cycle
    }
    mls {
      id
      code
      name
      isChild: is_child
      territory: territory_code
      state
    }
  }
`;

export const USER_EXISTS_QUERY = gql`
  query userExists($email: String) {
    userExists: user_exists(email: $email) {
      email
      mls {
        name
      }
    }
  }
`;

export const GET_USER_PLAN = gql`
  query getUserPlan {
    user {
      id
      name
      email
      phone: phone_number
      mls {
        id
        code
        name
        state
        territory: territory_code
        hasHomebeat: has_homebeat
        bundles {
          id
          key
          name
          tagline
          price
          groupKey: group_key
          billingCycle: billing_cycle
          products {
            key
            name
          }
        }
      }
      payment {
        cardType: card_type
        expirationDate: expiration_date
        maskedNumber: masked_number
      }
      addOns: add_on_subscriptions {
        id
        sku {
          id
          key
        }
      }
      guestPasses: guest_passes {
        guestPassId: id
        skuable {
          id
        }
        status
      }
      subscription {
        id
        status
        nextBillDate: next_bill_date
        nextBillingPeriodAmount: next_billing_period_amount
        price
        sku {
          id
          key
          name
          type
          groupKey: group_key
          billingCycle: billing_cycle
          price
        }
        discount {
          id
          code
          amount
        }
        products {
          key
        }
      }
    }
  }
`;

export const GET_PLANS_FOR_SELECTED_MLS = gql`
  query getPlansForSelectedMls(
    $mlsCode: String
    $mlsId: ID
    $promoCode: String
  ) {
    selectedMls: mls(code: $mlsCode, id: $mlsId) {
      id
      code
      name
      state
      territory: territory_code
      hasHomebeat: has_homebeat
      displayUpsellOptions: display_upsell_options
      products {
        key
      }
      bundles {
        id
        key
        name
        tagline
        price
        virtual
        groupKey: group_key
        billingCycle: billing_cycle
        products {
          key
          name
        }
      }
    }
    discounts(code: $promoCode) {
      id
      code
      amount
      bundles {
        key
      }
      addOns: add_ons {
        key
      }
    }
    products(show_all: "true") {
      id
      key
      name
      price
      value
      tagline
    }
    mls {
      id
      code
      name
      isChild: is_child
      territory: territory_code
      state
    }
    addOns: add_ons {
      id
      key
      name
      tagline
      price
      billingCycle: billing_cycle
    }
  }
`;

export const CREATE_AGENT_LEAD_MUTATION = gql`
  mutation createAgentLead(
    $name: String
    $email: String!
    $phone_number: String
    $mls: MlsInput!
  ) {
    createAgentLead(
      name: $name
      email: $email
      phone_number: $phone_number
      mls: $mls
    ) {
      id
      email
      name
    }
  }
`;

export const GET_DISCOUNTS = gql`
  query getDiscounts($code: String) {
    discounts(code: $code) {
      id
      code
      amount
      bundles {
        key
      }
      addOns: add_ons {
        key
      }
    }
  }
`;

export const CHANGE_USER_PLAN = gql`
  mutation changeUserPlan(
    $sku: SkuInput!
    $payment_method_nonce: String
    $discount_id: ID
    $mls_id: ID
    $source: ChangePlanSource!
  ) {
    changeUserPlan(
      sku: $sku
      payment_method_nonce: $payment_method_nonce
      discount_id: $discount_id
      mls_id: $mls_id
      source: $source
    ) {
      id
      status
      sku {
        id
        key
        name
        type
      }
      discount {
        id
        code
        amount
      }
      products {
        key
      }
    }
  }
`;

// Services
async function getUserSubscription(context) {
  const [checkoutConfig, userPlan] = await Promise.all([
    context.apiClient.query({ query: GET_CHECKOUT_CONFIG_QUERY }),
    context.apiClient.query({
      query: GET_USER_PLAN,
      context: { useAuthedEndpoint: true }
    })
  ]);

  const {
    data: { mls, products, bundles, addOns }
  } = checkoutConfig;

  const {
    data: { user }
  } = userPlan;

  const { subscription } = user;

  let promo = undefined;
  let discounts = { byPlanKey: {} };
  if (
    (subscription.discount && subscription.discount.code) ||
    context.promo !== ""
  ) {
    promo = context.promo || subscription.discount.code;
    const { data } = await context.apiClient.query({
      query: GET_DISCOUNTS,
      variables: { code: promo }
    });
    discounts = { byPlanKey: indexDiscountsByPlanKey(data.discounts) };
  }

  const selectedTerritory = mls.state;
  const productsByKey = keyBy(products, "key");
  const selectedMls = user.mls || NULL_MLS;
  const userPlanKey = subscription.sku.key;
  const selectedPlan =
    subscription.sku.type === "Bundle"
      ? user.mls.bundles.find((b) => b.key === userPlanKey) || {
          price: subscription.price,
          products: subscription.products
        }
      : products.find((p) => p.key === userPlanKey);

  return {
    mls,
    promo,
    addOns,
    discounts,
    selectedMls,
    selectedPlan,
    selectedTerritory,
    nonMemberBundles: bundles,
    products: { all: products, byKey: productsByKey },
    // We might have initiated the billingCycle using the `?billing=annual` query string param, so keep it if we did
    billingCycle:
      context.billingCycle === 12 ? 12 : subscription.sku.billingCycle,
    plans: user.mls.bundles,
    subscription,
    user
  };
}

async function initNewSubscriptionWorkflow(context) {
  const {
    data: { products, mls, bundles }
  } = await context.apiClient.query({ query: GET_CHECKOUT_CONFIG_QUERY });

  return {
    mls,
    nonMemberBundles: bundles,
    products: {
      all: products,
      byKey: keyBy(products, "key")
    }
  };
}

async function getPlansForSelectedMls(context) {
  const { data } = await context.apiClient.query({
    fetchPolicy: "network-only",
    query: GET_PLANS_FOR_SELECTED_MLS,
    variables: {
      mlsCode: context.selectedMls.code,
      promoCode: context.promo || "",
      ...(!!context.cookiedMlsId && { mlsId: context.cookiedMlsId })
    }
  });

  const mls = data.mls;
  const addOns = data.addOns;
  const selectedMls = data.selectedMls[0];
  const plans = selectedMls.bundles;
  const discounts = { byPlanKey: indexDiscountsByPlanKey(data.discounts) };
  const selectedTerritory = selectedMls.state.split(",")[0];
  const products = {
    all: data.products,
    byKey: keyBy(data.products, "key")
  };

  return {
    mls,
    addOns,
    selectedMls,
    plans,
    discounts,
    selectedTerritory,
    products
  };
}

async function getDiscounts(context) {
  const {
    data: { discounts }
  } = await context.apiClient.query({
    query: GET_DISCOUNTS,
    variables: { code: context.promo }
  });

  return {
    discounts: {
      byPlanKey: indexDiscountsByPlanKey(discounts)
    }
  };
}

async function upgradeToPaidPlan(context, event) {
  const { changePlanMutation } = event;
  const { selectedMls, selectedPlan, braintreeTokenRef, discounts } = context;
  const foundDiscount = discounts.byPlanKey[selectedPlan.key];
  const { nonce } = await braintreeTokenRef();

  const variables = {
    sku: {
      id: selectedPlan.id,
      type: selectedPlan.key === "homebeat" ? "AddOn" : "Bundle"
    },
    discount_id: foundDiscount ? foundDiscount.id : null,
    mls_id: selectedMls.id,
    payment_method_nonce: nonce,
    source: "change_plan"
  };

  const { data } = await changePlanMutation({ variables });

  return { subscription: data };
}

async function changePlan(context, event) {
  // This mutation will be either "changeUserPlan" or "cancelAddOnSubscription"
  const { mutation } = event;
  const { user, subscription, selectedMls, selectedPlan, discounts } = context;
  const foundDiscount = discounts.byPlanKey[selectedPlan.key];

  const homebeatSubscription = user.addOns.find(
    (addOn) => addOn.sku && addOn.sku.key === "homebeat"
  );

  // In this scenario, the user is subscribed to site license products PLUS a Homebeat Add-On.
  // Their subscription.sku.key will be the same and their selectedPlan.key
  // What they're actually looking to do here is cancel their Homebeat Add-On.
  const isCancellingHomebeatAddOn =
    subscription.sku.key === "site_license" &&
    homebeatSubscription &&
    selectedPlan.key === "site_license";

  const variables = isCancellingHomebeatAddOn
    ? { subscription_id: homebeatSubscription.id }
    : {
        sku: {
          id: selectedPlan.id,
          type: selectedPlan.key === "homebeat" ? "AddOn" : "Bundle"
        },
        discount_id: foundDiscount ? foundDiscount.id : null,
        mls_id: selectedMls.id,
        payment_method_nonce: null,
        source: "change_plan"
      };

  await mutation({ variables });
}

async function signUpUser(context, event) {
  const {
    formikContext: { values: formValues }
  } = event;
  const { apiClient, selectedPlan, selectedMls, discounts, braintreeTokenRef } =
    context;
  const foundDiscount = discounts.byPlanKey[selectedPlan.key];
  const revenue = foundDiscount
    ? selectedPlan.price - foundDiscount.amount
    : selectedPlan.price;

  let nonce = "";
  if (revenue > 0) {
    const braintreePayload = await braintreeTokenRef();
    nonce = braintreePayload.nonce;
  }

  const variables = {
    user: {
      name: formValues.name,
      email: formValues.email,
      phone_number: formValues.phone,
      mls_id: selectedMls.id
    },
    sku: {
      id: selectedPlan.id,
      type: selectedPlan.key === "homebeat" ? "AddOn" : "Bundle"
    },
    payment_method_nonce: nonce,
    discount_id: foundDiscount ? foundDiscount.id : null,
    affiliate_id: Cookies.get("affiliate_id") || null,
    source: "pricing"
  };

  const {
    data: {
      signUpUser: { jwt, id }
    }
  } = await apiClient.mutate({ mutation: SIGN_UP_USER_MUTATION, variables });

  sendSignupEventToAnalytics({
    sku: selectedPlan.key,
    revenue,
    promo: (foundDiscount || {}).code || ""
  });

  return { token: jwt, userId: id };
}
