import styled from "styled-components";
import theme from "../../../utils/theme";
import BaseTextField from "../../common/TextField";
import colors from "../../../utils/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 24px 24px 32px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (max-width: 578px) {
    flex-direction: column;
  }
`;

export const LogoLink = styled.a`
  display: flex;
  align-items: center;

  @media (max-width: 578px) {
    margin-bottom: 12px;
  }

  svg {
    width: 195px;
    height: 25px;
    fill: ${theme.colors.suite[900]};
  }
`;

export const Arrow = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 13px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Register = styled.a`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 5px 12px;
  color: #4a40bf;
  border: 1px solid #4a40bf;
  border-radius: 3px;
  text-decoration: none;
`;

export const Footer = styled.div`
  flex-shrink: 0;
  margin-top: 24px;
  text-align: center;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    margin: 0 12px;
  }

  a {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: underline;
    color: ${colors.gray[800]};
  }
`;

export const CloudIconLayout = styled.a`
  display: block;
  width: 56px;
  margin: 0 auto 42px;

  svg {
    fill: ${theme.colors.suite[900]};
  }
`;

export const Content = styled.div`
  flex-shrink: 0;
  margin: auto 0;
`;

export const Form = styled.form`
  display: flex;
  flex-flow: column;
  align-items: center;

  background-color: white;
  border-radius: 4px;
  border: ${({ isExternalLogin }) => isExternalLogin && "1px solid #e1e5e9"};
  box-shadow: ${({ theme, isExternalLogin }) =>
    isExternalLogin ? "none" : theme.shadows.raised};
  box-sizing: border-box;
  max-width: ${({ isExternalLogin }) => (isExternalLogin ? "340px" : "460px")};
  margin: 0 auto;
  padding: ${({ isExternalLogin }) =>
    isExternalLogin ? "24px 32px" : "30px 15px 40px"};
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  background-color: white;
  border-radius: 4px;
  border: ${({ isExternalLogin }) => isExternalLogin && "1px solid #e1e5e9"};
  box-shadow: ${({ theme, isExternalLogin }) =>
    isExternalLogin ? "none" : theme.shadows.raised};
  box-sizing: border-box;
  max-width: ${({ isExternalLogin }) => (isExternalLogin ? "340px" : "460px")};
  margin: 0 auto;
  padding: ${({ isExternalLogin }) =>
    isExternalLogin ? "24px 32px" : "30px 15px 40px"};
  width: 100%;
`;

export const FormHeading = styled.h3`
  font-size: ${({ isExternalLogin }) => (isExternalLogin ? "24px" : "20px")};
  font-weight: ${({ isExternalLogin }) => (isExternalLogin ? 600 : 400)};
  letter-spacing: 0;
  line-height: ${({ isExternalLogin }) => (isExternalLogin ? "30px" : "39px")};
  margin: 0 auto ${({ isExternalLogin }) => (isExternalLogin ? "16px" : "11px")};
  color: ${({ isExternalLogin }) => isExternalLogin && colors.gray[900]};
  text-align: center;
`;

export const FormInstructions = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 0 auto 30px;
  max-width: 312px;
  text-align: center;
  width: 100%;
  color: ${theme.colors.gray[800]};
`;

export const InputWrapper = styled.div`
  max-width: 356px;
  margin: 0 auto;
`;

export const ResetPasswordNotice = styled.p`
  width: 100%;
  padding: 8px 16px;
  margin: 0 auto 20px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.suite[500]};
  background-color: ${theme.colors.suite[100]};
  color: ${theme.colors.suite[500]};
  text-align: center;
  line-height: 20px;
`;

export const SubmissionError = styled.p`
  width: 100%;
  margin: 0 auto 20px;
  color: ${theme.colors.attract[700]};
  padding: 8px;
  background: ${theme.colors.attract[100]};
  border: 1px solid ${theme.colors.attract[200]};
  border-radius: 3px;
  text-align: center;
`;

export const ResetOption = styled.span`
  padding-top: 24px;
  font-weight: ${({ isExternalLogin }) => (isExternalLogin ? 600 : 400)};
  font-size: ${({ isExternalLogin }) => (isExternalLogin ? "12px" : "13px")};
  line-height: ${({ isExternalLogin }) => (isExternalLogin ? "16px" : "20px")};
  color: ${theme.colors.gray[800]};

  a {
    color: ${({ isExternalLogin, productKey }) =>
      isExternalLogin
        ? {
            cloud_cma: theme.colors.cma[700],
            cloud_streams: theme.colors.streams[700],
            cloud_mlx: theme.colors.mlx[700],
            cloud_attract: theme.colors.attract[700]
          }[productKey]
        : theme.colors.suite[600]};
    text-decoration: ${({ isExternalLogin }) =>
      isExternalLogin ? "none" : "underline"};
  }
`;

export const TextField = styled(BaseTextField)`
  margin-bottom: 24px;
`;

export const ProductIcons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const ProductIcon = styled.div`
  margin: 0 5.5px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};

  svg {
    width: ${({ isActive }) => (isActive ? "48px" : "32px")};
    height: ${({ isActive }) => (isActive ? "48px" : "32px")};
    fill: ${colors.white};
  }
`;

export const LWSSOLogin = styled.a`
  display: flex;
  align-items: stretch;
  border: 1px solid #2563eb;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
`;

export const LWSSOLoginIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 36px;
  background-color: #2563eb;

  svg {
    width: 18px;
    height: 14px;

    path {
      fill: #fff;
    }
  }
`;

export const LWSSOLoginText = styled.span`
  display: block;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  color: #fff;
  text-align: center;
  background-color: #2563eb;
`;
