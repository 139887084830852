import React, { useEffect } from "react";
import { config } from "../../../utils/lw-platform-config";
import Loading from "../../common/Loading";

export default function AgentBillingRedirect() {
  useEffect(() => {
    window.location.href = `${config.lwAgentUrl}/billing`;
  }, []);

  return <Loading />;
}
