import styled from "styled-components";

export const HeadingLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  text-align: center;
`;

export const GuestpassHeader = styled.h1`
  margin: 0;
  margin-top: 30px;
  font-size: 48px;
  line-height: 52px;
  font-weight: 600;
`;

export const GuestpassHeadline = styled.p`
  margin: 0;
  margin-top: 16px;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
`;

export const GuestpassHeadlineBold = styled.span`
  font-weight: 800;
  color: black;
  margin-bottom: -10px;
`;

export const GuestpassUnderline = styled.img`
  margin-top: -40px;
  margin-right: 44px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const GuestpassCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 372px;
  border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
  padding: 64px;
`;

export const FlashError = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.accent.red};
  border-radius: 3px;
  padding: 8px 16px;
  box-sizing: border-box;
  color: white;
  text-align: center;
  margin-top: 21px;
`;
