import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(1032px + (10 * 2px));
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 0;
  }
`;

export default Layout;
