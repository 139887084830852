import { numeral } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React from "react";
import {
  Container,
  FinalPrice,
  Free,
  LineThrough,
  OldPrice,
  PaymentInterval
} from "./styled/plan-price";

const PlanPrice = ({
  planKey,
  billingCycle,
  planPrice,
  finalPrice,
  className
}) => (
  <Container className={className}>
    {finalPrice < planPrice ? (
      <>
        <OldPrice planKey={planKey}>
          <LineThrough data-testid="price-strikethrough" />
          <Price
            planKey={planKey}
            billingCycle={billingCycle}
            price={planPrice}
          />
        </OldPrice>
        {finalPrice <= 0 ? (
          <Free>$0</Free>
        ) : (
          <FinalPrice planKey={planKey}>
            <Price
              planKey={planKey}
              billingCycle={billingCycle}
              price={finalPrice}
            />
          </FinalPrice>
        )}
      </>
    ) : finalPrice <= 0 ? (
      <Free>$0</Free>
    ) : (
      <FinalPrice planKey={planKey}>
        <Price
          planKey={planKey}
          billingCycle={billingCycle}
          price={finalPrice}
        />
      </FinalPrice>
    )}
  </Container>
);

const Price = ({ planKey, billingCycle, price }) => (
  <>
    <span>{numeral(price).format("$0,0[.]00")}</span>
    <PaymentInterval planKey={planKey}>
      {billingCycle === 1 && "/mo"}
      {billingCycle === 12 && "/yr"}
    </PaymentInterval>
  </>
);

PlanPrice.defaultProps = {
  planKey: "",
  billingCycle: 1, // monthly
  planPrice: 0,
  finalPrice: 0
};

PlanPrice.propTypes = {
  planKey: PropTypes.string.isRequired,
  billingCycle: PropTypes.oneOf([1, 12]).isRequired,
  planPrice: PropTypes.number.isRequired,
  finalPrice: PropTypes.number.isRequired
};

export default PlanPrice;
