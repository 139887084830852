import styled from "styled-components";
import theme from "../../../../utils/theme";
import BaseButton from "../../../common/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  margin: 0 auto;
`;

export const FieldsetLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Fieldset = styled.fieldset`
  width: 100%;
  max-width: 296px;
  border: none;
  padding: 12px;
`;

export const SectionHeading = styled.h3`
  font-size: 24px;
  line-height: 32px;
  margin: 0 auto 20px;
`;

export const PageSubheading = styled.p`
  margin: 0 auto 30px;
  text-align: center;
`;

export const Card = styled.div`
  width: auto;
  border-radius: 4px;
  background-color: white;
  padding: 40px 40px 60px;
  box-shadow: ${theme.shadows.raised};

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const SaveButton = styled(BaseButton)`
  margin: 30px auto 0;
`;
