import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "./LoadingIndicator";
import ResourceNotFound from "./ResourceNotFound";
import ResourceError from "./ResourceError";
import { Container, Loading } from "./styled/resource-loader";

function ResourceLoader({
  isLoading,
  loadingMsg,
  notFound,
  hasError,
  children
}) {
  if (isLoading) {
    return (
      <Container>
        <Loading>
          <LoadingIndicator /> {loadingMsg}
        </Loading>
      </Container>
    );
  }

  if (hasError) {
    return (
      <Container>
        <ResourceError />
      </Container>
    );
  }

  if (notFound) {
    return (
      <Container>
        <ResourceNotFound />
      </Container>
    );
  }

  return children;
}

ResourceLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired
};

ResourceLoader.defaultProps = {
  isLoading: false,
  loadingMsg: "Loading...",
  hasError: false,
  notFound: false
};

export default ResourceLoader;
