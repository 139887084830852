import { Link } from "@reach/router";
import React from "react";
import AddonPrice from "../components/PlanPrice";
import { config } from "../utils/lw-platform-config";
import CardOnFile from "./CardOnFile";
import PlanPrice from "./PlanPrice";
import ProductBadges from "./ProductBadges";
import UpgradeToAnnualOption from "./UpgradeToAnnualOption";
import {
  CardLabel,
  PlanPriceLayout,
  PlanTitle,
  PlanTitleLayout
} from "./styled/plan-change-overview";
import {
  AddonContainer,
  ContactSupport,
  Container,
  CreditCardContainer,
  EmailSupportLink,
  PlanCard,
  PlanOptions,
  PlanOptionsExternalLink,
  PlanOptionsHeading,
  PlanOptionsLink,
  PlanOptionsList,
  PlanOptionsListItem,
  PlanOptionsParagraph,
  PlanSection,
  ProductBadgesLayout,
  UpdatePaymentBlurb
} from "./styled/user-plan";

function UserPlan({ data }) {
  return (
    <Container>
      <PlanCard>
        <PlanSection>
          <CardLabel>Current Plan</CardLabel>
          <PlanTitleLayout>
            <PlanTitle>{data.user.subscription.sku.name}</PlanTitle>
          </PlanTitleLayout>
          <ProductBadgesLayout>
            <ProductBadges
              products={data.user.subscription.products}
              size={32}
            />
          </ProductBadgesLayout>
          <PlanPriceLayout>
            <PlanPrice
              planKey={data.user.subscription.sku.key}
              planPrice={data.user.subscription.nextBillingPeriodAmount}
              finalPrice={data.user.subscription.nextBillingPeriodAmount}
              billingCycle={data.user.subscription.sku.billingCycle}
            />
          </PlanPriceLayout>
        </PlanSection>
        <PlanOptions>
          <PlanOptionsHeading>Account Options</PlanOptionsHeading>
          {data.user.subscription.status === "pastDue" ? (
            <UpdatePaymentBlurb>
              To access your account, please update your card below.
            </UpdatePaymentBlurb>
          ) : data.user.subscription.canCancel ? (
            <PlanOptionsList>
              <PlanOptionsListItem>
                <PlanOptionsExternalLink
                  href={`${config.lwAgentUrl}/plan?code=${data.user.mls.code}&jwt=${data.user.jwt}`}>
                  Change Plan
                </PlanOptionsExternalLink>
              </PlanOptionsListItem>
              {data.user.subscription.transactions.length > 0 && (
                <PlanOptionsListItem>
                  <PlanOptionsLink to="/app/billing/invoices">
                    View Invoices
                  </PlanOptionsLink>
                </PlanOptionsListItem>
              )}
              <PlanOptionsListItem>
                <PlanOptionsLink to="/app/billing/cancel">
                  Cancel Account
                </PlanOptionsLink>
              </PlanOptionsListItem>
              <PlanOptionsListItem>
                <UpgradeToAnnualOption />
              </PlanOptionsListItem>
            </PlanOptionsList>
          ) : (
            <ContactSupport>
              To make changes to your plan, please{" "}
              <EmailSupportLink href="mailto:support@cloudagentsuite.com">
                contact Support
              </EmailSupportLink>
              .
              <br />
              <br />
              <Link to="/app/billing/invoices">View Paid Invoices</Link>
            </ContactSupport>
          )}
        </PlanOptions>
      </PlanCard>
      {data.user.addOns.map((addon) => (
        <AddonContainer key={addon.sku.key}>
          <PlanCard>
            <PlanSection>
              <CardLabel>Current Add-ons</CardLabel>
              <PlanTitleLayout>
                <PlanTitle>{addon.sku.name}</PlanTitle>
              </PlanTitleLayout>
              <ProductBadgesLayout>
                <ProductBadges products={addon.sku} size={32} />
              </ProductBadgesLayout>
              <PlanPriceLayout>
                <AddonPrice
                  planKey={addon.sku.key}
                  planPrice={addon.nextBillingPeriodAmount}
                  finalPrice={addon.nextBillingPeriodAmount}
                  billingCycle={addon.sku.billingCycle}
                />
              </PlanPriceLayout>
            </PlanSection>
            <PlanOptions>
              <PlanOptionsHeading>Account Options</PlanOptionsHeading>
              <PlanOptionsList>
                <PlanOptionsListItem>
                  {addon.nextBillingPeriodAmount > 0 ? (
                    <PlanOptionsLink
                      to={`/app/billing/addon/${addon.id}/cancel`}>
                      Cancel Addon
                    </PlanOptionsLink>
                  ) : (
                    <PlanOptionsParagraph>
                      Homebeat is included
                      <br /> with your current plan.
                    </PlanOptionsParagraph>
                  )}
                </PlanOptionsListItem>
              </PlanOptionsList>
            </PlanOptions>
          </PlanCard>
        </AddonContainer>
      ))}
      <CreditCardContainer>
        <CardOnFile
          payment={data.user.payment}
          isPastDue={data.user.subscription.status === "pastDue"}
          chargeAmount={data.user.subscription.nextBillingPeriodAmount}
          nextBillDate={data.user.subscription.nextBillDate}
        />
      </CreditCardContainer>
    </Container>
  );
}

export default UserPlan;
