import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  transition: 200ms all;
`;

export const IconLayout = styled.div`
  min-width: 43px;
  max-width: 43px;
  height: 43px;
  margin: 0 auto 7px;
  transition: 200ms all;
  will-change: transform;
  border-radius: 50%;
  background-color: ${({ theme, productKey }) =>
    theme.colors[productKey.split("_")[1]][500]};

  svg {
    fill: white;
    will-change: transform;
  }

  @media (max-width: 1023px) {
    border-radius: 13px;
    margin-bottom: 0px;
  }
`;
