import React from "react";
import { Container } from "./styled/powered-by";

function PoweredBy({ children, url }) {
  if (!url) {
    return <Container as="div">Powered by {children}</Container>;
  }

  return (
    <Container href={url} target="_blank">
      Powered by {children}
    </Container>
  );
}

export default PoweredBy;
