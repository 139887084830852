import React, { useMemo } from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "@reach/visually-hidden";
import { useApolloClient } from "react-apollo";
import { useMachine } from "@xstate/react";
import { isEmpty } from "lodash";
import { Link } from "@reach/router";
import launchpadProductMachine, {
  machineConfig as defaultConfig
} from "../../machines/launchpad-product";
import useWindowWidth from "../../utils/use-window-width";
import Button from "../common/Button";
import CloseIcon from "./icons/CloseIcon";
import { useLaunchpad } from "./LaunchpadProvider";
import LoadingText from "./LoadingText";
import LaunchCard from "./LaunchCard";
import {
  ProvisioningOptionLayout,
  OptionDivider,
  CancelIconLayout
} from "./styled/launchpad-products";
import { getProductLaunchUrl } from "../../utils/interProduct";
import { PRODUCT_KEYS, PLAN_SKUS } from "../../modules/products";
import { config } from "../../utils/lw-platform-config";
const { CLOUD_MLX, CLOUD_ATTRACT } = PRODUCT_KEYS;
const { SUITE } = PLAN_SKUS;
// apps that don't need a provisioned account due to CAS consolidation
const CONSOLIDATED_APPS = [CLOUD_MLX];

function LaunchpadProduct({ productKey, usePrimaryButton, machineConfig }) {
  const apiClient = useApolloClient();
  const { user, allProducts, refetchUserAndSubscription } = useLaunchpad();
  const { token, email, mls, provisionedAccounts, subscription } = user;
  const subscribedProduct = subscription.products.find(
    (p) => p.key === productKey
  );
  const product =
    subscribedProduct || allProducts.find((p) => p.key === productKey) || {};
  const productIsAvailableInUsersMls = !mls
    ? true
    : !!mls.products && !!mls.products.find((p) => p.key === productKey);
  const provisionedAccount =
    provisionedAccounts.find((acct) => acct.product.key === productKey) || {};
  const isProvisioning =
    !isEmpty(provisionedAccount) &&
    (provisionedAccount.provisionedAt === undefined ||
      provisionedAccount.provisionedAt === null);
  const isProvisioned = CONSOLIDATED_APPS.includes(productKey)
    ? true
    : !isEmpty(provisionedAccount) &&
      provisionedAccount.provisionedAt !== undefined &&
      provisionedAccount.provisionedAt !== null;
  const isntSubscribed = isEmpty(subscribedProduct);
  const canTryForFree = isntSubscribed && productIsAvailableInUsersMls;
  const isUnavailable = isntSubscribed && !canTryForFree;

  const stateMachine = useMemo(
    () =>
      launchpadProductMachine
        .withContext({
          apiClient,
          refetchUserAndSubscription,
          productId: product.id,
          canTryForFree,
          isProvisioned,
          isProvisioning,
          isUnavailable,
          productKey
        })
        .withConfig(machineConfig),
    []
  );
  const [state, send] = useMachine(stateMachine);
  const windowWidth = useWindowWidth();
  const isInStateThatNeedsWiderLayout =
    ["checkingStatus", "foundMatchingAccount", "noMatchingAccount"].includes(
      state.value
    ) && windowWidth < 1024;
  const appKey = "suite";
  const isMobileBreakpoint = windowWidth < 1024;
  const buttonSize = windowWidth < 1024 ? "small" : "medium";

  return (
    <LaunchCard
      isModal={isInStateThatNeedsWiderLayout}
      productKey={productKey}
      isActive={state.matches("complete")}
      isUnavailable={isUnavailable}>
      {state.matches("unavailable") && (
        <Button
          fullwidth
          size={buttonSize}
          variant="tertiary"
          app={appKey}
          as="a"
          href={`/tools/${productKey.split("_")[1]}`}>
          Learn more
        </Button>
      )}

      {state.matches("tryForFree") && (
        <Button
          fullwidth
          size={buttonSize}
          app={appKey}
          variant="secondary"
          as="a"
          href={`${config.lwAgentUrl}/plan?code=${mls.code}&jwt=${token}`}
          data-testid={`try-${productKey.split("_").pop()}`}>
          Try for free
        </Button>
      )}

      {state.matches("notStarted") && (
        <Button
          fullwidth
          size={buttonSize}
          app={appKey}
          variant="secondary"
          onClick={() => send("START_SETUP")}
          data-testid={`activate-${productKey.split("_").pop()}`}>
          Activate
        </Button>
      )}

      {state.matches("checkingStatus") && (
        <LoadingText productKey={productKey}>Looking for account</LoadingText>
      )}

      {state.matches("foundMatchingAccount") && (
        <>
          <CancelIconLayout onClick={() => send("CANCEL_SETUP")}>
            <CloseIcon />
            <VisuallyHidden>Cancel Activation</VisuallyHidden>
          </CancelIconLayout>
          <ProvisioningOptionLayout>
            <p>We found an account matching your email:</p>
            <pre title={email}>{email}</pre>
            <Button
              fullwidth
              size={buttonSize}
              app={appKey}
              variant="primary"
              as="a"
              href={state.context.connectPath}>
              Connect account
            </Button>
          </ProvisioningOptionLayout>
        </>
      )}

      {state.matches("noMatchingAccount") && (
        <>
          <CancelIconLayout onClick={() => send("CANCEL_SETUP")}>
            <CloseIcon />
            <VisuallyHidden>Cancel Activation</VisuallyHidden>
          </CancelIconLayout>
          <ProvisioningOptionLayout>
            <p>If you have a {product.name} account, you can:</p>
            <Button
              fullwidth
              size={buttonSize}
              app={appKey}
              variant="secondary"
              onClick={() =>
                (window.location.href = state.context.connectPath)
              }>
              Connect account
            </Button>
            <OptionDivider />
            <Button
              fullwidth
              size={buttonSize}
              app={appKey}
              variant="secondary"
              onClick={() => send("PROVISION_PRODUCT")}>
              Set up as new
            </Button>
          </ProvisioningOptionLayout>
        </>
      )}

      {(state.matches("provisioningProduct") || state.matches("settingUp")) && (
        <LoadingText productKey={productKey}>
          {isMobileBreakpoint ? "Setting up" : "Setting up your account"}
        </LoadingText>
      )}

      {state.matches("complete") && (
        <>
          <Button
            as="a"
            fullwidth
            size={buttonSize}
            app={appKey}
            href={getProductLaunchUrl({
              appKey,
              productId: product.id,
              token
            })}
            data-testid={`launch-${productKey.split("_").pop()}`}>
            Launch
          </Button>
        </>
      )}
    </LaunchCard>
  );
}

LaunchpadProduct.defaultProps = {
  usePrimaryButton: false,
  machineConfig: defaultConfig
};

LaunchpadProduct.propTypes = {
  usePrimaryButton: PropTypes.bool,
  productKey: PropTypes.oneOf(
    Object.keys(PRODUCT_KEYS).map((key) => PRODUCT_KEYS[key])
  ).isRequired,
  machineConfig: PropTypes.shape({
    services: PropTypes.object.isRequired
  }).isRequired
};

export default LaunchpadProduct;
