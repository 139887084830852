import React from "react";

const HomebeatIcon = (props) => (
  <svg width={52} height={52} {...props}>
    <defs>
      <path id="Homebeat__icon_svg__a" d="M0 0h24.97v25.95H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#131943" cx={26} cy={26} r={26} />
      <path
        d="M23.75 22C22.23 22 21 23.28 21 24.857 21 29.274 26.5 32 26.5 32s5.5-2.726 5.5-7.143C32 23.279 30.769 22 29.25 22s-2.75 1.28-2.75 2.857c0-1.578-1.231-2.857-2.75-2.857"
        fill="#FD5154"
      />
      <g transform="translate(14 11)">
        <mask id="Homebeat__icon_svg__b" fill="#fff">
          <use xlinkHref="#Homebeat__icon_svg__a" />
        </mask>
        <path
          d="M22.247 23.247H2.724V10.546l9.761-7.319 9.762 7.318v12.702zm2.328-14.383L13.1.262a1 1 0 0 0-1.23 0L.396 8.864c-.25.193-.396.493-.396.81v14.331C0 25.107.884 26 1.974 26h21.022c1.09 0 1.974-.893 1.974-1.995V9.675c0-.318-.146-.618-.395-.811z"
          fill="#FD5154"
          mask="url(#Homebeat__icon_svg__b)"
        />
      </g>
    </g>
  </svg>
);

export default HomebeatIcon;
