import React from "react";
import PropTypes from "prop-types";
import IconPlus from "./icons/IconPlus";
import CloudCma from "./icons/product/round/CloudCma";
import Homebeat from "./icons/product/round/Homebeat";
import CloudStreams from "./icons/product/round/CloudStreams";
import CloudMlx from "./icons/product/round/CloudMlx";
import CloudAttract from "./icons/product/round/CloudAttract";
import {
  Container,
  ProductPlusAddon,
  Product,
  IconCombo,
  ProductIcon,
  Plus,
  ProductNames,
  ProductName
} from "./styled/plan-product";

function PlanProduct({ productKey, hasHomebeat }) {
  return (
    <Container isCombo={productKey === "cloud_cma" && hasHomebeat}>
      {productKey === "cloud_cma" && hasHomebeat ? (
        <ProductPlusAddon>
          <IconCombo>
            <ProductIcon>
              <CloudCma />
            </ProductIcon>
            <Plus>
              <IconPlus />
            </Plus>
            <ProductIcon isAvailable={hasHomebeat}>
              <Homebeat />
            </ProductIcon>
          </IconCombo>
          <ProductNames>
            <ProductName productKey="cloud_cma">
              {getProductShortName("cloud_cma")}
            </ProductName>
            <ProductName productKey="homebeat">
              {getProductShortName("homebeat")}
            </ProductName>
          </ProductNames>
        </ProductPlusAddon>
      ) : (
        <Product>
          <ProductIcon>
            {productKey === "cloud_cma" && <CloudCma />}
            {productKey === "cloud_streams" && <CloudStreams />}
            {productKey === "cloud_mlx" && <CloudMlx />}
            {productKey === "cloud_attract" && <CloudAttract />}
          </ProductIcon>
          <ProductName productKey={productKey}>
            {getProductShortName(productKey)}
          </ProductName>
        </Product>
      )}
    </Container>
  );
}

PlanProduct.defaultProps = {
  isUnavailable: false
};

PlanProduct.propTypes = {
  productKey: PropTypes.oneOf([
    "cloud_cma",
    "cloud_streams",
    "cloud_mlx",
    "cloud_attract"
  ]),
  hasHomebeat: PropTypes.bool.isRequired
};

function getProductShortName(productKey) {
  return {
    cloud_cma: "CMA",
    cloud_streams: "Streams",
    cloud_mlx: "MLX",
    cloud_attract: "Attract",
    homebeat: "Homebeat"
  }[productKey];
}

export default PlanProduct;
