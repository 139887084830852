import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import useUserBilling from "../hooks/useUserBilling";
import useAnnualSavings from "../hooks/useAnnualSavings";
import Button from "./common/Button";

function UpgradeToAnnualOption() {
  const { loading, data } = useUserBilling();
  const annualSavings = useAnnualSavings(
    {
      groupKey: data.user.subscription.sku.groupKey,
      mlsCode: data.user.mls.code,
      promoCode: (data.user.subscription.discount || {}).code
    },
    { skip: loading }
  );

  // This component is only rendered if the user can save money by upgrading to an annual plan.
  if (
    annualSavings.loading ||
    !annualSavings.amount ||
    data.user.subscription.sku.billingCycle === 12
  )
    return null;
  return (
    <div>
      <Text>
        <strong>Save ${annualSavings.amount} a year</strong> if you switch to
        our annual plan!
      </Text>
      <Button
        size="small"
        as={Link}
        to={`/app/billing/plan?plan=${annualSavings.upgradePlanKey}`}>
        Switch to Annual Plan
      </Button>
    </div>
  );
}

// Styled Components
const Text = styled.p`
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 20px;
  color: black;

  strong {
    font-weight: 600;
  }
`;

export default UpgradeToAnnualOption;
