import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { PRODUCT_KEYS } from "../../../modules/products";
import ResourceLoader from "../../common/ResourceLoader";
import qs from "qs";
const productKeys = Object.keys(PRODUCT_KEYS).map((key) => PRODUCT_KEYS[key]);

const getProductNameByKey = (productKey) =>
  ({
    cloud_cma: "Cloud CMA",
    cloud_streams: "Cloud Streams",
    cloud_mlx: "Cloud MLX",
    cloud_attract: "Cloud Attract"
  }[productKey]);

function ExternalLogin({ productKey, navigate }) {
  useEffect(() => {
    if (productKeys.includes(productKey)) {
      const jwt = localStorage.getItem("token");
      const { continue_to } = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
      });
      const queryString = qs.stringify({
        jwt,
        ...(!!continue_to && { continue_to })
      });
      let railsRedirectPath = `${window.location.origin}/external_login/${productKey}?${queryString}`;
      window.location.replace(railsRedirectPath);
    } else {
      navigate("/app/launchpad", { replace: true });
    }
  }, [productKey, navigate]);

  return (
    <ResourceLoader
      isLoading={true}
      loadingMsg={`Logging you into ${getProductNameByKey(productKey)}...`}
    />
  );
}

ExternalLogin.propTypes = {
  productKey: PropTypes.oneOf(productKeys)
};

export default ExternalLogin;
