import gql from "graphql-tag";

export const GET_AUTHED_USER = gql`
  query getAuthedUser {
    user {
      id
      jwt
      name
      email
      lwaId: lwa_id
      lwaJwt: lwa_jwt
      avatarUrl: avatar_url
      intercom_user_hash
      newUser: new_user
      tosId: tos_id
      hasAgreedToTos: has_agreed_to_tos
      guestPasses: guest_passes {
        id
      }
      mls {
        id
        code
        name
        hasHomebeat: has_homebeat
        products {
          key
        }
      }
      guestPasses: guest_passes {
        id
        skuable {
          billingCycle: billing_cycle
          groupKey: group_key
          id
          key
          name
          price
          tagline
          type
        }
        source
        status
      }
    }
  }
`;

export const GET_USER_BILLING = gql`
  query getUserBilling {
    user {
      id
      jwt
      lwaId: lwa_id
      mls {
        code
        id
        name
      }
      payment {
        cardType: card_type
        expirationDate: expiration_date
        maskedNumber: masked_number
      }
      subscription {
        id
        price
        balance
        status
        canCancel: can_cancel
        nextBillDate: next_bill_date
        nextBillingPeriodAmount: next_billing_period_amount
        sku {
          id
          key
          name
          price
          groupKey: group_key
          billingCycle: billing_cycle
        }
        products {
          key
        }
        discount {
          id
          code
          amount
        }
        transactions {
          id
        }
      }
      addOns: add_on_subscriptions {
        id
        price
        nextBillDate: next_bill_date
        nextBillingPeriodAmount: next_billing_period_amount
        discount {
          id
          amount
        }
        sku {
          id
          key
          name
          price
          billingCycle: billing_cycle
        }
      }
    }
  }
`;
