import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { HostedField } from "react-braintree-fields";
import {
  Wrapper,
  FieldWrapper,
  FieldGroup
} from "./styled/edit-payment-fields";

export default function EditPaymentFields({ numberFieldRef }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  return (
    <Wrapper>
      <FieldWrapper>
        <HostedField
          type="number"
          ref={numberFieldRef}
          placeholder="Card number"
          onFocus={() => setFieldTouched("number", true, true)}
          onValidityChange={({ isValid }) => {
            setFieldValue("number", isValid, true);
          }}
        />
      </FieldWrapper>
      <FieldGroup>
        <FieldWrapper style={{ marginRight: 20 }}>
          <HostedField
            type="expirationDate"
            placeholder="MM/YYYY"
            onFocus={() => setFieldTouched("expirationDate", true, true)}
            onValidityChange={({ isValid }) => {
              setFieldValue("expirationDate", isValid, true);
            }}
          />
        </FieldWrapper>
        <FieldWrapper style={{ marginRight: 20 }}>
          <HostedField
            type="cvv"
            placeholder="CVV"
            onFocus={() => setFieldTouched("cvv", true, true)}
            onValidityChange={({ isValid }) => {
              setFieldValue("cvv", isValid, true);
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <HostedField
            type="postalCode"
            placeholder="Billing Zip"
            onFocus={() => setFieldTouched("postalCode", true, true)}
            onValidityChange={({ isValid }) => {
              setFieldValue("postalCode", isValid, true);
            }}
          />
        </FieldWrapper>
      </FieldGroup>
    </Wrapper>
  );
}

EditPaymentFields.propTypes = {
  numberFieldRef: PropTypes.object.isRequired
};
