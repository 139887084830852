import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../utils/theme";

export default function Checkbox({ size, labelPosition, children, ...props }) {
  return (
    <Label size={size} app={props.app}>
      {labelPosition === "left" && children}
      <input type="checkbox" {...props} />
      <Box size={size} labelPosition={labelPosition}>
        <IconCheckmark />
      </Box>
      {labelPosition === "right" && children}
    </Label>
  );
}

Checkbox.defaultProps = {
  app: "suite",
  labelPosition: "right",
  size: "lg"
};

Checkbox.propTypes = {
  app: PropTypes.oneOf(["suite", "cma", "streams", "mlx", "attract"]),
  labelPosition: PropTypes.oneOf(["left", "right"]),
  size: PropTypes.oneOf(["sm", "lg"])
};

const Box = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ size }) => (size === "lg" ? "20px" : "14px")};
  width: ${({ size }) => (size === "lg" ? "20px" : "14px")};
  min-height: ${({ size }) => (size === "lg" ? "20px" : "14px")};
  height: ${({ size }) => (size === "lg" ? "20px" : "14px")};
  border-radius: 3px;
  border: 1px solid;
  margin-left: ${({ labelPosition }) =>
    labelPosition === "left" ? "12px" : "0px"};
  margin-right: ${({ labelPosition }) =>
    labelPosition === "right" ? "12px" : "0px"};
  transition: all 200ms;

  svg {
    fill: white;
    transition: all 200ms;
    margin-top: -1px;
    transform: ${({ size }) => (size === "lg" ? "scale(1)" : "scale(0.7)")};
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.gray[900]};
  cursor: pointer;

  input[type="checkbox"] {
    /* Visually Hidden */
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }
  /* Unchecked - Default */
  input:not(:checked) + ${Box} {
    border-color: ${theme.colors.gray[600]};
  }
  /* Unchecked - Hover */
  input:not(:checked):hover + ${Box} {
    border-color: ${theme.colors.gray[700]};
  }
  /* Unchecked - Focus */
  input:not(:checked):focus + ${Box} {
    border-color: ${({ app }) => theme.colors[app][600]};
    box-shadow: 0 0 0 2px ${({ app }) => theme.colors[app][500]};
  }
  /* Unchecked - Active */
  input:not(:checked):active + ${Box} {
    border-color: ${theme.colors.gray[700]};
    background: ${theme.colors.gray[300]};
    box-shadow: none;

    svg {
      fill: ${theme.colors.gray[700]};
      transform: scale(0.7);
    }
  }
  /* Unchecked - Disabled */
  input:not(:checked):disabled + ${Box} {
    border-color: ${theme.colors.gray[500]};
    background: ${theme.colors.gray[300]};
  }

  /* Checked - Default */
  input:checked + ${Box} {
    border-color: ${({ app }) => theme.colors[app][500]};
    background: ${({ app }) => theme.colors[app][500]};
  }
  /* Checked - Hover */
  input:hover:checked + ${Box} {
    border-color: ${({ app }) => theme.colors[app][400]};
    background: 0 0 0 1px ${({ app }) => theme.colors[app][400]};
  }
  /* Checked - Focus */
  input:focus:checked + ${Box} {
    border-color: ${({ app }) => theme.colors[app][400]};
    box-shadow: 0 0 0 2px ${({ app }) => theme.colors[app][500]};
    background: ${({ app }) => theme.colors[app][400]};
  }
  /* Checked - Active */
  input:active:checked:not(:disabled) + ${Box} {
    border-color: ${({ app }) => theme.colors[app][600]};
    background: ${({ app }) => theme.colors[app][600]};
    box-shadow: none;

    svg {
      transform: scale(0.7);
      fill: ${({ app }) => theme.colors[app][600]};
    }
  }
  /* Checked - Disabled */
  input:checked:disabled + ${Box} {
    border-color: ${theme.colors.gray[500]};
    background: ${theme.colors.gray[500]};
  }
`;

const IconCheckmark = (props) => (
  <svg width={14} height={11} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.996.938a.75.75 0 0 1 .066 1.058l-7.5 8.5a.75.75 0 0 1-1.092.034l-3.5-3.5a.75.75 0 1 1 1.06-1.06l2.936 2.935 6.972-7.901a.75.75 0 0 1 1.058-.066z"
    />
  </svg>
);
