import { useEffect } from "react";

const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: true, subtree: true }
};

export default function useMutationObservable(
  targetEl,
  cb,
  options = DEFAULT_OPTIONS
) {
  useEffect(() => {
    const observer = new MutationObserver(cb);

    if (!targetEl || !observer) return;

    observer.observe(targetEl, options.config);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [targetEl, options]);
}
