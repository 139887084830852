import React from "react";

const CloudMlx = (props) => (
  <svg viewBox="0 0 245.26 245.26" {...props}>
    <circle
      cx={122.63}
      cy={122.63}
      r={122.63}
      transform="rotate(-76.72 122.63 122.63)"
      fill="#3a7abe"
    />
    <path d="M153.57 174.82l1.8-1.74a8.17 8.17 0 0 0-5.89-2.5 8 8 0 0 0-3.64.87 62.78 62.78 0 0 1-28.39 6.77h-.79a62.43 62.43 0 0 1-43.6-18.37 61.2 61.2 0 0 1-18-43.33v-.78a61.25 61.25 0 0 1 18.59-43.17 62.46 62.46 0 0 1 43.78-17.8h.79a62.39 62.39 0 0 1 43.6 18.37 61.18 61.18 0 0 1 18 43.33v.79a60.77 60.77 0 0 1-7.84 29.2 8.08 8.08 0 0 0-1 3.94 8.19 8.19 0 0 0 2.3 5.68l16.56 17.16a9.88 9.88 0 0 1 1.88 2.57 5.15 5.15 0 0 1 .5 2.13 7.36 7.36 0 0 1-1.34 3.85 26.8 26.8 0 0 1-4.08 4.76 31.23 31.23 0 0 1-5.45 4.35 9.5 9.5 0 0 1-4.76 1.57 5.31 5.31 0 0 1-2.18-.46 7.64 7.64 0 0 1-2.32-1.69l-16.68-17.28-1.8 1.74-1.8 1.73 16.68 17.28a12.65 12.65 0 0 0 3.85 2.74 10.36 10.36 0 0 0 4.21.9 14.49 14.49 0 0 0 7.32-2.28 35.64 35.64 0 0 0 6.36-5 31.47 31.47 0 0 0 4.84-5.7 12.31 12.31 0 0 0 2.11-6.51 9.92 9.92 0 0 0-1-4.25 14.45 14.45 0 0 0-2.84-4l-1.78 1.75 1.8-1.74-16.56-17.08a3.19 3.19 0 0 1-.9-2.21 3 3 0 0 1 .39-1.49 65.86 65.86 0 0 0 8.48-31.59v-.85a66.22 66.22 0 0 0-19.42-46.86 67.43 67.43 0 0 0-47.08-19.84h-.85A67.45 67.45 0 0 0 70.15 69a66.22 66.22 0 0 0-20 46.66v.85a66.22 66.22 0 0 0 19.42 46.86 67.41 67.41 0 0 0 47.08 19.84h.85a67.45 67.45 0 0 0 30.65-7.32 3 3 0 0 1 1.37-.32 3.2 3.2 0 0 1 2.29 1z" />
    <path d="M95.24 134.49v-23l22.26-16.13 22.41 17.27v22.61h5V111.4a2.48 2.48 0 0 0-1-2l-24.84-19.15a2.52 2.52 0 0 0-3 0l-24.8 18a2.49 2.49 0 0 0-1 2v24.22z" />
  </svg>
);

export default CloudMlx;
