import React from "react";

const CloudAttract = (props) => (
  <svg viewBox="0 0 245.26 245.26" {...props}>
    <circle
      cx={122.63}
      cy={122.63}
      r={122.63}
      transform="rotate(-45 122.628 122.636)"
      fill="#fd5154"
    />
    <path d="M121.51 195.71a73.5 73.5 0 0 1-12.82-1.15 68.78 68.78 0 0 1-37.55-20.06A71.23 71.23 0 0 1 51.93 136c-4.31-27 5.42-51.48 26.7-67.17l.21-.14 38.34-23a7.62 7.62 0 0 1 9.89.83 79.94 79.94 0 0 1 6.47 8.11 82 82 0 0 0 6.46 8.05 7.62 7.62 0 0 1-.44 11.1l-.22.16L104.9 98a31.63 31.63 0 0 0-13.2 32.05 31.28 31.28 0 0 0 8.16 15.53 30.11 30.11 0 0 0 18.93 9.08 29.71 29.71 0 0 0 27.15-13.3L169.82 106a1.3 1.3 0 0 1 .18-.23 7.6 7.6 0 0 1 11.21-.44l15.61 13.27.21.2a7.61 7.61 0 0 1 .71 9.66l-22.89 39.16a1.64 1.64 0 0 1-.14.22c-13.03 18.1-31.97 27.87-53.2 27.87zM81.72 73.2c-19.54 14.47-28.46 37-24.48 61.92A65.93 65.93 0 0 0 75 170.76a63.38 63.38 0 0 0 34.62 18.5c24.19 4.3 46.29-4.61 60.65-24.43l22.88-39.17c.05-.08.1-.16.16-.24a2.23 2.23 0 0 0-.11-2.8l-15.58-13.25-.21-.19a2.17 2.17 0 0 0-1.69-.7 2.22 2.22 0 0 0-1.55.7L150.4 144.4a34.84 34.84 0 0 1-54.4 4.95 36.58 36.58 0 0 1-9.57-18.2 37 37 0 0 1 15.39-37.53l34.27-24a2.18 2.18 0 0 0 .69-1.52 2.21 2.21 0 0 0-.64-1.66 84.28 84.28 0 0 1-6.93-8.66 79.56 79.56 0 0 0-6-7.53 2.24 2.24 0 0 0-2.94-.22 1.88 1.88 0 0 1-.23.16z" />
    <path
      transform="rotate(-48.43 166.458 142.616)"
      d="M163.79 126.97h5.38v31.31h-5.38z"
    />
    <path
      transform="rotate(-40.26 101.566 76.547)"
      d="M98.88 61.97h5.38v29.16h-5.38z"
    />
  </svg>
);

export default CloudAttract;
