import { INTERCOM_APP_ID } from "../constants/intercom";

export function loadExternalLibraries() {
  loadIntercom();
}

function loadIntercom() {
  const script = window.document.createElement("script");
  script.setAttribute("src", `//widget.intercom.io/widget/${INTERCOM_APP_ID}`);
  window.document.head.appendChild(script);
}
