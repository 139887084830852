import { useState, useEffect } from "react";
import { throttle } from "lodash";
import { css } from "styled-components";

export const sizes = {
  xs: 400,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200
};

const breakpoints = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] - 1}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const getDeviceConfig = (width) => {
  if (width < 320) {
    return "xs";
  } else if (width >= 320 && width < sizes.md) {
    return "sm";
  } else if (width >= sizes.md && width < sizes.lg) {
    return "md";
  } else if (width >= sizes.lg) {
    return "lg";
  }
};

export const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function() {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return brkPnt;
};

export default breakpoints;
