import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { isBefore } from "date-fns";
import { useApolloClient } from "@apollo/react-hooks";
import { useMachine } from "@xstate/react";
import { useQueryString } from "../utils/url";
import SubscriptionWorkflowContext, {
  Provider
} from "../contexts/subscription-workflow";
import subscriptionWorkflowMachine, {
  machineConfig
} from "../machines/subscription-workflow";
import LoadingWorkflow from "../styled/LoadingCheckoutSteps";
import Layout from "../styled/Layout";
import ErrorBoundary from "./ErrorBoundary";
import AccountStep from "./AccountStep";
import PlanStep from "./PlanStep";
import BillingStep from "./BillingStep";
import { WorkflowSteps } from "./styled/subscription-workflow";

const billingParamOptions = {
  monthly: 1,
  annual: 12
};

export default function SubscriptionWorkflow({ mode, machineConfig }) {
  const promoCodeFromSession = window.sessionStorage.getItem("promo") || "";
  const {
    plan = "",
    promo = promoCodeFromSession,
    mls = "",
    billing = ""
  } = useQueryString();
  const apiClient = useApolloClient();
  const [state, send] = useMachine(
    subscriptionWorkflowMachine
      .withContext({
        ...subscriptionWorkflowMachine.context,
        billingCycle: billingParamOptions[billing] || 12,
        promo: promo.toLowerCase(),
        selectedMls: { code: mls },
        mlsUrlParam: mls,
        planUrlParam: plan,
        apiClient,
        mode
      })
      .withConfig(machineConfig),
    { devTools: process.env.RAILS_ENV === "development" }
  );

  return (
    <Provider value={{ state, send }}>
      <Layout>
        {state.matches("loading") ? (
          <LoadingWorkflow>Loading...</LoadingWorkflow>
        ) : (
          <Formik
            validationSchema={state.context.validationSchema}
            initialValues={{
              name: state.context.user.name || "",
              email: state.context.user.email || "",
              phone: state.context.user.phone || "",
              price: state.context.selectedPlan.price || 0,
              number: false,
              expirationDate: false,
              cvv: false,
              postalCode: false,
              promo: state.context.promo || "",
              tos: mode === "edit"
            }}>
            <WorkflowSteps>
              <ErrorBoundary>
                <AccountStep />
                <PlanStep />
                <BillingStep />
              </ErrorBoundary>
            </WorkflowSteps>
          </Formik>
        )}
      </Layout>
    </Provider>
  );
}

SubscriptionWorkflow.defaultProps = {
  mode: "new",
  machineConfig
};

SubscriptionWorkflow.propTypes = {
  mode: PropTypes.oneOf(["new", "edit"]).isRequired,
  machineConfig: PropTypes.object.isRequired // TODO: break this down into actions, guards, services with individual func names
};

export const useSubscriptionWorkflow = () =>
  useContext(SubscriptionWorkflowContext);

export function useFinalPrice() {
  const { state } = useSubscriptionWorkflow();
  const {
    discounts,
    selectedPlan: { key, price }
  } = state.context;

  const foundDiscount = discounts.byPlanKey[key];
  const finalPrice = foundDiscount ? price - foundDiscount.amount : price;

  return finalPrice;
}

export function useIsUpgradingDuringFallPromo() {
  const { state } = useSubscriptionWorkflow();

  if (!state) return false;

  const { subscription } = state.context;
  const priceToBePaid = useFinalPrice();

  if (!subscription.sku) return false;

  return (
    priceToBePaid > subscription.sku.price &&
    isBefore(Date.now(), new Date(2020, 11, 31))
  );
}

/**
 * dependent upon `useSubscriptionWorkflow`
 * Provide a bool determining whether or not the user has selected a plan which is free
 * Take the presence of promo codes into account, and subtracts any associated discounts from the price
 *
 * It should be noted that we are using this in lieu of computed/derived state, where we would determine this
 * based on the selectedPlan.key, promo, or discount values changing
 */
export const useIsFreePlan = () => {
  const finalPrice = useFinalPrice();
  return finalPrice <= 0;
};
