import styled, { keyframes } from "styled-components";
import theme from "../../../utils/theme";

const rotate = keyframes`
  from {
    tranform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 36px;
  font-size: 11px;
  line-height: 11px;
  color: ${theme.colors.gray[700]};

  svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    fill: ${({ productKey }) => theme.colors.utils.getByProductKey(productKey)};
    animation: ${rotate} 1200ms linear infinite;
    transform-origin: center center;
  }
`;
