import { Machine, assign } from "xstate";
import matchSorter from "match-sorter";

export default Machine(
  {
    context: {
      input: "",
      index: 0,
      selected: {
        name: "",
        code: ""
      },
      items: [],
      filtered: [],
      wasClosedInternally: false,
      noMlsOption: {},
      initialized: false
    },
    initial: "closed",
    on: {
      INIT: { actions: assign({ initialized: () => true }) }
    },
    states: {
      closed: {
        on: {
          OPEN_DROPDOWN: "opened",
          KEYED_ENTER: "opened",
          KEYED_ARROWDOWN: "opened",
          KEYED_ARROWUP: "opened",
          SET_MLSES: { actions: ["setMlses"] }
        }
      },
      opened: {
        onEntry: ["resetDefaults"],
        on: {
          KEYED_ENTER: {
            target: "closed",
            actions: ["getSelectionAtIndex", "onSelect", "closeInternally"]
          },
          KEYED_ESCAPE: {
            target: "closed",
            actions: ["closeInternally"]
          },
          KEYED_TAB: {
            target: "closed",
            actions: ["closeExternally"]
          },
          KEYED_ARROWDOWN: { actions: "incrementIndex" },
          KEYED_ARROWUP: { actions: "decrementIndex" },
          CLOSE_DROPDOWN: {
            target: "closed",
            actions: ["closeExternally"]
          },
          INPUT_CHANGED: { actions: "handleInputChanged" },
          SELECT: {
            target: "closed",
            actions: ["handleSelect", "onSelect", "closeInternally"]
          },
          SET_INDEX: { actions: ["setIndex"] }
        }
      }
    }
  },
  {
    actions: {
      setMlses: assign({
        input: () => "",
        selected: () => ({ name: "", code: "" }),
        items: (_, event) => event.mlses,
        filtered: (ctx, event) => [...event.mlses, ctx.noMlsOption]
      }),
      closeInternally: assign({ wasClosedInternally: () => true }),
      closeExternally: assign({ wasClosedInternally: () => false }),
      getSelectionAtIndex: assign((ctx) => {
        const selected = ctx.filtered[ctx.index];
        return {
          ...ctx,
          input: selected.name,
          index: 0,
          selected,
          filtered: [...ctx.items, ctx.noMlsOption]
        };
      }),
      resetDefaults: assign({
        index: (ctx, event) =>
          event.type === "KEYED_ARROWUP" ? ctx.items.length - 1 : 0,
        input: () => "",
        filtered: (ctx) => [...ctx.items, ctx.noMlsOption],
        wasClosedInternally: () => false
      }),
      setIndex: assign({ index: (_, event) => event.index }),
      incrementIndex: assign({
        index: (ctx) =>
          ctx.index + 1 > ctx.filtered.length - 1 ? 0 : ctx.index + 1
      }),
      decrementIndex: assign({
        index: (ctx) =>
          ctx.index - 1 < 0 ? ctx.filtered.length - 1 : ctx.index - 1
      }),
      handleInputChanged: assign({
        filtered: (ctx, event) => [
          ...matchSorter(ctx.items, event.value, { keys: ["name"] }),
          ctx.noMlsOption
        ],
        input: (_, event) => event.value,
        index: () => 0
      }),
      handleSelect: assign({
        selected: (_, event) => event.mls,
        input: (_, event) => event.mls.name
      })
    }
  }
);
