import styled from "styled-components";

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  > * + * {
    margin-top: 32px;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  min-height: 188px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
  padding: 18px 24px;
  box-sizing: border-box;
`;

export const Heading = styled.h4`
  font-weight: 500;
  font-size: 18px;
  font-weight: normal;
  color: #131945;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 0 24px;
`;

export const ExpWarning = styled.span`
  font-size: 14px;
  color: #ec5252;
  margin-left: 16px;
`;

export const TLCBadge = styled.img`
  position: absolute;
  right: 18px;
  bottom: 18px;
  width: 112px;
  min-height: 112px;
  @media (max-width: 450px) {
    opacity: 0.5;
  }
`;

export const Nevermind = styled.button`
  outline: none;
  background: white;
  padding: 0;
  border: none;
  border-radius: 0;
  text-decoration: none;

  font-size: 13px;
  font-weight: normal;
  color: #2d335d;
  letter-spacing: 0;
  text-align: right;
  line-height: 24px;
  margin-left: 20px;
  cursor: pointer;
  &:focus {
    border-bottom: 2px solid #9013fe;
  }
`;
