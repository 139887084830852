import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 216px;
  height: 100%;
  margin: auto;
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
  h3 {
    font-size: 40px;
    margin: 0 auto;
  }
`;

export default () => (
  <Wrapper>
    <h3>🤔</h3>
    <h4>Something seems to have gone awry...</h4>
    <p>We're hard at work fixing it. Check back again soon.</p>
  </Wrapper>
);
