import { isEmpty, keyBy } from "lodash";
import queryString from "query-string";
import cmaBadge from "../images/cloud-cma-emblem.svg";
import streamsBadge from "../images/cloud-streams-emblem.svg";
import mlxBadge from "../images/cloud-mlx-emblem.svg";
import attractBadge from "../images/cloud-attract-emblem.svg";
import { transformAddOns } from "./addons";

export const SORT_ORDER = Object.freeze({
  cloud_cma: 0,
  homebeat: 1,
  cloud_streams: 2,
  cloud_mlx: 3,
  cloud_attract: 4
});

export const PRODUCT_KEYS = Object.freeze({
  CLOUD_CMA: "cloud_cma",
  CLOUD_STREAMS: "cloud_streams",
  CLOUD_MLX: "cloud_mlx",
  CLOUD_ATTRACT: "cloud_attract"
});

export const PRODUCT_TITLES = Object.freeze({
  cloud_cma: "Cloud CMA",
  cloud_streams: "Cloud Streams",
  cloud_mlx: "Cloud MLX",
  cloud_attract: "Cloud Attract"
});

export const PRODUCT_APP_BY_KEY = Object.freeze({
  cloud_cma: "cma",
  cloud_streams: "streams",
  cloud_mlx: "mlx",
  cloud_attract: "attract"
});

export const PLAN_SKUS = Object.freeze({
  ...PRODUCT_KEYS,
  SITE_LICENSE: "site_license",
  ESSENTIALS: "essentials",
  SUITE: "suite"
});

export function getProductSortOrder(productKey) {
  return SORT_ORDER[productKey] || 0;
}

export function transformProducts(products) {
  return {
    productsByKey: indexByProductKey(products),
    onboardingItemsByKey: transformOnboardingItems(products)
  };
}

export function indexByProductKey(products) {
  return products.reduce((byKey, product) => {
    byKey[product.key] = transformProduct(product);
    return byKey;
  }, {});
}

function transformProduct(product) {
  return {
    id: product.id || "",
    key: product.key || "",
    name: product.name || "",
    tagline: product.tagline || "",
    price: product.price,
    value: product.value,
    siteLicense: product.site_license,
    addons: transformAddOns(product.addons || []),
    ...(product.onboarding_items && {
      onboardingKeys: product.onboarding_items.map((item) => item.key)
    }),
    ...(product.provisioned_account && {
      provisionedAccount: transformProvisionedAccount(
        product.provisioned_account
      )
    })
  };
}

function transformOnboardingItems(products) {
  return products.reduce((byKey, product) => {
    if (!product.onboarding_items) return byKey;
    product.onboarding_items.forEach((item) => {
      byKey[item.key] = transformOnboardingItem(item);
    });
    return byKey;
  }, {});
}

export function transformOnboardingItem(onboardingItem) {
  return {
    id: onboardingItem.id,
    key: onboardingItem.key,
    sort: onboardingItem.sort,
    actionLink: onboardingItem.action_link,
    completedAt: onboardingItem.completed_at,
    description: onboardingItem.description,
    productId: onboardingItem.product_id
  };
}

function transformProvisionedAccount(account) {
  if (!account) return null;
  return {
    id: account.id,
    remoteUserEmail: account.remote_user_email,
    provisionedAt: account.provisioned_at
  };
}

export function getOnboardingStatus(onboardingItems) {
  if (!onboardingItems.length) return { percentComplete: 100 };
  const completedItems = onboardingItems.filter((item) => item.completedAt);
  const percentComplete =
    (completedItems.length / onboardingItems.length) * 100;
  const onboardingCompletionAsString = [
    completedItems.length,
    onboardingItems.length
  ].join("/");
  const nextOnboardingItem = onboardingItems
    .sort((a, b) => a.sort > b.sort)
    .find((item) => !item.completedAt);

  return {
    nextOnboardingItem,
    onboardingCompletionAsString,
    percentComplete
  };
}

// todo: might not need this once all plans/products are indexed by id
export function indexBundlesById(bundles) {
  return bundles.reduce((dictionary, bundle) => {
    const productDict = indexByProductKey(bundle.products);
    const bundleWithProductDict = { ...bundle, products: productDict };
    dictionary[bundle.id] = bundleWithProductDict;
    return dictionary;
  }, {});
}

// todo: once we've indexed plans by key we can likely delete this
export function extractPlans(state, mls = {}) {
  const { products = [], bundles = [] } = mls;
  const siteLicenseBundle = bundles.find((b) => b.key === "site_license") || {};
  const availableProducts = products.map((p) => p.key);
  const suite = {
    ...bundles.find((b) => b.key === "suite"),
    products: state.allProducts.map((p) => ({
      ...p,
      isAvailable: availableProducts.includes(p.key)
    }))
  };

  return {
    availableProducts: products,
    availableBundles: bundles,
    siteLicenseBundle,
    suite
  };
}

// * Network/State refactor
export function transformMlsPlans(context, mls) {
  const [{ bundles, products }] = mls;
  const bundleKeys = bundles.map((b) => b.key);
  const byKey = [...bundles, ...products].reduce((hash, plan) => {
    hash[plan.key] = bundleKeys.includes(plan.key)
      ? {
          ...plan,
          products: plan.products.map(
            (product) => context.products.byKey[product.key]
          )
        }
      : context.products.byKey[plan.key];

    return hash;
  }, {});
  return { byKey };
}

// * Network/State refactor
export function indexDiscountsByPlanKey(discounts) {
  return discounts.reduce((hash, discount) => {
    discount.bundles.forEach((bundle) => {
      hash[bundle.key] = discount;
    });
    discount.addOns.forEach((addOn) => {
      hash[addOn.key] = discount;
    });
    return hash;
  }, {});
}

export function getRoundProductLogo(productKey) {
  switch (productKey) {
    case "cloud_cma":
      return cmaBadge;
    case "cloud_streams":
      return streamsBadge;
    case "cloud_mlx":
      return mlxBadge;
    case "cloud_attract":
      return attractBadge;
    default:
      return null;
  }
}

export function isBundle(selectedPlan) {
  return [
    "annual_suite",
    "suite",
    "suite_yearly",
    "site_license",
    "essentials"
  ].includes(selectedPlan.key);
}

export function getDiscountObject(plan, promoCode) {
  return (
    (plan.discounts && plan.discounts.find((d) => d.code === promoCode)) || {}
  );
}

export function isPlanFree(plan, discount = {}) {
  return (
    plan.price === 0 ||
    plan.price - (!isEmpty(discount) && discount.amount) <= 0
  );
}

export function getSingleToolOption() {
  const defaultSingleTool = "cloud_cma";
  const validProducts = [
    defaultSingleTool,
    "cloud_streams",
    "cloud_mlx",
    "cloud_attract"
  ];
  const params = queryString.parse(location.search);
  const hasProductParam = Object.prototype.hasOwnProperty.call(
    params,
    "product"
  );
  if (hasProductParam && validProducts.includes(params.product)) {
    return params.product;
  } else {
    return defaultSingleTool;
  }
}

export function getProductNameByKey(productKey) {
  try {
    const name = {
      cloud_cma: "Cloud CMA",
      cloud_streams: "Cloud Streams",
      cloud_mlx: "Cloud MLX",
      cloud_attract: "Cloud Attract",
      homebeat: "Homebeat"
    }[productKey];

    return name;
  } catch (err) {
    console.error(err);
  }
}
