import React from "react";

const IconShortcutFlyer = (props) => (
  <svg width={24} height={24} fill="none" {...props}>
    <rect width={24} height={24} rx={5} fill="#BCF5DD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5.8a.6.6 0 0 1 .6-.6h8.8a.6.6 0 0 1 .424.176l2.4 2.4a.6.6 0 0 1 .176.424v10.4a.6.6 0 0 1-.6.6H6.6a.6.6 0 0 1-.6-.6V5.8zm1.2.6V18h10V8.449L15.152 6.4H7.2z"
      fill="#20B575"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2 13.2a.6.6 0 0 1 .6-.6h6.8a.6.6 0 1 1 0 1.2H8.8a.6.6 0 0 1-.6-.6zm0 2.4a.6.6 0 0 1 .6-.6h6.8a.6.6 0 1 1 0 1.2H8.8a.6.6 0 0 1-.6-.6zm.2-7.4a.6.6 0 0 1 .6-.6h2.4a.6.6 0 0 1 .6.6v2.4a.6.6 0 0 1-.6.6H9a.6.6 0 0 1-.6-.6V8.2zm1.2.6V10h1.2V8.8H9.6z"
      fill="#20B575"
    />
  </svg>
);

export default IconShortcutFlyer;
