export const config = {
  "staging.cloudagentsuite.com": {
    lwUrl: "stg.lwolf.com", // This doesn't need a prefix. It'll be added automatically.
    lwAgentUrl: "https://agent.stg.lwolf.com",
    platformUrl: "https://platform.stg.lwolf.com",
    authenticationUrl: "https://authentication.api.stg.lwolf.com"
  },

  "cloudagentsuite.com": {
    lwUrl: "lwolf.com", // This doesn't need a prefix. It'll be added automatically.
    lwAgentUrl: "https://agent.lwolf.com",
    platformUrl: "https://platform.lwolf.com",
    authenticationUrl: "https://authentication.api.lwolf.com"
  },

  "localhost:3400": {
    lwUrl: "dev.lwolf.com", // This doesn't need a prefix. It'll be added automatically.
    lwAgentUrl: "http://localhost:3401",
    platformUrl: "https://platform.dev.lwolf.com",
    authenticationUrl: "https://authentication.api.dev.lwolf.com"
  }
}[window.location.host];
