import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledHeading = styled.div`
  ${({ theme, level }) => theme.heading[level]};
`;

const Heading = ({ level, children, ...props }) => (
  <StyledHeading as={`h${level}`} level={level} {...props}>
    {children}
  </StyledHeading>
);

Heading.propTypes = {
  level: PropTypes.oneOf(["1", "2", "3", "4", "5", "6"])
};

export default Heading;
