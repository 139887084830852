import React from "react";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import VisuallyHidden from "@reach/visually-hidden";
import Button from "./common/Button";
import { Row } from "../styled/Flexbox";
import Yup from "yup";
import fetch from "isomorphic-unfetch";
import SendIcon from "./icons/SendIcon";
import ErrorOutline from "./icons/ErrorOutline";
import CloseIcon from "./Launchpad/icons/CloseIcon";
import {
  ModalLayoutContainer,
  ModalBox,
  CloseModalButton,
  ConfirmationMessage,
  ConfirmationIconLayout,
  BackButtonLayout
} from "./styled/request-mls-button";
import { translate } from "../utils/locale";

const RequestMls = () => {
  const [state, send] = useMachine(machine);
  return (
    <>
      <Button onClick={() => send("OPEN")}>
        Request{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: translate("mls.label", "MLS").replace("®", "<sup>®</sup>")
          }}
        />
      </Button>
      <ModalLayoutContainer isOpen={state.matches("opened")}>
        <ModalBox>
          <CloseModalButton onClick={() => send("CLOSE")}>
            <CloseIcon />
            <VisuallyHidden>Close</VisuallyHidden>
          </CloseModalButton>
          <Formik
            initialValues={{ name: "", email: "", mls: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => send("SUBMIT", { values })}>
            {({ isValid, values }) => (
              <>
                {(state.matches("opened.idle") ||
                  state.matches("opened.submitting")) && (
                  <>
                    <h4>
                      Request{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translate("mls.label", "MLS").replace(
                            "®",
                            "<sup>®</sup>"
                          )
                        }}
                      />
                    </h4>
                    <p>
                      If you'd like to know when our products are available to
                      your{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translate("mls.label", "MLS").replace(
                            "®",
                            "<sup>®</sup>"
                          )
                        }}
                      />
                      , fill this out and we'll be in touch.
                    </p>
                    <Form>
                      <label>
                        <Row>
                          Name <ErrorMessage component="span" name="name" />
                        </Row>
                        <Field
                          name="name"
                          placeholder="First and Last please"
                          autoFocus
                        />
                      </label>
                      <label>
                        <Row>
                          Email <ErrorMessage component="span" name="email" />
                        </Row>
                        <Field name="email" placeholder="email@example.com" />
                      </label>
                      <label>
                        <Row>
                          <div>
                            Your{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: translate("mls.label", "MLS").replace(
                                  "®",
                                  "<sup>®</sup>"
                                )
                              }}
                            />
                            's Name
                          </div>
                          <ErrorMessage component="span" name="mls" />
                        </Row>
                        <Field
                          name="mls"
                          placeholder={`Your ${translate(
                            "mls.label",
                            "MLS"
                          )}'s Name`}
                        />
                      </label>
                      <Button
                        loading={state.matches("opened.submitting")}
                        disabled={
                          state.matches("opened.submitting") || !isValid
                        }
                        type="submit">
                        Submit Request
                      </Button>
                    </Form>
                  </>
                )}
                {state.matches("opened.success") && (
                  <ConfirmationMessage status="success">
                    <ConfirmationIconLayout>
                      <SendIcon />
                    </ConfirmationIconLayout>
                    <h4>Request sent</h4>
                    <BackButtonLayout>
                      <Button onClick={() => send("CLOSE")}>Go Back</Button>
                    </BackButtonLayout>
                  </ConfirmationMessage>
                )}
                {state.matches("opened.error") && (
                  <ConfirmationMessage status="error">
                    <ConfirmationIconLayout>
                      <ErrorOutline />
                    </ConfirmationIconLayout>
                    <h4>Whoops! There was an error sending your request</h4>
                    <p>
                      <a
                        href={`mailto:katie@wrstudios.com?subject=MLS Request for ${values.mls}&body=Please notify me when Cloud Agent Suite products become available to ${values.mls} members.`}>
                        Email the request instead
                      </a>
                    </p>
                  </ConfirmationMessage>
                )}
              </>
            )}
          </Formik>
        </ModalBox>
      </ModalLayoutContainer>
    </>
  );
};

const machine = Machine(
  {
    initial: "closed",
    states: {
      closed: {
        on: { OPEN: "opened" }
      },
      opened: {
        on: { CLOSE: "closed" },
        initial: "idle",
        states: {
          idle: {
            on: { SUBMIT: "submitting" }
          },
          submitting: {
            invoke: {
              src: "requestMLS",
              onDone: "success",
              onError: "error"
            }
          },
          success: { type: "final" },
          error: { type: "final" }
        }
      }
    }
  },
  {
    services: {
      requestMLS: (_, { values }) =>
        fetch(ZAPIER_WEBHOOK, {
          method: "POST",
          headers: { Accept: "application/json" },
          body: JSON.stringify(values)
        })
    }
  }
);

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mls: Yup.string()
    .min(1)
    .required(`We'll need the ${translate("mls.label", "MLS")} name please`)
});

/**
 * Zapier Zap: Request MLS Support for CAS
 * Edit this Zap: https://zapier.com/app/editor/33198242
 * View Zap's Task History: https://zapier.com/app/history?root_id=33198242
 */
const ZAPIER_WEBHOOK = "https://hooks.zapier.com/hooks/catch/279836/zcz75e/";

export default RequestMls;
