import React from "react";

const IconShortcutHomebeat = (props) => (
  <svg width={24} height={24} fill="none" {...props}>
    <rect width={24} height={24} rx={5} fill="#BCF5DD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.607 5.146a.6.6 0 0 1 .785 0l5.6 4.846a.6.6 0 0 1 .208.454V17.6a.6.6 0 0 1-.6.6H6.4a.6.6 0 0 1-.6-.6v-7.154a.6.6 0 0 1 .207-.454l5.6-4.846zM7 10.72V17h10v-6.28l-5-4.327-5 4.327z"
      fill="#20B575"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15.2l-.348-.344c-1.236-1.223-2.052-2.03-2.052-3.017 0-.807.58-1.439 1.32-1.439.417 0 .818.212 1.08.545a1.395 1.395 0 0 1 1.08-.545c.74 0 1.32.632 1.32 1.439 0 .987-.816 1.794-2.052 3.017L12 15.2z"
      fill="#20B575"
    />
  </svg>
);

export default IconShortcutHomebeat;
