import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 380px;
`;

export const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  .braintree-hosted-field {
    transition: all 200ms;
    height: 36px;
    border: 1px solid #d1d5db;
    margin-bottom: 12px;
    padding: 8px 12px;

    font-size: 34px;
    border-radius: 3px;
    &.braintree-hosted-fields-focused {
      border: 1px solid #2d335d;
    }
    &.braintree-hosted-fields-valid {
      border: 1px solid #35b379;
    }
    &.braintree-hosted-fields-invalid {
      border: 1px solid #f7555c;
    }
    input {
      font-family: "Circular", sans-serif;
      font-weight: normal;
      color: #576474;
      outline: none;
      &::placeholder {
        color: #c4c8d0;
      }
    }
  }
`;

export const FieldGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
