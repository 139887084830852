import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { createContext, useContext } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import ResourceLoader from "../common/ResourceLoader";

const GuestpassContext = createContext();

function GuestpassProvider({ children }) {
  const [getBundles, { data: { bundles } = {} }] = useLazyQuery(GET_BUNDLES);
  const { data: user } = useCurrentUser({
    onCompleted({ user }) {
      const mlsCode = user && user.mls ? user.mls.code : "";
      getBundles({ variables: { mlsCode } });
    }
  });

  return (
    <ResourceLoader isLoading={!user || !bundles}>
      <GuestpassContext.Provider value={{ user, bundles }}>
        {children}
      </GuestpassContext.Provider>
    </ResourceLoader>
  );
}

export const GET_BUNDLES = gql`
  query Bundles($mlsCode: String!) {
    bundles(mls_code: $mlsCode) {
      id
      key
      price
      billingCycle: billing_cycle
      discounts {
        id
        amount
        code
      }
      products {
        id
        name
        price
        key
        tagline
      }
    }
  }
`;

export const ISSUE_GUEST_PASS_MUTATION = gql`
  mutation issueGuestPass($jwt: String!) {
    issueGuestPass(jwt: $jwt) {
      id
    }
  }
`;

export const CHANGE_USER_PLAN = gql`
  mutation changeUserPlan(
    $sku: SkuInput!
    $paymentMethodNonce: String
    $discountId: ID
    $mlsId: ID
    $source: ChangePlanSource!
    $guestPassId: ID
    $guestPassConversion: Boolean
  ) {
    changeUserPlan(
      sku: $sku
      payment_method_nonce: $paymentMethodNonce
      discount_id: $discountId
      mls_id: $mlsId
      source: $source
      guest_pass_id: $guestPassId
      guest_pass_conversion: $guestPassConversion
    ) {
      user {
        subscription {
          sku {
            id
          }
          nextBillingPeriodAmount: next_billing_period_amount
          nextBillDate: next_bill_date
          price
          status
          balance
        }
      }
    }
  }
`;

export const useGuestpass = () => useContext(GuestpassContext);
export default GuestpassProvider;
