import { Link } from "@reach/router";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: calc(518px - (52 * 2px));
  &::before {
    content: "Loading...";
    margin: 0;
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 80px;
  animation: ${fadeIn} 200ms linear;
`;

export const PlanCard = styled.div`
  position: relative;
  display: flex;
  min-height: 184px;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
  @media (max-width: 600px) {
    flex-flow: column;
    align-items: center;
    height: auto;
    padding: 40px 20px 20px;
  }
`;

export const ProductBadgesLayout = styled.div`
  display: flex;
`;

export const PlanSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: auto;
  border-right: 1px solid ${({ theme }) => theme.colors.gray[500]};
  @media (max-width: 600px) {
    border-right: none;
  }
`;

export const PlanOptions = styled.div`
  align-self: center;
  padding: 16px 24px 16px 40px;
  margin: 0;

  @media (max-width: 600px) {
    padding: 24px 0 0 0;
    text-align: center;
  }
`;

export const PlanOptionsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const PlanOptionsListItem = styled.li`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #2d335d;
  cursor: pointer;
`;

export const PlanOptionsLink = styled(Link)`
  color: ${({ theme }) => theme.colors.suite[600]};
  text-decoration: underline;
`;

export const PlanOptionsExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.suite[600]};
  text-decoration: underline;
`;

export const PlanOptionsParagraph = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #566373;
`;

export const PlanOptionsHeading = styled.h4`
  font-weight: 500;
  font-size: 18px;
  color: #131945;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 0 11px;
  span {
    display: inline-block;
  }
`;

export const UpdatePaymentBlurb = styled.span`
  color: #566373;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
`;

export const ContactSupport = styled.span`
  color: #566373;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;

  a {
    text-decoration: underline;
  }
`;

export const EmailSupportLink = styled.a`
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.suite[500]};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
`;

export const AddonContainer = styled.div`
  margin-top: 40px;
`;

export const CreditCardContainer = styled.div`
  margin-top: 40px;
`;
