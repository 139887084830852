import React from "react";
import styled from "styled-components";
import theme from "../utils/theme";
import { RadioGroup, Radio } from "./common/Radio";
import { useSubscriptionWorkflow } from "./SubscriptionWorkflow";

function BillingCycleControls({ value, onChange }) {
  const { state } = useSubscriptionWorkflow();
  const { bestSavingsAvailable } = state.context;
  return (
    <Layout>
      <RadioGroup labelledBy="billingCycle" value={value} onChange={onChange}>
        <Radio value={1}>Monthly</Radio>
        <Radio value={12}>Annual</Radio>
      </RadioGroup>
      {bestSavingsAvailable > 0 && (
        <Savings>(Save up to {bestSavingsAvailable}%)</Savings>
      )}
    </Layout>
  );
}

const Layout = styled.div`
  position: relative;
  margin: 0 auto;

  @media (max-width: 576px) {
    margin: 0 0 0 auto;
  }
`;

const Savings = styled.span`
  position: absolute;
  color: ${theme.colors.suite[600]};
  font-weight: 500;

  @media (min-width: 577px) {
    top: 50%;
    right: 0;
    transform: translate(calc(100% + 8px), -50%);
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 576px) {
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    font-size: 12px;
  }
`;

export default BillingCycleControls;
