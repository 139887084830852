import React from "react";
import { useIsAuthed } from "../../../utils/auth";
import ErrorBoundary from "../../ErrorBoundary";
import Login from "../Login";

function AuthedRoutesWithoutNav({ children }) {
  const isAuthed = useIsAuthed();
  return isAuthed ? (
    <ErrorBoundary>
      <main>{children}</main>
    </ErrorBoundary>
  ) : (
    <Login path="login" />
  );
}

export default AuthedRoutesWithoutNav;
