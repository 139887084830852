// * we shouldn't need to import the theme here as its a child of ThemeProvider, but consuming the theme using the template literal invocation fails, so we doing a hard import instead
import theme from "../../utils/theme";
import { createGlobalStyle } from "styled-components";
import CircularEOT from "../../../assets/fonts/lineto-circular-book.eot";
import CircularSVG from "../../../assets/fonts/lineto-circular-book.svg";
import CircularTTF from "../../../assets/fonts/lineto-circular-book.ttf";
import CircularWOFF from "../../../assets/fonts/lineto-circular-book.woff";
import CircularMediumEOT from "../../../assets/fonts/lineto-circular-medium.eot";
import CircularMediumSVG from "../../../assets/fonts/lineto-circular-medium.svg";
import CircularMediumTTF from "../../../assets/fonts/lineto-circular-medium.ttf";
import CircularMediumWOFF from "../../../assets/fonts/lineto-circular-medium.woff";

export default createGlobalStyle`
  @font-face {
    font-family: 'Circular';
        src: url('${CircularEOT}');
        src: url('${CircularEOT}?#iefix') format('embedded-opentype'),
            url('${CircularWOFF}') format('woff'),
            url('${CircularTTF}') format('truetype'),
            url('${CircularSVG}#CircularSVG') format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Circular';
        src: url('${CircularMediumEOT}');
        src: url('${CircularMediumEOT}?#iefix') format('embedded-opentype'),
            url('${CircularMediumWOFF}') format('woff'),
            url('${CircularMediumTTF}') format('truetype'),
            url('${CircularMediumSVG}#CircularBoldSVG') format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Circular';
        src: url('${CircularMediumEOT}');
        src: url('${CircularMediumEOT}?#iefix') format('embedded-opentype'),
            url('${CircularMediumWOFF}') format('woff'),
            url('${CircularMediumTTF}') format('truetype'),
            url('${CircularMediumSVG}#CircularBoldSVG') format('svg');
    font-weight: 600;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
  }

  body {
    margin: 0;
    font-family: ${theme.fonts.marketing};
    font-size: 1.5rem;
    color: ${theme.colors.gray[700]};
    -webkit-font-smoothing: antialiased;
    background-color: ${theme.colors.gray[200]};
    transition: background-color 200ms;
  }

  input, textarea {
    font-family: ${theme.fonts.system};
  }

  ul {
    padding: 0;
    margin: 0;
  }

  button {
    font-family: ${theme.fonts.system};
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.suite[500]};
    &:hover {
      text-decoration: none !important;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.suite[900]};
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  strong {
    font-weight: 500;
    color: ${theme.colors.suite[900]};
  }

  [data-reach-menu] {
    display: block;
    position: absolute;
    z-index: 10;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;
