import styled, { keyframes } from "styled-components";
import IconLoading from "../../icons/IconLoading";

const rotate = keyframes`
  from {
    transform: rotate(0deg) scaleX(-1);
  }
  to {
    transform: rotate(360deg) scaleX(-1);
  }
`;

export const RotatingLoadingIcon = styled(IconLoading)`
  animation: ${rotate} linear infinite 800ms;
  transform-origin: center;
`;
