import styled from "styled-components";
import ClampLines from "react-clamp-lines";
import { DialogOverlay, DialogContent } from "@reach/dialog";

export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px;
  box-shadow: ${({ theme, isActive }) =>
    isActive ? theme.shadows.raised : "none"};
  border: ${({ theme, isActive }) =>
    isActive ? "none" : `1px solid ${theme.colors.gray[500]}`};
  background-color: ${({ theme, isActive }) =>
    isActive ? "white" : theme.colors.gray[200]};

  @media (max-width: 1023px) {
    padding: 14px 12px;
    flex-direction: row;
    border-radius: 0px;
  }
`;

export const ModalOverlay = styled(DialogOverlay)`
  background-color: rgba(0, 0, 0, 0.24);
  padding: 24px 12px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
`;

export const SetupModal = styled(DialogContent)`
  position: relative;
  width: 100%;
  max-width: 328px;
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  box-shadow: ${({ theme }) => theme.shadows.overlay};
`;

export const ModalCopyLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;

  > * {
    text-align: center;
  }
`;

export const InvisibleLabel = styled.span`
  color: transparent;
`;

export const BusyIconLayout = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;

  svg {
    fill: ${({ theme }) => theme.colors.suite[600]};
  }
`;

export const ButtonLayout = styled.div`
  width: 100%;

  @media (max-width: 1023px) {
    max-width: 102px;
    margin-left: auto;
  }

  /* Override default button & link font family */
  > * {
    font-family: ${({ theme }) => theme.fonts.system};
  }
`;

export const CopyLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;

  @media (max-width: 1023px) {
    align-items: flex-start;
    padding-bottom: 0px;
    padding-left: 12px;
  }
`;

export const ProductDescription = styled(ClampLines)`
  display: block;
  width: 100%;
  padding-right: 12px;
  color: ${({ theme }) => theme.colors.gray[800]};
  font-family: ${({ theme }) => theme.fonts.system};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;

  @media (max-width: 1023px) {
    text-align: left;
    font-size: 13px;
    line-height: 17px;
  }
`;

export const ProductLogoType = styled.span`
  color: ${({ theme, productKey }) =>
    theme.colors[productKey.split("_")[1]][500]};
  font-family: ${({ theme }) => theme.fonts.system};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  padding-bottom: 3px;

  @media (max-width: 1023px) {
    padding-bottom: 1px;
  }
`;
