import styled from "styled-components";
import { DialogOverlay, DialogContent } from "@reach/dialog";

export const ModalLayoutContainer = styled(DialogOverlay)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.9);
`;

export const ModalBox = styled(DialogContent)`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 514px;
  min-height: 490px;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  h4 {
    margin: 0 0 20px;
    color: #233040;

    font-size: 24px;
    line-height: 33px;
  }
  p {
    margin: 0 0 20px;
  }
  label {
    margin: 0 0 16px;
    color: #566373;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    &:last-of-type {
      margin-bottom: 24px;
    }
    .form-error {
      color: #e53a40;
      font-size: 13px;
      margin-left: auto;
    }
    span {
      display: block;
      color: ${({ theme }) => theme.colors.attract[500]};
      margin-left: auto;
      font-size: 13px;
      font-weight: 500;
    }
  }
  input {
    width: 100%;
    height: 42px;
    margin: 10px 0 0;
    border: 2px solid #c5c9d1;
    border-radius: 4px;
    outline: none;

    font-size: 16px;
    color: #566373;
    letter-spacing: 0;
    line-height: 20px;
    padding: 0 40px 0 10px;
    cursor: pointer;
    &::placeholder {
      color: #e1e5e9;
      font-size: 16px;
    }
    &.has-error {
      border: 2px solid #e53a40;
    }
  }
  button {
    margin-left: auto;
  }
`;

export const CloseModalButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  z-index: 1;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    stroke: ${({ theme }) => theme.colors.gray[700]};
  }
`;

export const ConfirmationIconLayout = styled.div`
  width: 48px;
  height: 48px;
  margin: 0 auto 20px;
`;

export const ConfirmationMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  svg {
    fill: ${({ status, theme }) => {
      switch (status) {
        case "success":
          return theme.colors.cma[500];
        case "error":
          return theme.colors.accent.red;
        default:
          return theme.colors.gray[700];
      }
    }};
  }
  h4 {
    margin: 0;
    font-size: 18px;
    color: #233040;
  }
`;

export const BackButtonLayout = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  button {
    margin: 0 auto;
  }
`;
