import ApolloClient from "apollo-boost";
import { ApolloClient as RealApolloClient } from "apollo-client";
import { ApolloLink, concat } from "apollo-link";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import fetch from "isomorphic-unfetch";
import { getCsrfToken } from "../utils/dom";

export const apolloClient = new ApolloClient({
  uri: "/graphql",
  fetch,
  request: (operation) => {
    operation.setContext({
      headers: {
        "X-GraphQL-Operation-Name": operation.operationName
      }
    });
  }
});

export const authApolloClient = new ApolloClient({
  uri: "/graphql/authenticated",
  fetch,
  request: (operation) => {
    const token =
      sessionStorage.getItem("impersonate") || localStorage.getItem("token");
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": operation.operationName
      }
    });
  },
  onError: ({ networkError }) => {
    if (networkError && networkError.statusCode === 401) {
      window.localStorage.removeItem("token");
      window.sessionStorage.removeItem("impersonate");
      window.location.href = `/app/login${window.location.search}`;
    }
  }
});

const authLink = new ApolloLink((operation, forward) => {
  const { useAuthedEndpoint } = operation.getContext();
  operation.setContext({
    uri: useAuthedEndpoint ? "/graphql/authenticated" : "/graphql",
    headers: {
      "X-GraphQL-Operation-Name": operation.operationName,
      ...(!useAuthedEndpoint && {
        "X-CSRF-Token": getCsrfToken()
      }),
      ...(useAuthedEndpoint && {
        authorization: `Bearer ${sessionStorage.getItem("impersonate") ||
          localStorage.getItem("token")}`
      })
    }
  });
  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    window.localStorage.removeItem("token");
    window.sessionStorage.removeItem("impersonate");
    window.location.href = `/app/login${window.location.search}`;
  }
});

const httpLink = createUploadLink({
  uri: "/graphql",
  fetch
});

export const apiClient = new RealApolloClient({
  link: concat(authLink, httpLink, errorLink),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case "Sku":
          return object.key; // use the `key` field as the identifier
        case "Payment":
          return object.last_4;
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    }
  })
});
