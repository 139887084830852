import React from "react";
import ErrorState from "./ErrorState";
import { airbrake } from "../utils/errors";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.airbrake = airbrake;
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    if (process.env.RAILS_ENV === "production") {
      this.airbrake.notify({
        error: error,
        params: { info: info }
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { showOnError } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return showOnError ? showOnError : <ErrorState />;
    }
    return this.props.children;
  }
}
