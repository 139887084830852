import React from "react";

function ArrowLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M13.53 3.47c.3.3.3.77 0 1.06L8.06 10l5.47 5.47a.75.75 0 11-1.06 1.06L5.94 10l6.53-6.53c.3-.3.77-.3 1.06 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ArrowLeft;
