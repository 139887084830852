import React from "react";
import PropTypes from "prop-types";
import { Spring } from "react-spring";
import Rect from "@reach/rect";
import { Container, Content } from "../styled/Accordion";

class Accordion extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    visibilityThreshold: PropTypes.number,
    afterOpen: PropTypes.func,
    afterClose: PropTypes.func
  };

  static defaultProps = {
    visibilityThreshold: 96
  };

  containerRef = React.createRef();

  state = {
    finishedAnimating: false,
    loading: true,
    dimensions: {
      height: 0
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <Spring
        from={{ height: isOpen ? 0 : this.state.dimensions.height }}
        to={{ height: isOpen ? this.state.dimensions.height : 0 }}
        immediate={this.state.loading}
        onRest={this.handleOnRest}
        onStart={() => {
          this.setState({ finishedAnimating: false });
          this.containerRef.current.style.overflow = "hidden";
        }}
        onFrame={({ height }) => {
          if (
            (height / this.state.dimensions.height) * 100 >
            this.props.visibilityThreshold
          ) {
            this.toggleOverflowVisibility();
          }
        }}>
        {(styles) => (
          <Container style={styles} ref={this.containerRef}>
            <Rect
              onChange={(dimensions) => this.setState({ dimensions })}
              observe={isOpen}>
              {({ ref }) => <Content ref={ref}>{this.props.children}</Content>}
            </Rect>
          </Container>
        )}
      </Spring>
    );
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.containerRef.current.style.display = "flex";
    }
  }

  handleOnRest = () => {
    if (this.props.isOpen) {
      this.props.afterOpen && this.props.afterOpen();
    } else {
      this.containerRef.current.style.display = "none";
      this.props.afterClose && this.props.afterClose();
    }
  };

  toggleOverflowVisibility = () => {
    this.containerRef.current.style.overflow = this.props.isOpen
      ? "visible"
      : "hidden";
  };
}

export default Accordion;
