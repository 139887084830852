import React, { useRef, useState, useLayoutEffect } from "react";
import { Braintree, HostedField } from "react-braintree-fields";
import { useFormikContext } from "formik";
import { airbrake } from "../utils/errors";
import { useSubscriptionWorkflow } from "./SubscriptionWorkflow";
import PromoCode from "./PromoCode";
import {
  Container,
  FormHeading,
  FormGroup,
  FormField,
  Label,
  braintreeFormStyles,
  HostedFieldWrapper
} from "./styled/billing-fields";
import FlashError from "./CheckoutForm/FlashError";

export default function BillingFields({ shouldSetFocus }) {
  const [paymentFieldsAuthed, setPaymentFieldsAuthed] = useState(false);
  const { state, send } = useSubscriptionWorkflow();
  const { braintreeToken, signupErrors } = state.context;
  const formik = useFormikContext();

  const ccNumberField = useRef();
  const expirationField = useRef();
  const cvvField = useRef();
  const billingZipField = useRef();

  useLayoutEffect(() => {
    if (shouldSetFocus && paymentFieldsAuthed) {
      ccNumberField.current && ccNumberField.current.focus();
    }
  }, [shouldSetFocus, paymentFieldsAuthed, ccNumberField.current]);

  function handleBraintreeError(error) {
    // ! this is pretty noisy in dev, so disabling for now
    // airbrake.notify({ error });
    console.error("Braintree Error", error);
  }

  function handleLabelClick(ref) {
    ref.current && ref.current.focus();
  }

  function handleFocus({ container, isFocused }) {
    const fieldName = container.firstChild.id.split("-")[3];
    const shouldValidate = true;
    formik.setFieldTouched(fieldName, isFocused, shouldValidate);
  }

  function handleValidityChange({ container, isValid }) {
    const fieldName = container.firstChild.id.split("-")[3];
    const shouldValidate = true;
    formik.setFieldValue(fieldName, isValid, shouldValidate);
  }

  return (
    <Container>
      <Braintree
        authorization={braintreeToken}
        onAuthorizationSuccess={() => setPaymentFieldsAuthed(true)}
        getTokenRef={(tokenRef) =>
          send("SET_BRAINTREE_TOKEN_REF", { tokenRef })
        }
        onError={handleBraintreeError}
        styles={braintreeFormStyles}>
        <FormHeading variant="displayExtraSmall" as="h3">
          Card information
        </FormHeading>
        <FormField data-number="true">
          <Label onClick={() => handleLabelClick(ccNumberField)}>
            Card number
          </Label>
          <HostedFieldWrapper>
            <HostedField
              type="number"
              placeholder="Card number"
              ref={ccNumberField}
              onValidityChange={handleValidityChange}
              onFocus={handleFocus}
            />
          </HostedFieldWrapper>
        </FormField>
        <FormGroup>
          <FormField>
            <Label onClick={() => handleLabelClick(expirationField)}>
              Expiration
            </Label>
            <HostedFieldWrapper type="expiration">
              <HostedField
                type="expirationDate"
                placeholder="MM/YY"
                ref={expirationField}
                onValidityChange={handleValidityChange}
              />
            </HostedFieldWrapper>
          </FormField>
          <FormField data-cvv="true">
            <Label onClick={() => handleLabelClick(cvvField)}>CVV</Label>
            <HostedFieldWrapper type="cvv">
              <HostedField
                type="cvv"
                placeholder="CVV"
                ref={cvvField}
                onValidityChange={handleValidityChange}
              />
            </HostedFieldWrapper>
          </FormField>
          <FormField>
            <Label onClick={() => handleLabelClick(billingZipField)}>
              Billing Zip Code
            </Label>
            <HostedFieldWrapper>
              <HostedField
                type="postalCode"
                placeholder="00000"
                ref={billingZipField}
                onValidityChange={handleValidityChange}
              />
            </HostedFieldWrapper>
          </FormField>
        </FormGroup>
        <PromoCode />
      </Braintree>
      {signupErrors && <FlashError errors={signupErrors} />}
    </Container>
  );
}
