import "core-js/stable";
import "regenerator-runtime/runtime";
import "@reach/menu-button/styles.css";
import React from "react";
import { render } from "react-dom";
import { ApolloProvider } from "react-apollo";
import { loadExternalLibraries } from "../utils/external";
import { apiClient } from "../graphql/client";
import App from "../components/Launchpad/App";

document.addEventListener("DOMContentLoaded", () => {
  render(
    <ApolloProvider client={apiClient}>
      <App />
    </ApolloProvider>,
    document.getElementById("launchpad-root")
  );
  loadExternalLibraries();
});
