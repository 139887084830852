import React from "react";
import styled, { keyframes } from "styled-components";
import { translate } from "../../utils/locale";

export const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-bottom: 48px;
`;

export const PlanMiniCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 184px;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
`;

export const CardLabel = styled.span`
  position: absolute;
  top: 11px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 12px;
  font-weight: normal;
  color: ${({ color }) => (color ? color : "#89929D")};
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin: 7px 0 0;

  font-size: 24px;
  color: #131945;
  letter-spacing: 0;
  line-height: 30px;
`;

const PlanTitleElem = styled.h4`
  font-weight: 500;
  margin-top: 0;
`;

export const PlanTitle = ({ children, ...props }) => (
  <PlanTitleElem {...props}>
    {children === "Site License" ? (
      <span
        dangerouslySetInnerHTML={{
          __html: `Provided by ${translate("mls.label", "MLS").replace(
            "®",
            "<sup>®</sup>"
          )}`
        }}
      />
    ) : (
      children
    )}
  </PlanTitleElem>
);

export const ProductBadgesLayout = styled.div`
  display: flex;
`;

export const PlanTitleLayout = styled.div`
  margin: 0 auto 12px;
`;

export const PlanPriceLayout = styled.div`
  margin: 7px auto 0;
`;

const motionRight = keyframes`
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(10px);
  }
  20% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0)
  }
`;

const ArrowWrapper = styled.div`
  animation: infinite ${motionRight} 3s ease-in-out;
`;

export const Arrow = () => (
  <ArrowWrapper>
    <svg width="36" height="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 0l-1.41 1.41L32.17 7H0v2h32.17l-5.58 5.59L28 16l8-8z"
        fill="#9013FE"
        fillRule="nonzero"
      />
    </svg>
  </ArrowWrapper>
);
