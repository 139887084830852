import { useEffect, useLayoutEffect, useRef, useCallback } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import computeScrollIntoView from "compute-scroll-into-view";

export function isInViewport(elem) {
  const boundingClientRect = elem.getBoundingClientRect();
  return (
    boundingClientRect.top >= 0 &&
    boundingClientRect.left >= 0 &&
    boundingClientRect.right <=
      (window.innerWidth || document.documentElement.clientWidth) &&
    boundingClientRect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight)
  );
}

export function useBodyScrollLock() {
  useEffect(() => {
    disableBodyScroll(document.body);
    return () => enableBodyScroll(document.body);
  }, []);
}

/**
 *  useOutsideClick hook
 *
 * Checks if a click happened outside a Ref. Handy for dropdowns, modals and popups etc.
 *
 * @param ref Ref whose outside click needs to be listened to
 * @param handler Callback to fire on outside click
 * @param when A boolean which which activates the hook only when it is true. Useful for conditionally enable the outside click
 */
export function useOutsideClick(ref, handler, when = true) {
  const savedHandler = useRef(handler);

  const memoizedCallback = useCallback((e) => {
    if (ref && ref.current && !ref.current.contains(e.target)) {
      savedHandler.current(e);
    }
  }, []);

  useEffect(() => {
    savedHandler.current = handler;
  });

  useEffect(() => {
    if (when) {
      document.addEventListener("click", memoizedCallback);
      document.addEventListener("ontouchstart", memoizedCallback);
      return () => {
        document.removeEventListener("click", memoizedCallback);
        document.removeEventListener("ontouchstart", memoizedCallback);
      };
    }
  }, [ref, handler, when]);
}

export function scrollIntoView(targetNode, containerNode) {
  if (targetNode === null) {
    return;
  }

  const actions = computeScrollIntoView(targetNode, {
    boundary: containerNode,
    scrollMode: "if-needed",
    block: "nearest"
  });
  actions.forEach(({ el, top, left }) => {
    el.scrollTop = top;
    el.scrollLeft = left;
  });
}

export function useScrollIntoView(targetNode, containerNode, dependencies) {
  useLayoutEffect(() => {
    scrollIntoView(targetNode, containerNode);
  }, dependencies);
}

export function getCsrfToken() {
  const csrfTokenNode = document.querySelector("[name=csrf-token]");
  return csrfTokenNode ? csrfTokenNode.content : null;
}
