import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { useLaunchpad } from "./LaunchpadProvider";

function LaunchpadHeading() {
  const { user } = useLaunchpad();
  const todaysDate = format(Date.now(), "dddd, MMMM Do YYYY");
  return (
    <HeadingLayout>
      <EyebrowText>{todaysDate}</EyebrowText>
      <Heading data-testid="launchpad-greeting">
        Hi {user.name.split(" ")[0]}, what do we need to get done today?
      </Heading>
    </HeadingLayout>
  );
}

const HeadingLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;

  @media (max-width: 1023px) {
    padding: 24px 0;
    margin: 0 auto;
  }

  @media (max-width: 512px) {
    max-width: 270px;
  }
`;

const EyebrowText = styled.span`
  font-family: ${({ theme }) => theme.fonts.system};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
`;

const Heading = styled.h1`
  ${({ theme }) => theme.text.displayMedium};
  font-family: ${({ theme }) => theme.fonts.system};
  margin: 0;
  text-align: center;
`;

export default LaunchpadHeading;
