import React from "react";
import useUserBilling from "../../../hooks/useUserBilling";
import UserPlan from "../../UserPlan";
import Loading from "../../common/Loading";
import PageHeading from "../PageHeading";

function Billing() {
  const { loading, data } = useUserBilling();

  if (loading) return <Loading />;

  return (
    <>
      <PageHeading>My Account</PageHeading>
      <UserPlan data={data} />
    </>
  );
}

export default Billing;
