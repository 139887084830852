import React from "react";
import PageHeading from "../PageHeading";
import SubscriptionWorkflow from "../../SubscriptionWorkflow";

export default () => (
  <>
    <PageHeading>Change Plan</PageHeading>
    <SubscriptionWorkflow mode="edit" />
  </>
);
