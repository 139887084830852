import React, { useRef } from "react";
import { useFormikContext } from "formik";
import { Braintree } from "react-braintree-fields";
import tlcBadge from "../images/tlc-guarantee.svg";
import Button from "./common/Button";
import EditPaymentFields from "./CheckoutForm/EditPaymentFields";
import { braintreeFormStyles } from "./styled/billing-fields";
import PromoCode from "./PromoCode";
import { useSubscriptionWorkflow } from "./SubscriptionWorkflow";
import {
  Stack,
  CardContainer,
  Heading,
  TLCBadge
} from "./styled/new-credit-card";

export default function NewCreditCard({ onPlanChange }) {
  const { state, send } = useSubscriptionWorkflow();
  const { braintreeToken } = state.context;
  const { isValid } = useFormikContext();
  const numberField = useRef();

  return (
    <Stack>
      <CardContainer>
        <Heading>Payment</Heading>
        <Braintree
          authorization={braintreeToken}
          getTokenRef={(tokenRef) =>
            send("SET_BRAINTREE_TOKEN_REF", { tokenRef })
          }
          onAuthorizationSuccess={() =>
            numberField.current && numberField.current.focus()
          }
          styles={braintreeFormStyles}>
          <EditPaymentFields numberFieldRef={numberField} />
        </Braintree>
        <TLCBadge src={tlcBadge} />
      </CardContainer>

      <PromoCode />

      <Button
        onClick={onPlanChange}
        disabled={
          !isValid ||
          state.matches("billing.subscription.edit.upgradingToPaidPlan")
        }
        loading={state.matches(
          "billing.subscription.edit.upgradingToPaidPlan"
        )}>
        Change Plan
      </Button>
    </Stack>
  );
}
