import React from "react";
import cloudAgentSuiteLogo from "../../../images/cloud-agent-suite-logo--black.svg";
import LeftArrow from "../icons/LeftArrow";
import IconPrint from "../icons/IconPrint";
import { useInvoices } from "./InvoicesRoute";
import {
  HideHeader,
  PageLayout,
  InvoiceNav,
  BackLink,
  PrintButton,
  IconLayout,
  InvoiceHeader,
  HeaderLeft,
  InvoiceHeading,
  Table,
  HeaderRight,
  InvoiceTable,
  Footer
} from "./styled/invoice-route";

const InvoiceRoute = ({ invoiceId }) => {
  const { loading, transactions, user } = useInvoices();
  const transaction = transactions.find((t) => t.id === invoiceId);

  if (loading) return null;
  return (
    <>
      <HideHeader />
      <InvoiceNav>
        <BackLink to="/app/billing/invoices">
          <IconLayout>
            <LeftArrow />
          </IconLayout>
          All Invoices
        </BackLink>
        <PrintButton onClick={window.print}>
          <IconLayout>
            <IconPrint />
          </IconLayout>
          Print
        </PrintButton>
      </InvoiceNav>
      <PageLayout>
        <InvoiceHeader>
          <HeaderLeft>
            <img src={cloudAgentSuiteLogo} alt="Cloud Agent Suite" />
            <InvoiceHeading>Invoice</InvoiceHeading>
            <Table>
              <tbody>
                <tr>
                  <td>Invoice ID</td>
                  <td>{transaction.id}</td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>{transaction.paidOn}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{transaction.skuName}</td>
                </tr>
              </tbody>
            </Table>
          </HeaderLeft>
          <HeaderRight>
            <Table>
              <tbody>
                <tr>
                  <td valign="top" align="right">
                    From
                  </td>
                  <td valign="top">
                    <h2>Cloud Agent Suite</h2>
                    <h2>W+R Studios</h2>
                    <p>
                      123 Main Street, Ste. 201 <br />
                      Huntington Beach, CA 92648 <br />
                      EIN - 26 - 3512777
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td valign="top" style={{ paddingTop: "29pt" }}>
                    Invoice For
                  </td>
                  <td valign="top" style={{ paddingTop: "29pt" }}>
                    <h2>{user.name}</h2>
                    <p>{user.email}</p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </HeaderRight>
        </InvoiceHeader>
        <InvoiceTable>
          <tbody>
            <tr style={{ borderBottom: "1px solid black" }}>
              <th align="left">Description</th>
              <th align="right">Unit Price</th>
              <th align="right">Quantity</th>
              <th align="right">Amount</th>
            </tr>
            <tr>
              <td align="left">
                {transaction.skuName} {transaction.skuType}
              </td>
              <td align="right">{transaction.formattedAmount}</td>
              <td align="right">1</td>
              <td align="right">{transaction.formattedAmount}</td>
            </tr>
            <tr className="grey">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="tall">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="short">
              <td></td>
              <td align="right" className="small">
                Subtotal
              </td>
              <td></td>
              <td align="right" className="small dark">
                {transaction.formattedAmount}
              </td>
            </tr>
            <tr className="short">
              <td></td>
              <td align="right" className="small">
                Total
              </td>
              <td></td>
              <td align="right" className="small dark">
                {transaction.formattedAmount}
              </td>
            </tr>
            <tr className="short">
              <td></td>
              <td align="right">
                <h2>Amount Due</h2>
              </td>
              <td></td>
              <td align="right">
                <h2>{transaction.formattedAmount}</h2>
              </td>
            </tr>
          </tbody>
        </InvoiceTable>
        <Footer>
          If you have any questions about this invoice, please email us at
          support@cloudagentsuite.com
        </Footer>
      </PageLayout>
    </>
  );
};

export default InvoiceRoute;
