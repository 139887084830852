import { useMutation } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import { SIGN_IN_USER } from "../../graphql/mutations";
import {
  PRODUCT_APP_BY_KEY,
  PRODUCT_KEYS,
  PRODUCT_TITLES
} from "../../modules/products";
import { config } from "../../utils/lw-platform-config";
import { useQueryString } from "../../utils/url";
import Button from "../common/Button";
import CloudAttract from "../icons/product/round/CloudAttract";
import CloudCma from "../icons/product/round/CloudCma";
import CloudMlx from "../icons/product/round/CloudMlx";
import CloudStreams from "../icons/product/round/CloudStreams";
import ArrowLeft from "./icons/ArrowLeft";
import CloudAgentSuite from "./icons/CloudAgentSuite";
import CloudIcon from "./icons/CloudIcon";
import {
  Arrow,
  CloudIconLayout,
  Container,
  Content,
  Footer,
  FormContainer,
  FormHeading,
  FormInstructions,
  Header,
  InputWrapper,
  LWSSOLogin,
  LWSSOLoginIcon,
  LWSSOLoginText,
  LogoLink,
  ProductIcon,
  ProductIcons,
  Register,
  ResetOption,
  ResetPasswordNotice,
  SubmissionError,
  TextField
} from "./styled/login";

function Login() {
  const params = useQueryString();
  const [email, setEmail] = useState(params.email || "");
  const [password, setPassword] = useState("");
  const [isShowingPasswordResetMsg, setIsShowingPasswordResetMsg] =
    useState(false);
  const [error, setError] = useState(null);

  const [signInUser, { loading }] = useMutation(SIGN_IN_USER, {
    onCompleted: (data) => {
      const pathName = window.location.pathname;
      const user = data.signInUser;
      const redirectToPlatformLogin =
        pathName.includes("cloud_mlx") && user.lwa_jwt;
      const changePlanRedirectUrl = params.r;

      if (!redirectToPlatformLogin) {
        localStorage.setItem("token", user.jwt);
      }

      if (pathName === "/app/login") {
        if (changePlanRedirectUrl) {
          window.location.href = `${changePlanRedirectUrl}?code=${user.mlsCredential.code}&jwt=${user.jwt}`;
        } else {
          navigate("/app/launchpad");
        }
      } else if (redirectToPlatformLogin) {
        navigate(`${getLwPlatformUrl()}/product/launch?a=cm`);
      } else {
        navigate(pathName + window.location.search);
      }
    },
    onError: setError
  });

  useEffect(() => {
    const token = sessionStorage.getItem(
      "token" || localStorage.getItem("token")
    );
    tokenIsValid(token)
      ? navigate("/app/launchpad")
      : localStorage.removeItem("token");

    if (document.referrer.includes("/users/password/new")) {
      setIsShowingPasswordResetMsg(true);
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    signInUser({
      variables: {
        auth: {
          email,
          password
        }
      }
    });
  }

  const [, , , productKey] = window.location.pathname.split("/");
  const isExternalLogin = Object.values(PRODUCT_KEYS).includes(productKey);
  const productLWCode =
    productKey === "cloud_cma"
      ? "cm"
      : productKey === "cloud_streams"
      ? "cs"
      : productKey === "cloud_mlx"
      ? "cm"
      : productKey === "cloud_attract"
      ? "ca"
      : "";

  return (
    <Div100vh>
      <Container>
        {isExternalLogin && (
          <Header>
            <LogoLink href="/" title="Cloud Agent Suite Logo">
              <Arrow>
                <ArrowLeft />
              </Arrow>
              <CloudAgentSuite />
            </LogoLink>
            <Register href="/pricing" variant="secondary">
              Create an Account
            </Register>
          </Header>
        )}
        <Content>
          {!isExternalLogin && (
            <CloudIconLayout href="/" title="Cloud Agent Suite Logo">
              <CloudIcon />
            </CloudIconLayout>
          )}
          <FormContainer isExternalLogin={isExternalLogin}>
            {isExternalLogin && (
              <ProductIcons>
                <ProductIcon isActive={productKey === "cloud_cma"}>
                  <CloudCma />
                </ProductIcon>
                <ProductIcon isActive={productKey === "cloud_streams"}>
                  <CloudStreams />
                </ProductIcon>
                <ProductIcon isActive={productKey === "cloud_mlx"}>
                  <CloudMlx />
                </ProductIcon>
                <ProductIcon isActive={productKey === "cloud_attract"}>
                  <CloudAttract />
                </ProductIcon>
              </ProductIcons>
            )}
            <FormHeading isExternalLogin={isExternalLogin}>
              {isExternalLogin
                ? `Sign in to ${PRODUCT_TITLES[productKey]}`
                : "Nice to see you, Cloud Agent!"}
            </FormHeading>
            {isExternalLogin && (
              <LWSSOLogin
                href={`${config.platformUrl}/product/launch?a=${productLWCode}`}>
                <LWSSOLoginIcon>
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.04838 5.30995C9.09029 4.97007 9.17999 4.02309 9.48294 3.21615C9.72999 2.55379 9.81382 2.51443 10.3697 2.9338C10.7866 3.24794 10.9087 3.50077 10.8638 3.99886C10.8315 4.35237 10.8175 4.82624 10.8175 5.1964C11.2366 3.9209 11.5653 3.99811 11.5653 3.99811C11.7396 3.98145 11.9741 4.34405 11.9741 4.34405C12.1587 4.61505 12.0123 5.10103 11.9315 5.27362C11.9315 5.27362 11.3322 6.91247 11.2109 7.85491C11.2109 7.85491 11.0763 8.43249 11.0373 8.72544C10.9204 9.60277 10.8697 9.81019 10.8498 10.6936C10.8396 11.1092 10.7851 11.5558 11.0219 12.4695C11.1043 12.7972 11.5579 14.3066 11.169 16C12.8154 15.4002 14.2259 14.2621 15.1851 12.7597C16.1444 11.2572 16.5998 9.47288 16.4817 7.67903C16.3636 5.88519 15.6785 4.1804 14.5311 2.82493C13.3837 1.46947 11.8369 0.537787 10.127 0.172129C8.41703 -0.19353 6.63782 0.0269227 5.06097 0.799831C3.48411 1.57274 2.19626 2.85564 1.39401 4.45268C0.591754 6.04972 0.319182 7.87316 0.617901 9.64463C0.91662 11.4161 1.77022 13.0383 3.04838 14.2635C3.11088 14.1363 3.17044 14.0069 3.22559 13.8729C3.42706 13.3846 3.74029 12.938 4.04176 12.5005C4.43073 11.9365 4.85794 11.4006 5.2697 10.851C5.26309 10.8419 5.25573 10.8253 5.24397 10.8185C4.31162 10.3196 4.25353 10.2 5.08073 9.53162C5.90794 8.86321 6.51088 8.11456 6.81603 7.04343C7.01088 6.16609 7.34838 5.53704 8.04838 5.31222" />
                  </svg>
                </LWSSOLoginIcon>
                <LWSSOLoginText>Sign in with Lone Wolf Account</LWSSOLoginText>
              </LWSSOLogin>
            )}
            <form onSubmit={handleSubmit}>
              {!isExternalLogin && (
                <FormInstructions>
                  Please enter your email and password
                </FormInstructions>
              )}
              <InputWrapper>
                {isShowingPasswordResetMsg && (
                  <ResetPasswordNotice>
                    Please check your email for
                    <br /> password reset instructions.
                  </ResetPasswordNotice>
                )}
                {error && (
                  <SubmissionError>
                    Incorrect email and/or password
                  </SubmissionError>
                )}
                <TextField productKey={productKey}>
                  <label htmlFor="login-email">Email</label>
                  <input
                    autoFocus
                    type="email"
                    value={email}
                    placeholder="you@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                    data-testid="email"
                    id="login-email"
                  />
                </TextField>
                <TextField productKey={productKey}>
                  <label htmlFor="login-password">Password</label>
                  <input
                    type="password"
                    value={password}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    data-testid="password"
                    id="login-password"
                  />
                </TextField>
              </InputWrapper>
              <div className={"helix-w-100-percent flex"}>
                <div className={isExternalLogin ? "" : "helix-mx-10"}>
                  <Button
                    fullwidth={isExternalLogin}
                    size={isExternalLogin ? "medium" : "large"}
                    loading={loading}
                    app={
                      isExternalLogin ? PRODUCT_APP_BY_KEY[productKey] : "suite"
                    }>
                    {isExternalLogin ? "Sign In" : "Log in"}
                  </Button>
                </div>
              </div>
            </form>
            {!isExternalLogin && (
              <ResetOption>
                Forgot Password?{" "}
                <a href="/users/password/new">Reset it by email</a>
              </ResetOption>
            )}
            {isExternalLogin && (
              <ResetOption isExternalLogin productKey={productKey}>
                <a href="/users/password/new">Forgot Password?</a>
              </ResetOption>
            )}
          </FormContainer>
        </Content>
        {isExternalLogin && (
          <Footer>
            <ul>
              <li>
                <a href="/tos" target="_blank">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="http://wrstudios.com/privacy_policy" target="_blank">
                  Privacy
                </a>
              </li>
              <li>
                <a href="http://wrstudios.com/dmca_policy" target="_blank">
                  DMCA
                </a>
              </li>
            </ul>
          </Footer>
        )}
      </Container>
    </Div100vh>
  );
}

function tokenIsValid(token) {
  if (!!token) {
    return false;
  }
  return Boolean(token) && decode(token).exp > Math.floor(Date.now() / 1000);
}

export default Login;
