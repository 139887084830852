import { useQuery } from "react-apollo";
import { GET_AUTHED_USER } from "../api/queries/user";
import { useIsAuthed } from "../utils/auth";

export default function useCurrentUser(options = {}) {
  const isAuthed = useIsAuthed();
  const { data, ...rest } = useQuery(GET_AUTHED_USER, {
    context: { useAuthedEndpoint: true },
    skip: !isAuthed,
    ...options
  });

  return { ...rest, data: data ? data.user : undefined };
}
