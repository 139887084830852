import React from "react";
import styled from "styled-components";
import theme from "../utils/theme";
import Text from "./common/Text";
import PlanPrice from "./PlanPrice";
import PlanProduct from "./PlanProduct";
import { useSubscriptionWorkflow, useFinalPrice } from "./SubscriptionWorkflow";
import {
  Container as PlanPriceContainer,
  PaymentInterval as PlanPricePaymentInterval,
  Free as PlanPriceFree
} from "./styled/plan-price";

function PlanOverview() {
  const { state } = useSubscriptionWorkflow();
  const { selectedPlan, selectedMls } = state.context;
  const finalPrice = useFinalPrice();
  const showHomebeat =
    selectedPlan.key !== "site_license" && selectedMls.hasHomebeat;

  return (
    <Container>
      <Text variant="displaySmall" as="h3">
        {selectedPlan.name}
      </Text>
      {!["suite", "annual_suite"].includes(selectedPlan.key) && (
        <Text variant="heading" as="span">
          {selectedPlan.tagline}
        </Text>
      )}
      <SelectedPlanProductsContainer>
        <SelectedPlanProducts>
          {selectedPlan.products.map(({ key }) => (
            <Plan key={key}>
              <PlanProduct productKey={key} hasHomebeat={showHomebeat} />
            </Plan>
          ))}
        </SelectedPlanProducts>
      </SelectedPlanProductsContainer>
      <PlanPricing>
        <PlanPrice
          planKey={selectedPlan.key}
          billingCycle={selectedPlan.billingCycle}
          planPrice={selectedPlan.anualPriceFromMonthlyPrice}
          finalPrice={finalPrice}
        />
      </PlanPricing>
    </Container>
  );
}

// Styled Components
const Container = styled.div`
  padding: 24px;
  min-width: 328px;
  background-color: ${theme.colors.gray[200]};
  border: 1px solid ${theme.colors.gray[500]};
  border-radius: 6px;

  ${Text}:first-of-type {
    margin-bottom: 8px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const SelectedPlanProductsContainer = styled.div`
  min-height: 82px;
  margin: 24px 0 21px;
  overflow: hidden;
`;

const SelectedPlanProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
`;

const Plan = styled.div`
  padding: 8px;
`;

const PlanPricing = styled.div`
  display: flex;
  align-items: center;
  min-height: 38px;

  ${PlanPriceContainer} {
    ${theme.text.displayMedium}
  }

  ${PlanPricePaymentInterval} {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: ${theme.colors.gray[800]};
  }

  ${PlanPriceFree} {
    text-transform: capitalize;
  }
`;

export default PlanOverview;
