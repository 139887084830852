import React from "react";

const IconShortcutAttractBuyers = (props) => (
  <svg width={25} height={24} fill="none" {...props}>
    <rect x={0.232} width={24} height={24} rx={5} fill="#FFCCCD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.833 15.2a3.8 3.8 0 0 1 3.8-3.8h3.2a3.8 3.8 0 0 1 3.8 3.8v2.4a.6.6 0 0 1-.6.6h-9.6a.6.6 0 0 1-.6-.6v-2.4zm3.8-2.6a2.6 2.6 0 0 0-2.6 2.6V17h8.4v-1.8a2.6 2.6 0 0 0-2.6-2.6h-3.2z"
      fill="#FD5154"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.233 8a3 3 0 0 1 6 0v.8a3 3 0 1 1-6 0V8zm3-1.8a1.8 1.8 0 0 0-1.8 1.8v.8a1.8 1.8 0 0 0 3.6 0V8a1.8 1.8 0 0 0-1.8-1.8z"
      fill="#FD5154"
    />
    <path
      d="M13.033 14.8a.4.4 0 0 1 .4-.4h1.6a.4.4 0 0 1 0 .8h-1.6a.4.4 0 0 1-.4-.4z"
      fill="#FD5154"
    />
  </svg>
);

export default IconShortcutAttractBuyers;
