import React, { useEffect } from "react";
import styled from "styled-components";
import theme from "../utils/theme";
import BaseButton from "./common/Button";
import { useField, useFormikContext } from "formik";
import { useSubscriptionWorkflow, useFinalPrice } from "./SubscriptionWorkflow";
import TextField from "./common/TextField";

export default function PromoCode() {
  const { state, send } = useSubscriptionWorkflow();
  const { promo, validationErrors } = state.context;
  const formikContext = useFormikContext();
  const [promoField] = useField("promo");
  const [_, meta] = useField("email");
  const finalPrice = useFinalPrice();
  const isUsingWRPromoWithoutEmployeeEmail =
    meta.error === validationErrors.EMPLOYEE_EMAIL && promo === "wrstudios";

  useEffect(() => {
    formikContext.setFieldValue("price", finalPrice);
  }, [promo]);

  return (
    <>
      <Container>
        <TextField>
          <label htmlFor="signup-promo">Promo code</label>
          <input {...promoField} id="signup-promo" />
        </TextField>
        <ApplyButton
          variant="secondary"
          type="button"
          onClick={() =>
            send("APPLY_PROMOCODE", { promo: promoField.value.toLowerCase() })
          }>
          Apply
        </ApplyButton>
      </Container>
      {isUsingWRPromoWithoutEmployeeEmail && (
        <EmployeePromoReminder>
          <span>{validationErrors.EMPLOYEE_EMAIL}.</span>
          <button onClick={() => send("EDIT_MLS")}>Change Email</button>
        </EmployeePromoReminder>
      )}
    </>
  );
}

// Styled Components
export const Container = styled.div`
  display: inline-flex;
  align-items: flex-end;

  ${TextField} {
    margin-bottom: 0;
  }

  input {
    min-width: 200px;
    width: 200px;
    max-width: 200px;
  }
`;

export const ApplyButton = styled(BaseButton)`
  margin-left: 13px;
  align-self: flex-end;
`;

export const EmployeePromoReminder = styled.div`
  margin-top: 12px;

  span,
  button {
    ${theme.text.small}
    font-weight: 500;
    color: ${theme.colors.accent.red};
  }

  button {
    outline: none;
    border: none;
    background: transparent;
    font-family: ${theme.fonts.marketing};
    text-decoration: underline;
    border-radius: 2px;
    &:focus {
      box-shadow: 0 0 0 2px ${theme.colors.attract[300]};
    }
  }
`;
