import React from "react";
import theme from "../../utils/theme";
import BaseButton from "../common/Button";
import Text from "../common/Text";
import { inputStyles, labelStyles } from "../common/TextField";
import styled, { css, keyframes } from "styled-components";
import tlcBadge from "../../images/tlc-guarantee.svg";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 296px;

  @media (max-width: 767px) {
    max-width: 100%;
    margin-top: 24px;
  }
`;

const scaleAndFadeIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  } 
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const FormHeading = styled(Text)`
  margin-bottom: 12px;
  width: 100%;
`;

export const FormField = styled.div`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  position: relative;
  flex-direction: column;
  margin: 0 0 16px;
  flex: 1;
  &[data-number] {
    min-width: 100%;
  }
  &[data-cvv] {
    min-width: 72px;
    max-width: 72px;
  }
  @media (max-width: 768px) {
    margin: 0 0 20px;
    flex: 1 1 auto;
  }
  .braintree-hosted-field {
    ${inputStyles};
  }
  .braintree-hosted-fields-focused {
    outline: none;
    box-shadow: inset 0px 0px 0px 1px rgba(10, 161, 220, 1),
      inset 0px 1px 2px rgba(0, 0, 0, 0.1), inset 0px 0px 2px rgba(0, 0, 0, 0.2);
  }
  .braintree-hosted-fields-valid {
    position: relative;
    &::after {
      content: "check";
      position: absolute;
      right: 8px;
      top: 7px;
      font-family: "Material Icons";
      font-size: 18px;
      color: #2dc878;
      animation: ${scaleAndFadeIn} 200ms ease-out;
    }
  }
  .braintree-hosted-fields-invalid {
    border: 2px solid #e53a40;
  }
  label {
    ${labelStyles}
  }
  input {
    ${inputStyles};
    width: 100%;
    min-width: 296px;
    max-width: 296px;
    ${({ hasError }) =>
      hasError &&
      css`
        border: 2px solid #e53a40;
      `};
    &.validated {
      &::after {
        content: "check";
        position: absolute;
        right: 8px;
        top: 7px;
        font-family: "Material Icons";
        font-size: 18px;
        color: #2dc878;
        animation: ${scaleAndFadeIn} 200ms ease-out;
      }
    }
    @media (max-width: 768px) {
      max-width: 96%;
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;

  > * + * {
    margin-left: 16px;
  }
`;

export const LabelAndErrorLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.label`
  margin: 0 0 8px;
  font-size: 15px;
  font-weight: normal;
  color: #566373;
  letter-spacing: 0;
  line-height: 24px;
`;

export const ValidationError = styled.span`
  font-size: 13px;
  margin-left: auto;
  color: #e53a40;
`;

export const braintreeFormStyles = {
  input: {
    "font-family": theme.fonts.system,
    "font-size": "15px",
    "font-weight": "normal",
    transition: "color 0.1s",
    color: theme.colors.gray[900]
  },
  "::placeholder": {
    color: "#C4C8D0"
  }
};

export const LoginOptions = styled.span`
  margin-top: 10px;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray[700]};
  a {
    color: ${({ theme }) => theme.colors.suite[500]};
    text-decoration: underline;
    &:focus {
      outline: 2px solid ${({ theme }) => theme.colors.accent.purple};
    }
  }
`;

export const HostedFieldWrapper = styled.div`
  width: 100%;
`;

export const PlanNotice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 420px;
  margin: 6px 0 0;
  h6 {
    margin: 0 0 10px;

    font-weight: normal;
    font-size: 16px;
    color: #566373;
    letter-spacing: 0;
    line-height: 24px;
  }
  p {
    margin: 0 0 20px;

    font-weight: normal;
    font-size: 13px;
    color: #566373;
    letter-spacing: 0;
    line-height: 22px;
    strong {
      font-weight: 500;
      font-weight: 400;
    }
  }

  @media (max-width: 767px) {
    max-width: 94vw;
    width: 94vw;
  }
`;

const TLCBadgeElem = styled.img`
  margin: 8px 0 0 34px;
  height: 152px;
  width: 152px;
  @media (max-width: 767px) {
    position: absolute;
    right: 16px;
    bottom: 16px;
    height: 112px;
    width: 112px;
    margin: 0 auto;
  }
`;
export const TLCBadge = () => <TLCBadgeElem src={tlcBadge} />;

export const Hide = styled.div`
  display: ${({ when }) => (when ? "none" : "flex")};
`;
export const ButtonLayout = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px auto 50px;
`;

export const SubmitButton = styled(BaseButton)`
  @media (max-width: 767px) {
    margin-right: auto;
    margin-left: 0;
  }
`;
