import { useMutation } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import React, { useEffect } from "react";
import casLogo from "../../../assets/images/branding/Cloud_Agent_Suite_horizontal_blue.svg";
import { GET_AUTHED_USER } from "../../api/queries/user";
import { useQueryString } from "../../utils/url";
import ResourceLoader from "../common/ResourceLoader";
import GuestpassHeading from "./GuestpassHeading";
import GuestpassProducts from "./GuestpassProducts";
import GuestpassProvider, {
  ISSUE_GUEST_PASS_MUTATION,
  useGuestpass
} from "./GuestpassProvider";
import {
  GuestpassContent,
  GuestpassLayout,
  GuestpassSection,
  HomeLink,
  Invalid
} from "./styled/guestpass-redeem";

function GuestpassRedeem() {
  const { jwt } = useQueryString();
  const { user } = useGuestpass();
  const [issueGuestPass, { data, loading, error }] = useMutation(
    ISSUE_GUEST_PASS_MUTATION,
    {
      context: { useAuthedEndpoint: true },
      refetchQueries: [
        {
          query: GET_AUTHED_USER,
          context: { useAuthedEndpoint: true }
        }
      ],
      awaitRefetchQueries: true,
      errorPolicy: "ignore"
    }
  );

  useEffect(() => {
    issueGuestPass({ variables: { jwt } });
  }, [issueGuestPass, jwt]);

  const guestPass = user.guestPasses.find(
    (guestPass) =>
      String(guestPass.id) ===
      String(data && data.issueGuestPass && data.issueGuestPass.id)
  );

  if (error) {
    return (
      <Invalid>
        {error.message && error.message.includes("active subscription")
          ? "Subscription active"
          : "Invalid token"}
      </Invalid>
    );
  }

  if (!guestPass || loading) {
    return <ResourceLoader isLoading={!guestPass || loading} />;
  }

  if (guestPass && guestPass.status !== "issued") {
    navigate("/app/launchpad");
  }

  return (
    <GuestpassLayout>
      <GuestpassSection>
        <GuestpassContent>
          <HomeLink href="/">
            <img src={casLogo} alt="Cloud Agent Suite" />
          </HomeLink>
          <GuestpassHeading />
          <GuestpassProducts guestPass={guestPass} />
        </GuestpassContent>
      </GuestpassSection>
    </GuestpassLayout>
  );
}

export default function GuestPassRedeemWithProvider() {
  return (
    <GuestpassProvider>
      <GuestpassRedeem />
    </GuestpassProvider>
  );
}
