import { has } from "lodash";
import { useState } from "react";
import usePlansForMls from "./usePlansForMls";

export default function useAnnualSavings(
  { groupKey, mlsCode, promoCode },
  queryConfig = {}
) {
  const [annualSavings, setAnnualSavings] = useState(
    ["cma", "suite"].includes(groupKey)
      ? { loading: true, amount: undefined }
      : { loading: false, amount: 0 }
  );

  usePlansForMls(
    { promoCode, mlsCode },
    {
      onCompleted: (plans) => {
        // Monthly
        const monthlyPlan = plans.mls[0].bundles.find(
          (b) => b.groupKey === groupKey && b.billingCycle === 1
        );
        const monthlyDiscount =
          (
            plans.discounts.find(
              (discount = {}) =>
                discount.code === promoCode &&
                (discount.bundles[0] || {}).key === groupKey &&
                (discount.bundles[0] || {}).billingCycle === 1
            ) || {}
          ).amount || 0;
        const monthlyPriceAfterDiscount = has(monthlyPlan, "price")
          ? monthlyPlan.price - monthlyDiscount
          : 0;

        // Annual
        const annualPlan = plans.mls[0].bundles.find(
          (b) => b.groupKey === groupKey && b.billingCycle === 12
        );
        const annualDiscount =
          (
            plans.discounts.find(
              (discount = {}) =>
                discount.code === promoCode &&
                (discount.bundles[0] || {}).key === groupKey &&
                (discount.bundles[0] || {}).billingCycle === 12
            ) || {}
          ).amount || 0;
        const annualPriceAfterDiscount = has(annualPlan, "price")
          ? annualPlan.price - annualDiscount
          : 0;
        const amount = Math.max(
          monthlyPriceAfterDiscount * 12 - annualPriceAfterDiscount,
          0
        );

        setAnnualSavings({
          loading: false,
          upgradePlanKey: (annualPlan || {}).key,
          amount
        });
      },
      ...queryConfig
    }
  );

  return annualSavings;
}
