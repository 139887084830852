import React from "react";
import styled from "styled-components";
import { uniq } from "lodash";

const Container = styled.div`
  width: 100%;
  background-color: #fee7e7;
  border-radius: 3px;
  border: 1px solid #ef8f8f;
  margin: 12px 0 24px;
  padding: 8px 16px;
  color: #9c1616;
  text-align: center;
`;

function FlashError({ errors }) {
  return Array.isArray(errors) ? (
    <Container>
      {`We ran into ${
        uniq(errors).length === 1 ? "an issue" : "some issues"
      }: ${uniq(errors).join(", ")}.`}
    </Container>
  ) : null;
}

export default FlashError;
