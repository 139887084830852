import React from "react";

const IconShortcutBuyerTour = (props) => (
  <svg width={24} height={24} fill="none" {...props}>
    <rect width={24} height={24} rx={5} fill="#BCF5DD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.175 5.8a3.8 3.8 0 0 0-3.761 3.263l-.408 2.852a.601.601 0 0 0-.002.152 1.81 1.81 0 0 0-.004.117V14.4c0 .38.071.745.2 1.08v2.92a.8.8 0 0 0 .8.8h.8a.8.8 0 0 0 .8-.8v-1.026c.13.017.264.026.4.026h8c.136 0 .27-.009.4-.026V18.4a.8.8 0 0 0 .8.8h.8a.8.8 0 0 0 .8-.8V15.48c.129-.335.2-.7.2-1.08v-2.216c0-.039-.001-.077-.004-.116a.597.597 0 0 0-.002-.153l-.407-2.852A3.8 3.8 0 0 0 14.825 5.8h-5.65zm8.393 4.622l-.17-1.19A2.6 2.6 0 0 0 14.826 7h-5.65a2.6 2.6 0 0 0-2.573 2.232l-.17 1.19.015-.003 2.336-.468a7.8 7.8 0 0 1 1.53-.151h3.374a7.8 7.8 0 0 1 1.53.151l2.336.468.015.003zM10.314 11a6.61 6.61 0 0 0-1.294.128l-2.337.467a.6.6 0 0 0-.482.589v.641a.804.804 0 0 1 .2-.025h2A1.2 1.2 0 0 1 9.6 14a.4.4 0 0 1-.4.4h-3A1.8 1.8 0 0 0 8 16.2h8a1.8 1.8 0 0 0 1.8-1.8h-3a.4.4 0 0 1-.4-.4 1.2 1.2 0 0 1 1.2-1.2h2c.07 0 .136.009.2.025v-.641a.6.6 0 0 0-.482-.589l-2.337-.467A6.595 6.595 0 0 0 13.687 11h-3.374z"
      fill="#20B575"
    />
  </svg>
);

export default IconShortcutBuyerTour;
