import styled from "styled-components";
import ClampLines from "react-clamp-lines";
import { DialogOverlay, DialogContent } from "@reach/dialog";

export const Card = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const CopyLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 15px;
  padding-bottom: 10px;

  @media (max-width: 1023px) {
    align-items: flex-start;
    padding-bottom: 0px;
    padding-left: 12px;
  }
`;

export const ProductDescription = styled(ClampLines)`
  margin-top: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: relative;
  color: #4b5563;
`;

export const ProductLogoType = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.suite[900]};
  text-align: left;
  display: relative;
  color: ${({ theme, productKey }) =>
    theme.colors.utils.getByProductKey(productKey)};

  @media (max-width: 1023px) {
    padding-bottom: 1px;
  }
`;
