import React from "react";
import useWindowWidthUtil from "../utils/use-window-width";

const WindowWidthContext = React.createContext(window.innerWidth);
const useWindowWidth = () => React.useContext(WindowWidthContext);

function WindowWidthProvider({ children }) {
  const windowWidth = useWindowWidthUtil();
  return (
    <WindowWidthContext.Provider value={windowWidth}>
      {children}
    </WindowWidthContext.Provider>
  );
}

export { useWindowWidth, WindowWidthProvider };
