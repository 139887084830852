import PropTypes from "prop-types";
import React from "react";
import { PRODUCT_KEYS } from "../../modules/products";
import GuestpassCard from "./GuestpassCard";

function GuestpassProduct({ productKey }) {
  return <GuestpassCard productKey={productKey}></GuestpassCard>;
}

GuestpassProduct.propTypes = {
  productKey: PropTypes.oneOf(
    Object.keys(PRODUCT_KEYS).map((key) => PRODUCT_KEYS[key])
  )
};

export default GuestpassProduct;
