import React, { useState, useReducer, useEffect } from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { isEmpty, get } from "lodash";
import { Link, navigate } from "@reach/router";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import {
  getProductNameByKey,
  SORT_ORDER as PREFERRED_PRODUCT_ORDER
} from "../../../modules/products";
import theme from "../../../utils/theme";
import Button from "../../common/Button";
import Text from "../../common/Text";
import PlanProduct from "../../PlanProduct";
import PlanPrice from "../../PlanPrice";
import Covid19Message from "../Covid19Message";
import { translate } from "../../../utils/locale";
import Loading from "../../common/Loading";

function reducer(state, { type, payload }) {
  switch (type) {
    case "DETECTED_USER_WITHOUT_MLS":
    case "SET_USER_SUBSCRIPTION_FIELDS":
    case "SET_CANCELLATION_DETAILS":
      return { ...state, ...payload };
    case "SET_FINAL_PRICE":
      return { ...state, saveSku: { ...state.saveSku, finalPrice: payload } };
    default:
      return state;
  }
}

const initialState = {
  loading: true,
  fetchedCancellationDetails: false,
  isAttractOnlySubscriber: false,
  mlsId: null,
  loadingUser: true,
  hasHomebeat: null,
  discountId: null,
  discountCode: null,
  billingCycle: null,
  isOnSiteLicensedPlanAlready: null,
  siteLicenseBundle: null,
  isSiteLicensed: null,
  currentPlanGroupKey: null,
  hasSaveSku: null,
  saveSku: {},
  keeps: [],
  isLosingWork: null,
  lostProducts: [],
  lostWork: null
};

export default function CancellationOverview() {
  const [
    {
      loading,
      fetchedCancellationDetails,
      mlsId,
      hasHomebeat,
      discountId,
      discountCode,
      siteLicenseBundle,
      isOnSiteLicensedPlanAlready,
      isSiteLicensed,
      keeps,
      currentPlanGroupKey,
      hasSaveSku,
      isLosingWork,
      lostWork,
      lostProducts,
      saveSku
    },
    dispatch
  ] = useReducer(reducer, initialState);
  const [dialogIsOpen, setIsShowingDialog] = useState(false);
  const openDialog = () => setIsShowingDialog(true);
  const closeDialog = () => setIsShowingDialog(false);

  const [checkUserCancellationDetails] = useMutation(
    CHECK_USER_CANCELLATION_DETAILS_MUTATION,
    {
      fetchPolicy: "no-cache",
      context: { useAuthedEndpoint: true },
      onCompleted: ({ checkUserCancellationDetails }) => {
        const { productDetails, sku } = checkUserCancellationDetails;
        const hasSaveSku = isEmpty(sku) === false;

        try {
          const parsedDetails = JSON.parse(productDetails);

          dispatch({
            type: "SET_CANCELLATION_DETAILS",
            payload: {
              hasSaveSku,
              fetchedCancellationDetails: true,
              saveSku: hasSaveSku ? { ...sku, finalPrice: sku.price } : {},
              isLosingWork: isEmpty(parsedDetails) === false,
              lostWork: parsedDetails
            }
          });
        } catch (error) {
          console.error(error);
          // airbrake.notify({ error });
        }
      }
    }
  );

  useEffect(() => {
    checkUserCancellationDetails();
  }, []);

  useQuery(USER_SUBSCRIPTION_QUERY, {
    fetchPolicy: "no-cache",
    skip: !fetchedCancellationDetails,
    context: { useAuthedEndpoint: true },
    onCompleted: ({ user }) => {
      const { mls, subscription } = user;

      if (!mls) {
        return dispatch({
          type: "DETECTED_USER_WITHOUT_MLS",
          payload: {
            discountId: subscription.discount && subscription.discount.id,
            discountCode: subscription.discount && subscription.discount.code,
            hasSaveSku: false,
            saveSku: null,
            loading: false
          }
        });
      }

      const currentPlanGroupKey = subscription.sku.groupKey;
      const siteLicenseBundle = mls.bundles.find(
        (b) => b.key === "site_license"
      );
      const isSiteLicensed = Boolean(siteLicenseBundle);
      const isOnSiteLicensedPlanAlready =
        subscription.sku.key === "site_license";
      const products =
        saveSku.type === "Bundle"
          ? mls.bundles.find(
              (b) =>
                b.groupKey === saveSku.groupKey &&
                b.billingCycle === saveSku.billingCycle
            ).products
          : [];
      const saveSkuWithProducts = { ...saveSku, products };
      const currentPlanProductKeys = user.subscription.products.map(
        ({ key }) => key
      );
      const siteLicensePlanProductKeys = get(
        siteLicenseBundle,
        "products",
        []
      ).map(({ key }) => key);
      const lostProducts =
        isSiteLicensed && !isOnSiteLicensedPlanAlready
          ? // You're either downgrading to the site license bundle
            currentPlanProductKeys
              .filter((key) => !siteLicensePlanProductKeys.includes(key))
              .concat(mls.hasHomebeat ? "homebeat" : null)
              .filter(Boolean)
          : // Or you're losing everything
            currentPlanProductKeys
              .concat(mls.hasHomebeat ? "homebeat" : null)
              .filter(Boolean);

      dispatch({
        type: "SET_USER_SUBSCRIPTION_FIELDS",
        payload: {
          siteLicenseBundle,
          isOnSiteLicensedPlanAlready,
          mlsId: mls.id,
          hasHomebeat: mls.hasHomebeat,
          isSiteLicensed: mls.territoryCode
            ? mls.territoryCode.includes("SL")
            : false,
          discountId: subscription.discount && subscription.discount.id,
          discountCode: subscription.discount && subscription.discount.code,
          keeps: (
            (mls.bundles || []).find((b) => b.key === "site_license") || {}
          ).products,
          currentPlanGroupKey,
          saveSku: saveSkuWithProducts,
          lostProducts,
          loading: false
        }
      });
    }
  });

  useQuery(GET_DISCOUNTS_QUERY, {
    skip: loading || !discountCode,
    variables: { code: discountCode },
    onCompleted: ({ discounts }) => {
      const namespace = saveSku.type === "AddOn" ? "addOns" : "bundles";
      const foundDiscount = discounts.find(
        (discount) => (discount[namespace][0] || {}).key === saveSku.key
      );
      const finalPrice = foundDiscount
        ? saveSku.price - foundDiscount.amount
        : saveSku.price;

      dispatch({ type: "SET_FINAL_PRICE", payload: finalPrice });
    }
  });

  const [cancelAccount] = useMutation(CANCEL_ACCOUNT, {
    context: { useAuthedEndpoint: true },
    onCompleted: () => {
      localStorage.removeItem("token");
      sessionStorage.removeItem("impersonate");
      window.location.replace(`${window.location.origin}/cancelled`);
    }
  });

  const [changePlan, changePlanStatus] = useMutation(
    CHANGE_USER_PLAN_MUTATION,
    {
      context: { useAuthedEndpoint: true },
      update(cache, response) {
        const cachedQuery = cache.readQuery({ query: USER_SUBSCRIPTION_QUERY });
        cache.writeQuery({
          query: USER_SUBSCRIPTION_QUERY,
          data: {
            ...cachedQuery,
            user: {
              ...cachedQuery.user,
              subscription: response.data.changeUserPlan.user.subscription,
              addOns: response.data.changeUserPlan.user.addOns
            }
          }
        });
      },
      onCompleted: () => navigate("/app/billing")
    }
  );

  function handleChangePlan(sku) {
    changePlan({
      variables: {
        sku: {
          id: sku.id,
          type: sku.key === "homebeat" ? "AddOn" : "Bundle" // ? TODO: can I use sku.type here instead?
        },
        discount_id: discountId,
        mls_id: mlsId,
        source: "cancel"
      }
    });
  }

  if (loading) return <Loading />;

  return (
    <CancelContainer>
      <HeadingLayout>
        <Text variant="displayMedium" as="h1">
          Cancel Plan
        </Text>
      </HeadingLayout>

      <Card>
        <Link to="/app/billing">
          <LinkLayout>
            <LeftArrowLayout>
              <IconLeftArrow />
            </LeftArrowLayout>
            <LinkText>Back to My Account</LinkText>
          </LinkLayout>
        </Link>

        <Covid19Message />

        <Container>
          <CancellationConsequences>
            <Text
              variant="displayExtraSmall"
              as="h4"
              style={{ marginBottom: 18 }}>
              {lostProducts.length
                ? "Cancelling will do the following:"
                : "Do you wish to cancel?"}
            </Text>

            {isSiteLicensed && !isOnSiteLicensedPlanAlready && (
              <>
                <Text>
                  You'll keep these{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translate("mls.label", "MLS").replace(
                        "®",
                        "<sup>®</sup>"
                      )
                    }}
                  />{" "}
                  member benefits:
                </Text>
                <Keeps>
                  {keeps.map((product) => (
                    <li key={product.key}>
                      <Product>
                        <IconCheckmark />
                        <Text variant="displayExtraSmall" as="span">
                          {product.name}
                        </Text>
                      </Product>
                    </li>
                  ))}
                </Keeps>
              </>
            )}

            <LostWork>
              <Text>
                You'll lose the following{" "}
                <Dark>tools{isLosingWork && " and work"}</Dark> immediately:
              </Text>
              <Loses>
                {/* If they're downgrading from the suite, they may keep CMA, but they'll lost access to the TM Integration */}
                {currentPlanGroupKey === "suite" && (
                  <li>
                    <Work>
                      <Product>
                        <IconX />
                        <Text variant="displayExtraSmall" as="span">
                          Lone Wolf Transactions Integration
                        </Text>
                      </Product>
                    </Work>
                  </li>
                )}
                {lostProducts
                  .sort(
                    (a, b) =>
                      PREFERRED_PRODUCT_ORDER[a] - PREFERRED_PRODUCT_ORDER[b]
                  )
                  .map((productKey) => (
                    <li key={productKey} data-testid="lost-product">
                      <Work>
                        <Product>
                          <IconX />
                          <Text
                            variant="displayExtraSmall"
                            as="span"
                            data-testid="lost-product-name">
                            {getProductNameByKey(productKey)}
                          </Text>
                        </Product>
                        {lostWork[productKey] && (
                          <Stat>
                            {lostWork[productKey].reduce(
                              (jsxArray, stat, index, statsArray) =>
                                jsxArray.concat(
                                  <span key={stat.key}>
                                    <strong>{stat.value}</strong> {stat.format}
                                    {getConjoiner(index, statsArray)}
                                  </span>
                                ),
                              []
                            )}
                          </Stat>
                        )}
                      </Work>
                    </li>
                  ))}
              </Loses>
            </LostWork>

            <ButtonLayout>
              {isSiteLicensed && !isOnSiteLicensedPlanAlready ? (
                <Button
                  app="attract"
                  variant="secondary"
                  loading={changePlanStatus.loading}
                  onClick={() => handleChangePlan(siteLicenseBundle)}>
                  Cancel Paid Plan
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  app="attract"
                  // onClick={openDialog}
                  data-testid="cancel-plan-button"
                  as={Link}
                  to="confirm">
                  Cancel Plan
                </Button>
              )}
            </ButtonLayout>

            {dialogIsOpen && (
              <CancelConfirmationDialog onDismiss={closeDialog}>
                <DialogContainer>
                  <Text variant="displaySmall" as="h2">
                    Cancel Account
                  </Text>
                  <Text variant="body" as="p">
                    By cancelling your account, your data will be deleted
                    immediately.
                  </Text>
                  <Text variant="body" as="p">
                    This action cannot be undone.
                  </Text>
                  <ButtonGroup>
                    <Button variant="secondary" onClick={closeDialog}>
                      Go Back
                    </Button>
                    <Button app="attract" onClick={cancelAccount}>
                      Confirm Cancellation
                    </Button>
                  </ButtonGroup>
                </DialogContainer>
              </CancelConfirmationDialog>
            )}
          </CancellationConsequences>

          {hasSaveSku && (
            <SaveSku data-testid={saveSku.key}>
              <Name variant="displaySmall" as="h3">
                {saveSku.name}
              </Name>
              <Tagline variant="heading" as="span">
                {saveSku.tagline}
              </Tagline>
              <ProductsLayout>
                {saveSku.key === "homebeat" ? (
                  <PlanProduct
                    key="cloud_cma"
                    productKey="cloud_cma"
                    hasHomebeat={hasHomebeat}
                  />
                ) : (
                  saveSku.products.map((product) => (
                    <PlanProduct
                      key={product.key}
                      productKey={product.key}
                      hasHomebeat={hasHomebeat}
                    />
                  ))
                )}
              </ProductsLayout>
              <PriceLayout>
                <PlanPrice
                  planKey={saveSku.key}
                  planPrice={saveSku.price}
                  finalPrice={saveSku.finalPrice}
                  billingCycle={saveSku.billingCycle}
                />
              </PriceLayout>
              <Button
                fullwidth
                onClick={() => handleChangePlan(saveSku)}
                loading={changePlanStatus.loading}
                disabled={changePlanStatus.loading}>
                Switch to this plan
              </Button>
            </SaveSku>
          )}
        </Container>
      </Card>
    </CancelContainer>
  );
}

// Icon Components
const IconLeftArrow = (props) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M54.13 10.056L47.231 3 0 50.41l47.231 47.174 6.9-7.013-35.373-35.443 81.242.337v-9.943H18.758" />
  </svg>
);

const IconCheckmark = (props) => (
  <svg width={18} height={14} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.17L1.83 7 .41 8.41 6 14 18 2 16.59.59 6 11.17z"
      fill="#20B575"
    />
  </svg>
);

const IconX = (props) => (
  <svg width={14} height={14} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41z"
      fill="#EC5252"
    />
  </svg>
);

// Styled Components
const CancelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const HeadingLayout = styled.div`
  padding: 32px 16px;
  text-align: center;
`;

const Card = styled.div`
  width: auto;
  box-shadow: ${theme.shadows.raised};
  padding: 32px;
  background-color: white;
  border-radius: 6px;

  @media (max-width: 576px) {
    width: 100%;
    padding: 20px 16px 40px;
  }
`;

const LeftArrowLayout = styled.div`
  width: 10px;
  margin-right: 11px;
  cursor: pointer;
`;

const LinkLayout = styled.div`
  display: inline-flex;
  align-items: center;
`;

const LinkText = styled.span`
  ${theme.text.link};
  color: ${theme.colors.suite[900]};
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 45px;

  @media (max-width: 576px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }
`;

const CancellationConsequences = styled.div`
  flex: 1 1 auto;
  max-width: 428px;
`;

const Keeps = styled.ul`
  list-style: none;
  padding: 0;
  margin: 12px 0 0;
`;

const Product = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  svg {
    margin-right: 13px;
  }
`;

const LostWork = styled.div`
  margin-top: 24px;
`;

const Dark = styled.span`
  color: ${theme.colors.gray[900]};
`;

const Loses = styled(Keeps)`
  margin-top: 16px;
`;

const Work = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const Stat = styled.span`
  margin-top: -2px;
  margin-left: 28px;
  font-size: 16px;
  line-height: 26px;
  color: ${theme.colors.gray[800]};

  strong {
    color: ${theme.colors.gray[800]};
    font-weight: 500;
  }
`;

const ButtonLayout = styled.div`
  margin-top: 28px;
`;

const CancelConfirmationDialog = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1111;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const DialogContainer = styled(DialogContent)`
  box-shadow: ${theme.shadows.overlay};
  border-radius: 6px;
  background-color: white;
  max-width: 512px;
  padding: 24px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  > * + * {
    margin-left: 12px;
  }
`;

const SaveSku = styled.div`
  display: block;
  align-self: flex-start;
  min-width: 328px;
  margin-left: 32px;
  background: ${theme.colors.gray[200]};
  border: 1px solid ${theme.colors.gray[500]};
  border-radius: 6px;
  padding: 24px;

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    width: 100%;
    margin-left: 0;
    margin-top: 24px;
  }
`;

const Name = styled(Text)`
  margin-bottom: 8px;
`;

const Tagline = styled(Text)`
  display: block;
  font-weight: 400;
  margin-bottom: 24px;
`;

const ProductsLayout = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 21px;
`;

const PriceLayout = styled.div`
  margin-bottom: 24px;
`;

// Helpers
function getConjoiner(index, array) {
  // array length is zero or one, or is the last item in the array -> empty string
  if (array.length === 0 || array.length === 1) {
    return "";
    // array length is 2 and index is 0 -> " and "
  } else if (array.length === 2 && index === 0) {
    return " and ";
    // array length is greater than two and index === array.length - 2 ['one', 'two', 'three']
  } else if (array.length > 2 && index === array.length - 2) {
    return ", and ";
    // is the last item in the array -> "."
  } else if (index === array.length - 1) {
    return ".";
  } else {
    return ", ";
  }
}

// Network
export const USER_SUBSCRIPTION_QUERY = gql`
  query cancellationRouteQuery {
    user {
      id
      mls {
        id
        code
        hasHomebeat: has_homebeat
        territoryCode: territory_code
        bundles {
          id
          key
          name
          price
          groupKey: group_key
          billingCycle: billing_cycle
          products {
            id
            key
            name
            isSiteLicensed: site_license
          }
        }
        products {
          id
          key
          name
          price
        }
      }
      subscription {
        id
        sku {
          id
          key
          type
          groupKey: group_key
          billingCycle: billing_cycle
        }
        products {
          id
          key
          name
          isSiteLicensed: site_license
        }
        discount {
          id
          code
        }
      }
      addOns: add_on_subscriptions {
        id
        sku {
          id
          key
        }
      }
    }
  }
`;

export const GET_DISCOUNTS_QUERY = gql`
  query getDiscounts($code: String) {
    discounts(code: $code) {
      id
      amount
      bundles {
        key
      }
      addOns: add_ons {
        key
      }
    }
  }
`;

export const CHECK_USER_CANCELLATION_DETAILS_MUTATION = gql`
  mutation checkUserCancellationDetails {
    checkUserCancellationDetails {
      productDetails: product_details
      sku {
        id
        key
        type
        name
        tagline
        groupKey: group_key
        billingCycle: billing_cycle
        price
      }
    }
  }
`;

export const CANCEL_ACCOUNT = gql`
  mutation cancelAccount {
    cancelAccount {
      id
      email
    }
  }
`;

export const CHANGE_USER_PLAN_MUTATION = gql`
  mutation changeUserPlan(
    $sku: SkuInput!
    $payment_method_nonce: String
    $discount_id: ID
    $mls_id: ID
    $source: ChangePlanSource!
  ) {
    changeUserPlan(
      sku: $sku
      payment_method_nonce: $payment_method_nonce
      discount_id: $discount_id
      mls_id: $mls_id
      source: $source
    ) {
      user {
        id
        subscription {
          id
          sku {
            id
            key
            type
            groupKey: group_key
            billingCycle: billing_cycle
          }
          products {
            id
            key
            name
            isSiteLicensed: site_license
          }
        }
        addOns: add_on_subscriptions {
          id
          sku {
            id
            key
          }
        }
      }
    }
  }
`;
