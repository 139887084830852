import styled from "styled-components";
import theme from "../../utils/theme";

export default styled.h1`
  color: ${theme.colors.suite[900]};
  font-family: ${theme.fonts.system};
  font-size: 36px;
  font-weight: 600;
  line-height: 41px;
  text-align: center;
  margin: 0;
  padding: 24px 0 32px;

  @media (max-width: 500px) {
    font-size: 28px;
    line-height: 36px;
  }
`;
