import React from "react";

function IconArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 11">
      <path
        fill="#2D335D"
        d="M.5 6a.5.5 0 010-1v1zm9.06-1h.5v1h-.5V5zM.5 5.5V5H9.57v1h-.02-.02-.02-.02-.02-.07-.02-.02-.02-.02-.02-.02-.05-.02-.02-.02-.02-.07-.02-.02-.02-.07-.02-.02-.07-.02-.02-.05-.02-.02-.07-.02-.02-.05-.02-.02-.05-.02-.07-.02-.05-.02-.02-.05-.02-.05-.02-.05-.02-.02-.05-.02-.05-.02-.05-.02-.05-.02-.05-.02-.05-.02-.05-.02-.05-.02-.05-.05-.02-.05-.02-.05-.02-.05-.05-.02-.05-.02-.05-.02-.03-.02-.05-.02-.05-.05-.02-.05-.02-.05-.05-.02-.05-.02-.05-.05-.02-.05-.02-.03-.02-.05-.02-.05-.05-.02-.05-.02-.05-.02-.03-.02-.05-.02-.05-.02-.05-.02-.03-.02-.05-.02-.05-.02-.05-.02-.05-.02-.05-.02-.05-.02-.05-.02-.05H5h-.05-.02-.05-.02-.02-.05-.02-.05-.02-.02-.05-.02-.05-.02-.02-.05-.02-.02-.05-.02-.02-.05-.02-.02-.07-.02-.02-.05-.02-.02-.02-.07-.02-.02-.02-.05-.02-.02-.02-.02-.07-.02-.02-.02-.02-.02-.07-.02-.02-.02-.02-.02-.02-.02-.02-.11-.02-.02-.02-.02-.02-.02-.02-.02-.02-.02-.02-.02H3h-.02-.02-.02-.02-.02-.07-.02-.02-.02-.02-.02-.02-.02-.02-.03-.02-.02-.02-.02-.02-.03-.02-.02-.02-.02-.03-.02-.02-.02-.01-.02-.02-.02-.01-.02-.02-.02-.01-.02-.02-.03-.02-.03-.02-.03H2h-.03-.02-.01-.02-.02-.01-.02-.01-.02-.01-.02-.02-.01-.02-.01-.02-.01-.02-.01-.02-.01-.02-.01-.02-.01-.01-.02-.01-.02-.01-.02-.01-.01-.02-.01-.01-.02-.01-.04-.01-.02-.01-.01-.04-.01-.04-.01-.04-.01-.01-.01-.04-.01-.01-.01-.01-.01-.18-.01-.01-.01-.03-.01H.8.78.76.74.72.7.68.67.64.64.62.61.6.6.58.56.55.54.53.52.5v-.5z"
      />
      <path stroke="#2D335D" d="M5 10L.5 5.5M5 1L.5 5.5" />
    </svg>
  );
}

export default IconArrow;
