import React, { useMemo, useRef, useEffect, useLayoutEffect } from "react";
import { useMachine } from "@xstate/react";
import { uniq } from "lodash";
import territoryComboboxMachine from "../machines/territory-combobox";
import { scrollIntoView, useOutsideClick } from "../utils/dom";
import { useSubscriptionWorkflow } from "./SubscriptionWorkflow";
import {
  DropdownWrapper,
  DropdownInput,
  DropdownList,
  DropdownItem
} from "./styled/combobox";

function TerritoryCombobox() {
  const highlightedItemRef = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const listRef = useRef(null);

  const {
    state: subscriptionWorkflowState,
    send: sendToSubscriptionWorkflow
  } = useSubscriptionWorkflow();
  const { selectedTerritory } = subscriptionWorkflowState.context;

  const stateMachine = useMemo(() => {
    const uniqueTerritories = uniq(
      subscriptionWorkflowState.context.mls.reduce(
        (all, m) => all.concat(m.state.split(",")),
        []
      )
    ).sort();

    return territoryComboboxMachine
      .withContext({
        ...territoryComboboxMachine.context,
        selected:
          selectedTerritory || territoryComboboxMachine.context.selected,
        input: selectedTerritory || territoryComboboxMachine.context.input,
        items: uniqueTerritories,
        filtered: uniqueTerritories,
        highlightedItemRef,
        listRef
      })
      .withConfig({
        actions: {
          onSelect: ({ selected }) =>
            sendToSubscriptionWorkflow({
              type: "SELECT_TERRITORY",
              territory: selected
            })
        }
      });
  }, []);

  const [state, send] = useMachine(stateMachine);

  useEffect(() => {
    if (state.value === "closed" && state.context.wasClosedInternally) {
      buttonRef.current.focus();
    }
    if (state.value === "opened") {
      inputRef.current.focus();
    }
  }, [state.value]);

  useLayoutEffect(() => {
    if (!highlightedItemRef.current) return;
    scrollIntoView(highlightedItemRef.current, listRef.current);
  }, [highlightedItemRef.current]);

  useOutsideClick(
    containerRef,
    () => send("CLOSE_DROPDOWN"),
    state.matches("opened")
  );

  function handleKeyDown(e) {
    if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Enter") {
      e.preventDefault();
    }
    send(`KEYED_${e.key.toUpperCase()}`, { originalEvent: e });
  }

  return (
    <DropdownWrapper ref={containerRef} onKeyDown={handleKeyDown}>
      {state.matches("opened") ? (
        <DropdownInput
          ref={inputRef}
          role="combobox"
          id="territory-combobox"
          aria-autocomplete="list"
          aria-expanded={state.matches("opened")}
          aria-haspopup="true"
          aria-owns="territory-list"
          aria-activedescendant={state.context.selected}
          onChange={({ target }) =>
            send({ type: "INPUT_CHANGED", value: target.value })
          }
          isOpen={state.matches("opened")}
          placeholder="Type to search..."
          disabled={state.matches("closed")}
          data-testid="territory-input"
          value={state.context.input}
        />
      ) : (
        <DropdownInput
          type="button"
          ref={buttonRef}
          role="combobox"
          id="territory-combobox"
          aria-label="Choose State / Province"
          data-testid="territory-button"
          isOpen={state.matches("opened")}
          value={state.context.selected || "Choose state / province"}
          onClick={() => send("OPEN_DROPDOWN")}
        />
      )}
      {state.matches("opened") && (
        <DropdownList id="territory-list" ref={listRef}>
          {!!state.context.filtered.length ? (
            state.context.filtered.map((territory, i) => (
              <DropdownItem
                key={territory}
                data-index={i}
                ref={state.context.index === i ? highlightedItemRef : null}
                id={`territory-item-${i}`}
                isHighlighted={state.context.index === i}
                onClick={(e) => send({ type: "SELECT", territory })}
                onMouseEnter={() => send({ type: "SET_INDEX", index: i })}>
                {territory}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem empty>No matches found...</DropdownItem>
          )}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
}

export default TerritoryCombobox;
