import React from "react";

const CloudStreams = (props) => (
  <svg viewBox="0 0 245.26 245.26" {...props}>
    <circle cx={122.63} cy={122.63} r={122.63} fill="#0aa1dc" />
    <path d="M120.13 52.16v.05h2.5v-2.5A49.38 49.38 0 0 0 87.2 64.42a50.37 50.37 0 0 0-14.37 35.47v13.49a46.53 46.53 0 0 1-4.06 18.29 75.15 75.15 0 0 1-10.11 16.62L51.81 157a8.8 8.8 0 0 0-1.94 5.5 9 9 0 0 0 9.06 9h127.41a9 9 0 0 0 9-9 8.74 8.74 0 0 0-1.94-5.5l-6.84-8.66a74.4 74.4 0 0 1-10.11-16.62 46.37 46.37 0 0 1-4.07-18.29V99.89a50.37 50.37 0 0 0-14.37-35.47 49.38 49.38 0 0 0-35.43-14.71v5a44.35 44.35 0 0 1 31.92 13.22 45.32 45.32 0 0 1 12.94 32v13.49a51.51 51.51 0 0 0 4.47 20.27 79.88 79.88 0 0 0 10.79 17.74l6.84 8.66a3.73 3.73 0 0 1 .86 2.4 4.05 4.05 0 0 1-1.12 2.77 4 4 0 0 1-2.93 1.22H58.93a4 4 0 0 1-2.93-1.26 4.06 4.06 0 0 1-1.13-2.77 3.79 3.79 0 0 1 .86-2.4l6.85-8.66a79.84 79.84 0 0 0 10.78-17.74 51.51 51.51 0 0 0 4.47-20.27V99.89a45.31 45.31 0 0 1 12.93-32 44.37 44.37 0 0 1 31.87-13.22 2.51 2.51 0 0 0 1.77-.67 2.55 2.55 0 0 0 .73-1.77v-.05zM112.91 176.55a13.83 13.83 0 0 1-4.15 9.89 14.35 14.35 0 0 1-20.11 0 13.83 13.83 0 0 1-4.15-9.89h-5A18.85 18.85 0 0 0 85.14 190a19.36 19.36 0 0 0 27.14 0 18.88 18.88 0 0 0 5.63-13.45z" />
  </svg>
);

export default CloudStreams;
