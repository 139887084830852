import React from "react";
import styled from "styled-components";
import LoadingIndicator from "./LoadingIndicator";

export default function Loading({ children = "Loading" }) {
  return (
    <Container>
      <Inner>
        <LoadingIndicator /> {children}
      </Inner>
    </Container>
  );
}

export const Container = styled.div`
  padding: 5rem;
  text-align: center;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.gray[700]};

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
    fill: ${({ theme }) => theme.colors.suite[900]};
  }
`;
