import React from "react";

const CloudAgentSuite = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 195 25">
    <path d="M22.28 4.79A9.66 9.66 0 004.43 7.95a8.08 8.08 0 003.63 15.3c2.8 0 5.41-1.46 6.87-3.86l1.96-3.23a.38.38 0 00-.13-.52l-1.83-1.11a.38.38 0 00-.52.13l-1.96 3.23a5.17 5.17 0 11-6.07-7.62c.47-.17.8-.6.83-1.09v-.1a6.8 6.8 0 016.73-6.18c2.6 0 4.96 1.48 6.08 3.82l.04.09c.19.37.54.63.96.7h.09a6.44 6.44 0 013.44 10.91 6.56 6.56 0 01-4.66 1.93h-2.38a.38.38 0 00-.39.38v2.06c0 .26.22.47.48.47h2.3a9.5 9.5 0 009.47-9.48 9.33 9.33 0 00-7.09-9zM41.4 7.98c.26-.34.59-.62.96-.82.44-.22.94-.34 1.43-.33.7-.01 1.37.22 1.91.66.49.43.85 1 1.03 1.62h2.45a6.02 6.02 0 00-1.8-3.3 5.1 5.1 0 00-3.6-1.35 5.15 5.15 0 00-3 .87A5.46 5.46 0 0039 7.28c-.26.47-.43.98-.51 1.5a27.09 27.09 0 00-.16 3.68c-.03 1.22.02 2.44.16 3.66.04.28.1.57.2.84l.3.68a5.49 5.49 0 008.23 1.67c1-.88 1.67-2.06 1.91-3.37h-2.44c-.63 1.44-1.6 2.16-2.91 2.16-.5.01-.98-.1-1.4-.35a2.8 2.8 0 01-.96-.8 2.69 2.69 0 01-.6-1.32c-.14-1.05-.2-2.1-.16-3.16-.03-1.06.02-2.12.16-3.18.07-.48.27-.94.59-1.31zM53.17 17.46V4.56h-2.18v13.08c0 .98.27 1.68.81 2.1.57.42 1.26.64 1.97.61h.95v-2.12h-.75c-.53 0-.8-.25-.8-.77z" />
    <path
      fillRule="evenodd"
      d="M62.6 9.42c.47.25.88.6 1.21 1.01.45.44.76.99.93 1.6.2.89.28 1.8.25 2.72.03.9-.05 1.82-.25 2.7a3.9 3.9 0 01-1.44 2.1 4.3 4.3 0 01-2.78.96 4.45 4.45 0 01-3.3-1.44 4.39 4.39 0 01-.9-1.62 9.77 9.77 0 01-.28-2.7c-.03-.92.06-1.84.28-2.73a4 4 0 01.9-1.6 4.01 4.01 0 013.3-1.44c.72-.02 1.43.13 2.08.44zm-.31 7.98c.21-.28.36-.6.43-.96.08-.56.12-1.13.1-1.7.02-.58-.02-1.17-.1-1.75-.06-.35-.2-.68-.43-.96a1.58 1.58 0 00-.71-.6 2.28 2.28 0 00-1.05-.22c-.36 0-.71.07-1.03.23-.3.13-.55.33-.74.6-.2.28-.33.6-.4.95-.1.57-.15 1.15-.13 1.74-.02.57.02 1.15.12 1.71.08.34.22.67.41.96a2.25 2.25 0 001.76.82 2.23 2.23 0 001.77-.82z"
      clipRule="evenodd"
    />
    <path d="M73.82 15.84c.04.64-.18 1.27-.6 1.75-.4.43-.96.66-1.55.64a2.05 2.05 0 01-1.55-.64 2.45 2.45 0 01-.61-1.75V9.15h-2.19v7.44a3.56 3.56 0 001.2 2.87c.66.63 1.53 1 2.44 1.01 1.1.04 2.14-.46 2.82-1.32h.04l.48 1.2H76V9.12h-2.18v6.72z" />
    <path
      fillRule="evenodd"
      d="M83.56 9.35c.46.26.89.59 1.27.96V4.58H87v15.77h-1.7l-.48-1.17a3.65 3.65 0 01-5.73-.16 2.94 2.94 0 01-.6-1.43c-.14-.95-.2-1.9-.17-2.87-.03-.96.03-1.92.17-2.86.07-.51.28-1 .6-1.4A3.34 3.34 0 0182 8.99c.55 0 1.08.12 1.56.36zm.96 7.73c.15-.36.24-.75.28-1.14l.02-.44a13.88 13.88 0 00.02-1.42l-.01-.6a4.26 4.26 0 00-.29-1.1c-.1-.35-.32-.64-.61-.84-.37-.22-.8-.33-1.23-.31-.41-.03-.82.07-1.18.27-.3.2-.53.47-.66.8a4.3 4.3 0 00-.26 1.11 13.1 13.1 0 000 2.56c.03.4.12.79.26 1.16.13.32.35.6.63.8.35.22.77.33 1.19.3.43.03.86-.09 1.22-.33.3-.2.51-.48.62-.82zM111.3 8.92c1.09 0 2.12.5 2.8 1.33l.5-1.19h1.7v10.99c-.03 1.7-.5 2.93-1.42 3.73a4.9 4.9 0 01-7.07-.66l1.69-1.4c.28.28.6.5.95.69.95.55 2.14.43 2.96-.3.46-.49.71-1.14.69-1.81v-1.15a3.65 3.65 0 01-4.57.82c-.43-.23-.8-.53-1.12-.9-.3-.4-.51-.87-.62-1.36-.17-1-.23-2-.2-3.02a15.1 15.1 0 01.21-3.06 3.52 3.52 0 013.5-2.7zm2.38 8.4c.19-.25.31-.54.36-.85v.01c.06-.6.08-1.2.07-1.8 0-.6-.01-1.21-.07-1.82a1.7 1.7 0 00-.36-.83 2.6 2.6 0 00-.66-.6 2.22 2.22 0 00-2.13 0 2.4 2.4 0 00-.67.6c-.2.24-.32.53-.35.83-.07.6-.1 1.21-.09 1.82-.01.6.02 1.2.09 1.79.04.3.16.6.35.85a2 2 0 00.67.55c.31.21.69.32 1.06.3.38.02.76-.09 1.08-.3.25-.14.47-.33.65-.55zM121.53 9.22a4.44 4.44 0 016.04 4.46v1.86h-6.8c-.04.77.23 1.52.77 2.06.48.49 1.15.75 1.84.72a3.22 3.22 0 002.39-1.1l1.58 1.4a5.06 5.06 0 01-3.92 1.82c-.57 0-1.13-.09-1.67-.26-.28-.1-.55-.22-.8-.38-.27-.14-.52-.3-.75-.5a4.5 4.5 0 01-1.12-1.76 7.2 7.2 0 01-.48-2.81c-.03-.95.11-1.88.41-2.78.22-.66.58-1.27 1.05-1.78.4-.42.9-.75 1.46-.95zm-.05 2.49a3.1 3.1 0 00-.68 1.99h4.6a2.9 2.9 0 00-.68-2 2.39 2.39 0 00-3.24 0z"
      clipRule="evenodd"
    />
    <path d="M134.96 8.92a3.46 3.46 0 00-2.83 1.33h-.04l-.47-1.18h-1.71V20.3h2.18v-6.69c-.04-.66.18-1.3.62-1.8.4-.42.97-.64 1.55-.62.58-.02 1.14.2 1.54.62.44.5.66 1.15.61 1.8v6.7h2.19v-7.49a3.66 3.66 0 00-3.63-3.91zM143.65 5.64h-2.16v3.43h-1.1v1.72h1.1v6.75a2.54 2.54 0 002.63 2.77h1.14V18.2h-.81c-.54 0-.8-.27-.78-.84V10.8h1.6V9.07h-1.6l-.02-3.43z" />
    <path
      fillRule="evenodd"
      d="M93.37 19.5l5.27-14.98h1.95l5.26 14.98v.79h-2.16l-1.19-3.53h-5.79l-1.17 3.53h-2.17v-.79zm6.22-11.76l-2.2 6.9h4.47l-2.23-6.9h-.04z"
      clipRule="evenodd"
    />
    <path d="M157.77 11.3l-1.72-.25a2.93 2.93 0 01-1.65-.79c-.32-.33-.5-.77-.5-1.23a2.1 2.1 0 01.76-1.71 2.82 2.82 0 011.78-.56c1.35.02 2.67.45 3.78 1.22L161.51 6a8.37 8.37 0 00-4.95-1.64c-1.58 0-2.8.43-3.65 1.28a4.4 4.4 0 00-1.34 3.34 4.27 4.27 0 001.06 2.93 4.96 4.96 0 003 1.53l2.03.3c1.34.23 2 .95 1.99 2.12-.03 1.44-1.04 2.17-3.02 2.2a6.59 6.59 0 01-4.34-1.58l-1.52 1.8a8.52 8.52 0 005.83 2.16c3.5-.04 5.29-1.57 5.36-4.6a4.58 4.58 0 00-1.02-2.94 4.64 4.64 0 00-3.17-1.61zM170.78 15.76a2.5 2.5 0 01-.6 1.78c-.4.43-.97.66-1.55.64a2.05 2.05 0 01-1.55-.64 2.48 2.48 0 01-.62-1.78V9.07h-2.18v7.47a3.56 3.56 0 001.2 2.86c.66.64 1.53 1 2.44 1.02 1.1.03 2.15-.46 2.82-1.33h.04v1.2h2.18V9.06h-2.18v6.7zM175.76 4.52h2.18v2.25h-2.18V4.52zM175.76 9.06h2.18V20.3h-2.18V9.06zM183.06 5.64h-2.18v3.42h-1.1v1.73h1.1v6.74c-.05.76.23 1.5.76 2.03.5.5 1.16.76 1.86.74h1.14v-2.12h-.82c-.53 0-.79-.27-.77-.84V10.8h1.59V9.06h-1.6l.03-3.42z" />
    <path
      fillRule="evenodd"
      d="M193.66 10.26a4.7 4.7 0 011.34 3.49l-.01 1.85h-6.79c-.05.76.22 1.5.76 2.06.49.48 1.16.74 1.84.7.92 0 1.79-.4 2.4-1.09l1.6 1.42a5.07 5.07 0 01-3.92 1.81c-.56 0-1.13-.08-1.67-.25-.28-.1-.54-.23-.8-.38a4.02 4.02 0 01-.76-.51 4.51 4.51 0 01-1.12-1.76 7.1 7.1 0 01-.48-2.81c-.02-.94.11-1.88.41-2.77.22-.66.58-1.27 1.05-1.78.4-.42.89-.75 1.43-.95a4.5 4.5 0 014.72.97zm-4.76 1.5a3.1 3.1 0 00-.69 1.99h4.6a2.94 2.94 0 00-.68-2 2.38 2.38 0 00-3.23 0z"
      clipRule="evenodd"
    />
  </svg>
);

export default CloudAgentSuite;
