import React from "react";

const CloseIcon = (props) => (
  <svg width={16} height={16} {...props}>
    <g strokeWidth={2} fill="none" fillRule="evenodd" strokeLinecap="square">
      <path d="M2 2l12 12M14 2L2 14" />
    </g>
  </svg>
);

export default CloseIcon;
