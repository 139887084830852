import React from "react";
import casLogo from "../../../assets/images/branding/Cloud_Agent_Suite_horizontal_blue.svg";
import BillingForm from "./BillingForm";
import GuestpassProvider from "./GuestpassProvider";
import {
  BillingDescription,
  BillingTitle,
  CASLogoWrapper,
  Container,
  ContentWrapper,
  Heading,
  ReCaptaDisclaimer
} from "./styled/guestpass-billing";

export default function GuestpassBilling() {
  return (
    <GuestpassProvider>
      <Container>
        <ContentWrapper>
          <CASLogoWrapper href="/">
            <img src={casLogo} alt="Cloud Agent Suite" />
          </CASLogoWrapper>
          <Heading>
            <BillingTitle>Complete the Suite</BillingTitle>
            <BillingDescription>Choose your billing plan.</BillingDescription>
          </Heading>
          <BillingForm />
        </ContentWrapper>
        <ReCaptaDisclaimer>
          This site is protected by reCAPTCHA and the Google
          <span>
            {" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              Privacy Policy
            </a>{" "}
          </span>
          and{" "}
          <span>
            <a href="https://policies.google.com/terms" target="_blank">
              Terms of Service
            </a>{" "}
          </span>
          apply.
        </ReCaptaDisclaimer>
      </Container>
    </GuestpassProvider>
  );
}
