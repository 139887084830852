import React from "react";
import PropTypes from "prop-types";
import CloudCma from "./icons/CloudCma";
import CloudStreams from "./icons/CloudStreams";
import CloudMlx from "./icons/CloudMlx";
import CloudAttract from "./icons/CloudAttract";
import { Container, IconLayout } from "./styled/product-logo";

function ProductLogo({ productKey }) {
  return (
    <Container>
      <IconLayout productKey={productKey}>
        {{
          cloud_cma: <CloudCma />,
          cloud_streams: <CloudStreams />,
          cloud_mlx: <CloudMlx />,
          cloud_attract: <CloudAttract />
        }[productKey] || null}
      </IconLayout>
    </Container>
  );
}

ProductLogo.propTypes = {
  productKey: PropTypes.oneOf([
    "cloud_cma",
    "cloud_streams",
    "cloud_mlx",
    "cloud_attract"
  ])
};

export default ProductLogo;
