import styled from "styled-components";

export const Wrapper = styled.div`
  display: block;
  width: 270px;
`;

export const FieldWrapper = styled.div`
  flex: 1;
  .braintree-hosted-field {
    transition: all 200ms;
    height: 24px;
    border-bottom: 1px solid #e1e5e9;
    margin-bottom: 12px;
    &.braintree-hosted-fields-focused {
      border-bottom: 1px solid #2d335d;
    }
    &.braintree-hosted-fields-valid {
      border-bottom: 1px solid #35b379;
    }
    &.braintree-hosted-fields-invalid {
      border-bottom: 1px solid #f7555c;
    }
    input {
      padding: 0 4px;
      font-family: "Circular", sans-serif;
      font-size: 15px;
      font-weight: normal;
      color: #576474;
      outline: none;
      &::placeholder {
        color: #c4c8d0;
      }
    }
  }
`;

export const FieldGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;
