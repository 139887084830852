import React from "react";
import styled from "styled-components";
import { useWindowWidth } from "../../providers/window-width-provider";

function ShortcutsHeading() {
  const windowWidth = useWindowWidth();
  const headingText = windowWidth >= 1024 ? "Take a Shortcut" : "Shortcuts";
  return (
    <Container>
      <HeadingText>{headingText}</HeadingText>
      <Divider />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;

  @media (max-width: 1023px) {
    padding: 16px 0 8px;
  }
`;

const HeadingText = styled.h2`
  font-family: ${({ theme }) => theme.fonts.system};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  padding: 0 8px;
  background: ${({ theme }) => theme.colors.gray[200]};
  z-index: 1;
`;

const Divider = styled.hr`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  margin: 0;
  padding: 0;
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.gray[500]};
  border: none;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export default ShortcutsHeading;
