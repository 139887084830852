import styled from "styled-components";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import theme from "../../../../utils/theme";
import { default as BaseHeading } from "../../../common/styled/heading";
import { default as BaseText } from "../../../common/styled/text";
import BaseButton from "../../../common/Button";

export const CancelContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

export const PlanContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.raised};
  width: 100%;
  padding: 20px 28px 40px;
  background-color: white;

  @media (max-width: 576px) {
    padding: 20px 16px 40px;
  }
`;

export const LeftArrowLayout = styled.div`
  width: 10px;
  margin-right: 11px;
  cursor: pointer;
`;

export const BackLink = styled.span`
  ${theme.text.link};
  color: ${theme.colors.suite[900]};
`;

export const Heading = styled(BaseHeading)`
  width: 100%;
  text-align: center;
`;

export const Description = styled(BaseText)`
  margin: 0 auto 20px;
  text-align: center;
  color: ${theme.colors.gray[800]};
`;

export const AddonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Addon = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
    margin-right: 15px;
    fill: ${({ theme }) => theme.colors.attract[500]};
  }
`;

export const CancelButton = styled(BaseButton)`
  display: block;
  margin: 30px auto 0;
`;

export const ModalOverlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1111;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  h1 {
    font-size: 32px;
    line-height: 48px;
    margin: 0 auto;
  }

  h3 {
    margin: 0 0 12px;
  }

  strong {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.suite[900]};
  }
`;

export const ModalContent = styled(DialogContent)`
  box-shadow: ${({ theme }) => theme.shadows.overlay};
  border-radius: 6px;
  background-color: white;
  max-width: 512px;
  padding: 40px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 24px;

  button {
    margin-left: 12px;
  }
`;
