import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const BadgesWrapper = styled.div`
  min-height: 36px;
  display: flex;
  align-items: center;
  margin-right: -5px;
  margin-bottom: 8px;
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  > * + * {
    margin-top: 32px;
  }
`;

export const ErrorMessage = styled.div`
  display: block;
  width: 100%;
  max-width: 500px;
  padding: 12px;
  margin-bottom: 40px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.attract[100]};
  border: 1px solid ${({ theme }) => theme.colors.attract[300]};
  color: ${({ theme }) => theme.colors.attract[700]};

  > * + * {
    display: block;
    margin-top: 4px;
  }
`;

export const RecaptchaDisclaimer = styled.p`
  max-width: 300px;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[800]};

  @media (max-width: 578px) {
    font-size: 13px;
    line-height: 17px;
  }
`;
