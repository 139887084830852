import React from "react";
import PropTypes from "prop-types";
import { ButtonContainer, InnerButton } from "../styled/MlsNotFoundOption";
import { NO_MLS } from "../constants/config";

const MlsNotFoundOption = ({ isHighlighted, onMouseEnter, onClick }) => (
  <ButtonContainer
    isHighlighted={isHighlighted}
    onMouseEnter={onMouseEnter}
    onClick={onClick}>
    <InnerButton isHighlighted={isHighlighted}>{NO_MLS}</InnerButton>
  </ButtonContainer>
);

MlsNotFoundOption.propTypes = {
  isHighlighted: PropTypes.bool.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default MlsNotFoundOption;
