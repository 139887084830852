import { useEffect } from "react";
import { navigate } from "@reach/router";
import { useApolloClient } from "@apollo/react-hooks";

function Logout() {
  const client = useApolloClient();

  useEffect(() => {
    client.resetStore();
    localStorage.removeItem("token");
    sessionStorage.removeItem("impersonate");
    navigate("/app/login");
  }, []);

  return null;
}

export default Logout;
