import styled from "styled-components";

export const ContentLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  box-sizing: content-box;
  overflow: visible;
`;
