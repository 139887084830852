import React from "react";

const IconShortcutCma = (props) => (
  <svg width={24} height={24} fill="none" {...props}>
    <rect width={24} height={24} rx={5} fill="#BCF5DD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.273 6.697a.6.6 0 0 1 .654 0l2.8 1.82A.6.6 0 0 1 11 9.02v3.78a.6.6 0 0 1-.6.6H4.8a.6.6 0 0 1-.6-.6V9.02a.6.6 0 0 1 .273-.503l2.8-1.82zm8.8 0a.6.6 0 0 1 .654 0l2.8 1.82a.6.6 0 0 1 .273.503v3.78a.6.6 0 0 1-.6.6h-5.6a.6.6 0 0 1-.6-.6V9.02a.6.6 0 0 1 .273-.503l2.8-1.82zM5.4 9.346V12.2H7V11a.6.6 0 1 1 1.2 0v1.2h1.6V9.346l-2.2-1.43-2.2 1.43zm8.8 0V12.2h1.6V11a.6.6 0 1 1 1.2 0v1.2h1.6V9.346l-2.2-1.43-2.2 1.43zM5.6 16.2a.6.6 0 0 1 .6-.6H9a.6.6 0 1 1 0 1.2H6.2a.6.6 0 0 1-.6-.6zm8.8 0a.6.6 0 0 1 .6-.6h2.8a.6.6 0 1 1 0 1.2H15a.6.6 0 0 1-.6-.6z"
      fill="#20B575"
    />
  </svg>
);

export default IconShortcutCma;
