import React from "react";

const CloudAttract = (props) => (
  <svg viewBox="0 0 53.87 53.87" {...props}>
    <circle
      cx={26.94}
      cy={26.94}
      r={26.94}
      transform="rotate(-58.28 26.939 26.939)"
      fill="#fd5154"
    />
    <path
      d="M43.42 25.84L40 23a1.95 1.95 0 0 0-3 .16l-5.24 7.76a6.19 6.19 0 0 1-9.68.89 6.55 6.55 0 0 1 1.07-10l7.62-5.35a1.95 1.95 0 0 0 .23-2.89 18.78 18.78 0 0 1-1.39-1.75A18.46 18.46 0 0 0 28.12 10a1.94 1.94 0 0 0-2.51-.24l-8.51 5.12a15.13 15.13 0 0 0-6 15 15.92 15.92 0 0 0 4.3 8.62 15.44 15.44 0 0 0 8.42 4.5 16.62 16.62 0 0 0 2.87.25 14.43 14.43 0 0 0 12-6.3l5-8.55a2 2 0 0 0-.27-2.56zM26.6 11.22a.16.16 0 0 1 .12 0 .2.2 0 0 1 .14.06 15.48 15.48 0 0 1 1.29 1.63 19.45 19.45 0 0 0 1.55 1.89.21.21 0 0 1 .06.15v.09l-4.9 3.43-3.48-4.12zM37.18 36a12.79 12.79 0 0 1-13 5.28 13.73 13.73 0 0 1-7.45-4 14.21 14.21 0 0 1-3.82-7.68 13.39 13.39 0 0 1 5.21-13.29l1.78-1.07 3.56 4.21-1.21.85A8.32 8.32 0 0 0 20.88 33 8.1 8.1 0 0 0 26 35.44a8 8 0 0 0 7.31-3.56l1-1.44 4.08 3.62zm5-8.55l-3 5-4-3.54 3.23-4.78a.19.19 0 0 1 .15-.07s.1 0 .22.12l3.38 2.87c.12.14.13.25.02.41z"
      fill="#fff"
    />
  </svg>
);

export default CloudAttract;
