import styled from "styled-components";

export const LaunchpadProductsLayout = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  grid-gap: 24px;
  align-items: flex-start;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;

export const CancelIconLayout = styled.button`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  padding: 0px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    stroke: ${({ theme }) => theme.colors.gray[600]};
  }
`;

export const LogoLayout = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto 30px;
  transition: 200ms all;

  @media (max-width: 500px) {
    margin: ${({ isSettingUp }) =>
      isSettingUp ? `0 auto 30px` : `0 auto 20px;`};
  }
`;

export const IconLayout = styled.div`
  min-width: 72px;
  margin: 0 auto 20px;
  transition: 200ms all;
  will-change: transform;

  svg {
    fill: white;
    will-change: transform;
  }

  @media (max-width: 500px) {
    min-width: ${({ isSettingUp }) => (isSettingUp ? "72px" : "40px")};
    margin: ${({ isSettingUp }) =>
      isSettingUp ? `0 auto 20px` : `0 auto 12px`};
  }
`;

export const ProvisioningOptionLayout = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  width: 100%;

  p {
    margin: 0 0 12px;
  }

  pre {
    display: block;
    font-family: Monaco;
    color: ${({ theme }) => theme.colors.suite[500]};
    background-color: ${({ theme }) => theme.colors.gray[500]};
    padding: 4px 6px;
    margin: 0 0 18px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      overflow: visible;
      max-width: none;
    }
  }
`;

export const OptionDivider = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin: 12px 0;

  &::before {
    content: "or";
    font-weight: 500;
  }
`;

export const ProgressLayout = styled.div`
  width: 100%;
  padding: 20px 12px 12px;

  a {
    text-decoration: underline;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.gray[500]};

  &::before {
    content: "";
    display: block;
    height: 100%;
    min-width: 10px;
    width: ${({ percentComplete }) => `${percentComplete}%`};
    border-radius: 2px;
    background-color: ${({ color }) => color};
  }
`;

export const StepCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.gray[700]};
`;
