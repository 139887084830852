import styled from "styled-components";
import { Link } from "@reach/router";

export const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px 24px 0;
  background: transparent;
  z-index: 1111;

  @media (max-width: 1023px) {
    height: 48px;
    background-color: white;
    box-shadow: ${({ theme }) => theme.shadows.sticky};
    padding: 0 12px;
  }
`;

export const LogoLink = styled(Link)`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1023px) {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
  }
`;

export const CloudIconLayout = styled.div`
  display: block;
  width: 30px;

  svg {
    fill: ${({ theme }) => theme.colors.suite[900]};
  }
`;

export const Main = styled.main`
  position: relative;
  width: 100%;
`;

export const BackLinkLayout = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;

  a {
    color: ${({ theme }) => theme.colors.suite["900"]};
    font-weight: 500;
  }
`;

export const LeftArrowLayout = styled.div`
  margin-right: 11px;

  svg {
    width: 10px;
    height: 10px;
    fill: ${({ theme }) => theme.colors.suite[900]};
  }
`;
