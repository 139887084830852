import React from "react";

const CloudStreams = (props) => (
  <svg viewBox="0 0 53.87 53.87" {...props}>
    <circle cx={26.94} cy={26.94} r={26.94} fill="#0aa1dc" />
    <path d="M40.93 38h-28a2.3 2.3 0 0 1-1.82-3.71l1.5-1.9c1.39-1.76 3.05-4.48 3.05-7.47v-3a11.19 11.19 0 0 1 11.28-11.33 11.19 11.19 0 0 1 11.27 11.35v3c0 3 1.66 5.71 3 7.47l1.5 1.9A2.29 2.29 0 0 1 40.93 38zm-14-25.63a9.45 9.45 0 0 0-9.52 9.59v3c0 3.5-1.86 6.58-3.42 8.56l-1.5 1.9a.5.5 0 0 0-.06.56.53.53 0 0 0 .5.31h28a.53.53 0 0 0 .5-.31.5.5 0 0 0-.06-.56l-1.5-1.9c-1.56-2-3.42-5.06-3.42-8.56v-3a9.45 9.45 0 0 0-9.51-9.59zM21.68 44.32a4.53 4.53 0 0 1-4.55-4.5h1.76a2.79 2.79 0 0 0 5.58 0h1.76a4.53 4.53 0 0 1-4.55 4.5z" />
  </svg>
);

export default CloudStreams;
