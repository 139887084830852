import styled from "styled-components";
import theme from "../../utils/theme";

export const Container = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0;
  font-size: 24px;
  color: #131945;
  letter-spacing: 0;
  line-height: 30px;
`;

export const OldPrice = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-right: 14px;
`;

export const LineThrough = styled.div`
  position: absolute;
  top: 50%;
  left: -4px;
  right: -4px;
  transform: translateY(-50%);
  height: 3px;
  background-color: ${theme.colors.attract[500]};
  opacity: 0.8;
`;

export const FinalPrice = styled.span`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const PaymentInterval = styled.span`
  font-size: 18px;
  line-height: 30px;
  color: #89929d;
`;

export const Free = styled.span`
  text-transform: uppercase;
`;
