import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import cmaBadge from "../../assets/images/cloud-cma-badge-tiny.svg";
import streamsBadge from "../../assets/images/cloud-streams-badge-tiny.svg";
import mlxBadge from "../../assets/images/cloud-mlx-badge-tiny.svg";
import attractBadge from "../../assets/images/cloud-attract-badge-tiny.svg";
import homebeatBadge from "../../assets/images/homebeat-tiny.svg";

const ProductBadges = ({ products, size }) => {
  return Array.isArray(products) ? (
    products.map(({ key }, index) => (
      <Badge
        key={`${key}${index}`}
        productKey={key}
        size={size}
        zIndex={index}
        isStacked
      />
    ))
  ) : (
    <Badge productKey={products.key} size={size} zIndex={1} />
  );
};

ProductBadges.propTypes = {
  products: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

const badgeMap = {
  cloud_cma: cmaBadge,
  cloud_streams: streamsBadge,
  cloud_mlx: mlxBadge,
  cloud_attract: attractBadge,
  homebeat: homebeatBadge
};

export const Badge = styled.div`
  position: relative;
  display: block;
  min-width: ${({ size }) => size}px;
  max-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  max-height: ${({ size }) => size}px;
  ${({ isStacked }) => isStacked && `margin-left: -5px;`}
  border: 2px solid #fafafa;
  border-radius: 50%;
  overflow: hidden;
  z-index: ${({ zIndex }) => 10 - zIndex};
  background-size: contain;
  &::after {
    content: "";
    background-size: contain;
    background-image: ${({ productKey }) => css`url(${badgeMap[productKey]})`};
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

Badge.defaultProps = {
  isAvailable: true,
  size: 32
};

Badge.propTypes = {
  bgImg: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default ProductBadges;
