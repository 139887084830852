import { DialogOverlay, DialogContent } from "@reach/dialog";
import styled from "styled-components";
import theme from "../../../utils/theme";

export const TOSList = styled.div`
  color: #212d3b;
  font-size: 14px;
  margin: 0 0 18px 0;

  p {
    margin-bottom: 3px;
  }

  ul {
    padding-left: 25px;
  }
`;

export const TOSText = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gray[900]};
`;

export const ModalContent = styled(DialogContent)`
  box-shadow: ${({ theme }) => theme.shadows.modal};
  border-radius: 6px;
  background-color: white;
  padding: 25px;
  position: relative;
  width: 560px;
  display: flex;
  flex-direction: column;

  h1 {
    color: ${({ theme }) => theme.colors.gray[900]};
    font-size: 24px;
    margin: 0 0 12px;
  }

  h4 {
    color: ${({ theme }) => theme.colors.gray[700]};
    font-size: 16px;
    font-weight: 500;
  }

  span {
    align-self: flex-start;
  }

  button {
    align-self: flex-end;
  }

  a {
    color: ${({ theme }) => theme.colors.mlx[500]};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const TOSModal = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 20px;
  z-index: 99999;
  line-height: 1.15;
`;

export const ModalCloser = styled.button`
  outline: none;
  border: none;
  position: absolute;
  width: 42px;
  height: 42px;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 48px;
  line-height: 48px;
  font-weight: 500;
  background-color: transparent;
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  svg {
    stroke: ${theme.colors.gray[800]};
  }
`;
