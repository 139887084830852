import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import ClampLines from "react-clamp-lines";
import { Dialog as Modal } from "@reach/dialog";
import { Portal } from "@wrstudios/components";
import IconClose from "./icons/CloseIcon";
import WistiaEmbed from "../WistiaEmbed";
import ShortcutIcon from "./ShortcutIcon";
import IconChevronRight from "./icons/IconChevronRight";
import IconPlayVideo from "./icons/IconPlayVideo";
import { useLaunchpad } from "../Launchpad/LaunchpadProvider";
import VisuallyHidden from "@reach/visually-hidden";

function Shortcuts() {
  const { shortcuts, user, isSubscribedToHomebeat } = useLaunchpad();
  if (!shortcuts || !user) return null;

  // if the user is an Attract-only subscriber
  if (!user.mls) {
    return (
      <ShortcutsLayout>
        {/* Attract */}
        <ShortcutHeading productKey="attract">Cloud Attract</ShortcutHeading>
        <Stack>
          {(shortcuts["cloud_attract"] || []).map((shortcut) => (
            <Shortcut
              isActive={user.provisionedAccounts.find(
                (acct) => acct.product.key === "cloud_attract"
              )}
              id={shortcut.id}
              token={user.token}
              key={shortcut.key}
              title={shortcut.title}
              description={shortcut.description}
              productName={shortcut.product.name}
              productId={shortcut.product.id}
              shortcutKey={shortcut.key}
            />
          ))}
          <LearnLink
            href="#"
            appKey="attract"
            videoDuration="3m"
            title="Share on Facebook"
            description="Want to get maximum exposure for you landing page? Watch this."
            wistiaId="3h1oky66kv"
          />
        </Stack>
      </ShortcutsLayout>
    );
  }

  // otherwise, they're a normal suite subscriber
  return (
    <ShortcutsLayout>
      {/* CMA */}
      <ShortcutHeading productKey="cma">Cloud CMA</ShortcutHeading>
      <Stack>
        {(shortcuts["cloud_cma"] || [])
          .filter((shortcut) =>
            // if they don't have Homebeat, let's remove the homebeat shortcut keyed under `cloud_cma_01`
            !isSubscribedToHomebeat && shortcut.key === "cloud_cma_01"
              ? false
              : true
          )
          .map((shortcut) => (
            <Shortcut
              isActive={user.provisionedAccounts.find(
                (acct) => acct.product.key === "cloud_cma"
              )}
              id={shortcut.id}
              token={user.token}
              key={shortcut.key}
              title={shortcut.title}
              description={shortcut.description}
              productName={shortcut.product.name}
              productId={shortcut.product.id}
              shortcutKey={shortcut.key}
            />
          ))}
      </Stack>

      {/* Streams */}
      <ShortcutHeading productKey="streams">Cloud Streams</ShortcutHeading>
      <Stack>
        {(shortcuts["cloud_streams"] || []).map((shortcut) => (
          <Shortcut
            isActive={user.provisionedAccounts.find(
              (acct) => acct.product.key === "cloud_streams"
            )}
            id={shortcut.id}
            token={user.token}
            key={shortcut.key}
            title={shortcut.title}
            description={shortcut.description}
            productName={shortcut.product.name}
            productId={shortcut.product.id}
            shortcutKey={shortcut.key}
          />
        ))}
        <LearnLink
          appKey="streams"
          videoDuration="1:06"
          title="Creating Your First Stream"
          description="Want to know how to get beautiful listing alerts delivered to your buyer leads & clients? Watch this."
          wistiaId="u8fvdjvapp"
        />
        <LearnLink
          appKey="streams"
          videoDuration="1:20"
          title="Onboarding Your Clients"
          description="Want to understand how your prospects and clients will interact with Cloud Streams? Watch this."
          wistiaId="1e1m1h1mq1"
        />
        <LearnLink
          appKey="streams"
          videoDuration="0:51"
          title="Creating a Custom Area"
          description="Want to narrow your buyer's search down to a specific geographic area? Watch this."
          wistiaId="54uyzylprm"
        />
      </Stack>

      {/* MLX */}
      <ShortcutHeading productKey="mlx">Cloud MLX</ShortcutHeading>
      <Stack>
        {(shortcuts["cloud_mlx"] || []).map((shortcut) => (
          <Shortcut
            isActive={user.mls.products.find(
              (product) => product.key === "cloud_mlx"
            )}
            id={shortcut.id}
            token={user.token}
            key={shortcut.key}
            title={shortcut.title}
            description={shortcut.description}
            productName={shortcut.product.name}
            productId={shortcut.product.id}
            shortcutKey={shortcut.key}
          />
        ))}
        <LearnLink
          appKey="mlx"
          videoDuration="2:05"
          title="Searching in MLX"
          description="Want to save time & effort when searching for listings? Watch this."
          wistiaId="yak80p31l7"
        />
        <LearnLink
          appKey="mlx"
          videoDuration="1:40"
          title="Using the Map to Search"
          description="The map is a great way to narrow your search to a specific custom area. Learn how."
          wistiaId="r5plzqfx83"
        />
        <LearnLink
          appKey="mlx"
          videoDuration="1:36"
          title="Creating Custom Areas"
          description="Want to use a custom map shape in your search? Watch this."
          wistiaId="mbc8qm5q4b"
        />
        <LearnLink
          appKey="mlx"
          videoDuration="1:02"
          title="Saving Listings to Collections"
          description="Want to share a highly curated collection of properties to generate leads? Watch this."
          wistiaId="9ckfodz4vt"
        />
      </Stack>

      {/* Attract */}
      <ShortcutHeading productKey="attract">Cloud Attract</ShortcutHeading>
      <Stack>
        {(shortcuts["cloud_attract"] || []).map((shortcut) => (
          <Shortcut
            isActive={user.provisionedAccounts.find(
              (acct) => acct.product.key === "cloud_attract"
            )}
            id={shortcut.id}
            token={user.token}
            key={shortcut.key}
            title={shortcut.title}
            description={shortcut.description}
            productName={shortcut.product.name}
            productId={shortcut.product.id}
            shortcutKey={shortcut.key}
          />
        ))}
        <LearnLink
          appKey="attract"
          videoDuration="2:45"
          title="Creating and Sharing Pages"
          description="Want to know how to create your own lead-generating landing page? Watch this."
          wistiaId="d79hbaj9iv"
        />
        <LearnLink
          appKey="attract"
          videoDuration="1:37"
          title="Boosting on Facebook"
          description="Want to get maximum exposure for you landing page? Watch this."
          wistiaId="3h1oky66kv"
        />
        <LearnLink
          appKey="attract"
          videoDuration="8:48"
          title="Generating Lead Traffic"
          description="6 ways to use Cloud Attract to generate quality leads."
          wistiaId="p1ihrx61hi"
        />
      </Stack>
    </ShortcutsLayout>
  );
}

function Shortcut({
  id,
  token,
  isActive,
  title,
  description,
  productId,
  productName,
  shortcutKey
}) {
  return (
    <ShortcutCard
      isActive={isActive}
      {...(isActive
        ? {
            as: "a",
            href: `/product_login/${productId}?jwt=${token}&onboarding_item_id=${id}`,
            title: description
          }
        : {
            as: "div",
            title: `Activate ${productName} to enable this shortcut.`
          })}>
      <ShortcutIcon shortcutKey={shortcutKey} />
      <MobileOnlyIcon>
        <IconChevronRight />
      </MobileOnlyIcon>
      <ShortcutCopyLayout>
        <ShortcutTitle>{title}</ShortcutTitle>
        <ShortcutDescription
          id={description}
          text={description}
          lines={2}
          ellipses="..."
          buttons={false}
        />
      </ShortcutCopyLayout>
    </ShortcutCard>
  );
}

function LearnLink({ appKey, title, description, videoDuration, wistiaId }) {
  const [videoIsShowing, setVideoIsShowing] = React.useState(false);
  return (
    <>
      <LearnCard
        title={description}
        onClick={() => setVideoIsShowing(!videoIsShowing)}>
        <LearnBadge appKey={appKey}>
          <IconPlayVideo />
          <span>Learn</span>
        </LearnBadge>
        <VideoDuration>{videoDuration}</VideoDuration>
        <LearnCopyLayout>
          <LearnTitle appKey={appKey}>{title}</LearnTitle>
          <LearnDescription
            id={description}
            text={description}
            lines={2}
            ellipses="..."
            buttons={false}
          />
        </LearnCopyLayout>
      </LearnCard>
      {videoIsShowing && (
        <Portal>
          <LearnVideoModal onDismiss={() => setVideoIsShowing(false)}>
            <ModalCloser onClick={() => setVideoIsShowing(false)}>
              <IconWrapper>
                <IconClose />
              </IconWrapper>
              <VisuallyHidden>Close Video</VisuallyHidden>
            </ModalCloser>
            <VideoWrapper>
              <WistiaEmbed hashedId={wistiaId} />
            </VideoWrapper>
          </LearnVideoModal>
        </Portal>
      )}
    </>
  );
}

const ShortcutsLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-column-gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 500px) {
    padding: 0px 12px;
  }
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  > * + * {
    margin-top: 12px;
  }
`;

const ShortcutHeading = styled.h4`
  padding: 0 0 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fonts.system};
  color: ${({ productKey, theme }) => theme.colors[productKey][500]};

  @media (min-width: 1024px) {
    display: none;
  }
`;

const ShortcutCard = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 10px 10px 12px;
  max-height: 104px;

  ${({ isActive }) =>
    isActive
      ? css`
          background: #ffffff;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2),
            0px 1px 2px rgba(0, 0, 0, 0.1);

          ${MobileOnlyIcon} {
            @media (max-width: 1023px) {
              display: block;
            }
          }
        `
      : css`
          background: transparent;
          border: 1px solid #e2e5e8;
          opacity: 0.7;
          cursor: not-allowed;
        `}

  @media (max-width: 1023px) {
    flex-direction: row;
    padding-right: 32px;
  }
`;

const MobileOnlyIcon = styled.div`
  display: none;
  position: absolute;
  top: 13px;
  right: 8px;

  svg {
    fill: ${({ theme }) => theme.colors.gray[600]};
  }
`;

const ShortcutCopyLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;

  @media (max-width: 1023px) {
    padding-top: 2px;
    padding-left: 10px;
  }
`;

const ShortcutTitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.system};
  color: ${({ theme }) => theme.colors.gray[900]};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const ShortcutDescription = styled(ClampLines)`
  font-family: ${({ theme }) => theme.fonts.system};
  color: ${({ theme }) => theme.colors.gray[800]};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
  margin: 0;
`;

const LearnCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 12px 10px;
  max-height: 104px;
  cursor: pointer;

  @media (max-width: 1023px) {
    flex-direction: row;
    padding-right: 32px;
  }
`;

const LearnBadge = styled.div`
  font-family: ${({ theme }) => theme.fonts.system};
  background-color: ${({ theme, appKey }) => theme.colors[appKey][500]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 2px 6px;
  color: white;

  svg {
    fill: white;
  }

  span {
    margin-left: 6px;
  }

  @media (max-width: 1023px) {
    min-width: 24px;
    height: 24px;
    padding: 0px;
    background-color: ${({ theme, appKey }) => theme.colors[appKey][200]};
    svg {
      fill: ${({ theme, appKey }) => theme.colors[appKey][500]};
    }
    span {
      display: none;
    }
  }
`;

const LearnCopyLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 7px;

  @media (max-width: 1023px) {
    padding-top: 2px;
    padding-left: 10px;
  }
`;

const LearnTitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.system};
  color: ${({ theme }) => theme.colors.gray[800]};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;

  &::before {
    content: "Learn: ";
    color: ${({ theme, appKey }) => theme.colors[appKey][500]};

    @media (min-width: 1024px) {
      display: none;
    }
  }
`;

const LearnDescription = styled(ClampLines)`
  font-family: ${({ theme }) => theme.fonts.system};
  color: ${({ theme }) => theme.colors.gray[800]};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
  margin: 0;
`;

const VideoDuration = styled.span`
  position: absolute;
  top: 15px;
  right: 13px;
  color: ${({ theme }) => theme.colors.gray[700]};
  font-family: ${({ theme }) => theme.fonts.system};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
`;

const LearnVideoModal = styled(Modal)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  /* flex-flow: column; */
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 30px 20px 60px;
  z-index: 1111;
`;

const ModalCloser = styled.button`
  outline: none;
  border: none;
  position: absolute;
  width: 42px;
  height: 42px;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 48px;
  line-height: 48px;
  font-weight: 500;
  background-color: transparent;
`;

const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  svg {
    stroke: white;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  max-width: 1140px;
  padding: 0 40px;
`;

export default Shortcuts;
