// Color Utility Functions
function getByProductKey(productKey) {
  return (
    {
      cma: colors.cma[500],
      cloud_cma: colors.cma[500],
      streams: colors.streams[500],
      cloud_streams: colors.streams[500],
      mlx: colors.mlx[500],
      cloud_mlx: colors.mlx[500],
      attract: colors.attract[500],
      cloud_attract: colors.attract[500],
      homebeat: colors.suite[900]
    }[productKey] || colors.suite[900]
  );
}

// Design System Colors
const colors = {
  white: "#fff",
  black: "#000",
  gray: {
    100: "#fff",
    200: "#f9fafb",
    300: "#f4f5f6",
    400: "#eeeff1",
    500: "#e2e5e8",
    600: "#9ba4b0",
    700: "#737e8c",
    800: "#4d5a6a",
    900: "#212d3b"
  },
  cma: {
    100: "#e7fef4",
    200: "#bcf5dd",
    300: "#93ecc6",
    400: "#47d196",
    500: "#20b575",
    600: "#29a36f",
    700: "#178254",
    800: "#0a5c39",
    900: "#004d2c"
  },
  streams: {
    100: "#e6f8ff",
    200: "#b4e9fd",
    300: "#6ed1f7",
    400: "#4dbbe6",
    500: "#0aa1dc",
    600: "#0690c6",
    700: "#036d96",
    800: "#014965",
    900: "#00374d"
  },
  mlx: {
    100: "#e7f2fe",
    200: "#bad8f7",
    300: "#7db1e8",
    400: "#5c97d6",
    500: "#3a7abe",
    600: "#2964a3",
    700: "#174b82",
    800: "#0a325c",
    900: "#00254d"
  },
  attract: {
    100: "#ffebeb",
    200: "#ffcccd",
    300: "#ff999b",
    400: "#ff8082",
    500: "#fd5154",
    600: "#dd3c3f",
    700: "#c32225",
    800: "#790608",
    900: "#4d0001"
  },
  suite: {
    100: "#e7eafe",
    200: "#d1d7fa",
    300: "#939fec",
    400: "#707edb",
    500: "#5362C6",
    600: "#2939a3",
    700: "#172582",
    800: "#0a155c",
    900: "#131943"
  },
  blue: {
    100: "#E7EEFE",
    200: "#BACFF7",
    900: "#041B49"
  },
  accent: {
    teal: "#00C9C7",
    green: "#00CA72",
    yellow: "#F6A807",
    blue: "#5265EC",
    violet: "#9B52EC",
    magenta: "#E352EC",
    red: "#EC5252"
  },
  status: {
    active: "#00CA72",
    backup: "#00c9c7",
    closed: "#ec5252",
    pending: "#f6a807",
    subject: "#9b52ec"
  },
  utils: {
    getByProductKey
  }
};

export default colors;
