import React from "react";

const IconShortcutPropertyReport = (props) => (
  <svg width={24} height={24} fill="none" {...props}>
    <rect width={24} height={24} rx={5} fill="#BCF5DD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8 5.6a.6.6 0 0 1 .6-.6h8.8a.6.6 0 0 1 .424.176l2.4 2.4A.6.6 0 0 1 18.2 8v10.4a.6.6 0 0 1-.6.6H6.4a.6.6 0 0 1-.6-.6V5.6zm1.2.6v11.6h10V8.249L14.95 6.2H7z"
      fill="#20B575"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8 9.8a.6.6 0 0 1 .6-.6H15a.6.6 0 1 1 0 1.2h-3.6a.6.6 0 0 1-.6-.6zm0 2.4a.6.6 0 0 1 .6-.6h2.8a.6.6 0 1 1 0 1.2h-2.8a.6.6 0 0 1-.6-.6zm0 2.4a.6.6 0 0 1 .6-.6H15a.6.6 0 1 1 0 1.2h-3.6a.6.6 0 0 1-.6-.6zm-2.4-2.4a.6.6 0 0 1 .6-.6h.4a.6.6 0 1 1 0 1.2H9a.6.6 0 0 1-.6-.6zm0-2.4a.6.6 0 0 1 .6-.6h.4a.6.6 0 1 1 0 1.2H9a.6.6 0 0 1-.6-.6zm0 4.8A.6.6 0 0 1 9 14h.4a.6.6 0 1 1 0 1.2H9a.6.6 0 0 1-.6-.6z"
      fill="#20B575"
    />
  </svg>
);

export default IconShortcutPropertyReport;
