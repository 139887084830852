import { useMutation } from "@apollo/react-hooks";
import addDays from "date-fns/add_days";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { CANCEL_ADDON_SUBSCRIPTION_MUTATION } from "../api/mutations/subscription";
import {
  CHANGE_USER_PLAN,
  GET_USER_PLAN
} from "../machines/subscription-workflow";
import {
  StepHeader,
  StepIdentifier,
  StepName,
  StepNumber,
  StepWrapper
} from "../styled/StepWrapper";
import Accordion from "./Accordion";
import CardOnFile from "./CardOnFile";
import CompleteSignup from "./CompleteSignup";
import NewCreditCard from "./NewCreditCard";
import PlanChangeOverview from "./PlanChangeOverview";
import PromoCode from "./PromoCode";
import {
  useFinalPrice,
  useIsUpgradingDuringFallPromo,
  useSubscriptionWorkflow
} from "./SubscriptionWorkflow";
import Button from "./common/Button";
import { ErrorMessage, Layout, Stack } from "./styled/payment-step";

export default function BillingStep() {
  const [isReady, setIsReady] = useState(false);
  const { state, send } = useSubscriptionWorkflow();
  const { mode, subscription, user, selectedPlan, changePlanErrors } =
    state.context;
  const isCurrentStep = state.matches("billing");
  const formikContext = useFormikContext();
  const finalPrice = useFinalPrice();
  const isFreePlan = finalPrice <= 0;
  const homebeatSubscription =
    mode === "edit"
      ? user.addOns.find((addOn) => addOn.sku && addOn.sku.key === "homebeat")
      : undefined;
  const isChangingPlan =
    state.matches("billing.subscription.edit.changingPlan") ||
    state.matches("billing.subscription.edit.editSuccess");
  const isUpgradingDuringFallPromo = useIsUpgradingDuringFallPromo();

  useEffect(() => {
    formikContext.setFieldValue("price", finalPrice);
  }, [finalPrice]);

  const [changePlanMutation] = useMutation(CHANGE_USER_PLAN, {
    context: { useAuthedEndpoint: true },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_USER_PLAN,
        context: { useAuthedEndpoint: true }
      }
    ]
  });

  const [cancelAddOnSubscriptionMutation] = useMutation(
    CANCEL_ADDON_SUBSCRIPTION_MUTATION,
    {
      context: { useAuthedEndpoint: true },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USER_PLAN,
          context: { useAuthedEndpoint: true }
        }
      ]
    }
  );

  function handlePlanChange() {
    const isCancellingHomebeatAddOn =
      mode === "edit" &&
      subscription.sku.key === "site_license" &&
      homebeatSubscription &&
      selectedPlan.key === "site_license";

    send("CHANGE_PLAN", {
      mutation: isCancellingHomebeatAddOn
        ? cancelAddOnSubscriptionMutation
        : changePlanMutation
    });
  }

  return (
    <StepWrapper isCurrent={isCurrentStep}>
      <StepHeader>
        <StepIdentifier>
          <StepNumber isCurrent={isCurrentStep}>3</StepNumber>
          <StepName isCurrent={isCurrentStep}>Payment</StepName>
        </StepIdentifier>
      </StepHeader>
      <Accordion isOpen={isCurrentStep} afterOpen={() => setIsReady(true)}>
        <Layout>
          {state.matches("billing.subscription.new") && (
            <CompleteSignup isReady={isReady} />
          )}
          {state.matches("billing.subscription.edit") && (
            <>
              <PlanChangeOverview />
              {!isEmpty(changePlanErrors) && (
                <ErrorMessage>
                  {changePlanErrors.map((error) => (
                    <span key={error}>{error}</span>
                  ))}
                </ErrorMessage>
              )}
              {user.payment ? (
                <Stack>
                  <CardOnFile
                    isPastDue={subscription.status === "pastDue"}
                    payment={user.payment}
                    chargeAmount={finalPrice}
                    nextBillDate={
                      finalPrice > subscription.sku.price // if they're upgrading their service level, restart their trial
                        ? isUpgradingDuringFallPromo
                          ? new Date(2021, 1, 1)
                          : addDays(
                              new Date(),
                              String(window.trial_duration_days || 30)
                            )
                        : subscription.nextBillDate
                    }
                  />
                  <PromoCode />
                  <Button
                    loading={isChangingPlan}
                    disabled={isChangingPlan}
                    onClick={handlePlanChange}>
                    Change Plan
                  </Button>
                </Stack>
              ) : isFreePlan ? (
                <Stack>
                  <PromoCode />
                  <Button
                    loading={isChangingPlan}
                    disabled={isChangingPlan}
                    onClick={handlePlanChange}>
                    Change Plan
                  </Button>
                </Stack>
              ) : (
                <NewCreditCard
                  onPlanChange={() =>
                    send("UPGRADE_TO_PAID_PLAN", { changePlanMutation })
                  }
                />
              )}
            </>
          )}
        </Layout>
      </Accordion>
    </StepWrapper>
  );
}
