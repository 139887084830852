import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { GET_AUTHED_USER } from "../../api/queries/user";
import useMutationObservable from "../../hooks/useMutationObserver";
import Button from "../common/Button";
import Checkbox from "../common/Checkbox";
import {
  ModalContent,
  TOSList,
  TOSModal,
  TOSText
} from "./styled/tos-accept-wall";

export default function TOSAcceptanceWall({ user }) {
  const [tosAccepted, setTosAccepted] = useState(false);

  useMutationObservable(document.body, (mutations) => {
    for (const { target, removedNodes } of mutations) {
      if (removedNodes.length) {
        const isPortal =
          removedNodes[0].nodeName === "REACH-PORTAL" ||
          target.nodeName === "REACH-PORTAL";

        const isPortalContent =
          removedNodes[0].nodeName === "DIV" &&
          removedNodes[0].className.includes("tos");

        if (isPortal || isPortalContent) {
          target.appendChild(removedNodes[0]);
        }
      }
    }
  });

  const [acceptTos] = useMutation(ACCEPT_TOS, {
    context: { useAuthedEndpoint: true },
    update(cache, response) {
      const cachedQuery = cache.readQuery({ query: GET_AUTHED_USER });
      cache.writeQuery({
        query: GET_AUTHED_USER,
        data: {
          ...cachedQuery,
          user: {
            ...cachedQuery.user,
            hasAgreedToTos: response.data.acceptTos.success
          }
        }
      });
    }
  });

  return (
    <TOSModal>
      <ModalContent>
        <h1>Changes to product Terms of Service</h1>
        <h4>
          We've recently updated our{" "}
          <a href="/tos" target="_blank" tabIndex={-1}>
            Terms of Service
          </a>
          . Please take a few moments to review.
        </h4>
        <TOSList>
          <p>Highlights of the changes:</p>
          <ul>
            <li>Refund policy.</li>
            <li>Company email address.</li>
            <li>Company physical address and jurisdiction.</li>
          </ul>
        </TOSList>
        <span>
          <Checkbox
            name="tos"
            size="sm"
            value={tosAccepted}
            onChange={() => setTosAccepted(!tosAccepted)}>
            <TOSText>
              I have read and agree with the{" "}
              <a href="/tos" target="_blank" tabIndex={-1}>
                Terms of Service
              </a>
              .
            </TOSText>
          </Checkbox>
        </span>
        <Button
          disabled={!tosAccepted}
          size="medium"
          onClick={() => {
            acceptTos({ variables: { id: user.tosId } });
          }}>
          Agree
        </Button>
      </ModalContent>
    </TOSModal>
  );
}

const ACCEPT_TOS = gql`
  mutation AcceptTos($id: ID!) {
    acceptTos(id: $id) {
      success
      errors
    }
  }
`;
