import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import IconShortcutHomebeat from "./icons/IconShortcutHomebeat";
import IconShortcutCMA from "./icons/IconShortcutCMA";
import IconShortcutBuyerTour from "./icons/IconShortcutBuyerTour";
import IconShortcutPropertyReport from "./icons/IconShortcutPropertyReport";
import IconShortcutFlyer from "./icons/IconShortcutFlyer";
import IconShortcutStream from "./icons/IconShortcutStream";
import IconShortcutBuyerActivity from "./icons/IconShortcutBuyerActivity";
import IconShortcutSearch from "./icons/IconShortcutSearch";
import IconShortcutAttractSellers from "./icons/IconShortcutAttractSellers";
import IconShortcutAttractBuyers from "./icons/IconShortcutAttractBuyers";

const Container = styled.div`
  width: 24px;
  height: 24px;
`;

export default function ShortcutIcon({ shortcutKey }) {
  return (
    <Container>
      {{
        cloud_cma_01: <IconShortcutHomebeat />,
        cloud_cma_02: <IconShortcutCMA />,
        cloud_cma_03: <IconShortcutBuyerTour />,
        cloud_cma_04: <IconShortcutPropertyReport />,
        cloud_cma_05: <IconShortcutFlyer />,
        cloud_streams_01: <IconShortcutStream />,
        cloud_streams_02: <IconShortcutBuyerActivity />,
        cloud_mlx_01: <IconShortcutSearch />,
        cloud_attract_01: <IconShortcutAttractSellers />,
        cloud_attract_02: <IconShortcutAttractBuyers />
      }[shortcutKey] || null}
    </Container>
  );
}

ShortcutIcon.propTypes = {
  shortcutKey: PropTypes.oneOf([
    "cloud_cma_01",
    "cloud_cma_02",
    "cloud_cma_03",
    "cloud_cma_04",
    "cloud_cma_05",
    "cloud_streams_01",
    "cloud_streams_02",
    "cloud_mlx_01",
    "cloud_attract_01",
    "cloud_attract_02"
  ])
};
