import gql from "graphql-tag";

export const GO_TO_STEP = gql`
  mutation goToStep($stepNumber: Int!) {
    goToStep(stepNumber: $stepNumber) @client
  }
`;

export const GO_TO_STEP_TWO = gql`
  mutation goToStepTwo {
    goToStepTwo @client
  }
`;

export const SELECT_STATE = gql`
  mutation selectState($selectedState: String!) {
    selectState(selectedState: $selectedState) @client
  }
`;

export const SELECT_MLS = gql`
  mutation selectMls($selectedMls: String!) {
    selectMls(selectedMls: $selectedMls) @client
  }
`;

export const SELECT_PLAN = gql`
  mutation selectPlan($sku_id: String!, $planName: String!) {
    selectPlan(sku_id: $sku_id, planName: $planName) @client
  }
`;

export const SIGN_UP_USER_MUTATION = gql`
  mutation signUpUser(
    $user: UserRegistrationInput!
    $sku: SkuInput!
    $payment_method_nonce: String!
    $discount_id: ID
    $affiliate_id: ID
    $sso_jwt: String
    $source: String
  ) {
    signUpUser(
      user: $user
      sku: $sku
      payment_method_nonce: $payment_method_nonce
      discount_id: $discount_id
      affiliate_id: $affiliate_id
      sso_jwt: $sso_jwt
      source: $source
    ) {
      id
      email
      jwt
    }
  }
`;

export const SIGN_IN_USER = gql`
  mutation signInUser($auth: AUTH_PROVIDER_EMAIL!) {
    signInUser(auth: $auth) {
      id
      jwt
      name
      lwa_id
      mlsCredential: mls_credential {
        code
      }
    }
  }
`;

export const CHANGE_USER_PLAN = gql`
  mutation changeUserPlan(
    $sku: SkuInput!
    $payment_method_nonce: String
    $discount_id: ID
    $mls_id: ID
  ) {
    changeUserPlan(
      sku: $sku
      payment_method_nonce: $payment_method_nonce
      discount_id: $discount_id
      mls_id: $mls_id
    ) {
      user {
        jwt
        subscription {
          next_billing_period_amount
          next_bill_date
          price
          status
          balance
          products {
            id
            key
            name
            provisioned_account {
              id
              provisioned_at
            }
            onboarding_items {
              id
              key
              description
              action_link
              completed_at
              product_id
            }
          }
          sku {
            name
            key
            type
          }
          discount {
            id
            code
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_BILLING = gql`
  mutation updateUserBilling($payment_method_nonce: String!) {
    updateUserBilling(payment_method_nonce: $payment_method_nonce) {
      payment {
        card_type
        expiration_date
        image_url
        last_4
        masked_number
      }
      subscription {
        next_billing_period_amount
        next_bill_date
        price
        status
        balance
        products {
          key
          site_license
          discounts {
            code
            amount
          }
        }
        sku {
          name
          key
          type
        }
        discount {
          id
          code
        }
      }
    }
  }
`;

export const VALIDATE_SSO = gql`
  mutation validateSso($sso_jwt: String!) {
    validateSso(sso_jwt: $sso_jwt) {
      name
      email
      mls_credential {
        code
        name
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($user: UserRegistrationInput!) {
    updateUser(user: $user) {
      name
      email
      company_name
      phone_number
      website
      mls_credential {
        name
        code
      }
    }
  }
`;

export const UPDATE_INTERCOM_USER = gql`
  mutation updateIntercom {
    updateIntercom {
      id
    }
  }
`;

export const CANCEL_ACCOUNT = gql`
  mutation cancelAccount {
    cancelAccount {
      id
      email
    }
  }
`;

export const VERIFY_USER_BY_EMAIL = gql`
  mutation verifyUserByEmail($email: String!) {
    verifyUserByEmail(email: $email) {
      id
      name
      email
    }
  }
`;

export const CREATE_ADDON_MUTATION = gql`
  mutation createAddOn(
    $user: AddOnRegistrationInput!
    $sku: AddOnSkuInput!
    $payment_method_nonce: String!
    $grecaptcha_response: String!
    $external_user_id: ID!
    $jwt: String
    $discount_id: ID
  ) {
    createAddOn(
      user: $user
      sku: $sku
      payment_method_nonce: $payment_method_nonce
      grecaptcha_response: $grecaptcha_response
      external_user_id: $external_user_id
      jwt: $jwt
      discount_id: $discount_id
    ) {
      id
    }
  }
`;

export const FASTRACK_USER_SIGNUP_MUTATION = gql`
  mutation fastrackUserSignup(
    $user: FastrackUserInput!
    $sku: SkuInput!
    $payment_method_nonce: String!
    $grecaptcha_response: String!
    $discount_id: ID
  ) {
    fastrackUserSignup(
      user: $user
      sku: $sku
      payment_method_nonce: $payment_method_nonce
      grecaptcha_response: $grecaptcha_response
      discount_id: $discount_id
    ) {
      jwt
      isNewUser: new_user
    }
  }
`;

export const ISSUE_GUEST_PASS_MUTATION = gql`
  mutation issueGuestPass($jwt: String!) {
    issueGuestPass(jwt: $jwt) {
      id
      skuable {
        id
        type
      }
      source
      status
      user {
        jwt
        id
      }
    }
  }
`;
