import React from "react";
import useCurrentUser from "../../../hooks/useCurrentUser";
import Loading from "../../common/Loading";
import AgentBillingRedirect from "./AgentBillingRedirect";

export default function Billing({ children }) {
  const { loading, data } = useCurrentUser();

  if (loading) return <Loading />;
  if (data.lwaId) return <AgentBillingRedirect />;

  return <div>{children}</div>;
}
