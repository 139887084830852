import React, { useState } from "react";
import styled from "styled-components";
import PlanOverview from "./PlanOverview";
import BillingFields from "./BillingFields";
import TrialDetails from "./TrialDetails";
import TermsOfService from "./TermsOfService";
import SignupButton from "./SignupButton";
import PromoCode from "./PromoCode";
import { useIsFreePlan } from "./SubscriptionWorkflow";

export default function CompleteSignup({ isReady }) {
  const [userHasMovedFocus, setUserHasMovedFocus] = useState(false);
  const isFreePlan = useIsFreePlan();
  return (
    <SectionLayout onFocus={() => setUserHasMovedFocus(true)}>
      <PlanAndPayment>
        <PlanOverview />
        {!isFreePlan && (
          <BillingFields shouldSetFocus={!userHasMovedFocus && isReady} />
        )}
      </PlanAndPayment>
      {isFreePlan && (
        <PromoCodeLayout>
          <PromoCode />
        </PromoCodeLayout>
      )}
      <LegalStuff>
        <TrialDetails />
        <TermsOfService />
      </LegalStuff>
      <SignupButton />
    </SectionLayout>
  );
}

// Styled Components
const SectionLayout = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlanAndPayment = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (min-width: 768px) {
    > * + * {
      margin-left: 56px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PromoCodeLayout = styled.div`
  margin-top: 24px;
`;

const LegalStuff = styled.section`
  margin: 35px auto 0;
  width: 100%;
  max-width: 504px;
`;
