import Polyglot from "node-polyglot";

const polyglot = new Polyglot({
  phrases: window.translations,
  allowMissing: true,
  onMissingKey: () => {}
});

const locale = document.querySelector("html").getAttribute("lang") || "en";
polyglot.locale(locale);

export function translate(phrase, fallback = "") {
  return polyglot.t(phrase) || fallback;
}

export default polyglot;
