import styled from "styled-components";

export const GuestpassProductsLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 372px;
  border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
  padding: 64px;
`;

export const LogoLayout = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto 30px;
  transition: 200ms all;

  @media (max-width: 500px) {
    margin: ${({ isSettingUp }) =>
      isSettingUp ? `0 auto 30px` : `0 auto 20px;`};
  }
`;

export const IconLayout = styled.div`
  min-width: 72px;
  margin: 0 auto 20px;
  transition: 200ms all;
  will-change: transform;

  svg {
    fill: white;
    will-change: transform;
  }

  @media (max-width: 500px) {
    min-width: ${({ isSettingUp }) => (isSettingUp ? "72px" : "40px")};
    margin: ${({ isSettingUp }) =>
      isSettingUp ? `0 auto 20px` : `0 auto 12px`};
  }
`;

export const GuestpassProductAddon = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const AddonName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.suite[900]};
  text-align: left;
  display: relative;
  align-items: left;
  padding-left: 15px;
  color: ${({ theme, productKey }) =>
    theme.colors.utils.getByProductKey(productKey)};
`;

export const AddonTagline = styled.p`
  margin-top: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: relative;
  align-items: left;
  color: #4b5563;
`;

export const PlusIcon = styled.img`
  min-width: 43px;
  max-width: 43px;
  height: 43px;
  padding: 14px;
  svg {
    fill: white;
    will-change: transform;
  }

  @media (max-width: 1023px) {
    border-radius: 13px;
    margin-bottom: 0px;
  }
`;

export const HomebeatIcon = styled.img`
  min-width: 43px;
  max-width: 43px;
  height: 43px;
  padding: 7px;

  svg {
    fill: white;
    will-change: transform;
  }

  @media (max-width: 1023px) {
    border-radius: 13px;
    margin-bottom: 0px;
  }
`;

export const FlashError = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.accent.red};
  border-radius: 3px;
  padding: 8px 16px;
  box-sizing: border-box;
  color: white;
  text-align: center;
  margin-top: 21px;
`;

export const ButtonLayout = styled.div`
  display: flex;
  margin-top: 28px;
`;

export const Alert = styled.div`
  margin-top: 8px;
  color: red;
`;
