import React, { useState } from "react";
import yup from "yup";
import gql from "graphql-tag";
import { isEmpty, has } from "lodash";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import PageHeading from "../PageHeading";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextField from "../../common/TextField";
import {
  Container,
  FieldsetLayout,
  Fieldset,
  SectionHeading,
  PageSubheading,
  Card,
  SaveButton
} from "./styled/thanks";
import { translate } from "../../../utils/locale";

function Thanks() {
  const [isSaving, setIsSaving] = useState(false);
  const [{ mlsCredential, user, mls }, setUser] = useState({
    mlsCredential: {},
    user: {},
    mls: {}
  });
  const needsMlsCredential =
    has(mls, "id") && !(mlsCredential && mlsCredential.name);

  useQuery(GET_USER_PROFILE_QUERY, {
    context: { useAuthedEndpoint: true },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const { mls, mlsCredential, ...user } = data.user;
      setUser({ mls, mlsCredential, user });
    }
  });

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    context: { useAuthedEndpoint: true },
    onCompleted: () => navigate("/app")
  });

  function handleSubmit(formikValues) {
    setIsSaving(true);
    updateUser({ variables: buildUpdateUserMutationVariables(formikValues) });
  }

  if (isEmpty(user)) return null;
  return (
    <Container>
      <PageHeading>Thank You!</PageHeading>
      <PageSubheading>
        Please take a moment to complete your profile:
      </PageSubheading>
      <Card>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}
          initialValues={{
            name: user.name || "",
            email: user.email || "",
            website: user.website || "",
            company_name: user.company_name || "",
            phone_number: user.phone_number || "",
            mls_id: mls ? mls.id : "",
            mls_credential_attributes: {
              name: "",
              code: mls ? mls.code : ""
            }
          }}>
          {({ isValid }) => (
            <Form>
              <FieldsetLayout>
                <Fieldset>
                  <SectionHeading>Your Info</SectionHeading>
                  <TextField>
                    <label htmlFor="name">Name*</label>
                    <Field name="name" placeholder="Your Full Name" id="name" />
                    <ErrorMessage component="span" name="name" />
                  </TextField>
                  <TextField>
                    <label htmlFor="email">Email*</label>
                    <Field
                      name="email"
                      placeholder="you@example.com"
                      id="email"
                    />
                    <ErrorMessage component="span" name="email" />
                  </TextField>
                  <TextField>
                    <label htmlFor="company-name">Company Name</label>
                    <Field
                      id="company-name"
                      name="company_name"
                      placeholder="Your Company Name"
                    />
                  </TextField>
                  <TextField>
                    <label htmlFor="phone-number">Phone Number</label>
                    <Field
                      id="phone-number"
                      name="phone_number"
                      placeholder="Phone Number"
                    />
                  </TextField>
                  <TextField>
                    <label htmlFor="website">Website</label>
                    <Field
                      name="website"
                      placeholder="www.yourwebsite.com"
                      id="website"
                    />
                  </TextField>
                </Fieldset>

                {needsMlsCredential && (
                  <Fieldset>
                    <SectionHeading
                      dangerouslySetInnerHTML={{
                        __html: `${translate("mls.label", "MLS").replace(
                          "®",
                          "<sup>®</sup>"
                        )} Credentials`
                      }}
                    />
                    <TextField>
                      <label
                        htmlFor="mls-name"
                        dangerouslySetInnerHTML={{
                          __html: `${translate("mls.label", "MLS").replace(
                            "®",
                            "<sup>®</sup>"
                          )} Provider`
                        }}
                      />
                      <Field
                        readOnly
                        id="mls-name"
                        value={mls.name}
                        data-testid="mls-provider"
                        style={{ cursor: "not-allowed" }}
                      />
                    </TextField>
                    <Field
                      type="hidden"
                      name="mls_credential_attributes.code"
                    />
                    <TextField>
                      <label
                        htmlFor="mls-credential-name"
                        dangerouslySetInnerHTML={{
                          __html: `${translate("mls.label", "MLS").replace(
                            "®",
                            "<sup>®</sup>"
                          )} User ID`
                        }}
                      />
                      <Field
                        id="mls-credential-name"
                        name="mls_credential_attributes.name"
                        placeholder={`Your ${translate(
                          "mls.label",
                          "MLS"
                        )} Username`}
                      />
                    </TextField>
                  </Fieldset>
                )}
              </FieldsetLayout>
              <SaveButton
                id="thanks-form-submit"
                type="submit"
                disabled={!isValid || isSaving}
                loading={isSaving}>
                Save Profile
              </SaveButton>
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  );
}

const validationSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string().email("Invalid Email").required("Required")
});

export const GET_USER_PROFILE_QUERY = gql`
  query getUserProfile {
    user {
      id
      name
      email
      company_name
      phone_number
      website
      mls {
        id
        code
        name
      }
      mlsCredential: mls_credential {
        code
        name
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($user: UserRegistrationInput!) {
    updateUser(user: $user) {
      name
      email
      company_name
      phone_number
      website
      mls_credential {
        name
        code
      }
    }
  }
`;

export function buildUpdateUserMutationVariables(formikValues) {
  const { mls_credential_attributes, ...user } = formikValues;
  const variables = {
    user: {
      ...user,
      ...(!!mls_credential_attributes &&
        !!mls_credential_attributes.name && {
          mls_credential_attributes
        })
    }
  };
  return variables;
}

export default Thanks;
