import { decode } from "jsonwebtoken";
import React from "react";
import underline from "../../../assets/images/underline.svg";
import { useQueryString } from "../../utils/url";
import {
  GuestpassHeader,
  GuestpassHeadline,
  GuestpassHeadlineBold,
  GuestpassUnderline,
  HeadingLayout
} from "./styled/guestpass-heading";

function GuestpassHeading() {
  const { jwt } = useQueryString();
  const guestPassObject = decode(jwt);

  return (
    <HeadingLayout>
      <GuestpassHeader>Redeem your Guest Pass</GuestpassHeader>
      <GuestpassHeadline>
        Your pass is valid for{" "}
        <GuestpassHeadlineBold>
          {(guestPassObject && guestPassObject.duration) || "0"} days of
          unlimited access
        </GuestpassHeadlineBold>{" "}
        to the Cloud Agent Suite.
      </GuestpassHeadline>
      <GuestpassUnderline src={underline} alt="Underline" />
    </HeadingLayout>
  );
}

export default GuestpassHeading;
