import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Explanation = styled.div`
  ${({ theme }) => theme.text.body}
  max-width: 550px;
  text-align: center;
  margin: 0 auto 32px auto;
`;

export const Back = styled.button`
  font-family: Circular;
  font-size: 16px;
  line-height: 24px;
  margin-top: 32px;
  color: #2d335d;
  border: 0;
  background-color: transparent;
  appearance: none;

  svg {
    width: 11px;
    height: 11px;
    margin-right: 11px;
  }
`;

export const LaunchpadProductContainer = styled.div`
  width: 100%;
  max-width: 240px;

  > * {
    background-color: white;
  }
`;
