import {
  ApplicationSwitcher,
  Navbar,
  NavbarAdditionalItems,
  NavbarHelpLink,
  NavbarNavigationBar,
  NavbarUserMenu
} from "@helix/navbar";
import { Link } from "@reach/router";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useCurrentUser from "../hooks/useCurrentUser";

const PRODUCT_KEY = "cloud_agent_suite";

function getCasDomain() {
  switch (window.location.host) {
    case "staging.cloudagentsuite.com":
      return "https://staging.cloudagentsuite.com";
    case "cloudagentsuite.com":
      return "https://cloudagentsuite.com";
    default:
      return "http://localhost:3400";
  }
}

export default function LegacyNavigation() {
  const { data: currentUser } = useCurrentUser();
  const { data: appSwitcher = {} } = useAppSwitcher();

  return (
    <Container id="navigation">
      <Navbar
        dataLwtId="default"
        applicationName={
          !appSwitcher.enabled ? "Cloud Agent Suite" : undefined
        }>
        {appSwitcher.enabled && (
          <ApplicationSwitcher
            applicationName="Cloud Agent Suite"
            appSections={[
              {
                sectionName: "Promote",
                sectionItems: appSwitcher.products.map((product) => ({
                  selected: product.key === PRODUCT_KEY,
                  icon:
                    product.key === "cloud_cma"
                      ? "compare"
                      : product.key === "cloud_streams"
                      ? "alert"
                      : product.key === "cloud_mlx"
                      ? "search"
                      : product.key === "cloud_attract"
                      ? "agent"
                      : product.key === "launchpad"
                      ? "cards"
                      : undefined,
                  label: product.name || "N/A",
                  description: product.tagline || "",
                  onClick:
                    product.key !== PRODUCT_KEY
                      ? () => product.url && window.open(product.url, "_self")
                      : undefined
                }))
              }
            ]}
          />
        )}
        <NavbarNavigationBar
          navItems={[
            { label: "Launchpad", url: "/app/launchpad" },
            { label: "My Account", url: "/app/billing" }
          ]}
          linkWrapper={({ item }) => <Link to={item.url}>{item.label}</Link>}
        />
        <NavbarAdditionalItems>
          <NavbarHelpLink
            url="https://learn.cloudagentsuite.com/"
            linkWrapper={({ url, label }) => (
              <a href={url} target="_blank" rel="noreferrer">
                {label}
              </a>
            )}
          />
          <NavbarUserMenu
            id="avatar"
            user={{
              name: currentUser.name,
              email: currentUser.email,
              avatarURL: currentUser.avatarUrl
            }}
            menuItems={[
              {
                label: "Learning Resources",
                url: "https://learn.cloudagentsuite.com/"
              },
              {
                label: "Get Support",
                url: "https://lonewolf.my.site.com/s/cloud-cma-resources"
              },
              { divider: true },
              { label: "Sign Out", url: "/app/logout" }
            ]}
            linkWrapper={({ item }) => {
              const isExternal = ["Learning Resources", "Get Support"].includes(
                item.label
              );

              if (isExternal) {
                return (
                  <a href={item.url} target="_blank" rel="noreferrer">
                    {item.label}
                  </a>
                );
              }

              return <Link to={item.url}>{item.label}</Link>;
            }}
          />
        </NavbarAdditionalItems>
      </Navbar>
    </Container>
  );
}

export const Container = styled.div`
  margin-bottom: 3rem;
  position: sticky;
  top: 0;
  z-index: 1000;

  .label {
    font-size: 1.4rem !important;
  }

  .description {
    font-size: 1.2rem !important;
  }
`;

function useAppSwitcher() {
  const { data: currentUser } = useCurrentUser();
  const [data, setData] = useState();

  useEffect(() => {
    fetch(`${getCasDomain()}/graphql/protected`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.jwt}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        operationName: "ProductAvailability",
        query: /* GraphQL */ `
          mutation ProductAvailability($user: ExternalUserInputType!) {
            userProductAvailability(external_user: $user) {
              enabled: enable_app_switcher
              products {
                key
                name
                tagline
                url: switch_route
              }
            }
          }
        `,
        variables: {
          user: {
            application_key: PRODUCT_KEY,
            id: currentUser.id,
            email: currentUser.email,
            cas_user_id: currentUser.id,
            mls_code: currentUser.mls.code
          }
        }
      })
    })
      .then((res) => res.json())
      .then((res) => {
        const productsWithLaunchpad = res.data.userProductAvailability.products;
        const enableLaunchpad = !!currentUser.casUserId;

        if (enableLaunchpad) {
          productsWithLaunchpad.push({
            key: "launchpad",
            name: "Launchpad",
            tagline: "Learn and manage billing",
            url: `${getCasDomain()}/launchpad`
          });
        }

        setData({
          enabled: res.data.userProductAvailability.enabled,
          products: productsWithLaunchpad.map((product) => ({
            ...product,
            url: `${product.url}?jwt=${currentUser.jwt}`
          }))
        });
      })
      .catch(() => {
        setData({ enabled: false, products: [] });
      });
  }, [currentUser]);

  return { data };
}
