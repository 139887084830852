import React from "react";

const CloudCma = (props) => (
  <svg viewBox="0 0 53.87 53.87" {...props}>
    <circle
      cx={26.94}
      cy={26.94}
      r={26.94}
      transform="rotate(-76.72 26.932 26.936)"
      fill="#20b575"
    />
    <path d="M26.23 34v-1.3a5.5 5.5 0 0 1-1.57-.47 3.91 3.91 0 0 1-1.1-.87 3.13 3.13 0 0 1-.7-1.1 5.51 5.51 0 0 1-.32-1.1l2.12-.55a2.78 2.78 0 0 0 .16.79A2.14 2.14 0 0 0 26 30.58a2.41 2.41 0 0 0 1 .15 2 2 0 0 0 1.34-.39 1.12 1.12 0 0 0 .47-.94 1 1 0 0 0-.32-.79 2.76 2.76 0 0 0-.94-.47L26 27.83a3.81 3.81 0 0 1-2.2-1.18 3 3 0 0 1-.8-2.12 3.25 3.25 0 0 1 .24-1.26 3 3 0 0 1 .71-1 4.92 4.92 0 0 1 1-.78 3.79 3.79 0 0 1 1.25-.4v-1.4H28v1.46a3.61 3.61 0 0 1 1.26.47 3.87 3.87 0 0 1 .94.71 3.46 3.46 0 0 1 .63.87 3.3 3.3 0 0 1 .31.86l-2.14.63a1.18 1.18 0 0 0-.15-.47 2.19 2.19 0 0 0-.4-.55 1.66 1.66 0 0 0-.62-.4 1.78 1.78 0 0 0-.87-.15 1.77 1.77 0 0 0-1.26.39 1.13 1.13 0 0 0-.47.94.85.85 0 0 0 .32.71 1.92 1.92 0 0 0 .86.47L28 26a4.62 4.62 0 0 1 2.36 1.26 3.3 3.3 0 0 1 .78 2 3.1 3.1 0 0 1-.23 1.18 4.37 4.37 0 0 1-.63 1 5.34 5.34 0 0 1-1 .79 3.84 3.84 0 0 1-1.41.47V34z" />
    <path d="M37.72 42.2H16.15a2 2 0 0 1-1.95-2V13.62a2 2 0 0 1 1.95-1.95h21.57a2 2 0 0 1 2 1.95v26.63a2 2 0 0 1-2 1.95zM16.15 13.43a.19.19 0 0 0-.2.19v26.63a.19.19 0 0 0 .2.19h21.57a.19.19 0 0 0 .2-.19V13.62a.19.19 0 0 0-.2-.19z" />
  </svg>
);

export default CloudCma;
