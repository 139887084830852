import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import checkmark from "../images/checkmark.svg";

// Common Values //
const stepHeaderHeight = "80px";

// Styled Components //
export const StepWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e1e5e9;
  background-color: ${({ isCurrent, theme }) =>
    isCurrent ? theme.colors.gray[100] : theme.colors.gray[300]};
  min-height: ${stepHeaderHeight};
  padding-top: ${stepHeaderHeight};
  transition: all 200ms linear;

  &:first-of-type {
    border-radius: 6px 6px 0 0;
  }

  &:last-of-type {
    border-bottom: none;
    border-radius: 0 0 6px 6px;
  }
`;

export const StepHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  height: ${stepHeaderHeight};
`;

export const StepIdentifier = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 576px) {
    top: 28px;
    left: 16px;
  }
`;

const diameterDesktop = 32;
const diameterMobile = 24;
export const StepNumber = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${diameterDesktop}px;
  height: ${diameterDesktop}px;
  border-radius: 50%;
  overflow: hidden;
  color: ${({ isCurrent, theme }) =>
    isCurrent ? theme.colors.gray[900] : theme.colors.gray[800]};
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  background-color: ${({ theme }) => theme.colors.gray[500]};
  text-align: center;
  transition: all 200ms linear;

  &::after {
    content: "";
    background-image: url(${checkmark});
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    width: ${diameterDesktop}px;
    height: ${diameterDesktop}px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.accent.green};
    opacity: ${({ isComplete }) => (isComplete ? 1 : 0)};
  }

  @media (max-width: 576px) {
    width: ${diameterMobile}px;
    height: ${diameterMobile}px;
    &::after {
      width: ${diameterMobile}px;
      height: ${diameterMobile}px;
    }
  }
`;

export const StepName = styled.span`
  margin-left: 12px;
  ${({ theme }) => theme.text.displaySmall};
  color: ${({ isCurrent, theme }) =>
    isCurrent ? theme.colors.gray[900] : theme.colors.gray[800]};
  transition: all 200ms linear;
`;

export const StepSelection = styled.div`
  margin: 0 auto;
  font-weight: 500;
  font-size: 18px;
  color: #131945;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;

  @media (max-width: 767px) {
    display: none;
    position: absolute;
    top: 68px;
    right: 24px;
    left: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
`;

export const EditStep = styled.button`
  position: absolute;
  top: 28px;
  right: 16px;
  display: inline-block;
  outline: none;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 4px 8px;
  background: none;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.suite[500]};
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
  text-decoration: underline;
  font-family: ${({ theme }) => theme.fonts.marketing};
  transition: all 200ms linear;
  border-radius: 4px;
  &:focus {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.suite[500]},
      0 0 0 2px ${({ theme }) => theme.colors.suite[300]};
    text-decoration: none;
  }
`;

const StepContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ isCurrent }) => (isCurrent ? "0" : "-100%")};
  padding: 0 10px;
  box-sizing: border-box;
`;

export const StepContent = ({ isCurrent, children }) => {
  return (
    isCurrent && (
      <StepContentContainer isCurrent={isCurrent}>
        {children}
      </StepContentContainer>
    )
  );
};
StepContent.propTypes = {
  isCurrent: PropTypes.bool.isRequired
};
