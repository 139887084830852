import React from "react";
import { useWindowWidth } from "../../providers/window-width-provider";
import ProductLogo from "../Launchpad/ProductLogo";
import {
  Card,
  CopyLayout,
  ProductDescription,
  ProductLogoType
} from "./styled/guestpass-card";

const nameByProductKey = {
  cloud_cma: "Cloud CMA",
  cloud_streams: "Cloud Streams",
  cloud_mlx: "Cloud MLX",
  cloud_attract: "Cloud Attract",
  homebeat: "Homebeat"
};

const productDescriptionByProductKey = {
  cloud_cma: "Presentations That Sell.",
  cloud_streams: "MLS Text Alerts",
  cloud_mlx: "Easy MLS Search",
  cloud_attract: "Generate Your Own Leads.",
  homebeat: "Automatic CMAs"
};

function GuestpassCard({ productKey, isActive, isUnavailable }) {
  const isDesktop = useWindowWidth() >= 1024;
  const description = `${isUnavailable ? "* " : ""}${
    productDescriptionByProductKey[productKey]
  }`;

  return (
    <Card isActive={isActive} data-testid={`guestpass-card-${productKey}`}>
      <ProductLogo productKey={productKey} />
      <CopyLayout>
        <ProductLogoType productKey={productKey}>
          {nameByProductKey[productKey]}
        </ProductLogoType>
        <ProductDescription
          id={description}
          text={description}
          lines={isDesktop ? 4 : 2}
          ellipses="..."
          buttons={false}
        />
      </CopyLayout>
    </Card>
  );
}

export default GuestpassCard;
