import React, { useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { isEmpty } from "lodash";
import { navigate } from "@reach/router";
import { useQuery } from "@apollo/react-hooks";
import { PRODUCT_KEYS } from "../../../modules/products";
import PageHeading from "../PageHeading";
import LaunchpadProvider from "../LaunchpadProvider";
import LaunchpadProduct from "../../Launchpad/LaunchpadProduct";
import IconArrow from "../../icons/IconArrow";
import {
  Container,
  Explanation,
  Back,
  LaunchpadProductContainer
} from "./styled/product-activation";
import { translate } from "../../../utils/locale";

function ProductActivation({ productKey }) {
  const { loading, data } = useQuery(PRODUCT_ACTIVATION_QUERY, {
    context: { useAuthedEndpoint: true }
  });

  useEffect(() => {
    if (
      Object.keys(PRODUCT_KEYS)
        .map((key) => PRODUCT_KEYS[key])
        .includes(productKey) === false
    ) {
      navigate("/app/launchpad");
    }
  }, []);

  if (loading) return null;

  const product =
    data.user.subscription.products.find((p) => p.key === productKey) || {};
  const isSiteLicensed = product.isSiteLicensed;
  const isntSubscribed = isEmpty(product);
  const productIsAvailableInUsersMls = data.user.mls.products
    .map((p) => p.key === productKey)
    .includes(productKey);
  const canTryForFree = isntSubscribed && productIsAvailableInUsersMls;
  const isUnavailable = isntSubscribed && !canTryForFree;
  const isProvisioned =
    !!product.provisionedAccount &&
    product.provisionedAccount.provisionedAt !== null;
  const productName = product.name || "";

  return (
    <LaunchpadProvider>
      <Container>
        <PageHeading>Activate</PageHeading>
        {isProvisioned && (
          <Explanation>You have activated {productName}.</Explanation>
        )}
        {!isSiteLicensed && !isProvisioned && (
          <>
            {!canTryForFree && (
              <Explanation>
                You have {productName} as part of your subscription, all you
                need to do is activate your account. Don’t worry, it just takes
                a minute.
              </Explanation>
            )}
            {canTryForFree && (
              <Explanation>
                To use {productName}, you need to upgrade your account. Try it
                risk-free, it just takes a couple minutes to set up.
              </Explanation>
            )}
          </>
        )}
        {isSiteLicensed && !isProvisioned && (
          <Explanation>
            You have {productName} as part of your{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: translate("mls.label", "MLS").replace(
                  "®",
                  "<sup>®</sup>"
                )
              }}
            />{" "}
            membership, all you need to do is activate your free account. Don’t
            worry, it just takes a minute.
          </Explanation>
        )}
        {isUnavailable && !isProvisioned && (
          <Explanation>
            We're sorry, {productName} is unavailable at this time.
          </Explanation>
        )}
        <LaunchpadProductContainer>
          <LaunchpadProduct productKey={productKey} usePrimaryButton={true} />
        </LaunchpadProductContainer>
        <Back onClick={() => window.history.back()}>
          <IconArrow aria-hidden="true" />
          Take me Back
        </Back>
      </Container>
    </LaunchpadProvider>
  );
}

ProductActivation.propTypes = {
  productKey: PropTypes.string
};

export const PRODUCT_ACTIVATION_QUERY = gql`
  query productActivationQuery {
    user {
      id
      mls {
        id
        code
        products {
          key
        }
      }
      subscription {
        id
        products {
          id
          key
          name
          isSiteLicensed: site_license
          provisionedAccount: provisioned_account {
            provisionedAt: provisioned_at
          }
        }
      }
    }
  }
`;

export default ProductActivation;
