import React from "react";

const IconShortcutAttractSellers = (props) => (
  <svg width={25} height={24} fill="none" {...props}>
    <rect x={0.232} width={24} height={24} rx={5} fill="#FFCCCD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.84 5.146a.6.6 0 0 1 .785 0l5.6 4.846a.6.6 0 0 1 .207.454V17.6a.6.6 0 0 1-.6.6h-11.2a.6.6 0 0 1-.6-.6v-7.154a.6.6 0 0 1 .208-.454l5.6-4.846zM7.232 10.72V17h10v-6.28l-5-4.327-5 4.327z"
      fill="#FD5154"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.232 15.2l-.348-.344c-1.236-1.223-2.052-2.03-2.052-3.017 0-.807.58-1.439 1.32-1.439.418 0 .819.212 1.08.545a1.395 1.395 0 0 1 1.08-.545c.74 0 1.32.632 1.32 1.439 0 .987-.816 1.794-2.052 3.017l-.348.344z"
      fill="#FD5154"
    />
  </svg>
);

export default IconShortcutAttractSellers;
