import React from "react";
import { Redirect } from "@reach/router";

export default class PlanRedirect extends React.Component {
  render() {
    return (
      <Redirect noThrow to={`/app/billing/plan${window.location.search}`} />
    );
  }
}
