export function transformAddOns(addOns) {
  return addOns.reduce((map, ao) => {
    map[ao.key] = transformAddOn(ao);
    return map;
  }, {});
}

export function transformAddOn(addOn) {
  return {
    id: addOn.id || "",
    key: addOn.key || "",
    name: addOn.name || "",
    price: addOn.price || 0,
    tagline: addOn.tagline || "",
    billingCycle: addOn.billingCycle || 12,
    discountAmount: (addOn.discounts[0] || {}).amount || 0,
    discountId: (addOn.discounts[0] || {}).id || null
  };
}

export function transformAddOnSubscriptions(addOnSubscriptions) {
  return addOnSubscriptions.reduce((byId, addonSubscription) => {
    return {
      ...byId,
      [addonSubscription.id]: transformAddOnSubscription(addonSubscription)
    };
  }, {});
}

function transformAddOnSubscription(addOnSubscription) {
  const discount = addOnSubscription.discount || {};
  const sku = addOnSubscription.sku || {};

  return {
    id: addOnSubscription.id || "",
    key: sku.key || "",
    name: sku.name || "",
    price: addOnSubscription.price || 0,
    originalPrice: sku.price || 0,
    color: ADD_ON_COLORS[sku.key],
    discountAmount: discount.amount || 0,
    discountId: discount.id || null,
    nextBillingDate: addOnSubscription.next_bill_date || "",
    nextBillingPeriodAmount: addOnSubscription.next_billing_period_amount || 0
  };
}
