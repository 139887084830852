import React from "react";

const IconShortcutStream = (props) => (
  <svg width={25} height={24} fill="none" {...props}>
    <rect x={0.744} width={24} height={24} rx={5} fill="#B4E9FD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.143 11a4.6 4.6 0 1 1 9.2 0v1.03c0 .775.23 1.533.66 2.178l.84 1.26a.6.6 0 0 1-.5.932h-3.409a2.2 2.2 0 0 1-4.382 0H7.143a.6.6 0 0 1-.499-.933l.84-1.26c.43-.645.66-1.402.66-2.178V11zm3.62 5.4a1 1 0 0 0 1.96 0h-1.96zm.98-8.8a3.4 3.4 0 0 0-3.4 3.4v1.03c0 1.012-.3 2.001-.86 2.843l-.218.327h8.957l-.218-.327a5.126 5.126 0 0 1-.86-2.843V11a3.4 3.4 0 0 0-3.4-3.4z"
      fill="#0AA1DC"
    />
  </svg>
);

export default IconShortcutStream;
