import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  gap: 18px;
  padding-top: 92px;
  @media (max-width: 767px) {
    padding-top: 44px;
  }
`;
export const HomeLink = styled.a`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  max-height: 32px;
  width: 100%;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.h1`
  margin: 0;
  color: #111827;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
`;

export const Description = styled.p`
  margin: 0;
  text-align: center;
  color: #374151;
  font-size: 20px;
  font-weight: 600;
`;

export const Note = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #374151;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;
