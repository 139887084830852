import React, { useMemo, useEffect } from "react";
import { useFormikContext } from "formik";
import { useWindowSize } from "@reach/window-size";
import {
  StepWrapper,
  StepHeader,
  StepIdentifier,
  StepNumber,
  StepName,
  EditStep
} from "../styled/StepWrapper";
import StepSelection from "./StepSelection";
import Accordion from "./Accordion";
import { useSubscriptionWorkflow, useFinalPrice } from "./SubscriptionWorkflow";
import BillingCycleControls from "./BillingCycleControls";
import AvailablePlans from "./AvailablePlans";
import { ContentLayout } from "./styled/plan-step";

function PlanStep() {
  const { width } = useWindowSize();
  const { state, send } = useSubscriptionWorkflow();
  const { selectedPlan, billingCycle } = state.context;
  const isCurrentStep = state.matches("plan");
  const stepTwoComplete = state.matches("billing");
  const planSelection = useFormattedPlanSelection(selectedPlan);
  const formikContext = useFormikContext();
  const finalPrice = useFinalPrice();

  useEffect(() => {
    formikContext.setFieldValue("price", finalPrice);
  }, [finalPrice]);

  return (
    <StepWrapper
      isCurrent={isCurrentStep}
      hasSelection={stepTwoComplete || width < 768}>
      <StepHeader>
        <StepIdentifier>
          <StepNumber isCurrent={isCurrentStep} isComplete={stepTwoComplete}>
            2
          </StepNumber>
          <StepName isCurrent={isCurrentStep}>Plan</StepName>
        </StepIdentifier>
        {isCurrentStep && (
          <BillingCycleControls
            value={billingCycle}
            onChange={(value) => send("BILLING_CYCLE.SET", { value })}
          />
        )}
        {stepTwoComplete && (
          <>
            <StepSelection
              isCurrent={isCurrentStep}
              dataTestId="plan-step-selection">
              {planSelection}
            </StepSelection>
            <EditStep onClick={() => send("EDIT_PLAN")}>Edit</EditStep>
          </>
        )}
      </StepHeader>
      <Accordion isOpen={isCurrentStep}>
        <ContentLayout>{isCurrentStep && <AvailablePlans />}</ContentLayout>
      </Accordion>
    </StepWrapper>
  );
}

function useFormattedPlanSelection(selectedPlan) {
  const planSelection = useMemo(() => {
    switch (selectedPlan.key) {
      case "suite":
        return "The Suite";
      case "site_license":
        return selectedPlan.products
          .map((p) => p.name)
          .reduce(
            (str, product, i, arr) =>
              str.concat(`${product}${i === arr.length - 1 ? "" : " + "}`),
            ""
          );
      default:
        return selectedPlan.name;
    }
  }, [selectedPlan.key]);

  return planSelection;
}

export default PlanStep;
