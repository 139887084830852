import React from "react";
import ProductLogo from "./ProductLogo";
import IconEllipses from "./icons/IconEllipses";
import { Dialog } from "@reach/dialog";
import Button from "../common/Button";
import { useWindowWidth } from "../../providers/window-width-provider";
import {
  Card,
  ModalOverlay,
  SetupModal,
  CopyLayout,
  ButtonLayout,
  BusyIconLayout,
  InvisibleLabel,
  ModalCopyLayout,
  ProductLogoType,
  ProductDescription
} from "./styled/launch-card";
import { translate } from "../../utils/locale";

const nameByProductKey = {
  cloud_cma: "Cloud CMA",
  cloud_streams: "Cloud Streams",
  cloud_mlx: "Cloud MLX",
  cloud_attract: "Cloud Attract"
};

const productDescriptionByProductKey = {
  cloud_cma:
    "Beautiful reports and presentations that win you new and recurring sellers.",
  cloud_streams:
    "Fast listing alerts by text or email that help your buyers find their next home.",
  cloud_mlx: `The easy way to search the ${translate(
    "mls.label",
    "MLS"
  )} and share listings with your clients.`,
  cloud_attract:
    "Find new opportunities with landing pages that attract new clients."
};

function LaunchCard({
  children,
  productKey,
  isActive,
  isUnavailable,
  isModal
}) {
  const isDesktop = useWindowWidth() >= 1024;
  let description = `${isUnavailable ? "* " : ""}${
    productDescriptionByProductKey[productKey]
  }`;

  return (
    <Card isActive={isActive} data-testid={`launch-card-${productKey}`}>
      <ProductLogo productKey={productKey} />
      <CopyLayout>
        <ProductLogoType productKey={productKey}>
          {nameByProductKey[productKey]}
        </ProductLogoType>
        <ProductDescription
          id={description}
          text={description}
          lines={isDesktop ? 4 : 2}
          ellipses="..."
          buttons={false}
        />
      </CopyLayout>
      {isModal ? (
        <ButtonLayout>
          <Button disabled fullwidth size="small" variant="secondary">
            <InvisibleLabel>Activating...</InvisibleLabel>
            <BusyIconLayout>
              <IconEllipses />
            </BusyIconLayout>
          </Button>
          <Dialog isOpen>
            <ModalOverlay>
              <SetupModal
                aria-label={`Activate ${nameByProductKey[productKey]}`}>
                <ProductLogo productKey={productKey} />
                <ModalCopyLayout>
                  <ProductLogoType productKey={productKey}>
                    Activate {nameByProductKey[productKey]}
                  </ProductLogoType>
                  <ProductDescription
                    id={description}
                    text={description}
                    lines={4}
                    ellipses="..."
                    buttons={false}
                  />
                </ModalCopyLayout>
                {children}
              </SetupModal>
            </ModalOverlay>
          </Dialog>
        </ButtonLayout>
      ) : (
        <ButtonLayout>{children}</ButtonLayout>
      )}
    </Card>
  );
}

export default LaunchCard;
