import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { createObjectUrl, revokeObjectUrl } from "@wrstudios/utils";
import theme from "../../utils/theme";
import {
  Container,
  Avatar,
  Change,
  Input,
  Remove,
  Cancel,
  Crop
} from "./styled/user-avatar";

class UserAvatar extends Component {
  fileRef = createRef();

  state = {
    avatar: this.props.avatar || "",
    key: v4()
  };

  render() {
    return (
      <Container>
        <Avatar
          initials={this.props.initials}
          initialsColor={this.props.initialsColor}
          avatarUrl={this.state.avatar}
          size="xxl"
          loading={this.props.isFetching}
        />
        <Input
          ref={this.fileRef}
          key={this.state.key}
          type="file"
          tabIndex="-1"
          accept="image/jpg,image/jpeg,image/png"
          onChange={this.handleOnChange}
        />
        <Change
          type="button"
          variant="secondary"
          onClick={this.handleOnChangeClick}>
          Change Photo
        </Change>
        {this.state.avatar && (
          <Remove onClick={this.handleOnRemoveClick}>
            Remove my avatar and show initials instead
          </Remove>
        )}
      </Container>
    );
  }

  componentDidUpdate(_, prevState) {
    if (prevState.avatar !== this.state.avatar) {
      this.props.onChange(this.state.avatar);
    }
  }

  handleOnChangeClick = () => {
    this.fileRef.current.click();
  };

  handleOnRemoveClick = () => {
    this.setState({ avatar: "" }, () => {
      this.props.onRemove();
    });
  };

  handleOnChange = (e) => {
    const { files } = e.target;
    const blob = new Blob(files);
    const image = new File([blob], `avatar-${v4()}.png`, {
      type: "image/png"
    });

    if (this.imageUrl) revokeObjectUrl(this.image);
    this.imageUrl = createObjectUrl(image);

    this.setState({ key: v4(), avatar: this.imageUrl }, () => {
      this.props.onCrop({ image, imageUrl: this.imageUrl });
    });
  };
}

UserAvatar.defaultProps = {
  initialsColor: theme.colors.suite[500],
  onChange: () => {},
  onRemove: () => {},
  onCrop: () => {}
};

UserAvatar.propTypes = {
  initials: PropTypes.string,
  initialsColor: PropTypes.string,
  avatar: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onCrop: PropTypes.func
};

export default UserAvatar;
