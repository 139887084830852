import React from "react";

const IconPlayVideo = (props) => (
  <svg width={16} height={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.627 3.8a1.4 1.4 0 0 1 1.4-1.4h11.2a1.4 1.4 0 0 1 1.4 1.4v8a1.4 1.4 0 0 1-1.4 1.4h-11.2a1.4 1.4 0 0 1-1.4-1.4v-8zm1.4-.2a.2.2 0 0 0-.2.2v8c0 .11.09.2.2.2h11.2a.2.2 0 0 0 .2-.2v-8a.2.2 0 0 0-.2-.2h-11.2z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.427 7.8a.6.6 0 0 1-.3.52l-4.8 2.77a.6.6 0 0 1-.9-.519V5.03a.6.6 0 0 1 .9-.52l4.8 2.771a.6.6 0 0 1 .3.52zm-4.8-1.732v3.464l3-1.732-3-1.732z"
    />
  </svg>
);

export default IconPlayVideo;
