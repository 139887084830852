import React from "react";

const IconChevronRight = (props) => (
  <svg width={18} height={18} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.598 2.898a.675.675 0 0 0 0 .954l4.922 4.923-4.922 4.923a.675.675 0 1 0 .954.954l5.878-5.877-5.878-5.877a.675.675 0 0 0-.954 0z"
      fill="#9BA4B0"
    />
  </svg>
);

export default IconChevronRight;
