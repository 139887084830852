import { useEffect, useRef } from "react";
import jsonwebtoken from "jsonwebtoken";
import { useQueryString } from "./url";

export function isValidToken(token) {
  return (
    Boolean(token) &&
    jsonwebtoken.decode(token).exp > Math.floor(Date.now() / 1000)
  );
}

export function useIsAuthed() {
  const { impersonate, jwt } = useQueryString();
  const token =
    impersonate ||
    jwt ||
    sessionStorage.getItem("impersonate") ||
    localStorage.getItem("token");

  if (impersonate) {
    sessionStorage.setItem("impersonate", impersonate);
  }

  if (jwt) {
    localStorage.setItem("token", jwt);
  }

  return isValidToken(token);
}
