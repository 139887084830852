import gql from "graphql-tag";

export const CHECK_USER_PRODUCT_STATUS_MUTATION = gql`
  mutation checkUserProductStatus($product_id: ID!) {
    checkUserProductStatus(product_id: $product_id) {
      connectPath: connect_path
      userExists: user_exists
    }
  }
`;
export async function checkUserProductStatus(ctx) {
  const response = await ctx.apiClient.mutate({
    context: { useAuthedEndpoint: true },
    mutation: CHECK_USER_PRODUCT_STATUS_MUTATION,
    variables: { product_id: ctx.productId }
  });

  return response.data.checkUserProductStatus;
}

export const PROVISION_PRODUCT_MUTATION = gql`
  mutation provisionProduct($product_id: ID!) {
    provisionProduct(product_id: $product_id) {
      id
      provisioned_at
      remote_user_email
      response
    }
  }
`;
export async function provisionProduct(ctx) {
  const response = await ctx.apiClient.mutate({
    context: { useAuthedEndpoint: true },
    mutation: PROVISION_PRODUCT_MUTATION,
    variables: { product_id: ctx.productId }
  });

  return response.data.provisionProduct;
}

export const CHECK_PROVISIONED_ACCOUNT_STATUS_QUERY = gql`
  query {
    user {
      id
      provisionedAccounts: provisioned_accounts {
        id
        provisionedAt: provisioned_at
        product {
          key
        }
      }
    }
  }
`;
export async function hasSuccessfullyProvisionedProduct(ctx) {
  const {
    data: {
      user: { provisionedAccounts }
    }
  } = await ctx.apiClient.query({
    context: { useAuthedEndpoint: true },
    query: CHECK_PROVISIONED_ACCOUNT_STATUS_QUERY,
    fetchPolicy: "no-cache"
  });

  const hasProvisioned = provisionedAccounts
    .filter((acct) => acct.provisionedAt)
    .find((acct) => acct.product.key === ctx.productKey);

  return Boolean(hasProvisioned);
}
