import React from "react";

const CloudCma = (props) => (
  <svg viewBox="0 0 245.26 245.26" {...props}>
    <circle
      cx={122.63}
      cy={122.63}
      r={122.63}
      transform="rotate(-76.72 122.63 122.63)"
      fill="#20b575"
    />
    <path
      className="cls-2"
      d="M154.59 148.79h-5v-30l-27.27-20.68-26.64 20.07v30.61h-5v-31.86a2.48 2.48 0 0 1 1-2l29.15-22a2.48 2.48 0 0 1 3 0l29.77 22.58a2.51 2.51 0 0 1 1 2z"
    />
    <path
      className="cls-2"
      d="M120.86 146v-2.88a11.08 11.08 0 0 1-3.37-1 8.25 8.25 0 0 1-2.36-1.85 7.15 7.15 0 0 1-1.51-2.36 12.24 12.24 0 0 1-.68-2.35l4.55-1.18a5.91 5.91 0 0 0 .34 1.68 4 4 0 0 0 1 1.52 4 4 0 0 0 1.51 1 5.09 5.09 0 0 0 2.19.33 4.46 4.46 0 0 0 2.87-.84 2.4 2.4 0 0 0 1-2 2.14 2.14 0 0 0-.68-1.68 5.4 5.4 0 0 0-2-1l-3.37-.68a8.11 8.11 0 0 1-4.71-2.52 6.37 6.37 0 0 1-1.68-4.55 6.89 6.89 0 0 1 .5-2.69 6.27 6.27 0 0 1 1.52-2.19 10.47 10.47 0 0 1 2.19-1.69 8.46 8.46 0 0 1 2.69-.84v-3h3.7v3.14a7.4 7.4 0 0 1 2.7 1 8 8 0 0 1 2 1.51 7.72 7.72 0 0 1 1.35 1.86 8.6 8.6 0 0 1 .67 1.85l-4.53 1.41a2.45 2.45 0 0 0-.33-1 4.92 4.92 0 0 0-.85-1.18 3.48 3.48 0 0 0-1.34-.84 3.61 3.61 0 0 0-1.85-.34 3.72 3.72 0 0 0-2.7.84 2.42 2.42 0 0 0-1 2 1.85 1.85 0 0 0 .67 1.52 4.09 4.09 0 0 0 1.86 1l3.36.84a10 10 0 0 1 5.06 2.69 7.11 7.11 0 0 1 1.68 4.38 6.72 6.72 0 0 1-.51 2.53 9.66 9.66 0 0 1-1.34 2.19 11 11 0 0 1-2.19 1.68 8 8 0 0 1-3 1V146z"
    />
    <path
      className="cls-2"
      d="M171.75 190.63H73.51a7.39 7.39 0 0 1-7.38-7.38V62a7.39 7.39 0 0 1 7.38-7.38h98.24a7.39 7.39 0 0 1 7.38 7.38v121.25a7.39 7.39 0 0 1-7.38 7.38zm-98.24-131A2.39 2.39 0 0 0 71.13 62v121.25a2.39 2.39 0 0 0 2.38 2.38h98.24a2.39 2.39 0 0 0 2.38-2.38V62a2.39 2.39 0 0 0-2.38-2.38z"
    />
  </svg>
);

export default CloudCma;
