import { navigate } from "@reach/router";
import { isBefore } from "date-fns";
import React, { useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { translate } from "../utils/locale";
import theme from "../utils/theme";
import Button from "./common/Button";
import Text from "./common/Text";
import PlanPrice from "./PlanPrice";
import PlanProduct from "./PlanProduct";
import {
  Container as PlanPriceContainer,
  Free as PlanPriceFree,
  PaymentInterval as PlanPricePaymentInterval
} from "./styled/plan-price";
import { useSubscriptionWorkflow } from "./SubscriptionWorkflow";

export default function AvailablePlans() {
  const { state, send } = useSubscriptionWorkflow();
  const {
    user,
    mode,
    plans,
    addOns,
    products,
    discounts,
    billingCycle,
    subscription,
    selectedMls: { hasHomebeat, territory, code: selectedMlsKey }
  } = state.context;

  const isDuringFallPromo = isBefore(Date.now(), new Date(2020, 11, 31));

  // Get the plans matching the user's preferred billing cycle (monthly or yearly)
  const {
    cmaMonthly,
    cmaYearly,
    suiteMonthly,
    suiteYearly,
    homebeat,
    memberBenefits
  } = useMemo(
    () => ({
      cmaMonthly: plans.find(
        (plan) => plan.groupKey === "cma" && plan.billingCycle === 1
      ),
      cmaYearly: plans.find(
        (plan) => plan.groupKey === "cma" && plan.billingCycle === 12
      ),
      suiteMonthly: plans.find(
        (plan) => plan.groupKey === "suite" && plan.billingCycle === 1
      ),
      suiteYearly: plans.find(
        (plan) => plan.groupKey === "suite" && plan.billingCycle === 12
      ),
      homebeat: addOns.find((addOn) => addOn.key === "homebeat"),
      memberBenefits: plans.find((plan) => plan.groupKey === "license")
    }),
    [territory, billingCycle, hasHomebeat]
  );

  const cmaAnnualSavingsOverMonthly = useMemo(() => {
    if (cmaMonthly && cmaYearly) {
      const cmaMonthlyPriceAfterDiscount = getPriceAfterDiscount(
        cmaMonthly,
        discounts
      );
      const cmaYearlyPriceAfterDiscount = getPriceAfterDiscount(
        cmaYearly,
        discounts
      );
      return getMonthlySavingsWithAnnualPlan(
        cmaMonthlyPriceAfterDiscount,
        cmaYearlyPriceAfterDiscount
      );
    } else {
      return 0;
    }
  }, [territory]);

  const suiteAnnualSavingsOverMonthly = useMemo(() => {
    if (suiteMonthly && suiteYearly) {
      const suiteMonthlyPriceAfterDiscount = getPriceAfterDiscount(
        suiteMonthly,
        discounts
      );
      const suiteYearlyPriceAfterDiscount = getPriceAfterDiscount(
        suiteYearly,
        discounts
      );
      return getMonthlySavingsWithAnnualPlan(
        suiteMonthlyPriceAfterDiscount,
        suiteYearlyPriceAfterDiscount
      );
    } else {
      return 0;
    }
  }, [territory]);

  const bestSavingsAvailable = useMemo(() => {
    return suiteAnnualSavingsOverMonthly > cmaAnnualSavingsOverMonthly
      ? suiteAnnualSavingsOverMonthly
      : cmaAnnualSavingsOverMonthly;
  }, [suiteAnnualSavingsOverMonthly, cmaAnnualSavingsOverMonthly]);

  useEffect(() => {
    send("SET_BEST_SAVINGS", { bestSavingsAvailable });
  }, [bestSavingsAvailable]);

  // Now we determine which plans are offered to the user based on their MLS
  // These offerings can be understood by reviewing the Plans Offered per Territory Code matrix:
  // https://docs.google.com/spreadsheets/d/1ZEGJqmCpmxpnC4IBB_ohevY9GTzynP9YMCupLhhFaAc/edit?usp=sharing

  const offers = useMemo(() => {
    let firstOffer, secondOffer, thirdOffer;

    const isAlreadySubscribedToHomebeat =
      mode === "edit" &&
      user.addOns.find((addOn) => addOn.sku && addOn.sku === "homebeat");

    // Helper functions which build up the offer objects consumed by the UI in this component
    const buildMemberBenefitsOffer = () => {
      const memberBenefitsTitle = memberBenefits.products
        .map(({ key }) => products.byKey[key].name.split(" ")[1])
        .join(", ");

      return {
        plan: { ...memberBenefits, annualPriceFromMonthlyPrice: 0 },
        key: memberBenefits.groupKey,
        totalCost: 0,
        monthlyPrice: 0,
        monthlyNonAnnualPrice: 0,
        // Show Cloud before product name only if there are 2 or less products, otherwise the text wraps, breaking the design.
        title:
          memberBenefits.products.length <= 2
            ? "Cloud " + memberBenefitsTitle
            : memberBenefitsTitle,
        description: getPlanDescription(memberBenefits, territory),
        featuresHeading: "What's included:",
        features: memberBenefits.products.reduce(toFeatures, []),
        includesHomebeat: false,
        isCurrentPlan:
          mode === "edit" &&
          subscription.sku.key === "site_license" &&
          !user.addOns.find(
            (addOn) => addOn.sku && addOn.sku.key === "homebeat"
          )
      };
    };

    const buildCmaOffer = (precedingOffer) => {
      const plan = billingCycle === 1 ? cmaMonthly : cmaYearly;
      const priceAfterDiscount = getPriceAfterDiscount(plan, discounts);
      const anualPriceFromMonthlyPrice =
        billingCycle === 12
          ? getPriceAfterDiscount(cmaMonthly, discounts) * 12
          : priceAfterDiscount;
      return {
        plan: { ...plan, anualPriceFromMonthlyPrice },
        key: plan.groupKey,
        totalCost: priceAfterDiscount,
        monthlyPrice: getMonthlyPrice(priceAfterDiscount, billingCycle),
        monthlyNonAnnualPrice: getMonthlyPrice(
          getPriceAfterDiscount(cmaMonthly, discounts),
          1
        ),
        title: `${precedingOffer ? "Add " : ""}Cloud CMA${
          hasHomebeat ? " + Homebeat" : ""
        }`,
        description: getPlanDescription(plan),
        featuresHeading: precedingOffer
          ? `${precedingOffer.title}, plus:`
          : "What's included:",
        features: getFeaturesByKey("cloud_cma").concat(
          hasHomebeat ? getFeaturesByKey("homebeat") : []
        ),
        includesHomebeat: hasHomebeat,
        savingsOverMonthly: cmaAnnualSavingsOverMonthly,
        isCurrentPlan:
          mode === "edit" &&
          subscription.sku.groupKey === plan.groupKey &&
          subscription.sku.billingCycle === billingCycle
      };
    };

    const buildHomebeatOffer = (precedingOffer) => {
      const priceAfterDiscount = getPriceAfterDiscount(homebeat, discounts);
      return {
        plan: {
          ...homebeat,
          anualPriceFromMonthlyPrice: +(priceAfterDiscount / 12).toFixed(2),
          products: precedingOffer.plan.products // since Add On plans don't include a products array, we have to add one here
        },
        key: homebeat.key,
        totalCost: priceAfterDiscount,
        monthlyPrice: +(priceAfterDiscount / 12).toFixed(2),
        monthlyNonAnnualPrice: +(priceAfterDiscount / 12).toFixed(2),
        title: "Add Homebeat",
        description: getPlanDescription(homebeat),
        featuresHeading: `${precedingOffer.title}, plus:`,
        features: getFeaturesByKey("homebeat"),
        includesHomebeat: true,
        isCurrentPlan:
          mode === "edit" &&
          subscription.sku.key === "site_license" &&
          user.addOns.find(
            (addOn) => addOn.sku && addOn.sku.key === "homebeat"
          ) &&
          subscription.sku.billingCycle === billingCycle
      };
    };

    const buildSuiteOffer = (precedingOffer) => {
      // TODO: Once we have 100% support for MLS in the TM Integration, we can remove this array and the check against it below
      const mlsKeysWithoutTransactionsIntegrationSupportAtLaunch = [
        // Canadian MLSes
        "creb",
        "bcres",
        "hhimls",
        "ereb",
        "nsaor",

        // American MLSes
        "nwmls",
        "glvar",
        "nnrmls",
        "ivmls",
        "boix_de_soiux",
        "daar",
        "greater_alexandria",
        "lake_county",
        "lake_region",
        "northstar",
        "northwest_minnesota",
        "saaor",
        "semar",
        "chattanooga",
        "gsmar",
        "knoxville",
        "laar",
        "midsouth",
        "mtrmls"
      ];
      const plan = billingCycle === 1 ? suiteMonthly : suiteYearly;
      const priceAfterDiscount = getPriceAfterDiscount(plan, discounts);
      const precedingOfferProductKeys = precedingOffer.plan.products.map(
        ({ key }) => key
      );
      const anualPriceFromMonthlyPrice =
        billingCycle === 12
          ? getPriceAfterDiscount(suiteMonthly, discounts) * 12
          : priceAfterDiscount;

      return {
        plan: { ...plan, anualPriceFromMonthlyPrice },
        key: plan.groupKey,
        totalCost: priceAfterDiscount,
        monthlyPrice: getMonthlyPrice(priceAfterDiscount, billingCycle),
        monthlyNonAnnualPrice: getMonthlyPrice(
          getPriceAfterDiscount(suiteMonthly, discounts),
          1
        ),
        title: "Cloud Agent Suite",
        description: getPlanDescription(plan),
        featuresHeading: `${precedingOffer.title}, plus:`,
        // TODO: Once we have 100% support for TM Integration, we can replace this ternary with the contents of the ternary's false branch array
        features: [
          ...(mlsKeysWithoutTransactionsIntegrationSupportAtLaunch.includes(
            selectedMlsKey
          )
            ? []
            : ["Integrate with Lone Wolf Transactions"]),
          ...plan.products
            .filter((p) => !precedingOfferProductKeys.includes(p.key))
            .reduce(toFeatures, [])
        ],
        // END TODO
        savingsOverMonthly: suiteAnnualSavingsOverMonthly,
        includesHomebeat: hasHomebeat,
        isCurrentPlan:
          mode === "edit" &&
          subscription.sku.groupKey === plan.groupKey &&
          subscription.sku.billingCycle === billingCycle
      };
    };

    // Column A Offer
    firstOffer = memberBenefits ? buildMemberBenefitsOffer() : buildCmaOffer();

    // ! During Fall Promo 2020, we are removing the "Add Homebeat" column B offer, per marketing's request:
    // https://wrstudios.atlassian.net/browse/WSCP-16814

    // Fall Promo 2020 Column B Offer
    // Second offer is the suite unless its a market that already offered the suite in Column A
    if (firstOffer.key.includes("suite")) {
      secondOffer = null;
    } else {
      secondOffer = buildSuiteOffer(firstOffer);
    }

    // Fall Promo 2020 Column C Offer (there isn't one)
    thirdOffer = null;

    // START - Original 2020 Columns B & C

    // Column B Offer
    if (
      firstOffer.key.includes("cma") ||
      isAlreadySubscribedToHomebeat ||
      !hasHomebeat
    ) {
      secondOffer = buildSuiteOffer(firstOffer);

      // First offer included Cloud CMA but did not include Homebeat
    } else if (
      firstOffer.plan.products.map((p) => p.key).includes("cloud_cma") &&
      hasHomebeat
    ) {
      secondOffer = buildHomebeatOffer(firstOffer);

      // First offer did not include CMA (edge-case for some SDDSL markets)
    } else {
      secondOffer = buildCmaOffer(firstOffer);
    }

    // Column C Offer
    // Third offer is always the suite unless its a market that already offered the suite in Column A or B
    if (secondOffer.key.includes("suite")) {
      thirdOffer = null;
    } else {
      thirdOffer = buildSuiteOffer(secondOffer);
    }

    // END - Old Add Homebeat Option

    return [thirdOffer, secondOffer, firstOffer].filter(Boolean);
  }, [territory, billingCycle]);

  const currentSubscriptionGuestPass =
    user &&
    user.guestPasses &&
    user.guestPasses.find(
      (guestpass) =>
        guestpass && guestpass.skuable.id == user.subscription.sku.id
    );

  return (
    <Grid>
      {offers.map((offer, index) => {
        const shouldShowAnnualPricingSpecifics =
          (billingCycle === 12 && offer.key !== "license") ||
          offer.key === "homebeat";
        return (
          <React.Fragment key={index}>
            <Item gridArea={`plan${index}`} data-plan="true" data-child={index}>
              <ItemContent data-plan="true">
                <Text variant="displaySmall" as="h3">
                  {offer.title}
                </Text>
                <OfferDescription>{offer.description}</OfferDescription>
                <OfferProducts>
                  {offer.plan.products.map((product) => (
                    <PlanProduct
                      productKey={product.key}
                      key={product.key}
                      hasHomebeat={offer.includesHomebeat}
                    />
                  ))}
                </OfferProducts>
                <OfferPricing>
                  <PlanPrice
                    planKey={offer.plan.key}
                    billingCycle={1}
                    planPrice={offer.monthlyNonAnnualPrice}
                    finalPrice={offer.monthlyPrice}
                  />
                  {offer.key === "license" && (
                    <MlsBadge>
                      With{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translate("mls.label", "MLS").replace(
                            "®",
                            "<sup>®</sup>"
                          )
                        }}
                      />{" "}
                      Membership
                    </MlsBadge>
                  )}
                  {/* {offer.key === "homebeat" && billingCycle === 1 && <Clarifier>&nbsp;(Paid yearly)</Clarifier>} */}
                  {shouldShowAnnualPricingSpecifics && (
                    <>
                      {offer.savingsOverMonthly > 0 && (
                        <Savings>(Save {offer.savingsOverMonthly}%)</Savings>
                      )}
                      <BilledOnce>
                        Billed as one payment of ${offer.totalCost}
                        {isDuringFallPromo && " on February 1st, 2021"}
                      </BilledOnce>
                    </>
                  )}
                </OfferPricing>
                {currentSubscriptionGuestPass &&
                currentSubscriptionGuestPass.status == "activated" ? (
                  <Button
                    fullwidth
                    size="large"
                    onClick={() =>
                      navigate(
                        `/guestpass/billing/?guest_pass_id=${currentSubscriptionGuestPass.guestPassId}`
                      )
                    }>
                    Add payment Method
                  </Button>
                ) : (
                  <Button
                    fullwidth
                    size="large"
                    variant={offer.key === "suite" ? "primary" : "secondary"}
                    disabled={offer.isCurrentPlan}
                    onClick={() =>
                      send("SELECT_PLAN", { selectedPlan: offer.plan })
                    }>
                    {mode === "edit"
                      ? offer.isCurrentPlan
                        ? "Current Plan"
                        : "Choose Plan"
                      : offer.key === "license"
                      ? "Get Started"
                      : `Try for Free`}
                  </Button>
                )}
              </ItemContent>
            </Item>
            <Item
              gridArea={`feat${index}`}
              data-feature="true"
              data-child={index}>
              <ItemContent data-feature="true">
                <Text variant="smallHeading" as="h4">
                  {offer.featuresHeading}
                </Text>
                <FeaturesList>
                  {offer.features.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </FeaturesList>
              </ItemContent>
            </Item>
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

function getPlanDescription(plan, territory) {
  if (plan.groupKey) {
    return {
      cma: "Winning listing presentations, buyer tours, and flyers",
      suite:
        "Everything you need to impress sellers, serve buyers, and generate leads.",
      streams: "MLS text alerts & collaborative search.",
      license: {
        SSL: "Winning listing presentations, buyer tours, and flyers",
        SSLDD: "Winning listing presentations, buyer tours, and flyers",
        SSLSD: "Winning listing presentations, buyer tours, and flyers",
        DSL: "Winning listing presentations & collaborative buyer search",
        TSL: "Winning listing presentations, buyer collaboration & search.",
        QSL: "Everything you need to impress sellers, serve buyers, and generate leads.",
        SDDSL: "Collaborate with buyers & innovative listing search."
      }[territory]
    }[plan.groupKey];
  } else {
    return {
      homebeat: "Automate staying in touch with your clients."
    }[plan.key];
  }
}

function toFeatures(features = [], product) {
  return features.concat(getFeaturesByKey(product.key));
}

function getFeaturesByKey(key) {
  return {
    cloud_cma: [
      "CMAs",
      "Buyer Tours",
      "Property Tours",
      "Flyers",
      "1 lead-generating landing page"
    ],
    cloud_streams: [
      "Client search and collaboration",
      "Automatic listing alerts",
      "Curated listing alerts"
    ],
    cloud_mlx: ["Fast and innovative MLS search"],
    cloud_attract: [
      "Unlimited seller lead landing pages",
      "Unlimited buyer lead landing pages"
    ],
    homebeat: ["Automated CMAs", "Stay top-of-mind with past clients"]
  }[key];
}

function getMonthlySavingsWithAnnualPlan(monthlyPlanPrice, yearlyPlanPrice) {
  const yearlyPricePerMonth = yearlyPlanPrice / 12;
  const asPercentageOfNormalMonthlyPrice = Math.abs(
    (yearlyPricePerMonth / monthlyPlanPrice - 1) * 100
  ).toFixed(0);
  return Number(asPercentageOfNormalMonthlyPrice);
}

function getPriceAfterDiscount(plan, discounts) {
  const foundDiscount = discounts.byPlanKey[plan.key];
  return !!foundDiscount && !!foundDiscount.amount
    ? plan.price - foundDiscount.amount
    : plan.price;
}

function getMonthlyPrice(priceAfterDiscount, billingCycle) {
  return billingCycle === 1
    ? priceAfterDiscount
    : +(priceAfterDiscount / 12).toFixed(2);
}

// Styled Components - Quite a bit of layout logic in here
export const Grid = styled.div`
  @media (max-width: 1052px) {
    grid-template-rows: auto;
    grid-template-columns: 100%;
    grid-template-areas:
      "plan0"
      "feat0"
      "plan1"
      "feat1"
      "plan2"
      "feat2";
  }

  border-top: 1px solid ${theme.colors.gray[500]};
  width: 100%;
  display: grid;
  grid-gap: 0px;
  grid-template-rows: auto auto;
  grid-template-columns: ${({ children }) => {
    let childrenCount = React.Children.count(children);
    let planCount = childrenCount - 1 === 0 ? 1 : childrenCount; // calcute columns by subtracting footer, but ensure we always have at least 1 column
    return css`repeat(${planCount}, 1fr)`;
  }};
  grid-template-areas:
    /* 
      We can dynamically render template areas so we have one column for each plan offering:

      Grid with single plan offering:
      "plan0"
      "feat0"

      Grid with 2 plan offers:
      "plan0 plan1"
      "feat0 feat1"

      Grid with 3 plan offers:
      "plan0 plan1 plan2"
      "feat0 feat1 feat2"
    */

    /* Plan Row -> "plan0 plan1 plan2" */
    ${({ children }) =>
      `"${React.Children.toArray(children)
        .map((_, index) => `plan${index}`)
        .join(" ")}"`}
    /* Feat Row -> "feat0 feat1 feat2" */
    ${({ children }) =>
      `"${React.Children.toArray(children)
        .map((_, index) => `feat${index}`)
        .join(" ")}"`};
`;

export const Item = styled.div`
  width: 100%;
  align-self: stretch;
  grid-area: ${({ gridArea }) => gridArea};
  &[data-plan]:not([data-child="0"]),
  &[data-feature]:not([data-child="0"]) {
    border-left: 1px solid ${theme.colors.gray[500]};
  }
  &[data-feature] {
    border-top: 1px solid ${theme.colors.gray[500]};
  }

  @media (max-width: 1052px) {
    &[data-feature] {
      border-top: none;
      border-bottom: 1px solid ${theme.colors.gray[500]};
    }

    &[data-footer] {
      display: inline;
      padding: 24px;
      border-top: none;
    }
  }
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  padding: 24px;
  margin: 0 auto;

  &[data-plan] {
    min-height: 375px;
  }

  @media (max-width: 1052px) {
    max-width: 100%;

    &[data-plan] {
      padding-bottom: 0;
    }
  }
`;

const OfferDescription = styled.p`
  margin: 8px 0 26px;
  font-size: 16px;
  line-height: 26px;
  color: ${theme.colors.gray[900]};
`;

const OfferProducts = styled.div`
  display: flex;
  margin-bottom: 21px;
  min-height: 82px;

  @media (min-width: 1052px) {
    > * + * {
      margin-left: 16px;
    }
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    margin: 0 -8px 21px;

    > * {
      padding: 8px;
    }
  }
`;

const OfferPricing = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  margin-bottom: 48px;
  min-height: 38px;

  ${PlanPriceContainer} {
    ${theme.text.displayMedium}
  }

  ${PlanPricePaymentInterval} {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: ${theme.colors.gray[800]};
  }

  ${PlanPriceFree} {
    text-transform: capitalize;
  }
`;

const Clarifier = styled.span`
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: ${theme.colors.gray[800]};
  align-self: flex-end;
`;

const MlsBadge = styled.div`
  margin-left: 16px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.gray[900]};
  border: 1px solid ${theme.colors.gray[600]};
  border-radius: 100px;
`;

const Savings = styled.span`
  display: inline-flex;
  align-items: baseline;
  margin-left: 8px;
  font-size: 16px;
  line-height: 26px;
  color: ${theme.colors.attract[500]};
`;

const BilledOnce = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.gray[800]};
`;

const FeaturesList = styled.ul`
  margin: 6px 0 0;
  padding: 0 0 0 20px;
  color: ${theme.colors.gray[900]};
`;

const AnnualPricingToggle = styled.button`
  ${theme.text.body};
  margin-left: 4px;
  padding: 0;
  border: none;
  outline: none;
  font-family: ${theme.fonts.marketing};
  color: ${theme.colors.suite[500]};
  text-decoration: underline;
  background: transparent;
`;
