import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 22px;
  width: 100%;
  min-height: 100vh;
  padding-top: 92px;
  padding-bottom: 36px;
  @media (max-width: 767px) {
    padding-top: 44px;
    padding-bottom: 18px;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const CASLogoWrapper = styled.a`
  display: flex;
  max-height: 32px;
  width: 100%;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const BillingTitle = styled.h1`
  margin: 0;
  color: #111827;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
`;

export const BillingDescription = styled.h3`
  margin: 0;
  text-align: center;
  color: #374151;
  font-size: 20px;
  font-weight: 600;
`;

export const BillingCycleButtonWrapper = styled.div`
  padding: 4px;
  display: flex;
  justify-content: center;
  gap: 4px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
`;

export const BillingCycleButton = styled.button`
  flex: 1;
  border-radius: 6px;
  padding: 7px 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ isActive }) => (isActive ? "#2939A3" : "#1F2937 ")};
  background-color: ${({ isActive }) => (isActive ? "#E5E7EB" : "transparent")};
  border: none;
`;

export const BillingFormWrapper = styled.div`
  margin-top: 8px;
  background: white;
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  justify-content: center;
  padding: 34px;
  gap: 48px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 34px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  gap: 16px;
`;
export const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
`;

export const PromoCodeFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const PromoCodeInput = styled.input`
  display: flex;
  padding: 8px 12px;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #d1d5db;
  border-radius: 3px;
`;

export const PromoCodeButton = styled.button`
  padding: 8px 16px;
  height: 36px;
  border: 1px solid #2563eb;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  background: none;
  color: #2563eb;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const UpgradeButton = styled.button`
  padding: 12px 24px;
  gap: 8px;
  width: 100%;
  height: 46px;
  background: #2563eb;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 6px;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
`;

export const Error = styled.div`
  color: red;
`;

export const BillingInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 16px;
  gap: 24px;
  max-width: 270px;
`;

export const Pricing = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PlanPrice = styled.p`
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-decoration-line: line-through;
  color: ${({ theme }) => theme.colors.gray[600]};
`;

export const DiscountedPrice = styled.p`
  font-weight: 600;
  font-size: 30px;
  margin: 0;
  text-align: center;
  color: #111827;
`;

export const DiscountPercentage = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #172582;
`;

export const AvailableProducts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export const ProductName = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.suite[900]};
  color: ${({ theme, productKey }) =>
    theme.colors.utils.getByProductKey(productKey)};
`;

export const ProductTagline = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const BillingRemarks = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const ReCaptaDisclaimer = styled.p`
  margin: 0;
  color: #374151;
  text-align: center;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 400;
  & > a {
    color: #2939a3;
  }
`;
