import styled, { createGlobalStyle } from "styled-components";
import { Link as BaseLink } from "@reach/router";

export const HideHeader = createGlobalStyle`
  /* provide overrides for global styles to present the appearance that this route is a standalone screen (without navigation) */
  header { 
    display: none !important; 
  }
  body { 
    background-color: ${({ theme }) => theme.colors.gray[500]} !important; 
  }
  main { 
    padding: 0 !important; 
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

export const InvoiceNav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px;

  @media print {
    display: none;
  }
`;

export const BackLink = styled(BaseLink)`
  display: flex;
  align-items: center;
`;

export const PrintButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  background: transparent;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.suite[500]};
`;

export const IconLayout = styled.div`
  margin-right: 12px;
  width: 18px;
  height: 18px;

  svg {
    fill: ${({ theme }) => theme.colors.suite[500]};
  }
`;

export const PageLayout = styled.div`
  position: relative;
  margin: 0px auto 48px;
  box-shadow: ${({ theme }) => theme.shadows.raised};
  width: 8.5in;
  height: 11in;
  padding: 0.75in;
  background-color: white;

  @media print {
    margin: 0;
    padding: 0.75in;
  }
`;

export const InvoiceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 62pt;
`;

export const HeaderLeft = styled.div`
  flex: 1 1 auto;
  img {
    margin-bottom: 48pt;
  }
`;

export const InvoiceHeading = styled.h1`
  font-size: 16pt;
  line-height: 20pt;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  margin-bottom: 12pt;
`;

export const Table = styled.table`
  border-collapse: collapse;
  font-size: 10pt;
  line-height: 14pt;
  font-weight: 400;
  color: black;

  td:first-of-type {
    color: #808285;
    padding-right: 22pt;
    font-weight: 400;
  }

  h2 {
    font-size: 12pt;
    line-height: 14pt;
    font-weight: 500;
    color: black;
    margin-bottom: 4pt;
  }

  p {
    line-height: 14pt;
    margin: 0;
  }
`;

export const InvoiceTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    height: 28pt;
    &.grey {
      background-color: #f1f1f2;
    }
    &.short {
      height: 20pt;
    }
  }

  th {
    color: #808285;
    vertical-align: middle;
    font-size: 10pt;
    line-height: 14pt;
    font-weight: 400;
    border-bottom: 1px solid black;
  }

  td {
    font-size: 11pt;
    line-height: 13pt;
    font-weight: 400;
    vertical-align: middle;
    color: black;

    &.small {
      color: #808285;
      vertical-align: middle;
      font-size: 10pt;
      line-height: 14pt;
      font-weight: 400;
      &.dark {
        color: black;
      }
    }
  }

  h2 {
    font-size: 12pt;
    line-height: 14pt;
    font-weight: 500;
    color: black;
  }
`;

export const HeaderRight = styled.div`
  flex: 0 1 auto;
`;

export const Footer = styled.footer`
  position: absolute;
  right: 0;
  bottom: 0.75in;
  left: 0;
  text-align: center;
  font-size: 9pt;
  line-height: 12pt;
  margin: 0 auto;
  width: 100%;
  color: black;
`;
