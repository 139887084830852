import { useMutation, useQuery } from "@apollo/react-hooks";
import { Link } from "@reach/router";
import gql from "graphql-tag";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../utils/theme";
import Text from "../../common/Text";
import { Radio, RadioGroup } from "../../common/Radio";
import Button from "../../common/Button";
import airbrake from "../../../utils/errors";

// Link to Zap handling the webhook catch -> Excel Sheet data entry
// https://zapier.com/shared/384cae9692aa6cacc52599a53beae0cdac646615
const ZAPIER_CANCELLATION_SURVEY_WEBHOOK =
  "https://hooks.zapier.com/hooks/catch/279836/oxyjdp2";

const cancellationOptions = [
  "Subscription Price",
  "Market Conditions",
  "Covid Related",
  "Never Used",
  "No Time to Learn",
  "Customer Support Issue",
  "Product Issue"
];

export default function CancelConfirm() {
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancellationReason, setCancellationReason] = useState();

  const { loading, data } = useQuery(
    gql`
      query getCancellingUser {
        user {
          id
          name
          email
          mls {
            code
          }
          subscription {
            sku {
              key
            }
          }
        }
      }
    `,
    {
      context: { useAuthedEndpoint: true }
    }
  );

  const [cancelAccount] = useMutation(
    gql`
      mutation cancelAccount {
        cancelAccount {
          id
          email
        }
      }
    `,
    {
      context: { useAuthedEndpoint: true },
      onCompleted: () => {
        localStorage.removeItem("token");
        sessionStorage.removeItem("impersonate");
        window.location.replace(`${window.location.origin}`); // return to home page
      }
    }
  );

  async function handleAccountCancellation() {
    // TODO: post to Zapier webhook to record cancellation survey results
    try {
      const {
        user: {
          name,
          email,
          mls: { code: mls },
          subscription: {
            sku: { key: plan }
          }
        }
      } = data;
      await fetch(ZAPIER_CANCELLATION_SURVEY_WEBHOOK, {
        method: "POST",
        headers: { Accept: "application/json" },
        body: JSON.stringify({
          name,
          email,
          mls,
          plan,
          cancellationReason,
          cancellationDate: new Date().toLocaleDateString()
        })
      });
    } catch (error) {
      airbrake.notify(error);
    } finally {
      cancelAccount();
    }
  }

  useEffect(() => {
    if (isCancelling) {
      handleAccountCancellation();
    }
  }, [isCancelling]);

  return (
    <CancelContainer>
      <HeadingLayout>
        <Text variant="displayMedium" as="h1">
          Cancellation Survey
        </Text>
      </HeadingLayout>

      <Card>
        <Text variant="heading" as="p">
          Please select the option below that most closely matches your reason
          for cancelling. Your feedback is greatly appreciated!
        </Text>

        <VStack>
          <RadioGroup
            labelledBy="cancellationReason"
            value={cancellationReason}
            onChange={(reason) => setCancellationReason(reason)}>
            {cancellationOptions.map((option) => (
              <Radio value={option} key={option}>
                {option}
              </Radio>
            ))}
          </RadioGroup>
          <Text>
            By cancelling your account, your data will be deleted immediately.
            This action cannot be undone.
          </Text>
          <Button
            app="attract"
            size="large"
            disabled={loading || !cancellationReason || isCancelling}
            onClick={() => setIsCancelling(true)}>
            Confirm Cancellation
          </Button>
        </VStack>
      </Card>
    </CancelContainer>
  );
}

// Styled Components
const CancelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const HeadingLayout = styled.div`
  padding: 32px 16px;
  text-align: center;

  > ${Text} {
    margin: 0;
  }
`;

const Card = styled.div`
  width: auto;
  max-width: 512px;
  box-shadow: ${theme.shadows.raised};
  padding: 32px;
  background-color: white;
  border-radius: 6px;

  > ${Text} {
    margin: 0;
    padding-bottom: 24px;
  }

  @media (max-width: 576px) {
    width: 100%;
    padding: 20px 16px 40px;
  }
`;

const VStack = styled.div`
  display: flex;
  flex-direction: column;

  > ${Text} {
    line-height: 20px;
  }

  > * + * {
    margin-top: 24px;
  }

  [data-palmerhq-radio-group] {
    display: flex;
    flex-direction: column;
    > * {
      margin: 0;
    }

    [data-palmerhq-radio] + [data-palmerhq-radio] {
      margin-left: 0;
    }

    > * + * {
      margin-top: 20px;
    }
  }
`;
