import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  min-height: 188px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
  padding: 18px 24px;
  box-sizing: border-box;
`;

export const Heading = styled.h4`
  font-weight: 500;
  font-size: 18px;
  font-weight: normal;
  color: #131945;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 0 11px;
`;

export const ExpWarning = styled.span`
  font-size: 14px;
  color: #ec5252;
  margin-left: 16px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const TLCBadge = styled.img`
  position: absolute;
  right: 18px;
  bottom: 18px;
  width: 112px;
  min-height: 112px;
  @media (max-width: 450px) {
    opacity: 0.5;
  }
`;

export const Nevermind = styled.button`
  outline: none;
  background: white;
  padding: 0;
  border: none;
  border-radius: 0;
  text-decoration: none;

  font-size: 13px;
  font-weight: normal;
  color: #2d335d;
  letter-spacing: 0;
  text-align: right;
  line-height: 24px;
  margin-left: 20px;
  cursor: pointer;
  &:focus {
    border-bottom: 2px solid #9013fe;
  }
`;

export const CreditCard = styled.p`
  font-size: 18px;
  color: #576474;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0 0 12px;
`;

export const ExpirationWarning = styled.p`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #ec5252;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -6px;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    fill: ${({ theme }) => theme.colors.accent.red};
  }
`;

export const Microcopy = styled.p`
  max-width: 318px;
  font-size: 14px;
  font-weight: normal;
  color: #566373;
  letter-spacing: 0;
  line-height: 20px;
`;

export const NoBalance = styled.span`
  display: block;
  margin-bottom: 22px;
`;

export const UpdateCard = styled.a`
  color: #2d335d;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
`;

export const RedactedNumber = styled.span`
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 3px;
  margin-left: 8px;
  opacity: 0.6;
`;
