import React from "react";
import styled, { css } from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `};
  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `};
  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `};
`;
