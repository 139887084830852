import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Text = styled.span`
  ${({ theme, variant }) => theme.text[variant]};
`;

Text.propTypes = {
  variant: PropTypes.oneOf(["base", "link", "small", "micro"])
};

Text.defaultProps = {
  variant: "base"
};

export default Text;
