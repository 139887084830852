import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import { IS_PROD } from "../../../constants/config";
import LaunchpadHeading from "../LaunchpadHeading";
import LaunchpadProducts from "../LaunchpadProducts";
import LaunchpadProvider from "../LaunchpadProvider";
import PoweredBy from "../PoweredBy";
import Shortcuts from "../Shortcuts";
import ShortcutsHeading from "../ShortcutsHeading";

function Launchpad() {
  const [updateIntercomUser] = useMutation(UPDATE_INTERCOM_USER_MUTATION, {
    context: { useAuthedEndpoint: true }
  });

  useEffect(() => {
    if (IS_PROD) {
      updateIntercomUser();
    }
  }, []);

  useLayoutEffect(() => {
    // Scroll to top on mount
    window.scrollTo(0, 0);
  }, []);

  return (
    <LaunchpadProvider>
      <LaunchpadLayout>
        <LaunchpadHeading />
        <LaunchpadProducts />
        <ShortcutsHeading />
        <Shortcuts />
        <PoweredByContainer>
          <PoweredBy url="https://www.lwolf.com/">Lone Wolf</PoweredBy>
        </PoweredByContainer>
      </LaunchpadLayout>
    </LaunchpadProvider>
  );
}

// Styled Components
const LaunchpadLayout = styled.div`
  width: 100%;
  max-width: calc(1032px + (20px * 2));
  margin: 0 auto;
  padding: 0 20px 48px;

  @media (max-width: 500px) {
    padding: 0px;
  }
`;

const PoweredByContainer = styled.div`
  margin-top: 24px;
  text-align: center;
`;

export const UPDATE_INTERCOM_USER_MUTATION = gql`
  mutation updateIntercom {
    updateIntercom {
      id
    }
  }
`;

export default Launchpad;
