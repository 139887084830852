// ! marked for deletion
import yup from "yup";
import { apolloClient } from "../graphql/client";
import airbrake from "../utils/errors";
import { VERIFY_USER_BY_EMAIL } from "../graphql/mutations";

export const VALIDATION_ERRORS = Object.freeze({
  INVALID_EMAIL: "Invalid email",
  EMPLOYEE_EMAIL: "Must use employee email",
  EMAIL_TAKEN: "Email already exists",
  TOS_ACCEPTANCE: "Please accept the TOS.",
  REQUIRED: "Required"
});

const employeeEmail = /^\S+@(wr-?studios|lwolf)\.com$/;

export function generateCheckoutValidationSchema({
  isFreePlan = false,
  isNewSignup,
  promoCode
}) {
  return yup.object().shape({
    ...(isNewSignup
      ? {
          name: yup
            .string()
            .nullable(true)
            .required(VALIDATION_ERRORS.REQUIRED),
          email: yup
            .mixed()
            .required(VALIDATION_ERRORS.REQUIRED)
            .test({
              name: "isValidEmail",
              message: VALIDATION_ERRORS.INVALID_EMAIL,
              test: (value) => yup.string().email().isValidSync(value)
            })
            .test({
              name: "isEmployeeEmailAddress",
              message: VALIDATION_ERRORS.EMPLOYEE_EMAIL,
              test: (value) =>
                !!value && promoCode === "wrstudios"
                  ? value.match(employeeEmail)
                  : true
            })
            .test({
              name: "verifyUserByEmail",
              message: VALIDATION_ERRORS.EMAIL_TAKEN,
              test: async (value) => {
                if (!!value && yup.string().email().isValidSync(value)) {
                  try {
                    const {
                      data: {
                        verifyUserByEmail: { id }
                      }
                    } = await apolloClient.mutate({
                      mutation: VERIFY_USER_BY_EMAIL,
                      variables: { email: value }
                    });

                    return !id;
                  } catch (error) {
                    console.error(
                      "Error in VERIFY_USER_BY_EMAIL mutation:",
                      error
                    );
                    airbrake.notify({ error });
                  }
                }
              }
            }),
          tos: yup
            .boolean()
            .required()
            .oneOf([true], VALIDATION_ERRORS.TOS_ACCEPTANCE)
        }
      : {}),
    ...(isFreePlan // || process.env.RAILS_ENV === "staging" || process.env.RAILS_ENV === "development"
      ? {}
      : {
          number: yup.boolean().required().oneOf([true], "Invalid card number"),
          expiration: yup
            .boolean()
            .required()
            .oneOf([true], "Invalid expiration date"),
          cvv: yup.boolean().required().oneOf([true], "Invalid CVV"),
          postalCode: yup
            .boolean()
            .required()
            .oneOf([true], "Invalid Postal Code")
        })
  });
}

export function transformCheckoutError(error) {
  // SAMPLE PARSED ERROR
  // {
  //   credit_card: {
  //     status: "gateway_rejected",
  //     processor_response_code: "1000",
  //     cvv_response_code: "N"
  //   }
  // }
  // {
  //   recaptcha: {
  //     status: "invalid",
  //   }
  // }

  try {
    if (
      error.credit_card &&
      error.credit_card.status === "gateway_rejected" &&
      error.credit_card.cvv_response_code
    ) {
      switch (error.credit_card.cvv_response_code) {
        case "N":
          return "CVV does not match";
        case "U":
          return "CVV not verified";
        case "S":
          return "Card issuer does not participate";
        case "I":
          return "CVV not provided";
      }
    } else if (
      error.credit_card &&
      error.credit_card.status === "processor_declined"
    ) {
      return "Your card was declined";
    } else if (error.recaptcha && error.recaptcha.status === "invalid") {
      return "Your request has been rejected due to suspicious activity. Please contact support@cloudagentsuite.com if this is in error";
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
}

export function getMlsCredentialInfo(allMls, code) {
  const mlsCredential = allMls.find((mls) => mls.code === code);
  return {
    mlsName: mlsCredential.name || "",
    mlsCode: mlsCredential.code || ""
  };
}
