import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useState } from "react";
import { config } from "../../../utils/lw-platform-config";
import Button from "../../common/Button";
import TextField from "../../common/TextField";
import ErrorOutline from "../../icons/ErrorOutline";
import { Error, Success } from "./styled/settings";

const GET_USER = gql`
  query GetUser {
    user {
      lwaId: lwa_id
      email
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($user: UserRegistrationInput!) {
    updateUser(user: $user) {
      id
    }
  }
`;

function SettingsPassword() {
  const { data } = useQuery(GET_USER, { context: { useAuthedEndpoint: true } });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [updated, setUpdated] = useState(false);
  const [updateUser, { loading, error }] = useMutation(UPDATE_USER, {
    context: { useAuthedEndpoint: true },
    onCompleted: () => {
      setUpdated(true);
    }
  });
  const isDisabled =
    loading ||
    !currentPassword ||
    !newPassword ||
    !confirmNewPassword ||
    newPassword !== confirmNewPassword;

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser({
      variables: {
        user: {
          email: data.user.email,
          password: newPassword
        }
      }
    });
  };

  const isLWManaged = !!data.lwId;
  const token =
    sessionStorage.getItem("impersonate") || localStorage.getItem("token");

  return (
    <div className="helix-d-flex helix-flex-direction--column helix-gap-6">
      <div className="helix-d-flex helix-flex-direction--column helix-gap-4">
        <h4 className="helix-display-x-small">Lone Wolf Account</h4>
        {isLWManaged ? (
          <>
            <p className="helix-body">
              You are using your Lone Wolf login to sign into this account. To
              manage your password and app connections go to your Lone Wolf
              account.
            </p>
            <a
              href={`${config.platformUrl}/account/manage`}
              className="helix-btn helix-btn--primary helix-100-percent helix-text--center">
              Manage Lone Wolf Account
            </a>
          </>
        ) : (
          <>
            <p className="helix-body">
              A Lone Wolf Account allows you to sign into all of your Lone Wolf
              products with a single password, and switch between them
              seamlessly.
            </p>
            <a
              href={`/lone_wolf?token=${token}`}
              className="helix-btn helix-btn--primary helix-100-percent helix-text--center">
              Create or Connect a Lone Wolf Account
            </a>
          </>
        )}
      </div>
      {!isLWManaged && (
        <>
          <div
            className="helix-w-100-percent helix-bg-gray-200"
            style={{ height: 1 }}
          />
          <form onSubmit={handleSubmit}>
            <TextField>
              <label htmlFor="current-password">Current Password</label>
              <input
                id="current-password"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </TextField>
            <TextField>
              <label htmlFor="new-password">New Password</label>
              <input
                id="new-password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </TextField>
            <TextField>
              <label htmlFor="confirm-new-password">Confirm New Password</label>
              <input
                id="confirm-new-password"
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </TextField>
            <Button fullwidth disabled={isDisabled} loading={loading}>
              Update Password
            </Button>
            {error && (
              <Error>
                <ErrorOutline />
                {error.message}
              </Error>
            )}
            {updated && <Success>Updated Password!</Success>}
          </form>
        </>
      )}
    </div>
  );
}

export default SettingsPassword;
