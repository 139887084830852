import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import yup from "yup";
import gql from "graphql-tag";
import formatDate from "date-fns/format";
import addQuarters from "date-fns/add_quarters";
import isWithinRange from "date-fns/is_within_range";
import isPast from "date-fns/is_past";
import { useMutation } from "@apollo/react-hooks";
import { Braintree } from "react-braintree-fields";
import { Formik } from "formik";
import { GET_USER_BILLING } from "../api/queries/user";
import ErrorOutline from "./icons/ErrorOutline";
import { braintreeFormStyles } from "./styled/billing-fields";
import EditPaymentFields from "./CheckoutForm/EditPaymentFields";
import tlcBadge from "../images/tlc-guarantee.svg";
import Button from "./common/Button";
import {
  Container,
  Heading,
  TLCBadge,
  Nevermind,
  CreditCard,
  ExpirationWarning,
  Microcopy,
  UpdateCard,
  RedactedNumber,
  NoBalance,
  ButtonGroup
} from "./styled/card-on-file";

const CardOnFile = ({ payment, isPastDue, nextBillDate, chargeAmount }) => {
  const [isShowingPaymentFields, setIsShowingPaymentFields] = useState(false);
  const [hasUpdatedCard, setHasUpdatedCard] = useState(false);
  const getToken = useRef(() => {});
  const expDate = payment ? payment.expirationDate : null;
  const expDateAsValidDateObj = expDate ? expDate.split("/").join("/1/") : null;
  const [month, year] = payment
    ? payment.expirationDate.split("/")
    : ["1", "01"]; // default to time in past
  const currentCardIsExpired = isPast(`${month}/1/${year}`);
  const cardIsExpiringSoon = isWithinRange(
    expDateAsValidDateObj,
    Date.now(),
    addQuarters(Date.now(), 1)
  );
  const displayNextBillDate = formatDate(nextBillDate, "MMM Do, YYYY");

  const [updateUserBilling, { loading, data }] = useMutation(
    UPDATE_USER_BILLING,
    {
      context: { useAuthedEndpoint: true },
      update(cache, response) {
        const cachedQuery = cache.readQuery({ query: GET_USER_BILLING });
        cache.writeQuery({
          query: GET_USER_BILLING,
          data: {
            ...cachedQuery,
            user: {
              ...cachedQuery.user,
              payment: response.data.updateUserBilling.payment
            }
          }
        });
      }
    }
  );

  async function handleUpdateCard() {
    const { nonce } = await getToken.current();
    updateUserBilling({ variables: { payment_method_nonce: nonce } });
  }

  useEffect(() => {
    if (data) {
      setHasUpdatedCard(true);
      setIsShowingPaymentFields(false);
    }
  }, [data]);

  const numberField = useRef();
  return (
    // Due to a bug in updateUserBilling for user's w/o a CC, we're only showing this form for users with a form of payment (until this is fixed)
    !!payment && (
      <Container>
        <Heading>
          {!payment
            ? "No Card on File"
            : isShowingPaymentFields
            ? "Update Card"
            : hasUpdatedCard
            ? "Updated Card"
            : "Your Card"}
        </Heading>
        {isShowingPaymentFields ? (
          <Formik
            initialValues={{
              number: false,
              expirationDate: false,
              cvv: false,
              postalCode: false
            }}
            validationSchema={validationSchema}>
            {({ isValid, setFieldTouched, setFieldValue }) => (
              <Braintree
                authorization={window.braintree_token}
                getTokenRef={(tokenize) => (getToken.current = tokenize)}
                onAuthorizationSuccess={() =>
                  numberField.current && numberField.current.focus()
                }
                styles={braintreeFormStyles}>
                <EditPaymentFields
                  numberFieldRef={numberField}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                />
                <ButtonGroup>
                  <Button
                    size="small"
                    onClick={handleUpdateCard}
                    disabled={!isValid || loading}
                    loading={loading}>
                    {loading ? "Updating..." : "Update"}
                  </Button>
                  {!isPastDue && (
                    <Nevermind onClick={() => setIsShowingPaymentFields(false)}>
                      Nevermind
                    </Nevermind>
                  )}
                </ButtonGroup>
              </Braintree>
            )}
          </Formik>
        ) : (
          <>
            {!!payment && (
              <CreditCard>
                {payment.cardType}
                <RedactedNumber>{payment.maskedNumber}</RedactedNumber>
              </CreditCard>
            )}
            {!!payment && currentCardIsExpired ? (
              <ExpirationWarning>
                <ErrorOutline />
                Billing Error: Your credit card has expired (
                {payment.expirationDate}).
              </ExpirationWarning>
            ) : (
              isPastDue && (
                <ExpirationWarning>
                  <ErrorOutline />
                  Billing Error: We were unable to process your payment.
                </ExpirationWarning>
              )
            )}
            {cardIsExpiringSoon && !!payment && (
              <ExpirationWarning>
                Your card is expiring soon. &nbsp; ({expDate})
              </ExpirationWarning>
            )}
            <Microcopy>
              {chargeAmount <= 0 ? (
                <NoBalance>You have no balance due at this time.</NoBalance>
              ) : isPastDue || cardIsExpiringSoon || currentCardIsExpired ? (
                "Please update your credit card in order to prevent service interruption and automatic cancellation."
              ) : (
                `We will use this card to process your next payment of $${chargeAmount} on ${displayNextBillDate}.`
              )}
            </Microcopy>
            <UpdateCard onClick={() => setIsShowingPaymentFields(true)}>
              {!payment ? "Add" : "Update"} card
            </UpdateCard>
          </>
        )}
        <TLCBadge src={tlcBadge} />
      </Container>
    )
  );
};

CardOnFile.propTypes = {
  isPastDue: PropTypes.bool.isRequired,
  chargeAmount: PropTypes.number.isRequired,
  nextBillDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  payment: PropTypes.shape({
    cardType: PropTypes.string,
    expirationDate: PropTypes.string,
    maskedNumber: PropTypes.string
  })
};

export const UPDATE_USER_BILLING = gql`
  mutation updateUserBilling($payment_method_nonce: String!) {
    updateUserBilling(payment_method_nonce: $payment_method_nonce) {
      payment {
        cardType: card_type
        expirationDate: expiration_date
        maskedNumber: masked_number
      }
      subscription {
        next_billing_period_amount
        next_bill_date
        price
        status
        balance
        products {
          key
          site_license
          discounts {
            code
            amount
          }
        }
        sku {
          name
          key
          type
        }
        discount {
          id
          code
        }
      }
    }
  }
`;

const fieldValidation = yup.bool().oneOf([true]).required();

const validationSchema = yup.object().shape({
  number: fieldValidation,
  expirationDate: fieldValidation,
  cvv: fieldValidation,
  postalCode: fieldValidation
});

export default CardOnFile;
