import React from "react";
import styled from "styled-components";

function Covid19Message() {
  return (
    <MessageCard>
      <MessageHeading>
        ⚠️️️&nbsp;&nbsp;COVID-19 Subscription Assistance
      </MessageHeading>
      <MessageContents>
        If you are experiencing financial hardships due to the COVID-19
        pandemic, and you would like assistance with deferring payment on your
        current subscription, please submit a request to{" "}
        <a href="https://lonewolf.my.site.com/s/cloud-cma-resources">
          online support
        </a>
        . You're also welcome to call our Customer Support team at{" "}
        <a href="tel:855-977-8834">855-977-8834</a>, Option 2, M-F 9am - 5pm PST
        and Saturday 9am - 3pm PST.
      </MessageContents>
    </MessageCard>
  );
}

const MessageCard = styled.div`
  max-width: 859px;
  padding: 16px;
  background-color: #fef6e6;
  border: 1px solid #fde0b4;
  border-radius: 4px;
  margin-top: 26px;
`;

const MessageHeading = styled.h3`
  padding-bottom: 10px;
  color: #7c4c03;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

const MessageContents = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #212d3b;
  margin: 0;

  a {
    color: #2939a3;
    text-decoration: underline;
    white-space: nowrap;
  }
`;

export default Covid19Message;
