import React, { useState, useEffect } from "react";
import yup from "yup";
import { get } from "lodash";
import { useField, useFormikContext } from "formik";
import { useQuery } from "react-apollo";
import StepSelection from "./StepSelection";
import Accordion from "./Accordion";
import TerritoryCombobox from "./TerritoryCombobox";
import MlsCombobox from "./MlsCombobox";
import Button from "./common/Button";
import TextField from "./common/TextField";
import RequestMlsButton from "./RequestMlsButton";
import { USER_EXISTS_QUERY } from "../machines/subscription-workflow";
import { useSubscriptionWorkflow } from "./SubscriptionWorkflow";
import {
  StepWrapper,
  StepHeader,
  StepIdentifier,
  StepNumber,
  StepName,
  EditStep
} from "../styled/StepWrapper";
import {
  StepContentsLayout,
  FieldsLayout,
  FieldHeaderLayout,
  FieldValidation,
  SectionHeading,
  ContactInfo,
  YourMLS,
  LoginOptions,
  LoginOptionsLayout
} from "./styled/account-step";
import { VALIDATION_ERRORS } from "../modules/checkout";
import { translate } from "../utils/locale";

function AccountStep() {
  const { state, send } = useSubscriptionWorkflow();
  const {
    selectedTerritory,
    selectedMls,
    allowMlsEdit,
    nullMls,
    cookiedMlsId
  } = state.context;
  const isCurrentStep = state.matches("account");
  const hasMadeValidSelection = !!selectedMls.name;
  const hasCompletedStep = hasMadeValidSelection && !isCurrentStep;
  const mlsNotFound = selectedMls.code === nullMls.code;
  const { values } = useFormikContext();
  const isntPremiumMls = !cookiedMlsId;

  return (
    <StepWrapper isCurrent={isCurrentStep}>
      <StepHeader>
        <StepIdentifier>
          <StepNumber isCurrent={isCurrentStep} isComplete={hasCompletedStep}>
            1
          </StepNumber>
          <StepName isCurrent={isCurrentStep}>
            <span
              dangerouslySetInnerHTML={{
                __html: translate("mls.label", "MLS").replace(
                  "®",
                  "<sup>®</sup>"
                )
              }}
            />
          </StepName>
        </StepIdentifier>
        {!state.matches("account") && (
          <>
            <StepSelection
              isCurrent={isCurrentStep}
              dataTestId="mls-step-selection">
              {values.name} - {selectedMls.name}
            </StepSelection>
            {allowMlsEdit && (
              <EditStep
                onClick={() => send("EDIT_MLS")}
                data-testid="edit-step-one-link">
                Edit
              </EditStep>
            )}
          </>
        )}
      </StepHeader>
      <Accordion isOpen={isCurrentStep}>
        <StepContentsLayout>
          <FieldsLayout>
            <ContactInfo>
              <SectionHeading as="h3" variant="displayExtraSmall">
                Your contact info
              </SectionHeading>
              <ContactField
                fieldName="name"
                id="full-name"
                placeholder="First and last name"
                labelText="Full Name"
              />
              <ContactField
                fieldName="email"
                id="email-address"
                placeholder="you@yourdomain.com"
                labelText="Email address"
              />
              <ContactField
                fieldName="phone"
                id="phone-number"
                placeholder="555-555-5555"
                labelText="Phone number"
              />
            </ContactInfo>
            {isntPremiumMls && (
              <YourMLS>
                <SectionHeading as="h3" variant="displayExtraSmall">
                  Your{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translate("mls.label", "MLS").replace(
                        "®",
                        "<sup>®</sup>"
                      )
                    }}
                  />
                </SectionHeading>
                <TextField>
                  <label htmlFor="territory-combobox">State / Province</label>
                  <TerritoryCombobox />
                </TextField>
                <TextField disabled={!selectedTerritory}>
                  <label htmlFor="mls-combobox">
                    Your{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: translate("mls.label", "MLS").replace(
                          "®",
                          "<sup>®</sup>"
                        )
                      }}
                    />{" "}
                    Association
                  </label>
                  <MlsCombobox />
                </TextField>
              </YourMLS>
            )}
          </FieldsLayout>
          {mlsNotFound ? <RequestMlsButton /> : <ViewMlsPlansButton />}
        </StepContentsLayout>
      </Accordion>
    </StepWrapper>
  );
}

function ContactField({ fieldName, labelText, ...inputProps }) {
  const { state, send } = useSubscriptionWorkflow();
  const { validationErrors, mode } = state.context;
  const [field, meta] = useField(fieldName);
  const { name, value } = field;
  const emailIsTaken =
    meta.error === validationErrors.EMAIL_TAKEN && mode === "new";

  useEffect(() => {
    // formik is handling the form validations but we still need to
    // sync state with the user in the state machine's context
    send("SET_USER_FIELD", { fieldKey: name, value });
  }, [value]);

  return (
    <TextField>
      <FieldHeaderLayout>
        <label htmlFor={inputProps.id}>{labelText}</label>
        {meta.touched && meta.error && (
          <FieldValidation>{meta.error}</FieldValidation>
        )}
      </FieldHeaderLayout>
      <input
        disabled={!state.matches("account")}
        {...field}
        {...inputProps}
        readOnly={mode === "edit"}
      />
      {emailIsTaken && <EmailIsTakenMessage />}
    </TextField>
  );
}

function EmailIsTakenMessage() {
  const { values, errors } = useFormikContext();

  const { data } = useQuery(USER_EXISTS_QUERY, {
    variables: { email: values.email },
    skip: errors.email && errors.email !== VALIDATION_ERRORS.EMAIL_TAKEN
  });

  return (
    <LoginOptions>
      This email is already connected to: <br />
      <strong>
        {get(data, "userExists.mls.name") || (
          <span
            dangerouslySetInnerHTML={{
              __html: `An unknown ${translate("mls.label", "MLS").replace(
                "®",
                "<sup>®</sup>"
              )}`
            }}
          />
        )}
      </strong>
      <br />
      <LoginOptionsLayout>
        <a
          style={{ marginTop: "2px" }}
          href={`/app/login${
            values.email.length ? `?email=${values.email}` : ""
          }`}>
          Sign in
        </a>{" "}
        or <a href="/users/password/new">Reset your password</a>
      </LoginOptionsLayout>
    </LoginOptions>
  );
}

function ViewMlsPlansButton() {
  const { state, send } = useSubscriptionWorkflow();
  const { selectedMls, validationSchema, mode } = state.context;
  const [contactFieldsAreValid, setContactFieldsAreValid] = useState(
    mode === "edit"
  );
  const { values } = useFormikContext();

  useEffect(() => {
    // We're doing a partial form validation here on the required phone and email fields
    // to see if they are able to proceed to the plan selection step
    Promise.all([
      yup.reach(validationSchema, "name").isValid(values.name),
      yup.reach(validationSchema, "email").isValid(values.email)
    ]).then((validations) => {
      const validity = validations.every(Boolean);
      setContactFieldsAreValid(validity);
    });
  }, [values.name, values.email]);

  const noMlsSelected = !selectedMls.code;
  const isFetchingPlans = state.matches("account.fetchingPlans");
  const isOnAnotherStep = state.matches("account") === false;
  const contactFieldsAreInvalid = contactFieldsAreValid === false;
  const isDisabled =
    noMlsSelected ||
    isFetchingPlans ||
    isOnAnotherStep ||
    contactFieldsAreInvalid;

  return (
    <Button
      onClick={() => send("VIEW_MLS_PLANS")}
      loading={isFetchingPlans}
      disabled={isDisabled}>
      Review Plans
    </Button>
  );
}

export default AccountStep;
