import React from "react";
import PropTypes from "prop-types";
import IconLoading from "./icons/IconLoading";
import { Container } from "./styled/loading-text";

function LoadingText({ productKey, children }) {
  return (
    <Container productKey={productKey}>
      <IconLoading />
      {children}
    </Container>
  );
}

LoadingText.propTypes = {
  productKey: PropTypes.oneOf([
    "cloud_cma",
    "cloud_streams",
    "cloud_mlx",
    "cloud_attract"
  ])
};

export default LoadingText;
