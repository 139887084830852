import { useMutation } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import { format } from "date-fns";
import { Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Braintree, HostedField } from "react-braintree-fields";
import { useQueryString } from "../../utils/url";
import ProductLogo from "../Launchpad/ProductLogo";
import { CHANGE_USER_PLAN, useGuestpass } from "./GuestpassProvider";
import {
  FieldGroup,
  FieldWrapper,
  Wrapper
} from "./styled/edit-payment-fields";
import {
  AvailableProducts,
  BillingCycleButton,
  BillingCycleButtonWrapper,
  BillingFormWrapper,
  BillingInfo,
  BillingRemarks,
  DiscountedPrice,
  DiscountPercentage,
  Error,
  Form,
  Label,
  LeftSection,
  PlanPrice,
  Pricing,
  ProductInfo,
  ProductName,
  ProductTagline,
  ProductWrapper,
  PromoCodeButton,
  PromoCodeFormWrapper,
  PromoCodeInput,
  UpgradeButton
} from "./styled/guestpass-billing";

const braintreeFormStyles = {
  input: {
    "font-size": "15px",
    "font-weight": "normal",
    transition: "color 0.1s"
  },
  "::placeholder": {
    color: "#C4C8D0"
  }
};

function BillingForm() {
  const [getToken, setGetToken] = useState();
  const [isAnnual, setIsAnnual] = useState(true);
  const [promoCode, setPromoCode] = useState();
  const [availableDiscount, setAvailableDiscount] = useState();
  const { user, bundles } = useGuestpass();
  const [error, setError] = useState(null);
  const [braintreeError, setBrainTreeError] = useState(null);

  const bundle = bundles.find((bundle) =>
    isAnnual ? bundle.key === "annual_suite" : bundle.key === "suite"
  );

  const { guest_pass_id: guestPassId } = useQueryString();

  const currentGuestPass =
    user &&
    user.guestPasses &&
    user.guestPasses.find((guestPass) => guestPass.id == guestPassId);

  const hasGuestPassExpired = !!(
    currentGuestPass && currentGuestPass.status == "expired"
  );

  const planRenewalPeriod =
    bundle.billingCycle === 1
      ? "Mo"
      : bundle.billingCycle === 12
      ? "Yr"
      : `${bundle.billingCycle} months`;

  const discountedPlanPrice = availableDiscount
    ? bundle.price - availableDiscount.amount
    : bundle.price;
  const maxDicountPercentage =
    availableDiscount && bundle
      ? Math.abs(((discountedPlanPrice - bundle.price) / bundle.price) * 100)
      : 0;

  useEffect(() => {
    setAvailableDiscount(
      bundle.discounts.find(
        (discount) => discount.code === window.sessionStorage.getItem("promo")
      )
    );
  }, [isAnnual, bundle]);

  const nextBillDate = format(
    new Date().setMonth(new Date().getMonth() + bundle.billingCycle),
    "MM/DD/YYYY"
  );
  const [changePlan, changePlanStatus] = useMutation(CHANGE_USER_PLAN, {
    context: { useAuthedEndpoint: true },
    onCompleted: () => {
      navigate("/guestpass/complete");
    },
    onError: setError
  });

  const handleUpgrade = async () => {
    const { nonce } = (getToken &&
      (await getToken().catch((error) => {
        setBrainTreeError(error);
      }))) || { nonce: undefined };

    if (currentGuestPass && nonce) {
      changePlan({
        variables: {
          sku: {
            id: bundle && bundle.id,
            type: "Bundle"
          },
          paymentMethodNonce: nonce,
          discountId: availableDiscount && availableDiscount.id,
          mlsId: user && user.mls && user.mls.id,
          guestPassId,
          guestPassConversion: true,
          source: "change_plan"
        }
      });
    }
  };
  const numberField = useRef();
  const braintreeToken = window.braintree_token;
  const { setFieldValue, setFieldTouched } = useFormikContext();

  if (currentGuestPass && currentGuestPass.status !== "activated") {
    navigate("/app/launchpad");
  }

  return (
    <BillingFormWrapper>
      <LeftSection>
        <BillingCycleButtonWrapper>
          <BillingCycleButton
            isActive={isAnnual}
            onClick={() => setIsAnnual(true)}>
            Annual
          </BillingCycleButton>
          <BillingCycleButton
            isActive={!isAnnual}
            onClick={() => setIsAnnual(false)}>
            Monthly
          </BillingCycleButton>
        </BillingCycleButtonWrapper>
        <Form>
          <Braintree
            authorization={braintreeToken}
            getTokenRef={(tokenRef) => setGetToken(() => tokenRef)}
            onAuthorizationSuccess={() => {
              numberField.current && numberField.current.focus();
            }}
            styles={braintreeFormStyles}>
            <Wrapper>
              <FieldWrapper>
                <Label>Credit Card Number</Label>
                <HostedField
                  type="number"
                  ref={numberField}
                  placeholder="Card number"
                  onFocus={() => {
                    setFieldTouched("number", true, true);
                    setBrainTreeError(null);
                  }}
                  onValidityChange={({ isValid }) => {
                    setFieldValue("number", isValid, true);
                    setBrainTreeError(null);
                  }}
                />
              </FieldWrapper>
              <FieldGroup>
                <FieldWrapper>
                  <Label>Expiration</Label>
                  <HostedField
                    type="expirationDate"
                    placeholder="MM/YYYY"
                    onFocus={() => {
                      setFieldTouched("expirationDate", true, true);
                      setBrainTreeError(null);
                    }}
                    onValidityChange={({ isValid }) => {
                      setFieldValue("expirationDate", isValid, true);
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Label>CVV</Label>
                  <HostedField
                    type="cvv"
                    placeholder="CVV"
                    onFocus={() => {
                      setFieldTouched("cvv", true, true);
                      setBrainTreeError(null);
                    }}
                    onValidityChange={({ isValid }) => {
                      setFieldValue("cvv", isValid, true);
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Label>Billing Zip Code</Label>
                  <HostedField
                    type="postalCode"
                    placeholder="Billing Zip"
                    onFocus={() => {
                      setFieldTouched("postalCode", true, true);
                      setBrainTreeError(null);
                    }}
                    onValidityChange={({ isValid }) => {
                      setFieldValue("postalCode", isValid, true);
                    }}
                  />
                </FieldWrapper>
              </FieldGroup>
            </Wrapper>
          </Braintree>
          <FieldWrapper>
            <Label>Promo Code</Label>
            <PromoCodeFormWrapper>
              <PromoCodeInput
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value);
                }}
              />
              <PromoCodeButton
                disabled={!promoCode}
                onClick={() => {
                  window.sessionStorage.setItem("promo", promoCode);
                  setAvailableDiscount(
                    bundle.discounts.find(
                      (discount) =>
                        discount.code === window.sessionStorage.getItem("promo")
                    )
                  );
                  setPromoCode("");
                }}>
                Add
              </PromoCodeButton>
            </PromoCodeFormWrapper>
          </FieldWrapper>

          <UpgradeButton
            onClick={() => handleUpgrade()}
            disabled={
              hasGuestPassExpired ||
              !currentGuestPass ||
              changePlanStatus.loading
            }>
            {changePlanStatus.loading ? "Upgrading..." : "Upgrade"}
          </UpgradeButton>
          {error && (
            <Error role="alert" aria-live="polite">
              {error.message}
            </Error>
          )}
          {braintreeError && (
            <Error role="alert" aria-live="polite">
              {braintreeError.message}
            </Error>
          )}
          {hasGuestPassExpired && (
            <Error role="alert" aria-live="polite">
              Invitation has expired.
            </Error>
          )}
          {!currentGuestPass && (
            <Error role="alert" aria-live="polite">
              Couldn't find Guest Pass details
              {guestPassId && ` for provided guest_pass_id ${guestPassId}`}.
            </Error>
          )}
        </Form>
      </LeftSection>
      <BillingInfo>
        <Pricing>
          {availableDiscount && (
            <PlanPrice>
              ${bundle.price}/{planRenewalPeriod}
            </PlanPrice>
          )}
          <DiscountedPrice>
            ${discountedPlanPrice}/{planRenewalPeriod}
          </DiscountedPrice>
          {availableDiscount && (
            <DiscountPercentage>
              (Save up to {Math.round(maxDicountPercentage)}%)
            </DiscountPercentage>
          )}
        </Pricing>
        <AvailableProducts>
          {bundle.products.map((product) => (
            <ProductWrapper key={product.key}>
              <ProductLogo productKey={product.key} />
              <ProductInfo>
                <ProductName productKey={product.key}>
                  {product.name}
                </ProductName>
                <ProductTagline>{product.tagline}</ProductTagline>
              </ProductInfo>
            </ProductWrapper>
          ))}
        </AvailableProducts>
        <BillingRemarks>
          Billed{" "}
          {bundle.billingCycle === 1
            ? "monthly"
            : bundle.billingCycle === 12
            ? "annually"
            : `in ${bundle.billingCycle} months`}
          . Your plan will renew on {nextBillDate} at ${discountedPlanPrice}.
        </BillingRemarks>
      </BillingInfo>
    </BillingFormWrapper>
  );
}

export default function BillingFormProvider() {
  return (
    <Formik
      initialValues={{
        number: false,
        expirationDate: false,
        cvv: false,
        postalCode: false
      }}>
      <BillingForm />
    </Formik>
  );
}
