import React from "react";

const IconEllipses = (props) => (
  <svg width={20} height={20} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm14 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-7 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
    />
  </svg>
);

export default IconEllipses;
