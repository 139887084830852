import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RadioGroup as BaseRadioGroup, Radio } from "@palmerhq/radio-group";
import theme from "../../utils/theme";

const ScopedStyles = styled.div`
  [data-palmerhq-radio-group] {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  [data-palmerhq-radio-group]:focus {
    outline: none;
  }

  [data-palmerhq-radio] {
    border: none;
    border-radius: 0px;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 0;
    padding-left: 34px;
    padding-right: 0;
    cursor: pointer;
    outline: none;
    transition: all 200ms;
    color: ${theme.colors.gray[800]};
  }
  [data-palmerhq-radio][aria-checked="true"] {
    color: ${theme.colors.suite[900]};
  }

  [data-palmerhq-radio] + [data-palmerhq-radio] {
    margin-left: 28px;
  }

  [data-palmerhq-radio]::before {
    transition: all 200ms;
  }

  [data-palmerhq-radio]::before,
  [data-palmerhq-radio]::after {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(-20%, -50%);
    content: "";
  }

  [data-palmerhq-radio]:active::before {
    background-image: none;
  }

  /* States -------------------------- */

  /* Unchecked - Default */
  [data-palmerhq-radio]::before {
    width: 20px;
    height: 20px;
    border: 1px solid ${theme.colors.gray[600]};
    border-radius: 100%;
    background-image: none;
  }

  /* Unchecked - Hover */
  [data-palmerhq-radio]:hover {
    background: none;
  }
  [data-palmerhq-radio]:hover::before {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.gray[700]};
  }

  /* Unchecked - Focus */
  [data-palmerhq-radio][data-palmerhq-radio-focus="true"] {
    border-color: ${theme.colors.gray[700]};
    background-color: ${theme.colors.white};
  }
  [data-palmerhq-radio][data-palmerhq-radio-focus="true"]::before {
    box-shadow: 0 0 0 2px ${({ app }) => theme.colors[app][300]};
  }

  /* Unchecked - Active */
  [data-palmerhq-radio][aria-checked="false"]:active::before {
    border-color: ${theme.colors.gray[700]};
    background-color: ${theme.colors.gray[300]};
    box-shadow: none;
  }

  /* Checked - Default  */
  [data-palmerhq-radio][aria-checked="true"]::before {
    border-color: ${({ app }) => theme.colors[app][500]};
    background: ${({ app }) => theme.colors[app][500]};
    background-image: none;
  }
  [data-palmerhq-radio][aria-checked="true"]::after {
    display: inline-block;
    min-width: 8px;
    min-height: 8px;
    border: none;
    border-radius: 100%;
    transform: translate(33%, -50%);
    background-color: ${theme.colors.white};
  }

  /* Checked - Hover */
  [data-palmerhq-radio][data-palmerhq-radio-focus="true"][aria-checked="mixed"]:hover::before,
  [data-palmerhq-radio][data-palmerhq-radio-focus="true"][aria-checked="true"]:hover::before {
    background-color: ${({ app }) => theme.colors[app][400]};
    border-color: ${({ app }) => theme.colors[app][400]};
    box-shadow: none;
  }

  /* Checked - Focus */
  [data-palmerhq-radio][data-palmerhq-radio-focus="true"][aria-checked="true"]::before {
    border-color: ${({ app }) => theme.colors[app][500]};
  }

  /* Checked - Active */
  [data-palmerhq-radio][data-palmerhq-radio-focus="true"][aria-checked="mixed"]:active::before,
  [data-palmerhq-radio][data-palmerhq-radio-focus="true"][aria-checked="true"]:active::before {
    background-image: none;
    background-color: ${({ app }) => theme.colors[app][600]};
    border-color: ${({ app }) => theme.colors[app][600]};
    box-shadow: none;
  }
`;

const RadioGroup = ({ children, app, ...props }) => (
  <ScopedStyles app={app}>
    <BaseRadioGroup {...props}>{children}</BaseRadioGroup>
  </ScopedStyles>
);

RadioGroup.defaultProps = {
  app: "suite"
};

RadioGroup.propTypes = {
  app: PropTypes.oneOf(["cma", "streams", "mlx", "attract", "suite"])
    .isRequired,
  labelledBy: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any
};

export { RadioGroup, Radio };
