import "@helix/styles/dist/helix.css";
import { HelixThemeProvider } from "@helix/theming";
import { Redirect, Router } from "@reach/router";
import React from "react";
import { ThemeProvider } from "styled-components";
import useCurrentUser from "../../hooks/useCurrentUser";
import { WindowWidthProvider } from "../../providers/window-width-provider";
import GlobalStyles from "../../styled/globals/launchpad";
import theme from "../../utils/theme";
import { useQueryString } from "../../utils/url";
import GuestpassBilling from "../Guestpass/GuestpassBilling";
import GuestpassComplete from "../Guestpass/GuestpassComplete";
import GuestpassRedeem from "../Guestpass/GuestpassRedeem";
import AuthedRoutesWithNav from "./routes/AuthedRoutesWithNav";
import AuthedRoutesWithoutNav from "./routes/AuthedRoutesWithoutNav";
import Billing from "./routes/Billing";
import BillingHome from "./routes/BillingHome";
import Cancel from "./routes/Cancel";
import CancelAddon from "./routes/CancelAddon";
import CancelConfirm from "./routes/CancelConfirm";
import CancelOverview from "./routes/CancelOverview";
import ExternalLogin from "./routes/ExternalLogin";
import InvoiceRoute from "./routes/InvoiceRoute";
import InvoicesRoute from "./routes/InvoicesRoute";
import Launchpad from "./routes/Launchpad";
import Logout from "./routes/Logout";
import Plan from "./routes/Plan";
import PlanRedirect from "./routes/PlanRedirect";
import ProductActivation from "./routes/ProductActivation";
import Settings from "./routes/Settings";
import SettingsMls from "./routes/SettingsMls";
import SettingsPassword from "./routes/SettingsPassword";
import SettingsProfile from "./routes/SettingsProfile";
import Thanks from "./routes/Thanks";

const App = () => {
  const params = useQueryString();
  const { data: currentUser } = useCurrentUser();
  const changePlanRedirectUrl = params.r;

  if (changePlanRedirectUrl && currentUser) {
    window.location.href = `${changePlanRedirectUrl}?code=${currentUser.mls.code}&jwt=${currentUser.jwt}`;
  }

  return (
    <ThemeProvider theme={theme}>
      <WindowWidthProvider>
        <GlobalStyles />
        <HelixThemeProvider>
          <Router>
            <AuthedRoutesWithNav path="/app">
              <Redirect noThrow from="/" to="/app/launchpad" />
              <Redirect noThrow from="login" to="/app/launchpad" />
              <PlanRedirect path="plan" />
              <Thanks path="thanks" />
              <Launchpad path="launchpad" />
              <Billing path="billing">
                <BillingHome path="/" />
                <Plan path="plan" />
                <InvoicesRoute path="/invoices" />
                <InvoiceRoute path="/invoices/:invoiceId" />
                {/* <CancelRoute path="cancel" /> */}
                <Cancel path="cancel">
                  <CancelOverview path="/" />
                  <CancelConfirm path="confirm" />
                </Cancel>
                <CancelAddon path="addon/:id/cancel" />
              </Billing>
              <ProductActivation path="activate/:productKey" />
              <ExternalLogin path="external_login/:productKey" />
              <Settings path="settings">
                <SettingsProfile path="/" />
                <SettingsPassword path="password" />
                <SettingsMls path="mls" />
              </Settings>
              <Logout path="logout" />
            </AuthedRoutesWithNav>
            <AuthedRoutesWithoutNav path="/guestpass">
              <GuestpassBilling path="/billing" />
              <GuestpassComplete path="/complete" />
              <GuestpassRedeem path="/redeem" />
            </AuthedRoutesWithoutNav>
          </Router>
        </HelixThemeProvider>
      </WindowWidthProvider>
    </ThemeProvider>
  );
};

export default App;
