import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { Link, Redirect } from "@reach/router";
import { Flex } from "reflexbox";
import { GET_USER_BILLING } from "../../../api/queries/user";
import PageHeading from "../PageHeading";
import Text from "../../common/Text";
import Button from "../../common/Button";
import IconCancel from "../icons/Cancel";
import IconLeftArrow from "../icons/LeftArrow";
import {
  CancelContainer,
  PlanContainer,
  LeftArrowLayout,
  BackLink,
  Heading,
  Description,
  AddonContainer,
  Addon,
  CancelButton,
  ModalOverlay,
  ModalContent,
  ButtonGroup
} from "./styled/cancel-addon";
import Loading from "../../common/Loading";

function CancelAddon({ id }) {
  const [
    {
      sku: { name },
      nextBillingDate
    },
    setAddOn
  ] = useState({ sku: { name: null }, nextBillingDate: undefined });
  const [isCancelModalShowing, showCancelModal] = useState(false);
  const [cancellationSucceeded, setCancellationSucceeded] = useState(false);

  const { loading } = useQuery(GET_ADDON_SUBSCRIPTIONS, {
    context: { useAuthedEndpoint: true },
    onCompleted: (data) => {
      const targetAddOn = data.user.addOns.find((addon) => addon.id === id);
      setAddOn(targetAddOn);
    }
  });

  const [cancelAddon] = useMutation(CANCEL_ADDON, {
    context: { useAuthedEndpoint: true },
    onCompleted: () => setCancellationSucceeded(true),
    update(cache) {
      const cachedQuery = cache.readQuery({ query: GET_USER_BILLING });
      cache.writeQuery({
        query: GET_USER_BILLING,
        data: {
          user: {
            ...cachedQuery.user,
            addOns: cachedQuery.user.addOns.filter((ao) => ao.id !== id)
          }
        }
      });
    }
  });

  function handleCancellation() {
    cancelAddon({ variables: { subscription_id: id } });
  }

  if (cancellationSucceeded || !id) {
    return (
      <Redirect
        noThrow
        from={`/app/billing/addon/${id}/cancel`}
        to="/app/billing"
      />
    );
  }

  if (loading) return <Loading />;

  return (
    <>
      <CancelContainer>
        <PageHeading>Cancel Add-on</PageHeading>
        <PlanContainer>
          <Link to="/app/billing">
            <Flex alignItems="center" ml="-10px">
              <LeftArrowLayout>
                <IconLeftArrow />
              </LeftArrowLayout>
              <BackLink>Back to My Account</BackLink>
            </Flex>
          </Link>
          <Flex m="20px auto">
            <Heading level="3">Are you sure you want to cancel?</Heading>
          </Flex>
          <Description as="h5">
            You'll lose access to the following tools{" "}
            <strong>immediately</strong>:
          </Description>
          <AddonContainer>
            <Addon>
              <IconCancel />
              <Text variant="displayExtraSmall">{name}</Text>
            </Addon>
          </AddonContainer>
          <CancelButton
            app="attract"
            variant="primary"
            onClick={() => showCancelModal(true)}>
            Cancel Add-on
          </CancelButton>
        </PlanContainer>
      </CancelContainer>
      {isCancelModalShowing && (
        <ModalOverlay isOpen={isCancelModalShowing}>
          <ModalContent>
            <h3>Cancel Add-on</h3>
            <p>
              By cancelling this addon, you'll lose the following tools{" "}
              <strong>immediately</strong>.
            </p>
            <p>This action cannot be undone.</p>
            <ButtonGroup>
              <Button
                variant="secondary"
                onClick={() => showCancelModal(false)}>
                Go Back
              </Button>
              <Button app="attract" onClick={handleCancellation}>
                Delete Add-on
              </Button>
            </ButtonGroup>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
}

CancelAddon.propTypes = {
  id: PropTypes.string
};

export const GET_ADDON_SUBSCRIPTIONS = gql`
  query getAddOnSubscriptions {
    user {
      id
      addOns: add_on_subscriptions {
        id
        balance
        canCancel: can_cancel
        nextBillingDate: next_bill_date
        sku {
          id
          key
          name
        }
      }
    }
  }
`;

export const CANCEL_ADDON = gql`
  mutation ($subscription_id: ID!) {
    cancelAddOnSubscription(subscription_id: $subscription_id) {
      id
    }
  }
`;

export default CancelAddon;
