import React from "react";
import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 216px;
  background-color: white;
  border: 1px solid #e1e5e9;
  border-radius: 4px;
  margin: 0 auto 60px;
  text-align: center;

  font-size: 20px;
  margin: 0 auto 196px;
`;
