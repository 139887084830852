import React from "react";
import { useLaunchpad } from "./LaunchpadProvider";
import LaunchpadProduct from "./LaunchpadProduct";
import { LaunchpadProductsLayout } from "./styled/launchpad-products";

function LaunchpadProducts() {
  const { user } = useLaunchpad();
  const isSubscribedToAttractOnly = user.mls === null;
  return (
    <LaunchpadProductsLayout>
      {isSubscribedToAttractOnly ? (
        <LaunchpadProduct productKey="cloud_attract" />
      ) : (
        <>
          <LaunchpadProduct productKey="cloud_cma" />
          <LaunchpadProduct productKey="cloud_streams" />
          <LaunchpadProduct productKey="cloud_mlx" />
          <LaunchpadProduct productKey="cloud_attract" />
        </>
      )}
    </LaunchpadProductsLayout>
  );
}

export default LaunchpadProducts;
