import { useMutation } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import React from "react";
import homebeat from "../../../assets/images/homebeat-tiny.svg";
import plus from "../../../assets/images/plus.svg";
import { GET_AUTHED_USER } from "../../api/queries/user";
import Button from "../common/Button";
import GuestpassProduct from "./GuestpassProduct";
import { CHANGE_USER_PLAN, useGuestpass } from "./GuestpassProvider";
import {
  AddonName,
  AddonTagline,
  Alert,
  ButtonLayout,
  GuestpassProductAddon,
  GuestpassProductsLayout,
  HomebeatIcon,
  PlusIcon
} from "./styled/guestpass-products";

export default function GuestpassProducts({ guestPass }) {
  const { user } = useGuestpass();
  const hasCMA = user.mls.products.some((p) => p.key == "cloud_cma");
  const hasHomebeat = user.mls.hasHomebeat;
  const hasMLX = user.mls.products.some((p) => p.key == "cloud_mlx");
  const hasStreams = user.mls.products.some((p) => p.key == "cloud_streams");
  const hasAttract = user.mls.products.some((p) => p.key == "cloud_attract");
  const [changePlan, { error, loading }] = useMutation(CHANGE_USER_PLAN, {
    context: { useAuthedEndpoint: true },
    refetchQueries: [
      {
        query: GET_AUTHED_USER,
        context: { useAuthedEndpoint: true }
      }
    ],
    onCompleted: () => {
      navigate("/app/launchpad");
    }
  });

  return (
    <GuestpassProductsLayout>
      <>
        {!!hasCMA && <GuestpassProduct productKey="cloud_cma" />}
        {!!hasHomebeat && (
          <GuestpassProductAddon>
            <PlusIcon src={plus} alt="Plus" />
            <HomebeatIcon src={homebeat} alt="Homebeat" />
            <AddonName productKey={"homebeat"}>
              Homebeat <AddonTagline>Automatic CMAs.</AddonTagline>
            </AddonName>
          </GuestpassProductAddon>
        )}
        {!!hasStreams && <GuestpassProduct productKey="cloud_streams" />}
        {!!hasAttract && <GuestpassProduct productKey="cloud_attract" />}
        {!!hasMLX && <GuestpassProduct productKey="cloud_mlx" />}
      </>
      <ButtonLayout>
        <Button
          fullwidth
          onClick={() =>
            changePlan({
              variables: {
                sku: {
                  id: guestPass.skuable.id,
                  type: guestPass.skuable.type
                },
                guestPassId: guestPass.id,
                source: "change_plan"
              }
            })
          }
          loading={loading}
          disabled={loading || !guestPass}>
          Activate Guest Pass
        </Button>
      </ButtonLayout>
      {error && (
        <Alert role="alert" aria-live="polite">
          {error.message}
        </Alert>
      )}
    </GuestpassProductsLayout>
  );
}
