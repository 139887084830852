import React from "react";

const IconPrint = (props) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path
      d="M85.85 30h-70.7C6.767 30 0 36.803 0 45.23v30.462h20.2V96h60.6V75.692H101V45.231C101 36.803 94.233 30 85.85 30zM71 86H31V61h40v25zm15-36c-2.75 0-5-2.25-5-5s2.25-5 5-5 5 2.25 5 5-2.25 5-5 5zM81 5H20v20h61V5z"
      fillRule="nonzero"
    />
  </svg>
);

export default IconPrint;
