import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${({ isCombo }) =>
    isCombo &&
    css`
      @media (max-width: 500px) {
        justify-content: center;
      }
    `}
`;

export const ProductPlusAddon = styled.div``;

export const Product = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const IconCombo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  background-color: ${({ theme }) => theme.colors.gray[300]};
  height: 60px;
  border-radius: 56px;
  box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.gray[500]} inset;

  svg {
    width: 52px;
    height: 52px;
  }
`;

export const ProductIcon = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  padding: 2px;
  border: 2px solid transparent;

  svg {
    width: 52px;
    height: 52px;
    fill: white;
  }
`;

export const Plus = styled.span`
  margin: 0 auto;
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.colors.gray[600]};
  }
`;

export const ProductNames = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 140px;
  height: 20px;

  span {
    position: absolute;

    &:first-of-type {
      left: 12px;
    }

    &:last-of-type {
      right: -2px;
    }
  }
`;

export const ProductName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 2px;
  color: ${({ productKey, theme }) =>
    ({
      cloud_cma: theme.colors.cma[500],
      cloud_streams: theme.colors.streams[500],
      cloud_mlx: theme.colors.mlx[500],
      cloud_attract: theme.colors.attract[500],
      homebeat: theme.colors.suite[900]
    }[productKey] || theme.colors.suite[900])};
`;
