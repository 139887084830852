import React, { useState } from "react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { flatten } from "lodash";
import TextField from "../../common/TextField";
import Button from "../../common/Button";
import ErrorOutline from "../../icons/ErrorOutline";
import { Error, Success } from "./styled/settings";
import { isEmployeeEmail } from "../../../utils/user";
import { translate } from "../../../utils/locale";

const GET_MLSES = gql`
  query GetMlses {
    mls {
      name
      state
      code
    }
  }
`;

const GET_USER = gql`
  query GetUser {
    user {
      id
      email
      state
      mlsCredential: mls_credential {
        name
        code
        active
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($user: UserRegistrationInput!) {
    updateUser(user: $user) {
      id
      mlsCredential: mls_credential {
        name
        code
        active
      }
    }
  }
`;

function SettingsMls() {
  const [email, setEmail] = useState("");
  const [mlsState, setMlsState] = useState("");
  const [mlsName, setMlsName] = useState("");
  const [mlsUserId, setMlsUserId] = useState("");
  const [mlsCredentialInactive, setMlsCredentialInactive] = useState(false);
  const [mlsesByState, setMlsesByState] = useState({});
  const [statesByMls, setStatesByMls] = useState({});
  const [updated, setUpdated] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const [updateUser, { loading, error }] = useMutation(UPDATE_USER, {
    context: { useAuthedEndpoint: true },
    onCompleted: ({ updateUser }) => {
      setUpdated(true);
      setMlsCredentialInactive(!updateUser.mlsCredential.active);
    }
  });
  const isDisabled = !mlsName || !mlsUserId || loading;

  useQuery(GET_USER, {
    context: { useAuthedEndpoint: true },
    onCompleted: ({ user }) => {
      const mlsCredential = user.mlsCredential || {};
      setEmail(user.email || "");
      setMlsName(mlsCredential.code || "");
      setMlsUserId(mlsCredential.name || "");
      setCanUpdate(
        !mlsCredential.code ||
          !mlsCredential.name ||
          isEmployeeEmail(user.email)
      );
      if (Object.keys(statesByMls).length) {
        setMlsState(statesByMls[mlsCredential.code] || "");
      }

      setMlsCredentialInactive(!mlsCredential.active);
    }
  });

  useQuery(GET_MLSES, {
    onCompleted: ({ mls }) => {
      const mlsesByState = mls.reduce((byState, mls) => {
        mls.state.split(",").forEach((state) => {
          byState[state] = byState[state]
            ? [...byState[state], { code: mls.code, name: mls.name }]
            : [{ code: mls.code, name: mls.name }];
        });
        return byState;
      }, {});

      const statesByMls = mls.reduce(
        (byMls, mls) => ({ ...byMls, [mls.code]: mls.state }),
        {}
      );

      setMlsesByState(mlsesByState);
      setStatesByMls(statesByMls);

      if (mlsName) {
        setMlsState(statesByMls[mlsName] || "");
      }
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser({
      variables: {
        user: {
          email,
          mls_credential_attributes: {
            code: mlsName,
            name: mlsUserId
          }
        }
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField>
        <label htmlFor="state">State or Province</label>
        <select
          disabled={!canUpdate}
          id="state"
          value={mlsState}
          onChange={(e) => setMlsState(e.target.value)}>
          <option value="">Select a State</option>
          {Object.keys(mlsesByState)
            .sort()
            .map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
        </select>
      </TextField>
      <TextField>
        <label htmlFor="mls-name">
          {" "}
          <span
            dangerouslySetInnerHTML={{
              __html: translate("mls.label", "MLS").replace("®", "<sup>®</sup>")
            }}
          />{" "}
          Name
        </label>
        <select
          disabled={!canUpdate}
          id="mls-name"
          value={mlsName}
          onChange={(e) => setMlsName(e.target.value)}>
          <option value="">Select an {translate("mls.label", "MLS")}</option>
          {(mlsesByState[mlsState] || flatten(Object.values(mlsesByState)))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((mls, index) => (
              <option key={index} value={mls.code}>
                {mls.name}
              </option>
            ))}
        </select>
      </TextField>
      <TextField>
        <label htmlFor="mls-user-id">
          {" "}
          <span
            dangerouslySetInnerHTML={{
              __html: translate("mls.label", "MLS").replace("®", "<sup>®</sup>")
            }}
          />{" "}
          User ID
        </label>
        <input
          id="mls-user-id"
          value={mlsUserId}
          onChange={(e) => setMlsUserId(e.target.value)}
        />
      </TextField>
      <Button fullwidth disabled={isDisabled} loading={loading}>
        Update&nbsp;
        <span
          dangerouslySetInnerHTML={{
            __html: translate("mls.label", "MLS").replace("®", "<sup>®</sup>")
          }}
        />
        &nbsp; Credentials
      </Button>
      {error && (
        <Error>
          <ErrorOutline />
          {error.message}
        </Error>
      )}
      {mlsCredentialInactive && (
        <Error>
          <ErrorOutline />
          Your credentials are invalid, please update your information.
        </Error>
      )}
      {updated && (
        <Success>
          Updated{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: translate("mls.label", "MLS").replace("®", "<sup>®</sup>")
            }}
          />{" "}
          Credentials!
        </Success>
      )}
    </form>
  );
}

export default SettingsMls;
