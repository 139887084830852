import React, { useMemo, useRef, useEffect, useLayoutEffect } from "react";
import { useMachine } from "@xstate/react";
import mlsComboboxMachine from "../machines/mls-combobox";
import { scrollIntoView, useOutsideClick } from "../utils/dom";
import MlsNotFoundOption from "./MlsNotFoundOption";
import { useSubscriptionWorkflow } from "./SubscriptionWorkflow";
import {
  DropdownWrapper,
  DropdownInput,
  DropdownList,
  DropdownItem
} from "./styled/combobox";
import { translate } from "../utils/locale";

function MlsCombobox() {
  const highlightedItemRef = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const listRef = useRef(null);

  const {
    state: subscriptionWorkflowState,
    send: sendToSubscriptionWorkflow
  } = useSubscriptionWorkflow();

  const {
    selectedTerritory,
    selectedMls,
    mls,
    nullMls
  } = subscriptionWorkflowState.context;
  const mlses = useMemo(
    () =>
      mls
        .filter((m) => m.state.includes(selectedTerritory) && !m.isChild)
        .sort((a, b) => (a.name < b.name ? -1 : 1)),
    [selectedTerritory]
  );

  const stateMachine = useMemo(() => {
    return mlsComboboxMachine
      .withContext({
        ...mlsComboboxMachine.context,
        selected: selectedMls || mlsComboboxMachine.context.selected,
        input: selectedMls.name || "",
        noMlsOption: nullMls
      })
      .withConfig({
        actions: {
          onSelect: ({ selected }) =>
            sendToSubscriptionWorkflow({
              type: "SELECT_MLS",
              selectedMls: selected
            })
        }
      });
  }, []);
  const [state, send] = useMachine(stateMachine);

  useEffect(() => {
    // The useEffect hook below will toggle initalized to true,
    // allowing subsequent changes to selectedTerritory to trigger a refresh of the mlses
    if (state.context.initialized) {
      send({ type: "SET_MLSES", mlses });
    }
  }, [selectedTerritory]);

  useEffect(() => {
    // ensures the above useEffect hook won't fire on first load
    send("INIT");
  }, []);

  useLayoutEffect(() => {
    if (state.value === "closed" && state.context.wasClosedInternally) {
      buttonRef.current.focus();
    }
    if (state.value === "opened") {
      inputRef.current.focus();
    }
  }, [state.value]);

  useLayoutEffect(() => {
    if (!highlightedItemRef.current) return;
    scrollIntoView(highlightedItemRef.current, listRef.current);
  }, [highlightedItemRef.current]);

  useOutsideClick(
    containerRef,
    () => send("CLOSE_DROPDOWN"),
    state.matches("opened")
  );

  function handleKeyDown(e) {
    if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Enter") {
      e.preventDefault();
    }
    send(`KEYED_${e.key.toUpperCase()}`, { originalEvent: e });
  }

  return (
    <DropdownWrapper ref={containerRef} onKeyDown={handleKeyDown}>
      {state.matches("opened") ? (
        <DropdownInput
          ref={inputRef}
          role="combobox"
          id="mls-combobox"
          aria-autocomplete="list"
          aria-expanded={state.matches("opened")}
          aria-haspopup="true"
          aria-owns="territory-list"
          aria-activedescendant={state.context.selected}
          onChange={({ target }) =>
            send({ type: "INPUT_CHANGED", value: target.value.trim() })
          }
          disabled={state.matches("closed")}
          isOpen={state.matches("opened")}
          value={state.context.input}
          data-testid="mls-input"
        />
      ) : (
        <DropdownInput
          type="button"
          ref={buttonRef}
          role="combobox"
          id="mls-combobox"
          data-testid="mls-button"
          aria-label={`Choose ${translate("mls.label", "MLS")} Name`}
          onClick={() => send("OPEN_DROPDOWN")}
          isOpen={state.matches("opened")}
          value={
            state.context.selected.name ||
            `Choose ${translate("mls.label", "MLS")} Name`
          }
          disabled={!selectedTerritory}
        />
      )}
      {state.matches("opened") && (
        <DropdownList id="territory-list" ref={listRef}>
          {state.context.filtered.map((mls, i) =>
            mls.code === state.context.noMlsOption.code ? (
              <MlsNotFoundOption
                key={mls.code}
                ref={state.context.index === i ? highlightedItemRef : null}
                isHighlighted={state.context.index === i}
                onClick={() =>
                  send({ type: "SELECT", mls: state.context.noMlsOption })
                }
                onMouseEnter={() => send({ type: "SET_INDEX", index: i })}
              />
            ) : (
              <DropdownItem
                key={mls.code}
                data-index={i}
                ref={state.context.index === i ? highlightedItemRef : null}
                isHighlighted={state.context.index === i}
                onClick={() => send({ type: "SELECT", mls })}
                onMouseEnter={() => send({ type: "SET_INDEX", index: i })}>
                {mls.name}
              </DropdownItem>
            )
          )}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
}

export default MlsCombobox;
