import { IS_PROD } from "../constants/config";
import { isBundle } from "../modules/products";

// Fall Promo 2020 Analytics : START
export function sendSignupEventToAnalytics({ sku, revenue, promo = "" }) {
  // Facebook Pixel Tracking with Custom Events
  // https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
  if (IS_PROD && window.fbq) {
    window.fbq(
      "trackCustom",
      Boolean(revenue) ? "checkoutWithCC" : "checkoutWithoutCC",
      {
        revenue,
        sku,
        promo
      }
    );
  }

  // Google Analytics event for checkout events both with and without credit card
  if (IS_PROD && window.ga) {
    window.ga("send", {
      hitType: "event",
      eventCategory: "Signup",
      eventAction: revenue > 0 ? "Checkout With CC" : "Checkout Without CC",
      eventLabel: sku
    });
  }
}
// Fall Promo 2020 Analytics : END

// ! Our use of Google Analytics Ecommerce is inactive, but we're keeping these around in case we want to reinstate it
export function trackMlsCheckoutStep() {
  if (IS_PROD && dataLayer && dataLayer.push) {
    dataLayer.push({
      event: "viewing-mls",
      ecommerce: {
        checkout: {
          actionField: {
            step: 2
          }
        }
      }
    });
  }
}

export function trackPlanCheckoutStep(context) {
  if (IS_PROD && dataLayer && dataLayer.push) {
    dataLayer.push({
      event: "viewing-plans",
      ecommerce: {
        checkout: {
          actionField: {
            step: 3,
            option: context.selectedMls.name
          }
        }
      }
    });
  }
}

export function trackPaymentCheckoutStep(context) {
  if (IS_PROD && dataLayer && dataLayer.push) {
    const { selectedPlan, discounts, promo } = context;
    const discount = discounts.byPlanKey[selectedPlan.key];
    dataLayer.push({
      event: "viewing-billing",
      ecommerce: {
        checkout: {
          actionField: {
            step: 4,
            option: context.selectedPlan.name
          },
          products: [
            {
              name: context.selectedPlan.name,
              id: context.selectedPlan.id,
              price: !!discount
                ? selectedPlan.price - discount.amount
                : selectedPlan.price
            }
          ]
        }
      }
    });
  }
}

export function recordSignup({
  selectedPlan,
  transactionId,
  revenue,
  promoCode,
  jwt
}) {
  if (revenue > 0) {
    // Google Ecommerce Analytics
    dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId, // Transaction ID.  Must be unique.  Is required for purchases and refunds.
            revenue,
            coupon: promoCode
          },
          products: [
            {
              name: selectedPlan.name,
              id: selectedPlan.id,
              price: revenue,
              coupon: promoCode,
              category: isBundle(selectedPlan) ? "Bundle" : "Product"
            }
          ]
        }
      }
    });
    dataLayer.push({
      event: "new-signup",
      ecommerce: {
        checkout: {
          actionField: {
            step: 5
          },
          products: [
            {
              name: selectedPlan.name,
              id: selectedPlan.id,
              price: revenue
            }
          ]
        }
      },
      eventCallback: function () {
        window.location.replace(`${window.location.origin}/app/thanks`);
      }
    });
  } else {
    dataLayer.push({
      event: "new-signup",
      ecommerce: {
        checkout: {
          actionField: {
            step: 5
          },
          products: [
            {
              name: selectedPlan.name,
              id: selectedPlan.id,
              price: revenue
            }
          ]
        }
      },
      eventCallback: function () {
        window.location.replace(`${window.location.origin}/app/thanks`);
      }
    });
  }
}
// ! ----------------- END of Goolge Anayltics Ecommerce functionality
