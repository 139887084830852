import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import casLogo from "../../../assets/images/branding/Cloud_Agent_Suite_horizontal_blue.svg";
import Button from "../common/Button";
import {
  ButtonWrapper,
  Container,
  Description,
  Heading,
  HomeLink,
  Note,
  Title
} from "./styled/guestpass-complete";

export default function GuestpassComplete() {
  const [remainingTime, setRemainingTime] = useState(14);

  useEffect(() => {
    const timer1 = setInterval(() => setRemainingTime(remainingTime - 1), 1000);
    if (remainingTime <= 0) navigate("/app/launchpad");
    return () => {
      clearInterval(timer1);
    };
  }, [remainingTime]);

  return (
    <Container>
      <HomeLink href="/">
        <img src={casLogo} alt="Cloud Agent Suite" />
      </HomeLink>
      <Heading>
        <Title>Woo Hoo 🎉</Title>
        <Description>We’re glad to have you on board the Suite.</Description>
      </Heading>
      <ButtonWrapper>
        <Button size="large" onClick={() => navigate("/app/launchpad")}>
          Go to LaunchPad{" "}
        </Button>
      </ButtonWrapper>
      <Note>
        We’ll be redirecting you to the Launchpad in {remainingTime} seconds...
      </Note>
    </Container>
  );
}
