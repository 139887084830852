import styled from "styled-components";
import { Button, UserAvatar } from "@wrstudios/components";
import theme from "../../../utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  text-align: center;
  overflow: hidden;

  svg {
    width: 6rem;
    height: 6rem;
  }
`;

export const Avatar = styled(UserAvatar)`
  margin: 0 0 2.2rem;
`;

export const Change = styled(Button)`
  margin-bottom: 1.4rem;
  border-color: ${theme.colors.suite[500]};
  color: ${theme.colors.suite[500]};
`;

export const Remove = styled(Button)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: ${theme.colors.suite[500]};
`;

export const Input = styled.input`
  position: absolute;
  left: -9999rem;
  top: 0;
`;

export const Cancel = styled(Button)`
  border-color: ${theme.colors.suite[500]};
  color: ${theme.colors.suite[500]};

  @media (max-width: 578px) {
    width: 100%;
    justify-content: center;
  }
`;

export const Crop = styled(Button)`
  margin-left: 1rem;
  border-color: ${theme.colors.suite[500]};
  background-color: ${theme.colors.suite[500]};

  @media (max-width: 578px) {
    width: 100%;
    justify-content: center;
    margin-left: 0;
  }
`;
