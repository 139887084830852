import { Notifier } from "@airbrake/browser";

export const airbrake = new Notifier({
  projectId: 166113,
  projectKey: "97e68c7a00a606c711e326ec4cfbf79a3a932fe1"
});

airbrake.addFilter(function (notice) {
  if (notice.context.environment !== "production") {
    return null;
  }

  notice.context.environment = process.env.RAILS_ENV;
  return notice;
});

export default airbrake;
