import React from "react";

const CloudMlx = (props) => (
  <svg viewBox="0 0 53.87 53.87" {...props}>
    <circle
      cx={26.94}
      cy={26.94}
      r={26.94}
      transform="rotate(-76.72 26.932 26.936)"
      fill="#3a7abe"
    />
    <path d="M38.75 43.71a2.74 2.74 0 0 1-2-.9L33.1 39a.35.35 0 0 0-.42-.08 15.68 15.68 0 0 1-7.07 1.64A15.05 15.05 0 0 1 15 36.05a14.78 14.78 0 0 1-4.3-10.65 15.07 15.07 0 0 1 15.09-14.8H26a15.12 15.12 0 0 1 10.64 4.52A14.91 14.91 0 0 1 39 32.87a.35.35 0 0 0 .06.42l3.64 3.77a2.86 2.86 0 0 1 .92 2.06A4.39 4.39 0 0 1 42 42a5 5 0 0 1-3.25 1.71zm-5.91-6.57a2.09 2.09 0 0 1 1.52.65L38 41.58c.31.33.92.94 2.76-.84.73-.7 1.08-1.24 1.09-1.64 0-.09 0-.37-.43-.81l-3.64-3.78a2.11 2.11 0 0 1-.33-2.5 13.12 13.12 0 0 0-2.12-15.66 13.39 13.39 0 0 0-22.92 9.07 13.06 13.06 0 0 0 3.79 9.4 13.47 13.47 0 0 0 15.65 2.54 2.17 2.17 0 0 1 .99-.22z" />
    <path d="M32.16 29.71H30.4V24.9l-4.6-3.54-4.55 3.31v4.87h-1.76v-5.32a.9.9 0 0 1 .36-.71l5.45-4a.87.87 0 0 1 1.05 0l5.47 4.21a.91.91 0 0 1 .34.7z" />
  </svg>
);

export default CloudMlx;
