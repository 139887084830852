import { transformProducts } from "./products";
import format from "date-fns/format";

const SUBSCRIPTION_STATUS_ENUM = Object.freeze({
  ACTIVE: "active",
  PAST_DUE: "pastDue",
  TRIAL: "pending",
  CANCELED: "canceled"
});

export function transformSubscription(subscription) {
  const { productsByKey, onboardingItemsByKey, addons } = transformProducts(
    subscription.products
  );
  const transactions = transformTransactions(subscription.transactions || []);

  return {
    ...subscription,
    transactions,
    canCancel: subscription.can_cancel,
    isActive: subscription.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE,
    isPastDue: subscription.status === SUBSCRIPTION_STATUS_ENUM.PAST_DUE,
    isTrial: subscription.status === SUBSCRIPTION_STATUS_ENUM.TRIAL,
    isCancelled: subscription.status === SUBSCRIPTION_STATUS_ENUM.CANCELED,
    onboardingItemsByKey,
    productsByKey,
    addons
  };
}

export function transformTransactions(transactions) {
  return transactions.map((transaction) => ({
    id: transaction.id || null,
    amount: transaction.amount || 0,
    skuName: (transaction.sku || {}).name || "",
    skuType: (transaction.sku || {}).type || "",
    formattedAmount:
      transaction.amount < 0
        ? `($${Math.abs(transaction.amount)})`
        : `$${transaction.amount}`,
    paidOn: format(transaction.created_at, "MMMM D, YYYY")
  }));
}
