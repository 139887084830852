import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const WorkflowSteps = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.raised};
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  margin: 0 auto 196px;
  animation: ${fadeIn} 200ms linear;
`;
