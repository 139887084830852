import { Machine, assign } from "xstate";
import {
  hasSuccessfullyProvisionedProduct,
  checkUserProductStatus,
  provisionProduct
} from "../api/user";

// State Machine for product provisioning process
export default Machine({
  initial: "unknown",
  context: {
    apiClient: null,
    productId: null,
    productKey: null,
    canAddToPlan: null,
    canStartTrial: null,
    isUnavailable: null,
    isProvisioned: null,
    connectPath: null,
    userExists: null
  },
  states: {
    unknown: {
      on: {
        "": [
          {
            target: "unavailable",
            cond: (ctx) => ctx.isUnavailable
          },
          { target: "tryForFree", cond: (ctx) => ctx.canTryForFree },
          { target: "settingUp", cond: (ctx) => ctx.isProvisioning },
          { target: "complete", cond: (ctx) => ctx.isProvisioned },
          { target: "notStarted" }
        ]
      }
    },
    unavailable: {
      type: "final"
    },
    tryForFree: {
      type: "final"
    },
    notStarted: {
      on: { START_SETUP: "checkingStatus" }
    },
    checkingStatus: {
      invoke: {
        src: "checkUserProductStatus",
        onDone: {
          target: "routeAccountStatus",
          actions: assign((ctx, event) => ({ ...ctx, ...event.data }))
        },
        onError: {
          target: "unknown",
          actions: assign({ error: (ctx, event) => event.data })
        }
      }
    },
    routeAccountStatus: {
      on: {
        "": [
          {
            target: "noMatchingAccount",
            cond: (ctx) => !ctx.userExists
          },
          { target: "foundMatchingAccount", cond: (ctx) => ctx.userExists }
        ]
      }
    },
    foundMatchingAccount: {
      on: { CANCEL_SETUP: "unknown" }
    },
    noMatchingAccount: {
      on: {
        CANCEL_SETUP: "unknown",
        PROVISION_PRODUCT: "provisioningProduct"
      }
    },
    provisioningProduct: {
      invoke: {
        src: "provisionProduct",
        onDone: { target: "settingUp" },
        onError: {}
      }
    },
    settingUp: {
      invoke: { src: "pollForProvisioningStatus" },
      on: {
        PROVISIONING_COMPLETE: "complete"
      }
    },
    complete: { type: "final" }
  }
});

export const machineConfig = {
  services: {
    checkUserProductStatus,
    provisionProduct,
    pollForProvisioningStatus: (ctx) => (callback) => {
      const interval = setInterval(async () => {
        if (await hasSuccessfullyProvisionedProduct(ctx)) {
          await ctx.refetchUserAndSubscription();
          callback("PROVISIONING_COMPLETE");
        }
      }, 3000);

      return () => clearInterval(interval);
    }
  }
};
