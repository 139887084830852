import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_PLANS_FOR_SELECTED_MLS = gql`
  query getPlansForSelectedMls(
    $mlsCode: String
    $mlsId: ID
    $promoCode: String
  ) {
    mls(code: $mlsCode, id: $mlsId) {
      id
      code
      name
      state
      territory: territory_code
      hasHomebeat: has_homebeat
      displayUpsellOptions: display_upsell_options
      products {
        key
      }
      bundles {
        id
        key
        name
        tagline
        price
        virtual
        groupKey: group_key
        billingCycle: billing_cycle
        products {
          key
          name
        }
      }
    }
    discounts(code: $promoCode) {
      id
      code
      amount
      bundles {
        key
        groupKey: group_key
        billingCycle: billing_cycle
      }
      addOns: add_ons {
        key
      }
    }
    products(show_all: "true") {
      id
      key
      name
      price
      value
      tagline
    }
    addOns: add_ons {
      id
      key
      name
      tagline
      price
      billingCycle: billing_cycle
    }
  }
`;

export default function usePlansForMls(
  { mlsCode, promoCode },
  queryConfig = {}
) {
  return useQuery(GET_PLANS_FOR_SELECTED_MLS, {
    variables: {
      mlsCode,
      promoCode
    },
    ...queryConfig
  });
}
