import styled from "styled-components";
import theme from "../../utils/theme";
import BaseText from "../common/Text";

export const StepContentsLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
`;

export const FieldsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: calc(616px + 24px);
  margin: 0 -24px;
  padding-bottom: 12px;
  box-sizing: border-box;
  overflow: visible;

  @media (max-width: 512px) {
    flex-direction: column;
    max-width: 100%;
  }
`;

export const FieldHeaderLayout = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const FieldValidation = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.accent.red};
`;

export const SectionHeading = styled(BaseText)`
  margin-bottom: 12px;
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 auto;
  max-width: 320px;
  padding: 12px;

  @media (max-width: 512px) {
    max-width: 100%;
  }
`;

export const YourMLS = styled(ContactInfo)``;

export const LoginOptions = styled.span`
  margin-top: 10px;
  font-size: 13px;
  line-height: 18px;
  color: ${theme.colors.gray[700]};
  strong {
    color: ${theme.colors.gray[800]};
  }
  a {
    color: ${theme.colors.suite[500]};
    text-decoration: underline;
    &:focus {
      outline: 2px solid ${theme.colors.accent.purple};
    }
  }
`;

export const LoginOptionsLayout = styled.div`
  padding-top: 8px;
`;

// Used for Attract-Only option (which we aren't showing with 2020 pricing updates)
export const ViewNonMemberPlansButton = styled.button`
  margin-top: 8px;
  padding: 0;
  background-color: transparent !important;
  border: none;
  font-size: 14px;
  color: ${theme.colors.suite[500]};
  letter-spacing: 0;
  text-decoration: underline;
  transition: opacity 200ms;

  &:focus {
    outline: none;
    text-decoration: none;
    box-shadow: 0 0 0 1px ${theme.colors.suite[500]},
      0 0 0 3px ${theme.colors.suite[300]};
    border-radius: 2px;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
